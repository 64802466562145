import {Dialog, DialogContent, FormControl, TextField} from "@mui/material";
import {default as React, useContext, useEffect, useState} from "react";
import {UserType} from "../../common/APIRequests/user/getUsers";
import patchUser from "../../common/APIRequests/user/patchUser";
import {errorType} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import {LoadingIndicator, Spacer, Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import StringField from "../common/formFields/StringField";
import {Description} from "../common/Descriptions";
import getUser from "../../common/APIRequests/user/getUser";

const UserForm = (props: {
  handleClose: () => void;
  update: () => void;
  user: UserType;
}) => {
  const {handleClose, update, user} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [values, setValues] = useState(user);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({} as errorType);

  const [userProjects, setUserProjects] = useState<string[]>();
  const [loadingProjects, setLoadingProjects] = useState(false);

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
  };

  const handleSubmit = async () => {
    if (Object.keys(errors).length || loading) {
      return;
    }

    setLoading(true);
    const result = await patchUser(user.id_user, values);
    setLoading(false);

    if (result.success) {
      setErrors({});
      handleClose();
      setAlert({
        severity: "success",
        message: ["Описание изменено"],
      });
      update();
    } else {
      setErrors(result.errors);
    }
  };

  useEffect(() => {
    setLoadingProjects(true);
    getUser(props.user.id_user)
      .then(
        (result) =>
          result.success && setUserProjects(result.data?.projects?.split(","))
      )
      .finally(() => setLoadingProjects(false));
  }, []);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      onClose={() => !loading && handleClose()}
      maxWidth="sm"
      fullWidth
    >
      <ClosableDialogTitle onClose={() => !loading && handleClose()}>
        Информация о пользователе {user.display_name}
      </ClosableDialogTitle>
      <DialogContent id="users_form">
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            fullWidth
            label="Логин"
            placeholder="Логин"
            value={values.ldap_login}
            disabled
          />
        </FormControl>
        <StringField
          setValue={handleChange("comment_text")}
          value={values.comment_text || ""}
          name="comment_text"
          label="Описание"
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 1000}}
          multiline
          style={{marginTop: theme.spacing(2)}}
        />

        <Spacer height={30} />
        <Description title="Проекты" value={userProjects?.join?.(", ")} />
        {loadingProjects && (
          <LoadingIndicator styleProps={{position: "relative"}} />
        )}
        <FormButtons
          loading={loading}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          submitMessage="Сохранить"
        />
      </DialogContent>
    </Dialog>
  );
};

export default UserForm;
