import React, {useContext, useState} from "react";
import {Box} from "@mui/material";
import {BottomToolbarWrapper, ToolbarItem, ToolbarSeparator} from "../Toolbar";
import {AppContext} from "../../App-context";
import RequestPinDialog from "../pin/RequestPinDialog";
import {PrimaryButton} from "./PrimaryButton";
import {SecondaryButton} from "./SecondaryButton";
import {ButtonLoadingIndicator} from "../components";

const FormButtons = (props: {
  submitMessage?: string;
  cancelMessage?: string;
  loading?: boolean;
  disabled?: boolean;
  handleSubmit: () => void;
  handleClose?: () => void;
  children?: React.ReactNode;
  checkPin?: boolean;
  submitId?: string;
  closeId?: string;
  alignChildrenLeft?: boolean;
}) => {
  const {
    loading,
    disabled,
    submitMessage,
    cancelMessage,
    handleSubmit,
    handleClose,
    children,
    checkPin,
    submitId,
    closeId,
    alignChildrenLeft,
  } = props;

  const appContext = useContext(AppContext);
  const {pinStatus} = appContext;

  const [pinOpen, setPinOpen] = useState(false);

  const submit = async () => {
    if (!loading && checkPin && pinStatus) {
      setPinOpen(true);
    } else if (!loading) {
      await handleSubmit();
    }
  };

  return (
    <React.Fragment>
      {pinOpen && checkPin && (
        <RequestPinDialog
          onClose={() => setPinOpen(false)}
          onSubmit={handleSubmit}
        />
      )}

      <BottomToolbarWrapper>
        {children}
        {!alignChildrenLeft && <ToolbarSeparator />}
        <ToolbarItem>
          <PrimaryButton
            id={submitId ? submitId : "submit_form_button"}
            disabled={disabled || loading}
            onClick={submit}
          >
            {submitMessage || "Сохранить"}
            {loading && <ButtonLoadingIndicator />}
          </PrimaryButton>
        </ToolbarItem>
        {handleClose && (
          <Box>
            <SecondaryButton
              title={cancelMessage || "Отменить"}
              id={closeId ? closeId : "close_form_button"}
              fullWidth
              disabled={loading}
              onClick={handleClose}
            />
          </Box>
        )}
      </BottomToolbarWrapper>
    </React.Fragment>
  );
};

export default FormButtons;
