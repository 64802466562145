import React, {useEffect, useState} from "react";
import {transliterate} from "transliteration";
import getReportColumnUser, {
  UserReportColumn,
} from "../../common/APIRequests/reportColumnUser/getReportColumnsUser";
import downloadReport from "../../common/APIRequests/user_report/downloadReport";
import {UserReport} from "../../common/APIRequests/user_report/getUserReports";
import {errorType} from "../../common/types";
import {downloadFile} from "../../common/utils/files";
import FormButtons from "../common/buttons/FormButtons";
import SelectSingleField from "../common/formFields/SelectSingleField";
import {ColumnPropsForDownloadType} from "../common/buttons/DownloadMenuButton";
import {FormHelperText} from "@mui/material";

const fileOptions = [
  {
    key: "csv",
    value: "csv",
    text: "CSV",
  },
  {
    key: "xlsx",
    value: "xlsx",
    text: "XLSX",
  },
];

type Props = {
  userReport: UserReport;
};
const UserReportFile = (props: Props) => {
  const {userReport} = props;

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState({
    file_type: "csv",
  });

  const [fieldPropsForDownload, setFieldPropsForDownload] = useState(
    [] as ColumnPropsForDownloadType
  );

  const updateFieldPropsForDownload = () => {
    setLoading(true);

    getReportColumnUser(userReport.id_user_report)
      .then((result) => {
        if (result.success) {
          const field_result = result.rows.map((rCu: UserReportColumn) => ({
            name: rCu.system_name,
            title: rCu.name_column,
            width: 35,
          })) as ColumnPropsForDownloadType;

          setFieldPropsForDownload(field_result);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(updateFieldPropsForDownload, []);

  const handleDownload = async () => {
    if (fieldPropsForDownload) {
      setLoading(true);
      const result = await downloadReport(
        userReport.id_user_report,
        values.file_type,
        `Отчет ${userReport.name_report}`,
        fieldPropsForDownload
      );
      setLoading(false);
      if (result.success) {
        downloadFile(
          result.file,
          `report_${transliterate(userReport.name_report)}.${values.file_type}`
        );
      }
    }
  };

  return (
    <React.Fragment>
      <SelectSingleField
        options={fileOptions}
        setValue={(value: string) => {
          setValues({...values, file_type: value});
        }}
        value={values.file_type}
        label="Формат"
        errors={errors}
        setErrors={setErrors}
        name="file_type"
        disabled={!fieldPropsForDownload.length}
      />
      {fieldPropsForDownload.length === 0 && !loading && (
        <FormHelperText error id={`component-error-user-report-file`}>
          Не добавлены поля для формирования отчета
        </FormHelperText>
      )}
      <FormButtons
        loading={loading}
        handleSubmit={handleDownload}
        handleClose={undefined}
        submitMessage="Скачать"
        disabled={!fieldPropsForDownload.length}
      />
    </React.Fragment>
  );
};

export default UserReportFile;
