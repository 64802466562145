import {columnType} from "../types";

export const downloadFile = (data: any, fileName: string) => {
  let file = new Blob([data], {type: "text/plain;charset=utf-8"}),
    a = document.createElement("a"),
    url = URL.createObjectURL(file);
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

export function exportToCSV<T>(
  rows: T[],
  columns: columnType = [],
  filename: string = "export"
) {
  if (!rows || !rows.length) {
    return;
  }
  const separator = ",";
  let keys: string[];
  let headers: string[];
  if (columns.length > 0) {
    keys = columns.map((col) => col.name);
    headers = columns.map((col) => col.title);
  } else {
    keys = headers = Object.keys(rows[0]);
  }

  const csvContent =
    headers.join(separator) +
    "\n" +
    rows
      .map((row: any) =>
        keys
          .map((k) => {
            let cell = !row[k] ? "" : row[k];
            cell = cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator)
      )
      .join("\n");

  downloadFile(csvContent, `${filename}.csv`);
}
