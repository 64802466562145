import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type UserReportColumnType = {
  id_report_column_list: number;
  name_column: string;
  system_name: string;
};

const getReportColumnList = async (filters: {report_type: number | null}) =>
  baseGetRowsRequest<UserReportColumnType>("/report_column_list/", {filters});

export default getReportColumnList;
