import {Grid} from "@mui/material";
import React, {useState} from "react";
import {ApplicationType} from "../../common/APIRequests/application/getApplications";
import ApplicationVersionsTable from "./ApplicationVersionsTable";
import ApplicationTable from "./ApplicationsTable";
import {useTrigger} from "../../common/customHooks";

const ApplicationsPage = () => {
  const [selectedApplication, setSelectedApplication] = useState(
    undefined as undefined | ApplicationType
  );

  const [trigger, refresh] = useTrigger();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <ApplicationTable
          onAppSelect={setSelectedApplication}
          appSelected={selectedApplication}
          refreshTrigger={trigger}
        />
      </Grid>
      <Grid item xs={6}>
        {selectedApplication && (
          <ApplicationVersionsTable
            application={selectedApplication}
            onChange={refresh}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default ApplicationsPage;
