import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

export type BasePostResponse<T> = Promise<{
  success: boolean;
  row: T | undefined;
  errors: errorType;
}>;

export function basePostRequest<T>(
  url: string,
  data?: any
): BasePostResponse<T> {
  return new Promise((resolve) =>
    apiClient({
      method: "post",
      url: url,
      data: data,
    })
      .then((response) =>
        resolve({
          success: true,
          row: response.data as T,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          row: undefined,
          errors: errors,
        });
      })
  );
}
