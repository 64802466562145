import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import deleteScreenshot from "../../../common/APIRequests/screenshot/deleteScreenshot";
import getScreenshots, {
  screenshotType,
} from "../../../common/APIRequests/screenshot/getScreenshots";
import {useMounted} from "../../../common/customHooks";
import theme from "../../../theme";
import {AppContext} from "../../App-context";
import {ConfirmDialog} from "../../common/buttons/ConfirmDialog";
import {RefreshButton} from "../../common/buttons/ToolbarButtons";
import PickerDate from "../../common/formFields/PickerDate";
import {useGlobalStyles} from "../../common/styles";
import {
  ToolbarItem,
  ToolbarSeparator,
  ToolbarWrapper,
} from "../../common/Toolbar";
import SingleCommand from "../Commands/SingleCommand";
import ScreenshotImage from "./ScreenshotImage";
import {BACKEND_DATE_FORMAT} from "../../../common/types";
import {LoadingIndicator} from "../../common/components";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    position: "relative",
    minHeight: "500px",
    maxHeight: "68vh",
    marginRight: theme.spacing(-3),
    marginBottom: theme.spacing(2),
    overflowY: "auto",
    boxSizing: "border-box",
  },
}));

const Screenshots = (props: {
  id_device: number;
  loading: boolean;
  setLoading: Function;
}) => {
  const {id_device, loading, setLoading} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [rows, setRows] = useState([] as screenshotType[]);

  const [hideFilters, setHideFilters] = useState(true);

  const isMounted = useMounted();

  const updateRows = async () => {
    setLoading(true);
    const result = await getScreenshots(
      id_device,
      date ? moment(date).format(BACKEND_DATE_FORMAT) : undefined
    );
    setLoading(false);
    result.success && setRows(result.rows);

    if (!isMounted && result.rows.length > 0) {
      setHideFilters(false);
    }
  };

  const [date, setDate] = useState(null as string | null);

  useEffect(() => {
    updateRows();
  }, [date]);

  const [selected, setSelected] = useState(
    undefined as screenshotType | undefined
  );

  const handleDelete = async () => {
    if (!selected) {
      return;
    }

    setLoading(true);
    const result = await deleteScreenshot(id_device, selected.id_screenshot);
    setLoading(false);

    if (result.success) {
      setSelected(undefined);
      setAlert({
        severity: "success",
        message: ["Скриншот удален"],
      });
      await updateRows();
    }
  };

  const classes = useGlobalStyles();
  const {classes: localClasses} = useStyles();

  return (
    <React.Fragment>
      <ToolbarWrapper>
        <ToolbarItem id="screenshot_request">
          <SingleCommand
            selection={[id_device]}
            command="SCREENSHOT"
            dialogTitle="Запросить скриншот устройства?"
            buttonTitle="Запрос скриншота"
          />
        </ToolbarItem>
        <ToolbarItem>
          <RefreshButton
            disabled={loading}
            update={updateRows}
            id="screenshot_refresh"
          />
        </ToolbarItem>
        <ToolbarSeparator />
        <Box style={{marginRight: theme.spacing(8)}}>
          <PickerDate
            setValue={setDate}
            value={date}
            name={`date`}
            label={`Дата`}
            disabled={hideFilters}
          />
        </Box>
      </ToolbarWrapper>
      <Box className={localClasses.root}>
        {rows.map((screenshot: screenshotType) => (
          <ScreenshotImage
            key={screenshot.id_screenshot}
            screenshot={screenshot}
            id_device={id_device}
            setSelected={setSelected}
          />
        ))}
        {loading && <LoadingIndicator />}
      </Box>
      {selected && (
        <ConfirmDialog
          loading={loading}
          handleClose={() => setSelected(undefined)}
          handleConfirm={handleDelete}
          message="Удалить скриншот?"
        />
      )}
    </React.Fragment>
  );
};

export default Screenshots;
