import {Plugin} from "@devexpress/dx-react-core";
import {IntegratedSelection, SelectionState} from "@devexpress/dx-react-grid";
import {Box} from "@mui/material";
import * as React from "react";
import {ReactElement, ReactText, useContext, useRef, useState} from "react";
import deleteNestedPolicies from "../../common/APIRequests/nestedPolicy/deleteNestedPolicies";
import getNestedPolicies from "../../common/APIRequests/nestedPolicy/getNestedPolicies";
import postImportFromCsv from "../../common/APIRequests/nestedPolicy/postImportFromCsv";
import postNestedPolicies from "../../common/APIRequests/nestedPolicy/postNestedPolicies";
import {NestedPolicyType} from "../../common/APIRequests/policy/types";
import {useTrigger} from "../../common/customHooks";
import {alertType} from "../../common/types";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import UploadButton from "../common/buttons/UploadButton";
import {getExtension} from "../common/functions";
import {StyledTableSelection} from "../common/grid/cell";
import {useGlobalStyles} from "../common/styles";
import Column from "../Tables/Column";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import NestedPolicyEditDialog from "./NestedPolicyEditDialog";

type PoliciesCoreTableProps<PolicyType extends NestedPolicyType> = {
  name: "project" | "profile";
  columns: Column[];
  parentId: number;
  onClose: () => void;
  notifyHasChanges: (hasChanges: boolean) => void;
  getRowId: (row: PolicyType) => number;
  importPoliciesButton?: ReactElement;
  openPoliciesTree?: ReactElement;
};

const PoliciesCoreTable = <PolicyType extends NestedPolicyType>(
  props: PoliciesCoreTableProps<PolicyType>
) => {
  const {setAlert} = useContext(AppContext);

  const [refreshTrigger, refresh] = useTrigger();

  const [changedRows, setChangedRows] = useState(
    {} as {[id_prof_pol: number]: PolicyType}
  );

  const [loading, setLoading] = useState(false);
  const disableUpdate = useRef(false);
  const [selection, setSelection] = useState<(string | number)[]>([]);
  const [selectionAllowedRowIds, setSelectionAllowedRowIds] = useState<
    (string | number)[]
  >([]);
  const [rowClicked, setRowClicked] = useState<PolicyType>();

  const hasChanges = Object.keys(changedRows).length !== 0;

  const updateChangedRows = (rows: {[id_prof_pol: number]: PolicyType}) => {
    if (Object.keys(rows).length === 0 || !hasChanges)
      props.notifyHasChanges(Object.keys(rows).length !== 0);
    setChangedRows(rows);
  };

  const updateRows: UpdateRowsType<PolicyType> = async ({prevRows}) => {
    if (!disableUpdate.current) {
      return await getNestedPolicies<PolicyType>(
        props.parentId,
        props.name
      ).then((result) => {
        setSelectionAllowedRowIds(
          result.rows
            .filter(
              (row) =>
                !(row.affiliation === 0 || props.getRowId(row) in changedRows)
            )
            .map((row) => props.getRowId(row))
        );
        return {
          ...result,
          rows: hasChanges
            ? result.rows.map((row) => changedRows[props.getRowId(row)] || row)
            : result.rows,
        };
      });
    } else {
      disableUpdate.current = false;
      return {
        success: true,
        rows: prevRows.map((row) => changedRows[props.getRowId(row)] || row),
        errors: {},
      };
    }
  };

  const handleSave = async () => {
    setLoading(true);
    postNestedPolicies<PolicyType>(
      props.parentId,
      props.name,
      Object.values(changedRows)
    )
      .then((result) => {
        if (result.success) {
          refresh();
          updateChangedRows({});
          setAlert({
            severity: "success",
            message: ["Политики сохранены"],
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (selection: ReactText[]) => {
    const updated = Object.keys(changedRows).reduce((result, key) => {
      const numberKey = parseInt(key);
      return selection.includes(numberKey)
        ? result
        : {...result, [key]: changedRows[numberKey]};
    }, {});
    updateChangedRows(updated);
    return deleteNestedPolicies(props.parentId, props.name, selection);
  };

  const onHeaderSelectionClick = () => {
    if (selectionAllowedRowIds.length === 0) return;

    if (selectionAllowedRowIds.length === selection.length) {
      setSelection([]);
    } else {
      setSelection(selectionAllowedRowIds);
    }
  };

  // for project only
  const handleImport = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const {files} = e.target;
    if (!files) {
      return;
    }
    if (!["csv"].includes(getExtension(files[0].name))) {
      setAlert({
        severity: "error",
        message: ["Разрешается загружать файлы только с расширением .csv"],
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", files[0]);

    setLoading(true);
    // for projects only
    const result = await postImportFromCsv(props.parentId, formData);

    if (result.success) {
      refresh();
      updateChangedRows({});
    } else {
      setAlert({
        severity: "error",
        message: ["Недопустимое значение"],
      } as alertType);
    }
    setLoading(false);
  };

  const classes = useGlobalStyles();

  const getRowStyle = (row: PolicyType) => {
    if (props.getRowId(row) in changedRows) return classes.gridRowChanged;
    else if (row.affiliation === 0) return classes.gridRowSelected;
    else return classes.gridRow;
  };

  return (
    <>
      <CoreTable
        name={`${props.name}_policies`}
        columns={props.columns}
        getRowId={props.getRowId}
        updateRows={updateRows}
        defaultSorting={{columnName: "policy_name", direction: "desc"}}
        rowStyle={getRowStyle}
        onRowClick={(row) => setRowClicked(row)}
        updateTrigger={refreshTrigger}
        height={"60vh"}
        integratedSearch
        selectionButton={(tableSelection, update) => (
          <MenuDeleteAction
            selection={selection}
            update={update}
            action={() => {
              setSelection([]);
              return handleDelete(selection);
            }}
            checkPin
          />
        )}
        toolBarLeftItems={
          props.name === "project"
            ? [
                <UploadButton
                  upload={handleImport}
                  key="upload_policies_button"
                  extension={".csv"}
                  title="Импорт"
                />,
              ]
            : []
        }
        dialog={
          <NestedPolicyEditDialog
            onClose={() => setRowClicked(undefined)}
            policy={rowClicked}
            onChange={(row: PolicyType) => {
              disableUpdate.current = true;
              updateChangedRows({
                ...changedRows,
                [props.getRowId(row)]: row,
              });
              refresh();
            }}
          />
        }
        plugins={[
          <Plugin key="policies-core-table-selection-plugin">
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <IntegratedSelection />
            <StyledTableSelection
              selectionAllowedRowIds={selectionAllowedRowIds}
              onHeaderSelectionClick={onHeaderSelectionClick}
              allSelected={
                selectionAllowedRowIds.length === selection.length &&
                selection.length !== 0
              }
            />
          </Plugin>,
        ]}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="75px"
        padding="16px"
      >
        {props.openPoliciesTree ?? <Box />}
        <FormButtons
          loading={loading}
          handleSubmit={handleSave}
          disabled={!hasChanges || loading}
          handleClose={props.onClose}
          checkPin
        />
      </Box>
    </>
  );
};

export default PoliciesCoreTable;
