import {baseGetRequest} from "../base/baseGetRequest";

export type TaskProgressType = {
  id_taks: number;
  id_project?: number;
  name_project?: string;
  children: "0" | "1";
  id_app_version: number;
  app_version_name: string;
  app_version_description: string;
  geo_fullname: string;
  geo_count: number;
  status: 0 | 1 | 2 | 3;
  device_count: number;
  total_progress: TaskGeoProgressType;
  progress: Array<TaskGeoProgressType>;
};

export type TaskGeoProgressType = {
  geo_fullname: string;
  devices_count: number;
  success_count: number;
  errors_count: number;
  progress_percent: number;
};

const getTaskProgress = async (id_task: number) =>
  baseGetRequest<TaskProgressType>(`/task/${id_task}/`);

export default getTaskProgress;
