import {basePatchRequest} from "../base/basePatchRequest";
import {PostTaskTemplateType} from "./postTaskTemplate";
import {TaskTemplateDetailsType} from "./getTemplate";

const patchTaskTemplate = async (
  takTemplateId: number,
  taskTemplate: PostTaskTemplateType
) =>
  basePatchRequest<TaskTemplateDetailsType>(
    `/task_template/${takTemplateId}/`,
    taskTemplate
  );

export default patchTaskTemplate;
