import React from "react";
import {profileType} from "../../../common/APIRequests/profile/getProfiles";
import PoliciesCoreTable from "../../NestedPolicies/PoliciesCoreTable";
import ProfilePolicyColumns from "./ProfilePolicyColumns";
import {ProfilePolicy} from "../../../common/APIRequests/policy/types";

const ProfilePoliciesTable = (props: {
  profile: profileType;
  onClose: () => void;
  notifyHasChanges: (hasChanges: boolean) => void;
}) => {
  const {profile, ...tableProps} = props;
  return (
    <PoliciesCoreTable<ProfilePolicy>
      name={"profile"}
      columns={ProfilePolicyColumns}
      parentId={profile.id_prof}
      getRowId={(row) => row.id_prof_pol}
      {...tableProps}
    />
  );
};

export default ProfilePoliciesTable;
