import {basePostRequest} from "../base/basePostRequest";
import {NotificationType} from "./getNotification";

const toggleNotification = async (
  notification: NotificationType,
  is_active: "1" | "0"
) =>
  basePostRequest<NotificationType>(
    `/notify/${notification.id_notify}/toggle/`,
    {
      is_active,
    }
  );

export default toggleNotification;
