import {useState} from "react";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import {FilterComponentProps} from "../Tables/CoreTable";
import {consoleLogActionTitles} from "./ConsoleLogColumns";
import {ConsoleLogActionType} from "../../common/APIRequests/consoleLog/getConsoleLogs";
import {Grid} from "@mui/material";
import StringFilterField from "../common/filterFields/StringFilterField";
import {StyledMenuFilterGrid} from "../common/grid/StyledFilterGrid";
import FormButtons from "../common/buttons/FormButtons";
import PickerDateTime from "../common/formFields/PickerDateTime";

export type ConsoleLogsFilterType = {
  source: number | null;
  action: number | null;
  date_start: string | null;
  date_end: string | null;
  ldap_login: string | null;
};

export const ConsoleLogsFilter = (
  props: FilterComponentProps<ConsoleLogsFilterType>
) => {
  const [focus, setFocus] = useState("");
  const [filters, setFilters] = useState<ConsoleLogsFilterType>({
    ...props.filters,
  });

  return (
    <>
      <Grid container>
        <StyledMenuFilterGrid item xs={4}>
          <SelectSingleFilterField
            name="console_log_action"
            label="Действие"
            value={filters.action}
            update={(value) => {
              setFilters({...filters, action: value});
            }}
            disabled={props.loading}
            includeAllOption
            options={Object.keys(consoleLogActionTitles).map((value) => {
              const valueNumber = parseInt(value);
              return {
                value: valueNumber,
                text: consoleLogActionTitles[
                  valueNumber as ConsoleLogActionType
                ],
              };
            })}
            onFocus={() => setFocus("")}
            focus={focus}
          />
        </StyledMenuFilterGrid>
        <StyledMenuFilterGrid item xs={4}>
          <StringFilterField
            label="Логин инициатора"
            name="ldap_login"
            value={filters.ldap_login ?? ""}
            onChange={(value: string) =>
              setFilters({...filters, ldap_login: value.trim()})
            }
            onFocus={() => setFocus("ldap_login")}
            onEnterPress={(value: string) => {
              setFilters({...filters, ldap_login: value.trim()});
            }}
            onResetClick={() => {
              setFilters({...filters, ldap_login: ""});
            }}
            disabled={props.loading}
          />
        </StyledMenuFilterGrid>
        <StyledMenuFilterGrid item xs={4}>
          <PickerDateTime
            setValue={(value) =>
              setFilters({
                ...filters,
                date_start: value,
              })
            }
            value={filters.date_start}
            name={"date_start"}
            label={"Дата от"}
            fullWidth
          />
        </StyledMenuFilterGrid>
        <StyledMenuFilterGrid item xs={4}>
          <PickerDateTime
            setValue={(value) =>
              setFilters({
                ...filters,
                date_end: value,
              })
            }
            value={filters.date_end || null}
            name={"date_end"}
            label={"Дата до"}
            fullWidth
          />
        </StyledMenuFilterGrid>
      </Grid>
      <FormButtons
        loading={props.loading}
        handleSubmit={() => {
          props.updateFilters(filters);
        }}
        handleClose={() => {
          const updatedFilters = {
            source: props.filters.source,
          } as ConsoleLogsFilterType;
          setFilters(updatedFilters);
          props.updateFilters(updatedFilters);
          // TODO: consider removing focus, it does not seem to be doing anything
          setFocus("");
        }}
        submitMessage="Показать"
        cancelMessage="Сбросить все"
      />
    </>
  );
};
