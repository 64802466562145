import {IconButton} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React from "react";

type FilterIconButtonProps = {
  onClick: () => void;
};

const FilterIconButton = (props: FilterIconButtonProps) => {
  return (
    <IconButton
      color="primary"
      aria-label="Фильтр"
      component="span"
      onClick={props.onClick}
      size="medium"
      style={{top: "5px"}}
      data-testid="open_button"
      id="open_button"
    >
      <FilterListIcon />
    </IconButton>
  );
};

export default FilterIconButton;
