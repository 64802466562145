import * as React from "react";
import {useContext} from "react";
import DownloadMenuButton from "../common/buttons/DownloadMenuButton";
import {downloadFile} from "../../common/utils/files";
import downloadConsoleLogsFile from "../../common/APIRequests/consoleLog/downloadConsoleLogsFile";
import TableContext from "../Tables/TableContext";
import {ConsoleLogsFilterType} from "./ConsoleLogsFilter";

type DownloadConsoleLogsFileButtonProps = {
  setLoading: (loading: boolean) => void;
};

export const DownloadConsoleLogsFileButton = (
  props: DownloadConsoleLogsFileButtonProps
) => {
  const {sorting, filters, hiddenColumnNames, columns} =
    useContext(TableContext);

  const contextFilters = filters as ConsoleLogsFilterType;

  const columnsToDownload = columns
    .filter((column) => !hiddenColumnNames.includes(column.name))
    .map((column) => ({
      name: column.name,
      title: column.title,
      width: column.width / 10,
    }));

  const handleDownLoad = (extension: "xlsx" | "csv") => () => {
    props.setLoading(true);
    downloadConsoleLogsFile(
      contextFilters,
      columnsToDownload,
      extension,
      sorting?.[0]?.columnName,
      sorting?.[0]?.direction === "desc"
    )
      .then((result) => {
        if (result.success) {
          downloadFile(result.file, `console_logs.${extension}`);
        }
      })
      .finally(() => props.setLoading(false));
  };

  return (
    <DownloadMenuButton
      disabled={!contextFilters?.source}
      actionOptions={[
        {
          onClick: handleDownLoad("csv"),
          label: "CSV",
        },
        {
          onClick: handleDownLoad("xlsx"),
          label: "XLS",
        },
      ]}
    />
  );
};
