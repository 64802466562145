import {AppBar, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import React, {PropsWithChildren} from "react";
import LoginMenu from "./LoginMenu";

const drawerWidth = 240;

const useStyles = makeStyles()((theme) => ({
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
  toolbar: {
    paddingRight: theme.spacing(2.5), // keep right padding when drawer closed
    paddingLeft: theme.spacing(1.5),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  menuButtonHidden: {
    display: "none",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const MenuPanel = (
  props: PropsWithChildren<{
    leftSidebarOpen: boolean;
    openLeftSidebar: () => void;
    userLogout: () => void;
  }>
) => {
  const {classes, cx} = useStyles();
  const {
    children: children,
    openLeftSidebar,
    leftSidebarOpen,
    userLogout,
  } = props;

  const handleOpenLeftSidebar = () => {
    openLeftSidebar();
  };

  return (
    <AppBar
      position="absolute"
      className={cx(classes.appBar, leftSidebarOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleOpenLeftSidebar}
          className={cx(
            classes.menuButton,
            leftSidebarOpen && classes.menuButtonHidden
          )}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        {typeof children === "string" ? (
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {children}
          </Typography>
        ) : (
          children
        )}
        <LoginMenu userLogout={userLogout} />
      </Toolbar>
    </AppBar>
  );
};

export default MenuPanel;
