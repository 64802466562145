import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";

export type taskFilterType = {
  id_net: string | null;
  id_branch: string | null;
  code_division: string | null;
  id_project: number | null;
  id_app: number | null;
  id_app_version: number | null;
  login: string | null;
};

export type taskType = {
  id_task?: number;
  id_project?: number;
  id_geo?: number;
  geo_fullname?: string;
  geo_count?: number;
  name_project?: string;
  name_geo?: string;
  children: "0" | "1";
  ldap_login: string;
  id_app_version?: number;
  create_date?: string;
  app_version_description?: string;
  app_version_name?: string;
  status?: "0" | "1" | "2";
};

const getTasks = async (
  sort_field: string | undefined,
  sort_desc: boolean | undefined,
  filters?: taskFilterType
) =>
  baseGetRowsRequestWithCount<taskType>(`/task/`, {
    sort_field,
    sort_desc,
    filters,
  });

export default getTasks;
