import React from "react";
import {makeStyles, withStyles} from "tss-react/mui";
import {Box, useTheme} from "@mui/material";
import {Toolbar} from "@devexpress/dx-react-grid-material-ui";

export const ToolbarItem = withStyles(Box, (theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
}));

export const ToolbarSeparator = () => {
  return (
    <div
      style={{
        flex: "0 0 0px",
        marginLeft: "auto",
      }}
    />
  );
};

export const ToolbarWrapper = (props: {children: React.ReactNode}) => {
  const {children} = props;

  const useStyles = makeStyles()((theme) => ({
    toolbarWrapper: {
      display: "flex",
      paddingBottom: theme.spacing(2),
      alignItems: "flex-end",
      minHeight: "64px",
    },
  }));

  const {classes} = useStyles();

  return <Box className={classes.toolbarWrapper}>{children}</Box>;
};

export const BottomToolbarWrapper = (props: {children: React.ReactNode}) => {
  const {children} = props;

  const useStyles = makeStyles()((theme) => ({
    toolbarWrapper: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }));

  const {classes} = useStyles();

  return <Box className={classes.toolbarWrapper}>{children}</Box>;
};

export const ToolbarRoot = (props: Toolbar.RootProps) => {
  const theme = useTheme();
  return (
    <Toolbar.Root
      {...props}
      style={{
        alignItems: "flex-end",
        minHeight: 0,
        padding: theme.spacing(0, 0, 2, 0),
        overflowX: "auto",
        borderBottom: "none",
      }}
    />
  );
};
