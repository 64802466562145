import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import getTasksSettings, {
  tasksSettingType,
} from "../../common/APIRequests/task/getTasksSettings";
import theme from "../../theme";
import FormButtons from "../common/buttons/FormButtons";
import {sanitizeInt} from "../common/formFields/sanitizers";
import patchTasksSetting from "../../common/APIRequests/task/patchTasksSetting";

type Props = {
  formClose: () => void;
};

const TaskSettingForm = (props: Props) => {
  const {formClose} = props;

  const [loading, setLoading] = useState(false);

  const [tasksSettings, setTasksSettings] = useState(
    [] as Array<tasksSettingType>
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getTasksSettings();

      result.success && setTasksSettings(result.rows);
      setLoading(false);
    })();
  }, []);

  const updateTaskSetting = (value: string, index: number) => {
    const newTasksSettings = tasksSettings.map(
      (t: tasksSettingType, i: number) => {
        if (i === index) {
          const v = sanitizeInt(value);
          return {
            ...t,
            value: Boolean(v) ? v : "0",
          };
        }
        return t;
      }
    );
    setTasksSettings(newTasksSettings);
  };

  const submitForm = async () => {
    setLoading(true);
    await Promise.all(
      tasksSettings.map((t: tasksSettingType) => patchTasksSetting(t.id, t))
    ).then((v: any) => {
      setLoading(false);
      formClose();
    });
  };

  return (
    <React.Fragment>
      {tasksSettings.length > 0 &&
        tasksSettings.map((t: tasksSettingType, index: number) => (
          <TextField
            variant="outlined"
            fullWidth
            key={t.id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateTaskSetting(e.target.value, index)
            }
            value={t.value.toString()}
            name={t.name}
            label={t.display_name}
            style={{marginBottom: theme.spacing(2)}}
          />
        ))}
      <FormButtons
        loading={loading}
        handleSubmit={submitForm}
        handleClose={formClose}
        submitMessage="Сохранить"
        checkPin
      />
    </React.Fragment>
  );
};

export default TaskSettingForm;
