import moment from "moment";
import {taskDeviceType} from "../../common/APIRequests/task/getTaskDevices";
import {deviceAppStatusTitles} from "../common/grid/providers";
import Column from "../Tables/Column";

export const taskDeviceColumns: Array<Column> = [
  new Column("id_task_device", "ID задачи устройства", 100, {
    hiddenByDefault: true,
  }),
  new Column("id_device", "ID устройства", 100, {hiddenByDefault: true}),
  new Column("app_status", "Статус установки", 260, {
    getCellValue: (row: taskDeviceType) =>
      row.app_status in deviceAppStatusTitles
        ? deviceAppStatusTitles[row.app_status]
        : undefined,
  }),
  new Column("last_seen_time", "Последняя синхронизация", 230, {
    getCellValue: (row: taskDeviceType) =>
      row.last_seen_time ? moment(row.last_seen_time).fromNow() : undefined,
  }),
  new Column("name", "Наименование устройства", 200, {
    auto: true,
    sortingEnabled: false,
    getCellValue: (row: taskDeviceType) =>
      `${row.code_division} ${row.ldap_login} android ${row.code || ""} ${
        row.serial_number
      }`,
  }),
  new Column("device_registration_status", "Статус регистрации", 180, {
    getCellValue: (row: taskDeviceType) =>
      row.device_registration_status ? "Зарегистрирован" : "Не зарегистрирован",
  }),
  new Column("id_project", "ID Проекта", 130, {hiddenByDefault: true}),
  new Column("name_project", "Проект", 150),
  new Column("name_model", "Модель", 200, {hiddenByDefault: true}),
  new Column("code_division", "Код подразделения", 200, {
    hiddenByDefault: true,
  }),
  new Column("serial_number", "Серийный номер", 200, {hiddenByDefault: true}),
];
