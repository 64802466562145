import Column from "../../Tables/Column";

const AddApplicationColumns = [
  new Column("id_app", "ID", 70, {hiddenByDefault: true}),
  new Column("name", "Наименование", 400),
  new Column("app_uid", "Идентификатор Android", 70),
  new Column("description", "Описание", 400, {auto: true}),
];

export default AddApplicationColumns;
