import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Button, Dialog, DialogContent} from "@mui/material";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {ApplicationType} from "../../../common/APIRequests/application/getApplications";
import {applicationVersionType} from "../../../common/APIRequests/applicationVersion/getApplicationVersions";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import deleteDeviceApplications from "../../../common/APIRequests/deviceApplication/deleteDeviceApplications";
import getDeviceApplication, {
  deviceApplicationType,
} from "../../../common/APIRequests/deviceApplication/getDeviceApplication";
import postDeviceApplications from "../../../common/APIRequests/deviceApplication/postDeviceApplications";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../../common/customHooks";
import {
  columnType,
  FRONT_DATETIME_FORMAT,
  sortingType,
} from "../../../common/types";
import {AppContext} from "../../App-context";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import {RefreshButton} from "../../common/buttons/ToolbarButtons";
import {LoadingIndicator, Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {StyledTableSelection, StyledVirtualTable} from "../../common/grid/cell";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import {
  BooleanTypeProvider,
  DeviceAppStatusProvider,
} from "../../common/grid/providers";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../../common/styles";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import DeviceApplications from "./DeviceApplications";
import DeviceApplicationVersions from "./DeviceApplicationVersions";

const columns: columnType = [
  {name: "id_dev_app", title: "ID"},
  {name: "status", title: "Статус"},
  {name: "app_name", title: "Наименование"},
  {name: "app_description", title: "Описание"},
  {name: "name", title: "Текущая"},
  {name: "name_target", title: "Назначенная"},
  {name: "file_size", title: "Размер файла"},
  {
    name: "install_time",
    title: "Дата установки",
    getCellValue: (row: deviceApplicationType) =>
      row.install_time
        ? moment(row.install_time).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
];
const columnExtensions = [
  {columnName: "id_dev_app", width: 100},
  {columnName: "status", width: 90},
  {columnName: "app_name", width: 150},
  {columnName: "app_description", width: 200},
  {columnName: "name", width: 150},
  {columnName: "name_target", width: 150},
  {columnName: "file_size", width: 150},
  {columnName: "install_time", width: 200},
];
const columnAlignExtension = [{columnName: "status", align: "center"}];

const hiddenColumnNames = ["id_dev_app", "file_size"];

const DeviceApplicationsTab = (props: {
  device: deviceType;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const {device, loading, setLoading} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [selection, setSelection] = useState([] as Array<string | number>);

  const [selectedApplication, setSelectedApplication] = useState(
    undefined as undefined | ApplicationType
  );

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "dev_app_tab_sorting",
    [{columnName: "name", direction: "asc"}]
  );

  const [selectedApplicationVersion, setSelectedApplicationVersion] = useState(
    undefined as undefined | applicationVersionType
  );

  const [rows, setRows] = useState([] as Array<deviceApplicationType>);

  useEffect(() => {
    updateRows();
  }, [device]);

  const updateRows = async () => {
    setSelection([]);

    setLoading(true);
    const result = await getDeviceApplication(device.id_device);
    setLoading(false);

    result.success && setRows(result.rows);
  };

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "device_application_tab_ordering",
    columns.map((row) => row.name)
  );

  const [addOpen, setAddOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setSelectedApplication(undefined);
      setSelectedApplicationVersion(undefined);
    })();
  }, [addOpen]);

  const installApplication = async (
    app: ApplicationType,
    app_version?: applicationVersionType
  ) => {
    setLoading(true);
    const result = await postDeviceApplications(
      device.id_device,
      app.id_app,
      app_version && app_version.id_app_version
    );
    setLoading(false);

    if (result.success) {
      setAddOpen(false);
      setAlert({
        severity: "success",
        message: ["Отправлена команда на установку приложения"],
      });
      updateRows();
    }
  };

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      {addOpen && (
        <Dialog
          open={true}
          onClose={() => setAddOpen(false)}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="lg"
        >
          <ClosableDialogTitle
            id="add-project-modal"
            onClose={() => setAddOpen(false)}
          >
            Установка приложения на устройство пользователя:{" "}
            {device.user_ldap_login}, серийный номер: {device.serial_number}
          </ClosableDialogTitle>
          <DialogContent>
            <DeviceApplications
              selected={selectedApplication}
              setSelected={setSelectedApplication}
            />
            <DeviceApplicationVersions
              selectedApplication={selectedApplication}
              selected={selectedApplicationVersion}
              setSelected={setSelectedApplicationVersion}
              handleClose={() => setAddOpen(false)}
              installApplication={installApplication}
              loading={loading}
              setLoading={setLoading}
              checkPin
            />
          </DialogContent>
        </Dialog>
      )}
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_dev_app}>
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddOpen(true)}
              id="device_apps_install"
            >
              Установить приложение
            </Button>
          </ToolbarItem>
          <ToolbarItem id="device_apps_delete">
            <MenuDeleteAction
              selection={selection}
              update={() => updateRows()}
              action={(ids: Array<number | string>) =>
                deleteDeviceApplications(device.id_device, ids)
              }
              checkPin
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateRows}
              id="device_apps_refresh"
            />
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              className={
                row.is_inherited === 1
                  ? classes.gridRowSelected
                  : classes.gridRow
              }
              data-type="device_apps_row"
              data-id={row.id_dev_app}
            />
          )}
          columnExtensions={columnAlignExtension}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="device_applications"
          autoWidthColumnName={"app_description"}
        />

        {/*Row Selection*/}
        <SelectionState
          selection={selection}
          onSelectionChange={setSelection}
        />
        <IntegratedSelection />
        <StyledTableSelection showSelectAll />

        <DeviceAppStatusProvider for={["status"]} />
        <BooleanTypeProvider for={["is_inherited"]} />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default DeviceApplicationsTab;
