import Column from "../Tables/Column";
import {BooleanTypeProvider} from "../common/grid/providers";

const UserColumns = [
  new Column("id_user", "№", 100, {hiddenByDefault: true}),
  new Column("ldap_login", "Логин", 150),
  new Column("display_name", "Имя", 150),
  new Column("email", "Электронная почта", 150),
  new Column("comment_text", "Описание", 200, {auto: true}),
  new Column(
    "authorized_devices_count",
    "Количество авторизованных устройств",
    300
  ),
  new Column("delete_session_action", "Действие", 150, {sortingEnabled: false}),
  new Column("is_admin", "Администратор", 150, {provider: BooleanTypeProvider}),
];

export default UserColumns;
