import {useContext, useMemo, useState} from "react";
import {Grid} from "@mui/material";
import {StyledFilterGrid} from "../common/grid/StyledFilterGrid";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import {FilterComponentProps} from "../Tables/CoreTable";
import {QrCodeFilterType} from "../../common/APIRequests/qrCode/getQrCodes";
import StringFilterField from "../common/filterFields/StringFilterField";
import {AppContext} from "../App-context";
import {generateProjectOptionsForEachProject} from "../common/functions";

export const QrCodesFilter = (
  props: FilterComponentProps<QrCodeFilterType>
) => {
  const {projects} = useContext(AppContext);
  const {filters, updateFilters} = props;
  const [login, setLogin] = useState("");

  const options = useMemo(() => {
    return generateProjectOptionsForEachProject(projects);
  }, [projects]);

  const handleEnterPress = (fieldName: string) => (value: string) => {
    props.updateFilters({...props.filters, [fieldName]: value.trim()});
  };

  return (
    <Grid container wrap="nowrap">
      <StyledFilterGrid item xs={6}>
        <SelectSingleFilterField
          name="project"
          label={"Проект"}
          value={filters.id_project}
          update={(value) => {
            updateFilters({...filters, id_project: value});
          }}
          includeAllOption
          options={options}
        />
      </StyledFilterGrid>
      <StyledFilterGrid item xs={6}>
        <StringFilterField
          name="login"
          label="Логин"
          value={login}
          onChange={setLogin}
          onEnterPress={handleEnterPress("login")}
          onResetClick={() => {
            setLogin("");
            props.updateFilters({...props.filters, login: ""});
          }}
        />
      </StyledFilterGrid>
    </Grid>
  );
};
