import * as React from "react";
import {ReportFilterProps} from "./eventFilters/EventIdReportFilter";
import BooleanReportFilter from "./commonFilters/BooleanReportFilter";
import ProjectReportFilter from "./deviceFilters/ProjectReportFilter";
import AppIdReportFilter from "./appFilters/AppIdFilter";

type EventReportFilterProps = {
  systemName: string;
} & ReportFilterProps;

const AppReportFilters = (props: EventReportFilterProps) => {
  const {systemName, ...filterProps} = props;
  switch (systemName) {
    case "inherited":
      return <BooleanReportFilter {...filterProps} />;
    case "id_app":
      return <AppIdReportFilter {...filterProps} />;
    case "id_project":
      return <ProjectReportFilter {...filterProps} />;
    default:
      return null;
  }
};

export default AppReportFilters;
