import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type screenshotType = {
  id_device: number;
  id_screenshot: number;
  screenshot_name: string;
  screenshot_path: string;
  create_date: number;
  cols: number;
};

const getScreenshots = async (id_device: number, date: string | undefined) =>
  baseGetRowsRequest<screenshotType>(`/device/${id_device}/screenshot/`, {
    date,
  });

export default getScreenshots;
