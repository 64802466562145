import React from "react";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {fieldProps} from "../../../common/types";

const BooleanField = (props: fieldProps) => {
  const {setValue, value, name, label, style, disabled} = props;

  return (
    <FormControl fullWidth style={style}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={value === "1"}
            id={name}
            name={name}
            onChange={(e: any) =>
              setValue(e.target.checked === true ? "1" : "0")
            }
            disabled={disabled || false}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default BooleanField;
