import * as React from "react";
import {ReportFilterProps} from "./eventFilters/EventIdReportFilter";
import DateReportFilter from "./commonFilters/DateReportFilter";
import BooleanReportFilter from "./commonFilters/BooleanReportFilter";
import StringReportFilter from "./commonFilters/StringReportFilter";
import ProjectReportFilter from "./deviceFilters/ProjectReportFilter";
import GeoReportFilter from "./deviceFilters/GeoReportFilter";

type EventReportFilterProps = {
  systemName: string;
} & ReportFilterProps;

const DeviceReportFilters = (props: EventReportFilterProps) => {
  const {systemName, ...filterProps} = props;
  switch (systemName) {
    case "use_profile":
    case "inherited":
    case "device_registration_status":
      return <BooleanReportFilter {...filterProps} />;
    case "model":
    case "id_sub":
    case "firmware":
      return <StringReportFilter {...filterProps} />;
    case "last_seen_time":
      return <DateReportFilter {...filterProps} />;
    case "id_project":
      return <ProjectReportFilter {...filterProps} />;
    case "id_geo":
      return <GeoReportFilter {...filterProps} />;
    default:
      return null;
  }
};

export default DeviceReportFilters;
