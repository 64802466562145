import React, {useEffect, useRef, useState} from "react";
import {Chip, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {fieldProps} from "../../../common/types";
import DeleteIcon from "@mui/icons-material/Cancel";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

const SelectMultipleField = (props: fieldProps & {options: string[]}) => {
  const {setValue, value, options, name, label, style} = props;

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    // @ts-ignore
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleDelete = (option: string) => {
    let values = value ? value.split(",") : [];
    values = values.filter((x: string) => x !== option);
    setValue(values.join(","));
  };

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const value = e.target.value as string[];
    setValue(value.join(","));
  };

  return (
    <React.Fragment>
      <FormControl fullWidth variant="outlined" style={style}>
        <InputLabel ref={inputLabel} htmlFor="value">
          {label}
        </InputLabel>
        <Select
          value={value ? value.split(",") : []}
          onChange={handleChange}
          // labelWidth={labelWidth}
          inputProps={{name: name, id: name}}
          multiple
          MenuProps={{
            // getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          renderValue={(selected) => (
            <React.Fragment>
              {(selected as string[]).map((value) => (
                <Chip
                  variant="outlined"
                  key={value}
                  label={value}
                  deleteIcon={
                    <DeleteIcon
                      onMouseDown={(e: any) => {
                        if (e.button === 0) {
                          e.stopPropagation();
                          handleDelete(value);
                        }
                      }}
                    />
                  }
                  onDelete={() => handleDelete(value)}
                />
              ))}
            </React.Fragment>
          )}
        >
          {options.map((row: string) => (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default SelectMultipleField;
