import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type reportLastConnectType = {
  id_device: number;
  serial_number: string;
  code_division: string;
  name_project: string;
  ldap_login: string;
  display_name: string;
  email: string;
  last_seen_time: string;
};

const getReportLastConnect = async (
  id_project: number,
  sort_field: string,
  sort_desc: boolean,
  include_child?: 1 | 0
) =>
  baseGetRowsRequest<reportLastConnectType>(`/report/last_connect/`, {
    id_project,
    sort_field,
    sort_desc,
    include_child,
  });

export default getReportLastConnect;
