import downloadExternalFile from "../API/downloadExternalFile";
import {downloadFile} from "../../../../common/utils/files";
import {rcApiClient} from "../API/rcApiClient";
import {deviceType} from "../../../../common/APIRequests/device/getDevices";
import GetDeviceApplication from "../../../../common/APIRequests/deviceApplication/getDeviceApplication";
import {isDev} from "../../../../settings";

export const downloadFileFromUrlRcApi = async (url: string, name: string) => {
  const data = await downloadExternalFile(url, rcApiClient);
  let file = new File([data.file], name);
  downloadFile(file, name);
};

export enum RemoteControlErrors {
  MODEL_NOT_SUPPORTED = "Данная модель устройства не поддерживает удаленное управление.",
  AGENT_VERSION_TOO_LOW = "Версия агента EMM должна быть 2.30 и выше. ",
}
export const RC_MODEL_PRODUCER = "urovo zebra gausys newland qcom";
export const RC_MIN_AGENT_VERSION = 2.3;
export const AGENT_UID = "ru.magnit.itlab.agent";
export const checkRemoteControlSupport = async (device: deviceType) => {
  let errors = [];
  if (
    !isDev &&
    !RC_MODEL_PRODUCER.split(" ").includes(device.model_producer.toLowerCase())
  ) {
    errors.push(RemoteControlErrors.MODEL_NOT_SUPPORTED);
  } else {
    const result = await GetDeviceApplication(device.id_device);
    if (
      result.rows.some((row) => {
        return (
          row.app_uid === AGENT_UID &&
          parseFloat(row.name) < RC_MIN_AGENT_VERSION
        );
      })
    ) {
      errors.push(RemoteControlErrors.AGENT_VERSION_TOO_LOW);
    }
  }

  return {errors, isSupported: !errors.length};
};
