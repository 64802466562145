import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type commandType = {
  id_com: number;
  tag: string;
  description: string;
};

const getCommands = async () => baseGetRowsRequest<commandType>(`/command/`);

export default getCommands;
