import {basePostRequest} from "../base/basePostRequest";

export type userPermsType = 0 | 1 | 2 | 3;

export type loginType = {
  user_id?: number;
  login?: string;
  session_uid?: string;
  user_type: userPermsType;
};

const postLogin = (data: {username: string; password: string}) =>
  basePostRequest<loginType>("/session/", data);

export default postLogin;
