import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import * as React from "react";
import {useEffect, useState} from "react";
import getProfiles, {
  DEVICE_PROFILE_TYPE,
  profileType,
} from "../../../common/APIRequests/profile/getProfiles";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import postProjectProfiles from "../../../common/APIRequests/projectProfile/postProjectProfiles";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../../common/customHooks";
import {columnType, sortingType} from "../../../common/types";
import FormButtons from "../../common/buttons/FormButtons";
import {RefreshButton} from "../../common/buttons/ToolbarButtons";
import {StyledTableSelection, StyledVirtualTable} from "../../common/grid/cell";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../../common/styles";
import {
  BottomToolbarWrapper,
  ToolbarItem,
  ToolbarRoot,
  ToolbarSeparator,
} from "../../common/Toolbar";
import {LoadingIndicator} from "../../common/components";

const columns: columnType = [
  {name: "id_prof", title: "ID"},
  {name: "name", title: "Наименование"},
  {name: "specification", title: "Описание"},
  {name: "priority", title: "Приоритет"},
];
const columnExtensions = [
  {columnName: "id_prof", width: 100},
  {columnName: "name", width: 200},
  {columnName: "specification", width: 200},
  {columnName: "priority", width: 200},
];
const ProjectProfiles = (props: {
  handleClose: () => void;
  update: Function;
  installed: Array<number>;
  project: projectType;
}) => {
  const {handleClose, update, installed, project} = props;

  const filters = {id_type_profile: DEVICE_PROFILE_TYPE};

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "project_profile_add_list_sorting",
    [{columnName: "name", direction: "asc"}]
  );

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("project_profile_add_list_column", ["id_prof"]);

  const [selection, setSelection] = useState([] as Array<string | number>);

  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([] as Array<profileType>);

  const updateRows = async () => {
    setLoading(true);
    const result = await getProfiles(filters);
    setLoading(false);

    result.success &&
      setRows(
        result.rows.filter(
          (row: profileType) => !installed.includes(row.id_prof)
        )
      );
  };

  useEffect(() => {
    updateRows();
  }, []);

  const handleAdd = async (): Promise<void> => {
    setLoading(true);
    const result = await postProjectProfiles(project.id_project, selection);
    setLoading(false);

    if (result.success) {
      update();
      handleClose();
    }
  };

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "project_profiles_ordering",
    columns.map((row) => row.name)
  );

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_prof}>
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateRows}
              id="profiles_refresh"
            />
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              className={classes.gridRow}
              data-type="profiles_row"
              data-id={row.id_prof}
            />
          )}
          height="60vh"
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="project_profile_select"
        />

        {/*Row Selection*/}
        <SelectionState
          selection={selection}
          onSelectionChange={setSelection}
        />
        <IntegratedSelection />
        <StyledTableSelection showSelectAll />

        {loading && <LoadingIndicator />}
      </Grid>
      <BottomToolbarWrapper>
        <ToolbarSeparator />
        <FormButtons
          loading={loading}
          handleSubmit={handleAdd}
          handleClose={() => handleClose()}
          submitMessage="Добавить"
          submitId="project_profiles_choose"
          closeId="project_profiles_close"
          disabled={loading || !selection.length}
          checkPin
        />
      </BottomToolbarWrapper>
    </React.Fragment>
  );
};

export default ProjectProfiles;
