import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type policyValueType =
  | "int"
  | "float"
  | "list"
  | "boolean"
  | "string"
  | "";

export type policyType = {
  id_pol: number;
  display_name: string;
  policy_name: string;
  description?: string;
  value_type: policyValueType;
  possible_values: string;
  value: string;
  is_project: number;
  is_profile_device: number;
  is_profile_app: number;
  group_name?: string;
};

const getPolicies = async () => baseGetRowsRequest<policyType>(`/policy/`);

export default getPolicies;
