import * as React from "react";
import {useEffect, useState} from "react";
import GeneralInfoSection from "./GeneralInfoSection";
import ProgressBar from "./ProgressBar";
import {LoadingIndicator, Spacer} from "../../common/components";
import {Box, IconButton, Typography} from "@mui/material";
import DetailedGeoView from "./DetailedGeoView";
import RefreshIcon from "@mui/icons-material/Refresh";
import getTaskProgress, {
  TaskProgressType,
} from "../../../common/APIRequests/task/getTaskProgress";

type TaskProgressViewProps = {
  id_task: number;
};
const TaskProgressView = (props: TaskProgressViewProps) => {
  const [progressInfo, setProgressInfo] = useState<
    TaskProgressType | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  const update = () => {
    setLoading(true);
    getTaskProgress(props.id_task)
      .then((result) => {
        if (result.success) {
          setProgressInfo(result.data);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(update, []);

  const spacerHeight = 40;

  return (
    <>
      <Spacer height={spacerHeight} />
      <GeneralInfoSection taskDetails={progressInfo} />

      <Spacer height={spacerHeight} />
      <Box display="flex" alignItems="center">
        <Typography variant="h6">Ход установки</Typography>
        <IconButton onClick={update} size="large">
          <RefreshIcon color="secondary" />
        </IconButton>
      </Box>
      <ProgressBar progress={progressInfo?.total_progress?.progress_percent} />

      <Spacer height={spacerHeight} />
      <Typography variant="h6">Подробная информация</Typography>
      <DetailedGeoView progressByGeo={progressInfo?.progress} />

      {loading && <LoadingIndicator styleProps={{top: "55%"}} />}
    </>
  );
};

export default TaskProgressView;
