import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

export function baseGetRequest<T>(
  url: string,
  params?: any
): Promise<{
  success: boolean;
  data: T | undefined;
  errors: errorType;
}> {
  return new Promise((resolve) =>
    apiClient({
      method: "get",
      url: url,
      params: params,
    })
      .then((response) =>
        resolve({
          success: true,
          data: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          data: undefined,
          errors: errors,
        });
      })
  );
}
