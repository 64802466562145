import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {StyledFilterGrid} from "../common/grid/StyledFilterGrid";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import {
  FirmwareFilterType,
  GmsValues,
} from "../../common/APIRequests/firmware/getFirmwares";
import {optionType} from "../../common/types";
import {FilterComponentProps} from "../Tables/CoreTable";
import getModels from "../../common/APIRequests/firmware/getModels";
import getAndroidVersions from "../../common/APIRequests/firmware/getAndroidVersions";
import {compareVersion} from "../../common/utils/utils";

export const FirmwaresFilter = (
  props: FilterComponentProps<FirmwareFilterType>
) => {
  const {filters, updateFilters, loading} = props;
  const [localLoading, setLocalLoading] = useState(false);
  const [modelOptions, setModelOptions] = useState([] as optionType[]);
  const [androidVersionOptions, setAndroidVersionOptions] = useState(
    [] as optionType[]
  );

  useEffect(() => {
    setLocalLoading(true);
    Promise.all([getModels(), getAndroidVersions()])
      .then((results) => {
        const models = results[0];
        setModelOptions(
          models.rows.map((element) => {
            return {value: element.id_model, text: element.name} as optionType;
          })
        );
        const androidVersions = results[1];
        setAndroidVersionOptions(
          androidVersions.rows.map((element) => {
            return {
              value: element.id_dev_version,
              text: element.code,
            } as optionType;
          })
        );
      })
      .finally(() => setLocalLoading(false));
  }, []);

  return (
    <Grid container wrap="nowrap">
      <StyledFilterGrid item xs={6}>
        <SelectSingleFilterField
          name="id_model"
          label={"Модель"}
          value={filters.id_model}
          update={(value) => {
            updateFilters({...filters, id_model: value});
          }}
          disabled={localLoading}
          includeAllOption
          options={modelOptions}
        />
      </StyledFilterGrid>
      <StyledFilterGrid item xs={6}>
        <SelectSingleFilterField
          name="version_code"
          label={"Версия Android"}
          value={filters.id_dev_version}
          update={(value) => {
            updateFilters({...filters, id_dev_version: value});
          }}
          disabled={localLoading}
          includeAllOption
          options={androidVersionOptions.sort((a, b) =>
            compareVersion(a.text, b.text)
          )}
        />
      </StyledFilterGrid>
      <StyledFilterGrid item xs={6}>
        <SelectSingleFilterField
          name="has_gms"
          label={"GMS сервисы"}
          value={filters.has_gms}
          update={(value) => {
            updateFilters({...filters, has_gms: value});
          }}
          disabled={loading}
          includeAllOption
          options={Object.keys(GmsValues).map((key) => {
            return {value: key, text: GmsValues[parseInt(key)]};
          })}
        />
      </StyledFilterGrid>
    </Grid>
  );
};
