import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableColumnVisibility,
  TableFixedColumns,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useContext, useEffect, useState} from "react";
import {transliterate} from "transliteration";
import {DevicePolicyTreeType} from "../../../common/APIRequests/devicePolicyTree/getDevicePolicyTree";
import getGeneratePolicyTreeCsv from "../../../common/APIRequests/policy/getGeneratePolicyTreeCsv";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {
  columnExtensionsType,
  columnType,
  sortingType,
} from "../../../common/types";
import {downloadFile} from "../../../common/utils/files";
import {AppContext} from "../../App-context";
import {LoadingIndicator, Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {smashTree} from "../../common/functions";
import {StyledVirtualTable} from "../../common/grid/cell";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import StyledLinearProgress from "../../common/StyledLinearProgress";
import {useGlobalStyles} from "../../common/styles";
import {
  BottomToolbarWrapper,
  ToolbarItem,
  ToolbarRoot,
  ToolbarSeparator,
} from "../../common/Toolbar";
import CompareDialog from "./CompareDialog";

const useStyles = makeStyles()(() => ({
  buttonProgress: {
    position: "absolute",
    left: "50%",
    marginLeft: "-12px",
    color: "white",
  },
}));
const leftColumns = ["display_name", "value_result"];

const PoliciesTree = (props: {
  handleClose: () => void;
  caption: string;
  action: Function;
  id_project?: number;
}) => {
  const appContext = useContext(AppContext);
  const {projects} = appContext;

  const {handleClose, caption, action, id_project} = props;

  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    "policy_name",
  ] as Array<string>);

  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState([] as columnType);

  const [columnWidths, setColumnWidths] = useState([] as columnExtensionsType);

  const [sorting, setSorting] = useState([] as sortingType);

  useEffect(() => {
    updateRows();
  }, []);

  const updateRows = async () => {
    setLoading(true);
    const result = await action();
    setLoading(false);

    if (!result.rows.length) {
      return;
    }

    const columns = Object.keys(result.columns).map((key) => ({
      name: key,
      title: result.columns[key],
    }));

    setColumnWidths(
      Object.keys(result.columns).map((key, i) => ({
        columnName: key,
        width: i === 0 ? 220 : 300,
      }))
    );

    setRows(result.rows);
    setSorting([{columnName: columns[0].name, direction: "asc"}]);
    setColumns(columns);
  };

  const [rows, setRows] = useState([] as Array<DevicePolicyTreeType>);

  const [compareFormOpen, setCompareFormOpen] = useState(false);

  const [filterRows, setFilterRows] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleGenerateCsv = async () => {
    if (id_project && projects) {
      setIsDownloading(true);
      const result = await getGeneratePolicyTreeCsv(id_project);
      setIsDownloading(false);
      if (result.success) {
        // filename with transliterated name of a project
        const smashedProjects: Array<projectType> = [];
        projects.forEach((project: projectType) =>
          smashTree(project, smashedProjects)
        );
        const project = smashedProjects.filter(
          (p: projectType) => p.id_project === id_project
        )[0];
        const fileName = `project_policies_${project.project_name}`;

        downloadFile(result.file, `${transliterate(fileName)}.csv`);
      }
    }
  };

  const classes = useGlobalStyles();
  const {classes: localClasses} = useStyles();

  return (
    <React.Fragment>
      {loading ? (
        <Dialog open={true} keepMounted TransitionComponent={Transition}>
          <DialogTitle>Идет загрузка...</DialogTitle>
          <DialogContent>
            <StyledLinearProgress />
          </DialogContent>
        </Dialog>
      ) : (
        <React.Fragment>
          {compareFormOpen && id_project && (
            <CompareDialog
              idProject={id_project}
              onClose={() => setCompareFormOpen(false)}
            />
          )}
          <Dialog
            open={true}
            maxWidth="xl"
            fullWidth
            onClose={() => handleClose()}
            keepMounted
            TransitionComponent={Transition}
          >
            <ClosableDialogTitle onClose={() => handleClose()}>
              {caption}
            </ClosableDialogTitle>
            <DialogContent>
              {Object.keys(columns).length > 0 && (
                <Grid
                  columns={columns}
                  rows={
                    filterRows
                      ? rows.filter(
                          (row) => row.value_default !== row.value_result
                        )
                      : rows
                  }
                  getRowId={(row: any) => columns.length > 0 && row.id_pol}
                >
                  {/*Sorting*/}
                  <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                  />
                  <IntegratedSorting />

                  {/** Toolbar */}
                  <Toolbar rootComponent={ToolbarRoot} />
                  <Template name="toolbarContent">
                    <React.Fragment>
                      {id_project && (
                        <React.Fragment>
                          <ToolbarItem>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                setCompareFormOpen(!compareFormOpen)
                              }
                              id="policy_tree_compare_form_open"
                            >
                              Сравнить
                            </Button>
                          </ToolbarItem>
                          <ToolbarItem>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleGenerateCsv}
                              id="policy_tree_export"
                            >
                              Выгрузить настройки
                              {isDownloading && (
                                <CircularProgress
                                  size={28}
                                  className={localClasses.buttonProgress}
                                />
                              )}
                            </Button>
                          </ToolbarItem>
                        </React.Fragment>
                      )}
                      <ToolbarItem id="policies_group">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={filterRows}
                              onChange={() => setFilterRows((prev) => !prev)}
                              color="primary"
                            />
                          }
                          label={filterRows ? "Переопределенные" : "Все"}
                        />
                      </ToolbarItem>
                    </React.Fragment>
                    <TemplatePlaceholder />
                  </Template>

                  {/*Search*/}
                  <SearchState />
                  <StyledSearchPanel />
                  <IntegratedFiltering />

                  <StyledVirtualTable height="70vh" wrap />

                  {/*Column Visibility*/}
                  <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                  />
                  <StyledColumnChooser />

                  {/*Column Resizing*/}
                  <ColumnResizingHeader
                    resizingMode="widget"
                    columnExtensions={columnWidths}
                    localStorageName="policies_tree"
                    autoWidthColumnName={columns[columns.length - 1].name}
                  />

                  <TableFixedColumns leftColumns={leftColumns} />

                  {loading && <LoadingIndicator />}
                </Grid>
              )}
              <BottomToolbarWrapper>
                <ToolbarSeparator />
                <Box>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClose()}
                  >
                    Закрыть
                  </Button>
                </Box>
              </BottomToolbarWrapper>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PoliciesTree;
