const actionPermissions = new Map<string, number>([
  ["button_add_project", 2],
  ["button_delete_projects", 2],
  ["projects_selection", 2],
  ["tasks_setting_button", 3],
  ["delete_app_versions", 3],
]);

export const actionHasPermissions = (
  user_type: number,
  componentId?: string | null
) =>
  user_type >=
  ((user_type && componentId && actionPermissions.get(componentId)) ?? 1);

export default actionHasPermissions;
