import Column from "../../../Tables/Column";
import moment from "moment/moment";
import {FRONT_DATETIME_FORMAT} from "../../../../common/types";
import {Link} from "@mui/material";
import {ScreenshotFileType} from "./DeviceScreenshotTypes";
import {downloadFileFromUrlRcApi} from "../utils/utils.";

const DeviceScreenshotColumns = [
  new Column("datetime", "Дата создания", 250, {
    getCellValue: (row: ScreenshotFileType) =>
      row.datetime
        ? moment(row.datetime).format(FRONT_DATETIME_FORMAT)
        : undefined,
  }),
  new Column("name", "Имя файла", 150, {auto: true}),
  new Column("size", "Размер в байтах", 150),
  new Column("download", "Ссылка для скачивания", 150, {
    getCellValue: (row: ScreenshotFileType) => (
      <Link
        onClick={() =>
          downloadFileFromUrlRcApi(`${row.baseUrl}${row.name}`, row.name)
        }
        title={row.name}
      >
        Скачать
      </Link>
    ),
  }),
];

export default DeviceScreenshotColumns;
