import React, {useRef} from "react";
import {
  TableColumnResizing,
  TableColumnResizingProps,
} from "@devexpress/dx-react-grid-material-ui";
import {columnExtensionsType} from "../../../common/types";
import {TableColumn, TableColumnWidthInfo} from "@devexpress/dx-react-grid";
import {Plugin, TemplateConnector} from "@devexpress/dx-react-core";
import {useLocalStorageColumnWidths} from "../../../common/customHooks";

export type StyledColumnResizingProps = {
  autoWidthColumnName?: string;
  localStorageName?: string;
  columnExtensions: columnExtensionsType;
} & TableColumnResizingProps;

const StyledColumnResizing = (props: StyledColumnResizingProps) => {
  const {resizingMode, columnExtensions} = props;
  const [storedColumnWidths, setStoredColumnWidths] =
    useLocalStorageColumnWidths(
      props.localStorageName &&
        `${props.localStorageName}_table_default_widths`,
      columnExtensions.map(({columnName, width}) => ({
        columnName,
        width,
      }))
    );

  const viewportWidthRef = useRef(0);
  const renderNum = useRef(0);
  const columnWidths = useRef<TableColumnWidthInfo[]>(storedColumnWidths);

  const updateWidths = (widths: TableColumnWidthInfo[]) => {
    columnWidths.current = widths;
    setStoredColumnWidths(widths);
  };

  const toNumber = (v: number | string | undefined) =>
    typeof v === "number" ? v : 0;

  const updateAutoColumnWidth = (
    viewportWidth: number,
    tableColumns: TableColumn[]
  ) => {
    if (viewportWidth === viewportWidthRef.current) {
      return;
    }
    viewportWidthRef.current = viewportWidth;
    const widths = tableColumns.map(
      (cur) =>
        toNumber(
          cur.width ||
            columnWidths.current.find((c) => c.columnName === cur.column?.name)
              ?.width
        ) || 0
    );
    const widthsSum = widths.reduce((acc, cur) => acc + cur, 0);

    if (viewportWidth != widthsSum) {
      const autoColumnMinWidth = props.columnExtensions.find(
        (c) => c.columnName === props.autoWidthColumnName
      )?.minWidth;
      const currentAutoColumnWidth = toNumber(
        tableColumns.find((c) => c.column?.name === props.autoWidthColumnName)
          ?.width
      );
      const shift = viewportWidth - widthsSum - 1;
      if (
        shift !== 0 &&
        currentAutoColumnWidth &&
        autoColumnMinWidth &&
        currentAutoColumnWidth + shift >= autoColumnMinWidth
      ) {
        // recreate object to trigger TableColumnResizing clearCache function
        columnWidths.current = columnWidths.current.map((c) =>
          c.columnName === props.autoWidthColumnName
            ? {...c, width: currentAutoColumnWidth + shift}
            : c
        );
      }
    }
  };

  const fixMinWidths = () =>
    (columnWidths.current = columnWidths.current.map((column) => {
      const minWidth = columnExtensions.find(
        (c) => c.columnName === column.columnName
      )?.minWidth;
      if (minWidth && typeof column.width === "number")
        return {...column, width: Math.max(column.width, minWidth)};
      else return column;
    }));

  return (
    <Plugin name="StyledColumnResizing" dependencies={[{name: "VirtualTable"}]}>
      <TemplateConnector>
        {({viewport, tableColumns}) => {
          if (renderNum.current === 2) {
            // firstly fix the case where for some reasons width is less than minWidth
            fixMinWidths();
          } else if (
            // after the second render table width and min widths are known
            // which are needed for calculations
            renderNum.current > 2 &&
            props.autoWidthColumnName
          ) {
            updateAutoColumnWidth(viewport.width, tableColumns);
          }
          renderNum.current += 1;
          return (
            <TableColumnResizing
              columnWidths={columnWidths.current}
              resizingMode={resizingMode}
              columnExtensions={columnExtensions}
              onColumnWidthsChange={updateWidths}
            />
          );
        }}
      </TemplateConnector>
    </Plugin>
  );
};

export default StyledColumnResizing;
