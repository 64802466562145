import Column from "../Tables/Column";
import moment from "moment/moment";
import {FRONT_DATETIME_FORMAT} from "../../common/types";
import {QrCodeType} from "../../common/APIRequests/qrCode/getQrCodes";

const QrCodeColumns = [
  new Column("token", "№", 100, {hiddenByDefault: true}),
  new Column("create_date", "Дата генерации", 250, {
    getCellValue: (row: QrCodeType) =>
      row.create_date
        ? moment(row.create_date).format(FRONT_DATETIME_FORMAT)
        : undefined,
  }),
  new Column("expire_date", "Дата окончания действия", 250, {
    getCellValue: (row: QrCodeType) =>
      row.expire_date
        ? moment(row.expire_date).format(FRONT_DATETIME_FORMAT)
        : undefined,
  }),
  new Column("login", "Пользователь", 150),
  new Column("project_name", "Проект", 150, {auto: true}),
];

export default QrCodeColumns;
