import {Grid, Theme} from "@mui/material";
import {useContext, useEffect, useMemo, useState} from "react";
import {makeStyles} from "tss-react/mui";
import getApplications, {
  ApplicationType,
} from "../../common/APIRequests/application/getApplications";
import getApplicationVersions, {
  applicationVersionType,
} from "../../common/APIRequests/applicationVersion/getApplicationVersions";
import getBranch, {
  geographyType,
} from "../../common/APIRequests/geography/getBranch";
import {projectType} from "../../common/APIRequests/project/getProjects";
import {taskFilterType} from "../../common/APIRequests/task/getTasks";
import {optionType} from "../../common/types";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import StringFilterField from "../common/filterFields/StringFilterField";
import {generateProjectOptions} from "../common/functions";
import {FilterComponentProps} from "../Tables/CoreTable";
import {DEFAULT_TASK_FILTER} from "./TasksTable";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: "62vh",
    zIndex: 1000,
  },
  filterItem: {
    padding: theme.spacing(1),
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
}));

const TaskFilter = (props: FilterComponentProps<taskFilterType>) => {
  const {loading} = props;
  const [filters, setFilters] = useState({...props.filters});
  const [filterLoading, setFilterLoading] = useState(false);

  const appContext = useContext(AppContext);
  const {projects} = appContext;

  const projectOptions = useMemo(() => {
    const result: Array<optionType> = [];
    projects.forEach((project: projectType) =>
      generateProjectOptions(project, result)
    );
    return result;
  }, [projects]);

  useEffect(() => {
    (async () => {
      updateApplications();
      updateGeoLevelOne();
    })();
  }, []);

  const [applications, setApplications] = useState<Array<ApplicationType>>([]);
  const updateApplications = async () => {
    const result = await getApplications();
    result.success && setApplications(result.rows);
  };

  const [appVersions, setAppVersions] = useState<Array<applicationVersionType>>(
    []
  );
  useEffect(() => {
    (async () => {
      setFilterLoading(true);
      await updateAppVersions();
      setFilterLoading(false);
    })();
  }, [filters.id_app]);
  const updateAppVersions = async () => {
    if (filters.id_app) {
      setFilterLoading(true);
      const result = await getApplicationVersions(filters.id_app);
      result.success && setAppVersions(result.rows);
      setFilterLoading(false);
    } else {
      setAppVersions([]);
      setFilters({...filters, id_app_version: null});
    }
  };

  const [geoLevelOne, setGeoLevelOne] = useState<Array<geographyType>>([]);
  const updateGeoLevelOne = async () => {
    setFilterLoading(true);
    const result = await getBranch();
    result.success && setGeoLevelOne(result.rows);
    setFilterLoading(false);
  };

  const [geoLevelTwo, setGeoLevelTwo] = useState<Array<geographyType>>([]);
  useEffect(() => {
    (async () => {
      await updateGeoLevelTwo();
    })();
  }, [filters.id_net]);
  const updateGeoLevelTwo = async () => {
    if (filters.id_net) {
      setFilterLoading(true);
      const result = await getBranch(filters.id_net);
      result.success && setGeoLevelTwo(result.rows);
      setFilterLoading(false);
    } else {
      setGeoLevelTwo([]);
    }
  };

  const [login, setLogin] = useState<string | null>(filters.login);
  useEffect(() => {
    filters.login !== login && setLogin(filters.login);
  }, [filters]);

  const [codeDivision, setCodeDivision] = useState<string | null>(
    filters.code_division
  );
  useEffect(() => {
    filters.code_division !== codeDivision &&
      setCodeDivision(filters.code_division);
  }, [filters]);

  const [focus, setFocus] = useState<string | undefined>(undefined);

  const {classes: localClasses} = useStyles();

  return (
    <>
      <Grid container>
        {projectOptions.length > 0 && (
          <Grid item xs={4} className={localClasses.filterItem}>
            <SelectSingleFilterField
              name="id_project"
              includeAllOption
              options={projectOptions}
              label="Проект"
              value={
                (filters.id_project && filters.id_project.toString()) || null
              }
              onFocus={() => setFocus("id_project")}
              update={(value: string | null) => {
                setFilters({
                  ...filters,
                  id_project: value ? parseInt(value) : null,
                });
              }}
              disabled={loading || filterLoading || !projectOptions.length}
              focus={focus}
            />
          </Grid>
        )}
        {applications.length > 0 && (
          <Grid item xs={4} className={localClasses.filterItem}>
            <SelectSingleFilterField
              name="id_app"
              includeAllOption
              options={applications.map((app: ApplicationType) => ({
                key: app.id_app,
                value: app.id_app,
                text: app.name,
              }))}
              label="Приложение"
              value={(filters.id_app && filters.id_app.toString()) || null}
              onFocus={() => setFocus("id_app")}
              update={(value: string | null) => {
                setFilters({
                  ...filters,
                  id_app: value ? parseInt(value) : null,
                  id_app_version: null,
                });
              }}
              disabled={loading || filterLoading || !projectOptions.length}
              focus={focus}
            />
          </Grid>
        )}
        <Grid item xs={4} className={localClasses.filterItem}>
          <SelectSingleFilterField
            name="id_app_version"
            includeAllOption
            options={appVersions.map((aV: applicationVersionType) => ({
              key: aV.id_app_version,
              value: aV.id_app_version,
              text: aV.name,
            }))}
            label="Версия приложения"
            value={
              (filters.id_app_version && filters.id_app_version.toString()) ||
              null
            }
            onFocus={() => setFocus("id_app_version")}
            update={(value: string | null) => {
              setFilters({
                ...filters,
                id_app_version: value ? parseInt(value) : null,
              });
            }}
            disabled={loading || filterLoading || !appVersions.length}
            focus={focus}
          />
        </Grid>
        {geoLevelOne.length && (
          <Grid item xs={4} className={localClasses.filterItem}>
            <SelectSingleFilterField
              name="id_one_geo"
              includeAllOption
              options={geoLevelOne.map((levOne: geographyType) => ({
                key: levOne.id_geo,
                value: levOne.id_geo,
                text: levOne.name_geo,
              }))}
              label="Сеть"
              value={(filters.id_net && filters.id_net) || null}
              onFocus={() => setFocus("id_one_geo")}
              update={(value: string | null) => {
                setFilters({
                  ...filters,
                  id_net: value,
                  id_branch: null,
                });
              }}
              disabled={loading || filterLoading || !geoLevelOne.length}
              focus={focus}
            />
          </Grid>
        )}
        <Grid item xs={4} className={localClasses.filterItem}>
          <SelectSingleFilterField
            name="id_geo"
            includeAllOption
            options={geoLevelTwo.map((levTwo: geographyType) => ({
              key: levTwo.id_geo,
              value: levTwo.id_geo,
              text: levTwo.name_geo,
            }))}
            label="Филиал"
            value={filters.id_branch}
            onFocus={() => setFocus("id_geo")}
            update={(value: string | null) => {
              setFilters({
                ...filters,
                id_branch: value,
              });
            }}
            disabled={loading || filterLoading || !geoLevelTwo.length}
            focus={focus}
          />
        </Grid>
        <Grid item xs={4} className={localClasses.filterItem}>
          <StringFilterField
            label="Код подразделения"
            name="code_division"
            value={codeDivision || ""}
            onChange={(value: string) => setCodeDivision(value)}
            onFocus={() => setFocus("code_division")}
            onEnterPress={(value: string) => {
              setFilters({...filters, code_division: value.trim()});
            }}
            onResetClick={() => {
              setFilters({...filters, code_division: ""});
              setCodeDivision("");
            }}
            disabled={loading || filterLoading}
          />
        </Grid>
        <Grid item xs={4} className={localClasses.filterItem}>
          <StringFilterField
            label="Инициатор"
            name="login"
            value={login || ""}
            onChange={(value: string) => setLogin(value)}
            onFocus={() => setFocus("login")}
            onEnterPress={(value: string) => {
              setFilters({...filters, login: value.trim()});
            }}
            onResetClick={() => {
              setFilters({...filters, login: ""});
              setCodeDivision("");
            }}
            disabled={loading || filterLoading}
          />
        </Grid>
      </Grid>
      <FormButtons
        loading={loading || filterLoading}
        handleSubmit={() => {
          setFocus(undefined);
          props.updateFilters(filters);
        }}
        handleClose={() => {
          setFocus(undefined);
          setFilterLoading(true);
          setFilters(DEFAULT_TASK_FILTER);
          props.updateFilters(DEFAULT_TASK_FILTER);
          setFilterLoading(false);
          props.onClose?.();
        }}
        submitMessage="Показать"
        cancelMessage="Сбросить все"
      />
    </>
  );
};

export default TaskFilter;
