import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type projectType = {
  id_project: number;
  project_name: string;
  ldap_group: string;
  higher: number | null;
  successor: string;
  description?: string;
  children?: Array<projectType>;
};

const getProjects = async () => baseGetRowsRequest<projectType>(`/project/`);

export default getProjects;
