import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Dialog, DialogContent} from "@mui/material";
import React, {useEffect, useState} from "react";
import {deviceProfileType} from "../../../common/APIRequests/deviceProfile/getDeviceProfiles";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import deleteProjectProfiles from "../../../common/APIRequests/projectProfile/deleteProjectProfiles";
import getProjectProfiles, {
  projectProfileType,
} from "../../../common/APIRequests/projectProfile/getProjectProfiles";
import toggleProjectProfiles from "../../../common/APIRequests/projectProfile/toggleProjectProfiles";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../../common/customHooks";
import {columnType, sortingType} from "../../../common/types";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import {AddNewButton, RefreshButton} from "../../common/buttons/ToolbarButtons";
import SwitchWithPin from "../../common/buttons/SwitchWithPin";
import {LoadingIndicator, Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {
  StyledSelectionCell,
  StyledSelectionCellDisabled,
  StyledTableSelection,
  StyledVirtualTable,
} from "../../common/grid/cell";
import {BooleanTypeProvider} from "../../common/grid/providers";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../../common/styles";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import ProjectProfiles from "./ProjectProfiles";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";

const columns: columnType = [
  {name: "id_prof", title: "ID"},
  {name: "name", title: "Наименование"},
  {name: "specification", title: "Описание"},
  {name: "is_inherited", title: "Наследуется"},
  {name: "id_project", title: "ID проекта"},
  {name: "name_project", title: "Наименование проекта"},
  {name: "is_active", title: "Состояние"},
  {name: "priority", title: "Приоритет"},
];
const columnExtensions = [
  {columnName: "id_prof", width: 100},
  {columnName: "name", width: 200},
  {columnName: "specification", width: 200},
  {columnName: "is_inherited", width: 200},
  {columnName: "id_project", width: 200},
  {columnName: "name_project", width: 200},
  {columnName: "is_active", width: 150},
  {columnName: "priority", width: 150},
];
const columnAlignExtension = [{columnName: "is_inherited", align: "center"}];

const ProjectProfilesTab = (props: {
  project: projectType;
  loading: boolean;
  setLoading: Function;
}) => {
  const {project, loading, setLoading} = props;

  const [selection, setSelection] = useState([] as Array<string | number>);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("project_profile_column", [
    "id_prof",
    "is_inherited",
    "id_project",
    "name_project",
  ]);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "project_profile_sorting",
    [{columnName: "name", direction: "asc"}]
  );

  const [rows, setRows] = useState([] as Array<projectProfileType>);

  useEffect(() => {
    updateRows();
  }, []);

  const updateRows = async () => {
    setSelection([]);

    setLoading(true);
    const result = await getProjectProfiles(project.id_project);
    setLoading(false);

    result.success && setRows(result.rows);
  };

  const [addOpen, setAddOpen] = useState(false);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "project_profile_tab_ordering",
    columns.map((row) => row.name)
  );

  const ActivityFormatter = (data: any) => {
    return (
      <SwitchWithPin
        onChange={() => toggleActive(data.row)}
        checked={Boolean(data.row.is_active)}
        disabled={data.row.is_inherited == 1}
        checkPin
      />
    );
  };
  const ActivityProvider = (props: any) => (
    <DataTypeProvider formatterComponent={ActivityFormatter} {...props} />
  );

  const toggleActive = async (current_row: deviceProfileType) => {
    if (loading) {
      return;
    }
    const is_active = current_row.is_active ? 0 : 1;
    const old_rows = rows;
    setRows(
      rows.map((row) =>
        row.id_prof === current_row.id_prof
          ? {...row, is_active: is_active}
          : row
      )
    );
    setLoading(true);
    const result = await toggleProjectProfiles(
      project.id_project,
      [current_row.id_prof],
      is_active
    );
    setLoading(false);
    if (!result.success) {
      setRows(old_rows);
    }
  };

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      {addOpen && (
        <Dialog
          open={true}
          onClose={() => setAddOpen(false)}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="lg"
          id="project_profiles_dialog"
        >
          <ClosableDialogTitle
            id="add-project-modal"
            onClose={() => setAddOpen(false)}
          >
            Привязка профиля к проекту {project.project_name}
          </ClosableDialogTitle>
          <DialogContent>
            <ProjectProfiles
              handleClose={() => setAddOpen(false)}
              update={updateRows}
              installed={rows.reduce(
                (result: Array<number>, row) =>
                  row.is_inherited === 0 ? [...result, row.id_prof] : result,
                []
              )}
              project={project}
            />
          </DialogContent>
        </Dialog>
      )}
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_prof}>
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <AddNewButton
              onClick={() => setAddOpen(true)}
              id="project_profiles_add_btn"
            />
          </ToolbarItem>
          <ToolbarItem id="project_profiles_delete">
            <MenuDeleteAction
              selection={selection}
              update={() => updateRows()}
              action={(ids: Array<number | string>) =>
                deleteProjectProfiles(project.id_project, ids)
              }
              checkPin
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateRows}
              id="project_profiles_refresh"
            />
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              className={
                row.is_inherited === 1
                  ? classes.gridRowSelected
                  : classes.gridRow
              }
              data-type="project_profiles_row"
              data-id={row.id_prof}
            />
          )}
          height="40em"
          columnExtensions={columnAlignExtension}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="project_profile"
          autoWidthColumnName={"specification_profile"}
        />

        <ActivityProvider for={["is_active"]} />
        <BooleanTypeProvider for={["is_inherited"]} />

        {/*Row Selection*/}
        <SelectionState
          selection={selection}
          onSelectionChange={(selection: Array<string | number>) => {
            setSelection(
              rows.reduce(
                (result: Array<string | number>, row) =>
                  row.is_inherited === 0 && selection.includes(row.id_prof)
                    ? [...result, row.id_prof]
                    : result,
                []
              )
            );
          }}
        />
        <IntegratedSelection />
        <StyledTableSelection
          cellComponent={({row, ...restProps}: any) =>
            row.is_inherited === 1 ? (
              <StyledSelectionCellDisabled {...restProps} disabled />
            ) : (
              <StyledSelectionCell {...restProps} />
            )
          }
          showSelectAll
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default ProjectProfilesTab;
