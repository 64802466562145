import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type deviceLocationType = {
  id_device: number;
  id_pos: number;
  information: string;
  pos_date: string;
  width: number;
  length: number;
  precision: string;
  number: number;
};

const getDeviceLocations = async (
  id_device: number,
  sort_field: string,
  sort_desc: boolean
) =>
  baseGetRowsRequest<deviceLocationType>(`/device/${id_device}/location/`, {
    sort_field,
    sort_desc,
  });

export default getDeviceLocations;
