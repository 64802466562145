import {ConsoleLogsFilterType} from "../../../components/ConsoleLogs/ConsoleLogsFilter";
import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";

export type ConsoleLogSourceType = 1 | 2 | 3 | 4 | 5 | 6;
export type ConsoleLogActionType = 1 | 2 | 3 | 4 | 5;

export type ConsoleLogType = {
  id_console_log: number;
  source: ConsoleLogSourceType;
  object: string;
  action: ConsoleLogActionType;
  description: string;
  ldap_login: string;
  log_date: string;
};

const getConsoleLogs = (
  page?: number,
  page_size?: number,
  sort_field?: string,
  sort_desc?: boolean,
  filters?: ConsoleLogsFilterType
) =>
  baseGetRowsRequestWithCount<ConsoleLogType>("/console_log/", {
    page,
    page_size,
    sort_field,
    sort_desc,
    filters,
  });

export default getConsoleLogs;
