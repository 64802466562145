import * as React from "react";
import {useState} from "react";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import UserColumns from "./UserColumns";
import getUsers, {UserType} from "../../common/APIRequests/user/getUsers";
import UserForm from "./UserForm";
import {UserFilterType, UsersFilter} from "./UsersFilter";
import {useTrigger} from "../../common/customHooks";
import {DataTypeProvider} from "@devexpress/dx-react-grid";
import DeleteSessionButton from "./DeleteSessionButton";

export const UsersTable = () => {
  const updateRows: UpdateRowsType<UserType, UserFilterType> = ({
    sorting,
    paging,
    filters,
  }) =>
    getUsers(
      paging?.page,
      paging?.page_size,
      sorting?.sort_field,
      sorting?.sort_desc,
      filters
    );

  const [rowClicked, setRowClicked] = useState(
    undefined as UserType | undefined
  );

  const [refreshTrigger, refresh] = useTrigger();

  return (
    <CoreTable
      columns={UserColumns}
      getRowId={(row) => row.id_user}
      name="users"
      updateRows={updateRows}
      onRowClick={setRowClicked}
      updateTrigger={refreshTrigger}
      pagingEnabled
      sortingEnabled
      defaultSorting={{columnName: "ldap_login", direction: "desc"}}
      ToolBarFilterComponent={UsersFilter}
      plugins={[
        <DataTypeProvider
          key="users_delete_session_action"
          for={["delete_session_action"]}
          formatterComponent={(props: {row?: UserType}) =>
            props.row?.authorized_devices_count === 1 ? (
              <DeleteSessionButton id_user={props.row.id_user} />
            ) : null
          }
        />,
      ]}
      dialog={
        rowClicked && (
          <UserForm
            handleClose={() => setRowClicked(undefined)}
            update={refresh}
            user={rowClicked}
          />
        )
      }
    />
  );
};
