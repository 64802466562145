import {IconButton} from "@mui/material";
import React, {useContext, useState} from "react";
import {AppContext} from "../../App-context";
import RequestPinDialog from "../pin/RequestPinDialog";

type Props = {
  onClick: () => void;
  checkPin?: boolean;
  children: JSX.Element;
  loading: boolean;
  setLoading: (l: boolean) => void;
};

const IconButtonWithPin = (props: Props) => {
  const appContext = useContext(AppContext);
  const {pinStatus} = appContext;

  const [pinOpen, setPinOpen] = useState(false);

  const submit = async (e: React.SyntheticEvent<HTMLElement, Event>) => {
    if (props.checkPin && pinStatus) {
      setPinOpen(true);
    } else {
      props.setLoading(true);
      await props.onClick();
      props.setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {pinOpen && props.checkPin && (
        <RequestPinDialog
          onClose={() => setPinOpen(false)}
          onSubmit={props.onClick}
        />
      )}
      <IconButton onClick={submit} disabled={props.loading} size="large">
        {props.children}
      </IconButton>
    </React.Fragment>
  );
};

export default IconButtonWithPin;
