import {CSSProperties} from "react";

const FindPhoneCommandIcon = (props: {style?: CSSProperties}) => {
  return (
    <img
      src={process.env.PUBLIC_URL + "/images/find_phone_icon.svg"}
      height={`${props.style?.height || 20}px`}
      alt="find device image"
      style={props.style}
    />
  );
};

export default FindPhoneCommandIcon;
