import * as React from "react";
import {useState} from "react";
import getTaskTemplates, {
  TaskTemplateType,
} from "../../common/APIRequests/taskTemplate/getTaskTemplates";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import {AddNewButton} from "../common/buttons/ToolbarButtons";
import TaskTemplatesColumns from "./TaskTemplatesColumns";
import TaskTemplateDialog from "./Dialogs/TaskTemplateDialog";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import deleteTaskTemplates from "../../common/APIRequests/taskTemplate/deleteTaskTemplates";
import {useTrigger} from "../../common/customHooks";

const TaskTemplatesTable = () => {
  const [refreshTrigger, refresh] = useTrigger();

  const [rowClicked, setRowClicked] = useState(
    undefined as TaskTemplateType | undefined
  );

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const updateTaskTemplates: UpdateRowsType<TaskTemplateType> = () =>
    getTaskTemplates();

  return (
    <>
      {isAddDialogOpen && (
        <TaskTemplateDialog
          onClose={() => setAddDialogOpen(false)}
          update={refresh}
        />
      )}
      {rowClicked && (
        <TaskTemplateDialog
          onClose={() => setRowClicked(undefined)}
          update={refresh}
          taskTemplateId={rowClicked.id_template}
        />
      )}
      <CoreTable
        name={"task_templates"}
        columns={TaskTemplatesColumns}
        updateRows={updateTaskTemplates}
        updateTrigger={refreshTrigger}
        defaultSorting={{columnName: "id_task_template", direction: "asc"}}
        integratedSearch
        onRowClick={setRowClicked}
        getRowId={(row: TaskTemplateType) => row.id_template || ""}
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddDialogOpen(true)}
            key="task_template_add_button"
          />,
        ]}
        selectionEnabled
        selectionButton={(selection, update) => (
          <MenuDeleteAction
            selection={selection}
            update={update}
            action={deleteTaskTemplates}
            checkPin
          />
        )}
      />
    </>
  );
};

export default TaskTemplatesTable;
