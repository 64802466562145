import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

export function basePatchRequest<T>(
  url: string,
  data?: any,
  params?: any
): Promise<{
  success: boolean;
  row: T | undefined;
  errors: errorType;
}> {
  return new Promise((resolve) =>
    apiClient({
      method: "patch",
      url,
      data,
      params,
    })
      .then((response) =>
        resolve({
          success: true,
          row: response.data as T,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          row: undefined,
          errors: errors,
        });
      })
  );
}
