import React, {useEffect, useState} from "react";
import ruLocale from "date-fns/locale/ru";
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {TextField} from "@mui/material";
import moment from "moment";
import {BACKEND_DATETIME_FORMAT} from "../../../common/types";

const PickerDateTime = (props: {
  setValue: (value: string | null) => void;
  onError?: () => void;
  value: string | null;
  name: string;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  format?: string;
}) => {
  const [tempValue, setTempValue] = useState<string | null>(
    props.value ?? null
  );

  const submitValue = (date: string | null) => {
    const formattedDate = date && moment(date).format(BACKEND_DATETIME_FORMAT);
    props.setValue(formattedDate);
  };

  useEffect(() => setTempValue(props.value ?? null), [props.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <DateTimePicker
        label={props.label}
        value={tempValue}
        onChange={(date: any) => {
          setTempValue(date ? date.toString() : null);
        }}
        ampm={false}
        mask="__.__.____ __:__:__"
        inputFormat={props.format ? props.format : "dd.MM.yyyy HH:mm:ss"}
        renderInput={(props: any) => (
          <TextField label={props.label} {...props} />
        )}
        clearable={true}
        onError={(reason: any, date: any) => {
          if (!reason) {
            submitValue(date ? date.toString() : null);
          } else {
            props.onError?.();
          }
        }}
        onAccept={(date: any) => {
          submitValue(date);
        }}
        disabled={props.disabled}
      />
    </LocalizationProvider>
  );
};

export default PickerDateTime;
