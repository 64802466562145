import React, {useState} from "react";
import {Dialog, FormControl, FormHelperText, TextField} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {Transition} from "../../common/components";
import FormButtons from "../../common/buttons/FormButtons";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {dataType} from "./MenuCommandsAction";
import RequestPinDialog from "../../common/pin/RequestPinDialog";

const CommandsMessage = (props: {
  loading: boolean;
  handleClose: () => void;
  sendCommand: (data?: dataType) => void;
}) => {
  const {loading, handleClose, sendCommand} = props;
  const [message, setMessage] = useState("");
  const [pinOpen, setPinOpen] = useState(false);
  const [error, setError] = useState(false);

  const validate = async () => {
    if (!message) {
      setError(true);
      return;
    }
    setPinOpen(true);
  };

  return (
    <>
      {pinOpen && (
        <RequestPinDialog
          onClose={() => setPinOpen(false)}
          onSubmit={() => sendCommand({additionalData: message})}
        />
      )}
      <Dialog
        open={true}
        onClose={() => handleClose()}
        keepMounted
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
      >
        <ClosableDialogTitle onClose={() => handleClose()}>
          Отправить сообщение
        </ClosableDialogTitle>
        <DialogContent>
          <FormControl error={error} fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              id="comment_text"
              label="Сообщение"
              name="message"
              placeholder="Сообщение"
              value={message}
              error={error}
              aria-describedby="component-error-comment_text"
              disabled={loading}
              multiline
              maxRows="6"
              onChange={(e: any) => {
                setError(false);
                setMessage(e.target.value);
              }}
              inputProps={{maxLength: 2000}}
            />
            {error && (
              <FormHelperText id="component-error-comment_text">
                Введите сообщение
              </FormHelperText>
            )}
          </FormControl>
          <FormButtons
            loading={loading}
            handleSubmit={validate}
            handleClose={handleClose}
            submitMessage="Отправить"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CommandsMessage;
