import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {SortingState} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import moment from "moment";
import React, {useEffect, useState} from "react";
import getDeviceLogs, {
  deviceLogType,
} from "../../../common/APIRequests/logs/getDeviceLogs";
import getDeviceLogTags from "../../../common/APIRequests/logs/getDeviceLogTags";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
  useMounted,
} from "../../../common/customHooks";
import {
  BACKEND_DATETIME_FORMAT,
  columnType,
  FRONT_DATETIME_FORMAT,
  sortingType,
} from "../../../common/types";
import {RefreshButton} from "../../common/buttons/ToolbarButtons";
import PickerDateTime from "../../common/formFields/PickerDateTime";
import {StyledVirtualTable} from "../../common/grid/cell";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import {useGlobalStyles} from "../../common/styles";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../../common/components";

const columns: columnType = [
  {
    name: "log_time",
    title: "Время события",
    getCellValue: (deviceLog: deviceLogType) =>
      moment(deviceLog.log_time).format(FRONT_DATETIME_FORMAT),
  },
  {name: "tag", title: "Тэг"},
  {name: "message", title: "Сообщение"},
  {name: "id_device", title: "ID устройства"},
  {name: "id_devlog", title: "ID лога"},
  {name: "information", title: "Информация"},
];
const columnExtensions = [
  {columnName: "log_time", width: 200},
  {columnName: "tag", width: 200},
  {columnName: "message", width: 200},
  {columnName: "id_device", width: 100},
  {columnName: "id_devlog", width: 100},
  {columnName: "information", width: 200},
];

const hiddenColumnNames = ["id_device", "id_devlog", "information"];

const Logs = (props: {
  loading: boolean;
  setLoading: Function;
  id_device: number;
}) => {
  const {loading, setLoading, id_device} = props;

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "device_logs_sorting",
    [{columnName: "tag", direction: "desc"}]
  );

  const [tags, setTags] = useState([] as Array<string>);
  useEffect(() => {
    (async () => {
      const result = await getDeviceLogTags(id_device);
      result.success && setTags(result.rows);
    })();
  }, []);

  const [values, setValues] = useState({
    start: null,
    end: null,
    tag: "",
  } as {
    start: string | null;
    end: string | null;
    tag: string;
  });

  const [rows, setRows] = useState([] as Array<deviceLogType>);

  const [hideFilters, setHideFilters] = useState(true);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "log_ordering",
    columns.map((row) => row.name)
  );

  const isMounted = useMounted();

  useEffect(() => {
    updateLogs();
  }, [values, sorting]);

  const updateLogs = async () => {
    const {direction, columnName} = sorting[0];

    setLoading(true);
    const result = await getDeviceLogs(
      id_device,
      values.tag !== "all" ? [values.tag] : undefined,
      values.start
        ? moment(values.start).format(BACKEND_DATETIME_FORMAT)
        : undefined,
      values.end
        ? moment(values.end).format(BACKEND_DATETIME_FORMAT)
        : undefined,
      undefined,
      columnName,
      direction == "desc"
    );
    setLoading(false);
    result.success && setRows(result.rows);

    if (!isMounted && result.rows.length > 0) {
      setHideFilters(false);
    }
  };

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_devlog}>
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateLogs}
              id="logs_refresh"
            />
          </ToolbarItem>
          <TemplatePlaceholder />
          <ToolbarItem>
            <PickerDateTime
              setValue={(value) => setValues({...values, start: value})}
              value={values.start}
              label="От"
              name="start_time"
              disabled={hideFilters}
            />
          </ToolbarItem>
          <ToolbarItem>
            <PickerDateTime
              setValue={(value) => setValues({...values, end: value})}
              value={values.end}
              label="До"
              name="end_time"
              disabled={hideFilters}
            />
          </ToolbarItem>
          <ToolbarItem>
            <FormControl style={{width: "200px"}}>
              <InputLabel htmlFor="deviceLogTag">Тэг</InputLabel>
              <Select
                id="deviceLogTag"
                value={values.tag}
                onChange={(e: any) => {
                  setValues({...values, tag: e.target.value});
                }}
                inputProps={{name: "deviceLogTag", id: "deviceLogTag"}}
                disabled={hideFilters}
              >
                <MenuItem key="all" value="all">
                  Все
                </MenuItem>
                {tags.map((tag: string) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ToolbarItem>
        </Template>

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              data-type="logs_row"
              data-id={row.id_devlog}
              {...restProps}
            />
          )}
          height="68vh"
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="device_log"
          autoWidthColumnName={"message"}
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default Logs;
