import React, {useState} from "react";
import ruLocale from "date-fns/locale/ru";
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import {TextField} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const PickerDate = (props: {
  setValue: (value: string | null) => void;
  value: string | null;
  name: string;
  label: string;
  disabled?: boolean;
}) => {
  const [tempValue, setTempValue] = useState<string | null>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <DateTimePicker
        label={props.label}
        value={tempValue}
        onChange={(date: any) => {
          setTempValue(date ? date.toString() : null);
        }}
        ampm={false}
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        renderInput={(props: any) => (
          <TextField label={props.label} {...props} />
        )}
        clearable={true}
        onError={(reason: any, date: any) => {
          if (!reason) props.setValue(date ? date.toString() : null);
        }}
        onAccept={(date: any) => props.setValue(date ? date.toString() : null)}
        disabled={props.disabled}
      />
    </LocalizationProvider>
  );
};

export default PickerDate;
