import * as React from "react";
import {useContext, useMemo} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {optionType} from "../../../common/types";
import {FormHelperText, TextField} from "@mui/material";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {generateProjectOptions} from "../../common/functions";
import {AppContext} from "../../App-context";
import CloseIcon from "@mui/icons-material/Close";
import {makeStyles} from "tss-react/mui";
import theme from "../../../theme";

const ComboboxClose = (props: {onClose: () => void}) => {
  return <CloseIcon fontSize="small" onClick={props.onClose} />;
};

type SelectProjectFormProps = {
  loading: boolean;
  selectedProjectId: number | undefined;
  onSelect: (projectId: number | undefined) => void;
  errors: string[];
};
const SelectProjectForm = (props: SelectProjectFormProps) => {
  const appContext = useContext(AppContext);
  const {projects} = appContext;

  const options = useMemo(() => {
    const result: Array<optionType> = [{key: 0, value: 0, text: ""}];
    projects.forEach((project: projectType) =>
      generateProjectOptions(project, result)
    );
    return result;
  }, [projects]);

  const {classes: localClasses} = makeStyles()(() => ({
    noOptions: {
      display: "none",
    },
  }))();

  return (
    <>
      <Autocomplete
        options={options}
        autoComplete={true}
        autoHighlight={true}
        placeholder="Проект"
        getOptionLabel={(option: optionType) => option.text}
        clearIcon={<ComboboxClose onClose={() => props.onSelect(undefined)} />}
        style={{marginTop: theme.spacing(2)}}
        disabled={props.loading}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            label="Проект"
            id="id_project"
            fullWidth
            name="id_project"
            error={!!props.errors}
            aria-describedby="component-error-id_project"
            disabled={props.loading}
          />
        )}
        value={
          (props.selectedProjectId
            ? options.filter(
                (o: optionType) => o.value === props.selectedProjectId
              )[0]
            : options[0]) || null
        }
        onChange={(e: any, option: optionType | null) => {
          if (option) {
            props.onSelect(option.value as number);
          }
        }}
        classes={{
          noOptions: localClasses.noOptions,
        }}
      />
      {props.errors && (
        <FormHelperText id="component-error-id_project">
          {props.errors}
        </FormHelperText>
      )}
    </>
  );
};

export default SelectProjectForm;
