import Column from "../Tables/Column";

const ApplicationColumns = [
  new Column("id_app", "ID", 150, {hiddenByDefault: true}),
  new Column("name", "Наименование", 200),
  new Column("app_uid", "Идентификатор Android", 200, {hiddenByDefault: true}),
  new Column("description", "Описание", 200, {auto: true}),
  new Column("visible", "Отображать", 100, {
    sortingEnabled: false,
    hiddenByDefault: true,
  }),
];

export default ApplicationColumns;
