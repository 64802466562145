import {
  AutocompleteRenderInputParams,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import {useContext, useMemo, useState} from "react";
import {projectType} from "../../common/APIRequests/project/getProjects";
import patchProject from "../../common/APIRequests/project/patchProject";
import postProject from "../../common/APIRequests/project/postProject";
import {errorType, optionType} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import BooleanField from "../common/formFields/BooleanField";
import StringField from "../common/formFields/StringField";
import {generateProjectOptions} from "../common/functions";
import {ToolbarItem} from "../common/Toolbar";

const ProjectForm = (props: {
  handleClose: (newRowId?: number) => void;
  project: projectType;
  loading: boolean;
  updateProjects: () => void;
  setLoading: (loading: boolean) => void;
}) => {
  const {handleClose, project, loading, setLoading, updateProjects} = props;

  const useStyles = makeStyles()(() => ({
    noOptions: {
      display: "none",
    },
  }));

  const appContext = useContext(AppContext);
  const {setAlert, projects} = appContext;
  const options = useMemo(() => {
    const result: Array<optionType> = [{key: 0, value: 0, text: ""}];
    projects.forEach((project: projectType) =>
      generateProjectOptions(project, result)
    );
    return result;
  }, [projects]);

  const isCreated = project.id_project !== 0;

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState(project);

  const handleSubmit = async () => {
    let result;

    setLoading(true);
    if (isCreated) {
      result = await patchProject(values.id_project, values);
    } else {
      result = await postProject(values);
    }

    if (result.success) {
      setErrors({});
      handleClose(isCreated ? undefined : result?.row?.id_project);
      setAlert({
        severity: "success",
        message: ["Проект сохранен"],
      });
      updateProjects();
    } else {
      setErrors(result.errors);
    }

    setLoading(false);
  };

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
  };

  const ComboboxClose = () => {
    return (
      <CloseIcon
        fontSize="small"
        onClick={() => setValues({...values, higher: null})}
      />
    );
  };

  const {classes: localClasses} = useStyles();

  return (
    <React.Fragment>
      <StringField
        setValue={handleChange("project_name")}
        value={values.project_name}
        name="project_name"
        label="Наименование"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 60}}
        disabled={loading || isCreated}
      />
      <StringField
        setValue={handleChange("description")}
        value={values.description || ""}
        name="description"
        label="Описание"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 1000}}
        multiline
        style={{marginTop: theme.spacing(2)}}
        disabled={loading}
      />
      <StringField
        setValue={handleChange("ldap_group")}
        value={values.ldap_group}
        name="ldap_group"
        label="Группа LDAP"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 60}}
        style={{marginTop: theme.spacing(2)}}
        disabled={loading || isCreated}
      />
      <FormControl
        error={!!errors.higher}
        fullWidth
        style={{marginTop: theme.spacing(2)}}
      >
        <Autocomplete
          options={options}
          autoComplete={true}
          autoHighlight={true}
          placeholder="Родитель проекта"
          getOptionLabel={(option: optionType) => option.text}
          clearIcon={<ComboboxClose />}
          disabled={loading || isCreated}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              variant="outlined"
              label="Родитель"
              id="higher"
              fullWidth
              name="higher"
              error={!!errors.higher}
              aria-describedby="component-error-higher"
              disabled={loading || isCreated}
            />
          )}
          value={
            values.higher
              ? options.filter((o: optionType) => o.value === values.higher)[0]
              : options[0]
          }
          onChange={(e, value: optionType | null) =>
            value && setValues({...values, higher: value.value as number})
          }
          classes={{
            noOptions: localClasses.noOptions,
          }}
        />
      </FormControl>
      {errors.higher && (
        <FormHelperText id="component-error-higher" error>
          {errors.higher}
        </FormHelperText>
      )}
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitMessage="Сохранить"
      >
        <ToolbarItem>
          <BooleanField
            setValue={handleChange("successor")}
            name="is_active"
            value={values.successor ? values.successor.toString() : "0"}
            label="Наследовать параметры"
            errors={errors}
            setErrors={setErrors}
            disabled={loading || isCreated}
          />
        </ToolbarItem>
      </FormButtons>
    </React.Fragment>
  );
};

export default ProjectForm;
