import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  CustomGrouping,
  GroupingState,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Dialog, DialogContent} from "@mui/material";
import React, {useEffect, useState} from "react";
import getPolicies, {
  policyType,
} from "../../common/APIRequests/policy/getPolicies";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {columnType, sortingType} from "../../common/types";
import {AddNewButton, RefreshButton} from "../common/buttons/ToolbarButtons";
import {LoadingIndicator, Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import {groupBy} from "../common/functions";
import {StyledTableGroupRow, StyledVirtualTable} from "../common/grid/cell";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import {BooleanTypeProvider} from "../common/grid/providers";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import PolicyForm from "./PolicyForm";
import GroupSwitch from "../LogEvents/GroupSwitch";

const columns: columnType = [
  {name: "id_pol", title: "ID"},
  {name: "display_name", title: "Наименование"},
  {name: "policy_name", title: "Системное наименование"},
  {name: "description", title: "Описание"},
  {name: "value_type", title: "Тип значения"},
  {name: "possible_values", title: "Возможные значения"},
  {
    name: "value",
    title: "Значение",
    getCellValue: (row: policyType) => {
      if (row.value_type === "boolean") {
        return row.value === "1" ? "true" : "false";
      } else return row.value;
    },
  },
  {name: "group_name", title: "Группа"},
  {name: "is_project", title: "Для проекта"},
  {name: "is_profile_device", title: "Для профиля устройств"},
  {name: "is_profile_app", title: "Для профиля приложений"},
];
const columnExtensions = [
  {columnName: "id_pol", width: 70},
  {columnName: "display_name", width: 200},
  {columnName: "policy_name", width: 250},
  {columnName: "description", width: 200},
  {columnName: "value_type", width: 150},
  {columnName: "possible_values", width: 200},
  {columnName: "value", width: 150},
  {columnName: "group_name", width: 100},
  {columnName: "is_project", width: 130},
  {columnName: "is_profile_device", width: 210},
  {columnName: "is_profile_app", width: 220},
];
const columnAlignExtension = [
  {columnName: "is_project", align: "center"},
  {columnName: "is_profile_device", align: "center"},
  {columnName: "is_profile_app", align: "center"},
];

const customGetChildGroups = (currentRows: Array<policyType>) => {
  const group_names: Array<string> = [];

  currentRows.forEach(
    (row: policyType) =>
      row.group_name &&
      group_names.indexOf(row.group_name) === -1 &&
      group_names.push(row.group_name)
  );

  group_names.sort();

  const grouped = groupBy<policyType>(
    currentRows,
    (row: policyType) => row.group_name
  );

  return group_names.map((name: string) => ({
    key: name,
    value: name,
    childRows: grouped.get(name),
  }));
};

const Policies = () => {
  let cancelled = false;

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [selected, setSelected] = useState(undefined as policyType | undefined);

  const [grouped, setGrouped] = useLocalStorage<boolean>(
    "policies_grouping",
    true
  );

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("policy_columns", ["id_pol", "policy_name"]);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "policies_sorting",
    [{columnName: "display_name", direction: "asc"}]
  );

  const updateRows = async () => {
    setLoading(true);
    const result = await getPolicies();
    if (cancelled) {
      return;
    }
    setLoading(false);

    result.success && setRows(result.rows);
  };

  const [rows, setRows] = useState([] as Array<policyType>);

  useEffect(() => {
    updateRows();
    return () => {
      cancelled = true;
    };
  }, []);

  const [addOpen, setAddOpen] = useState(false);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "policy_ordering",
    columns.map((row) => row.name)
  );

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      {addOpen && (
        <Dialog
          open={true}
          onClose={() => !formLoading && setAddOpen(false)}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="sm"
        >
          <ClosableDialogTitle
            onClose={() => !formLoading && setAddOpen(false)}
          >
            Добавить политику
          </ClosableDialogTitle>
          <DialogContent>
            <PolicyForm
              handleClose={() => setAddOpen(false)}
              update={updateRows}
              policy={{
                id_pol: 0,
                display_name: "",
                policy_name: "",
                description: "",
                value_type: "",
                value: "",
                possible_values: "",
                group_name: "",
                is_profile_app: 0,
                is_profile_device: 0,
                is_project: 0,
              }}
              loading={formLoading}
              setLoading={setFormLoading}
            />
          </DialogContent>
        </Dialog>
      )}

      {selected && (
        <Dialog
          open={true}
          onClose={() => !formLoading && setSelected(undefined)}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="sm"
        >
          <ClosableDialogTitle
            id="modify-policy-modal"
            onClose={() => !formLoading && setSelected(undefined)}
          >
            Изменить политику
          </ClosableDialogTitle>
          <DialogContent>
            {selected && (
              <PolicyForm
                handleClose={() => setSelected(undefined)}
                update={updateRows}
                policy={selected}
                loading={formLoading}
                setLoading={setFormLoading}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_pol}>
        {/*FormatColumns*/}
        <BooleanTypeProvider
          for={["is_project", "is_profile_device", "is_profile_app"]}
        />

        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnSortingEnabled={!grouped}
        />
        <IntegratedSorting />

        {/* Grouping*/}
        {grouped && <GroupingState grouping={[{columnName: "group_name"}]} />}
        {grouped && <CustomGrouping getChildGroups={customGetChildGroups} />}

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <AddNewButton onClick={() => setAddOpen(true)} />
          </ToolbarItem>
          <ToolbarItem id="policies_group">
            <GroupSwitch
              checked={grouped}
              onChange={() => setGrouped(!grouped)}
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateRows}
              id="policies_refresh"
            />
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              onClick={() => setSelected(row)}
              className={classes.gridRow}
              data-type="policies_row"
              data-id={row.id_pol}
            />
          )}
          columnExtensions={columnAlignExtension}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="policies"
          autoWidthColumnName={"description"}
        />

        {grouped && <StyledTableGroupRow />}

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default Policies;
