import Column from "../../Tables/Column";
import {
  UserReportFilterTypeProvider,
  UserReportFilterValueProvider,
} from "../../common/grid/providers";

const UserReportFilterColumns = [
  new Column("id_report_filter_user", "ID", 100, {hiddenByDefault: true}),
  new Column("name", "Наименование", 380),
  new Column("system_name", "Системное наименование", 380, {
    hiddenByDefault: true,
  }),
  new Column("value", "Значение", 200, {
    auto: true,
    provider: UserReportFilterValueProvider,
  }),
  new Column("value_type", "Тип", 200, {
    hiddenByDefault: true,
    provider: UserReportFilterTypeProvider,
  }),
];

export default UserReportFilterColumns;
