import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

export type DevicePolicyTreeType = {
  id_pol: number;
  display_name: string;
  policy_name: string;
  value_default: string;
  value_result: string;
} & {[s: string]: any};

const getDevicePolicyTree = async (
  id_device: number
): Promise<{
  success: boolean;
  columns: {[key: string]: string};
  rows: Array<DevicePolicyTreeType>;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "get",
      url: `/device/${id_device}/policy_tree/`,
    })
      .then((response) =>
        resolve({
          success: true,
          columns: response.data.columns,
          rows: response.data.rows,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          columns: {},
          rows: [],
          errors: errors,
        });
      })
  );

export default getDevicePolicyTree;
