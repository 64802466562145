import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type DirectoryType = {
  id_dir: number;
  name_dir: string;
  id_type_dir: number;
  editable?: number;
  status: string;
};

export const directoryTypes = {
  1: "Пользовательский",
  2: "Тип",
  3: "Источник",
  4: "Уровень хранения",
};

const getDirectory = async (id_type_dir: number) =>
  baseGetRowsRequest<DirectoryType>(`/directory/`, {filters: {id_type_dir}});

export default getDirectory;
