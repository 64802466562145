import * as React from "react";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import getNotificationMessages, {
  NotificationMessageFilterType,
  NotificationMessageType,
} from "../../../common/APIRequests/notifyMessage/getNotificationMessages";
import NotificationMessageColumns from "./NotificationMessageColumns";
import NotificationMessagesFilter from "./NotificationMessagesFilter";
import {NotificationType} from "../../../common/APIRequests/notify/getNotification";

type NotificationMessagesProps = {
  notification: NotificationType;
};

const NotificationMessagesTable = (props: NotificationMessagesProps) => {
  const updateRows: UpdateRowsType<
    NotificationMessageType,
    NotificationMessageFilterType
  > = ({sorting, paging, filters}) =>
    getNotificationMessages(
      paging?.page,
      paging?.page_size,
      sorting?.sort_field,
      sorting?.sort_desc,
      filters
    );

  return (
    <CoreTable
      name="notification_messages"
      columns={NotificationMessageColumns}
      getRowId={(row) => row.id_queue}
      updateRows={updateRows}
      defaultSorting={{columnName: "event_date", direction: "desc"}}
      ToolBarFilterComponent={NotificationMessagesFilter}
      pagingEnabled
      sortingEnabled
      defaultFilters={{
        id_notify: props.notification.id_notify,
        status: null,
      }}
      height="60vh"
    />
  );
};

export default NotificationMessagesTable;
