import React, {useContext, useState} from "react";
import {Button, CircularProgress} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {AppContext} from "../../App-context";
import RequestPinDialog from "../pin/RequestPinDialog";

const useStyles = makeStyles()(() => ({
  buttonProgress: {
    position: "absolute",
    left: "50%",
    marginLeft: "-12px",
  },
}));
type Props = {
  submitMessage: string;
  loading: boolean;
  disabled?: boolean;
  handleSubmit: () => void;
  checkPin?: boolean;
  submitId?: string;
};

const ButtonWithPin = (props: Props) => {
  const {submitMessage, loading, disabled, handleSubmit, checkPin, submitId} =
    props;

  const appContext = useContext(AppContext);
  const {pinStatus} = appContext;

  const [pinOpen, setPinOpen] = useState(false);

  const submit = async (e: React.SyntheticEvent<HTMLElement, Event>) => {
    if (!loading && checkPin && pinStatus) {
      setPinOpen(true);
    } else if (!loading) {
      await handleSubmit();
    }
  };

  const {classes} = useStyles();

  return (
    <React.Fragment>
      {pinOpen && checkPin && (
        <RequestPinDialog
          onClose={() => setPinOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
      <Button
        id={submitId ? submitId : "buttonSubmit"}
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={submit}
      >
        {submitMessage}
        {loading && (
          <CircularProgress size={28} className={classes.buttonProgress} />
        )}
      </Button>
    </React.Fragment>
  );
};

export default ButtonWithPin;
