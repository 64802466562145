import Column from "../Tables/Column";
import {ConsoleLogType} from "../../common/APIRequests/consoleLog/getConsoleLogs";
import moment from "moment";
import {FRONT_DATETIME_FORMAT} from "../../common/types";

export const consoleLogSourceTitles = {
  1: "Проекты",
  2: "Список политик",
  3: "Профили приложений",
  4: "Профили устройств",
  5: "Список устройств",
  6: "Список приложений",
};

export const consoleLogActionTitles = {
  1: "Создание",
  2: "Изменение",
  3: "Удаление",
  4: "Установка",
  5: "Отправка команды",
};

const ConsoleLogColumns = [
  new Column("id_console_log", "ID", 100, {hiddenByDefault: true}),
  new Column("source", "Источник", 150, {
    hiddenByDefault: true,
    getCellValue: (row: ConsoleLogType) => consoleLogSourceTitles[row.source],
  }),
  new Column("object", "Наименование", 150),
  new Column("action", "Тип действия", 150, {
    getCellValue: (row: ConsoleLogType) => consoleLogActionTitles[row.action],
  }),
  new Column("description", "Описание", 100, {auto: true}),
  new Column("ldap_login", "Логин инициатора", 100),
  new Column("log_date", "Дата и время", 150, {
    getCellValue: (row) =>
      row.log_date
        ? moment(row.log_date).format(FRONT_DATETIME_FORMAT)
        : undefined,
  }),
];

export default ConsoleLogColumns;
