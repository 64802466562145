import {alertType} from "../../../common/types";
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@mui/material";

const SnackbarAlert = (props: {alert: alertType; onClose: () => void}) => {
  const {alert, onClose} = props;

  const handleClose = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={true}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      autoHideDuration={alert.severity === "error" ? undefined : 6000}
      onClose={handleClose}
    >
      <Alert severity={alert.severity} onClose={handleClose}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
