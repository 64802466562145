import {Theme} from "@mui/material/styles";
import moment from "moment";
import {useCallback, useEffect, useMemo, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import getApplications, {
  ApplicationType,
} from "./common/APIRequests/application/getApplications";
import getDirectory, {
  DirectoryType,
} from "./common/APIRequests/directory/getDirectory";
import getEvents, {
  defaultEventFilter,
  EventType,
} from "./common/APIRequests/event/getEvents";
import getBranch, {
  geographyType,
} from "./common/APIRequests/geography/getBranch";
import {loginType, userPermsType} from "./common/APIRequests/login/postSession";
import getProjects, {
  projectType,
} from "./common/APIRequests/project/getProjects";
import consolePinIsActive from "./common/APIRequests/user_pin/consolePinIsActive";
import {alertType, errorType} from "./common/types";
import {apiClient} from "./common/utils/apiClient";
import getLoginInfo from "./common/utils/getLoginInfo";
import parseErrorMessage from "./common/utils/parseErrorMessage";
import {parseVersion} from "./common/utils/utils";
import {AppContext} from "./components/App-context";
import ApplicationsPage from "./components/Applications/ApplicationsPage";
import AlertNotification from "./components/common/alert/AlertNotification";
import {LoadingIndicator} from "./components/common/components";
import CustomRoute from "./components/common/CustomRoute";
import {ConsoleLogsTable} from "./components/ConsoleLogs/ConsoleLogsTable";
import Content from "./components/content";
import Dashboard from "./components/Dashboard/Dashboard";
import Devices from "./components/Devices/Devices";
import Events from "./components/Event/Events";
import Geography from "./components/Geography/Geography";
import LogEvents from "./components/LogEvents/LogEvents";
import NotificationsTable from "./components/Notifications/NotificationsTable";
import Policies from "./components/Policies/Policies";
import ProfileAppsTable from "./components/Profiles/ProfileAppsTable";
import ProfileDevicesTable from "./components/Profiles/ProfileDevicesTable";
import Projects from "./components/Projects/Projects";
import Reports from "./components/Reports/Reports";
import TasksPage from "./components/Tasks/TasksPage";
import UserReportsTable from "./components/UserReports/UserReportsTable";
import {UsersTable} from "./components/Users/UsersTable";
import {FirmwaresTable} from "./components/Firmwares/FirmwaresTable";

require("moment/locale/ru");
moment.locale("ru");

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: "flex",
    },
    paperLoader: {
      position: "absolute",
      height: "100%",
      width: "100%",
      zIndex: 9999,
      backgroundColor: theme.palette.common.white,
    },
    tableProgress: {
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 1,
    },
  };
});

const App = () => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(true);
  const [alert, setAlert] = useState<alertType | undefined>(undefined);
  const loginInfo = getLoginInfo() as loginType;
  const [permissions, setPermissions] = useState<{user_type: userPermsType}>({
    user_type: loginInfo.user_type,
  });

  useEffect(() => {
    const errorInterceptor = apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          userLogout();
          return;
        } else if (error.response && error.response.status === 403) {
          console.warn(error.response.data);
          return;
        }
        const errors: errorType = parseErrorMessage(error);

        if (errors.non_field_error) {
          setAlert({
            severity: "error",
            message: errors.non_field_error,
          });
          delete errors.non_field_error;
        }
        return Promise.reject(errors);
      }
    );
    return () => {
      apiClient.interceptors.response.eject(errorInterceptor);
    };
  });

  const userLogin = useCallback(
    (login: loginType) => {
      localStorage.setItem("auth", JSON.stringify(login));
      setIsAuth(true);
      setLoaded(true);
      setPermissions({user_type: login.user_type});
    },
    [setIsAuth, setLoaded, setPermissions]
  );

  const userLogout = useCallback(() => {
    localStorage.removeItem("auth");
    setIsAuth(false);
  }, [setIsAuth]);

  // pinStatus state
  const [pinStatus, setPinStatus] = useState<number>(0);
  const updatePinStatus = useCallback(async () => {
    const result = await consolePinIsActive();
    if (result.success && result.data !== undefined) {
      setPinStatus(result.data);
    }
  }, [setPinStatus]);
  useEffect(() => {
    isAuth && updatePinStatus();
  }, [isAuth]);

  // projects state
  const [projects, setProjects] = useState<Array<projectType>>([]);
  const [geoNets, setGeoNets] = useState<Array<geographyType>>([]);
  const [events, setEvents] = useState<Array<EventType>>([]);
  const [directories, setDirectories] = useState<Array<DirectoryType>>([]);
  const [eventTypes, setEventTypes] = useState<Array<DirectoryType>>([]);
  const [apps, setApps] = useState<ApplicationType[]>([]);

  const updateProjects = () =>
    getProjects().then((result) => {
      if (result.success) {
        setProjects(result.rows);
      }
    });

  const updateApps = () =>
    getApplications().then((result) => {
      if (result.success) setApps(result.rows);
    });

  const updateEvents = () =>
    getEvents(defaultEventFilter).then((result) => {
      if (result.success) {
        setEvents(result.rows);
      }
    });

  // load web app data
  useEffect(() => {
    if (!isAuth) return;

    setLoading(true);
    Promise.all([
      updateProjects(),
      //updateEvents(),
      updateApps(),
      getBranch().then((result) => {
        if (result.success) {
          setGeoNets(result.rows);
        }
      }),
      getDirectory(1).then((result) => {
        if (result.success) {
          setDirectories(result.rows);
        }
      }),
      getDirectory(2).then((result) => {
        if (result.success) {
          setEventTypes(result.rows);
        }
      }),
    ]).finally(() => {
      setLoaded(true);
      setLoading(false);
    });
  }, [isAuth]);

  const props = useMemo(
    () => ({
      isAuth,
      userLogin,
      userLogout,
    }),
    [isAuth, userLogin, userLogout]
  );

  const context = useMemo(
    () => ({
      setAlert,
      projects,
      updateProjects,
      geoNets,
      events,
      updateEvents,
      directories,
      eventTypes,
      apps,
      updateApps,
      loading,
      permissions,
      pinStatus,
      version: parseVersion(process.env.REACT_APP_GIT_TAG || ""),
    }),
    [setAlert, projects, apps, events, permissions, pinStatus, loading]
  );

  const DevicesContent = () => (
    <Content barContent="Устройства" Cont={Devices} {...props} />
  );

  const LogEventsContent = () => (
    <Content barContent="Журнал событий" Cont={LogEvents} {...props} />
  );

  const {classes} = useStyles();

  return (
    <div className={classes.root}>
      {!loaded && isAuth && (
        <div className={classes.paperLoader}>
          <LoadingIndicator disableShrink />
        </div>
      )}
      <AppContext.Provider value={context}>
        <Router>
          <CustomRoute
            exact
            path="/"
            render={() => (
              <Content barContent="Аналитика" Cont={Dashboard} {...props} />
            )}
          />
          <CustomRoute
            exact
            path="/projects/"
            render={() => (
              <Content barContent="Проекты" Cont={Projects} {...props} />
            )}
          />
          <CustomRoute
            exact
            path="/users/"
            render={() => (
              <Content barContent="Пользователи" Cont={UsersTable} {...props} />
            )}
          />
          <CustomRoute exact path="/devices/" render={DevicesContent} />
          <CustomRoute
            exact
            path="/devices/:code_division/"
            render={DevicesContent}
          />
          <CustomRoute
            exact
            path="/devices/project/:id_project/"
            render={DevicesContent}
          />
          <CustomRoute
            exact
            path="/policies/"
            render={() => (
              <Content barContent="Политики" Cont={Policies} {...props} />
            )}
          />
          <CustomRoute
            exact
            path="/applications/"
            render={() => (
              <Content
                barContent="Приложения"
                Cont={ApplicationsPage}
                {...props}
              />
            )}
          />
          <CustomRoute
            exact
            path="/geography/"
            render={() => (
              <Content barContent="География" Cont={Geography} {...props} />
            )}
          />
          <CustomRoute
            exact
            path="/profiles_apps/"
            render={() => (
              <Content
                barContent="Профили приложений"
                Cont={ProfileAppsTable}
                {...props}
              />
            )}
          />
          <CustomRoute
            exact
            path="/profiles_devices/"
            render={() => (
              <Content
                barContent="Профили устройств"
                Cont={ProfileDevicesTable}
                {...props}
              />
            )}
          />
          <CustomRoute
            exact
            path="/tasks/"
            render={() => <TasksPage {...props} />}
          />
          <CustomRoute
            exact
            path="/events/"
            render={() => (
              <Content barContent="События" Cont={Events} {...props} />
            )}
          />
          <CustomRoute
            exact
            path="/user_reports/"
            render={() => (
              <Content
                barContent="Настраиваемые отчеты"
                Cont={UserReportsTable}
                {...props}
              />
            )}
          />
          <CustomRoute
            exact
            path="/reports/"
            render={() => (
              <Content barContent="Отчеты" Cont={Reports} {...props} />
            )}
          />
          <CustomRoute exact path="/log_events/" render={LogEventsContent} />
          <CustomRoute
            exact
            path="/log_events/:id_event/"
            render={LogEventsContent}
          />
          <CustomRoute
            exact
            path="/notifications/"
            render={() => (
              <Content
                barContent="Оповещения"
                Cont={NotificationsTable}
                {...props}
              />
            )}
          />
          <CustomRoute
            exact
            path="/console_logs/"
            render={() => (
              <Content
                barContent="Журнал консоли"
                Cont={ConsoleLogsTable}
                {...props}
              />
            )}
          />
          <CustomRoute
            exact
            path="/firmwares/"
            render={() => (
              <Content barContent="Прошивки" Cont={FirmwaresTable} {...props} />
            )}
          />
        </Router>
      </AppContext.Provider>
      {alert && (
        <AlertNotification alert={alert} onClose={() => setAlert(undefined)} />
      )}
    </div>
  );
};

export default App;
