import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import getDirectory, {
  DirectoryType,
} from "../../common/APIRequests/directory/getDirectory";
import getEvents, {
  defaultEventFilter,
  EventType,
} from "../../common/APIRequests/event/getEvents";
import getGroupByClasses, {
  logEventGroupedType,
} from "../../common/APIRequests/logEvent/getLogEventGrouped";
import getLogEvents, {
  logEventFilterType,
  logEventType,
} from "../../common/APIRequests/logEvent/getLogEvents";
import {useLocalStorage} from "../../common/customHooks";
import {optionType, sortingType} from "../../common/types";
import LogEventGroupedTable from "./LogEventGroupedTable";
import LogEventTable from "./LogEventTable";
import {calcPageSize} from "../Tables/helpers";

export const DEFAULT_LOG_EVENT_FILTER = {
  start_event_time: null,
  end_event_time: null,
  start_input_date: null,
  end_input_date: null,
  ids_event: [],
  serial_number: null,
  code_division: "",
  app_version_name: "",
  name_app: "",
  name_object: "",
  id_type_user: null,
  id_type_property: null,
  id_type_by: null,
  id_type_level: null,
};

const LogEvents = () => {
  let cancelled = false;
  const [loading, setLoading] = useState(false);

  const {id_event} = useParams<{id_event: string}>();
  useEffect(() => {
    // fixes bug: when goes from event clear filter values
    if (id_event) {
      logEventFilters.ids_event = [parseInt(id_event)];
      logEventFilters.start_event_time = null;
      logEventFilters.end_event_time = null;
      logEventFilters.start_input_date = null;
      logEventFilters.end_input_date = null;
      logEventFilters.serial_number = null;
      logEventFilters.code_division = "";
      logEventFilters.app_version_name = "";
      logEventFilters.name_app = "";
      logEventFilters.name_object = "";
      logEventFilters.id_type_user = null;
      logEventFilters.id_type_property = null;
      logEventFilters.id_type_by = null;
      logEventFilters.id_type_level = null;
    } else {
      !logEventFilters.ids_event && (logEventFilters.ids_event = []);
    }
  }, [id_event]);

  const [logEventFilters, setLogEventFilters] =
    useLocalStorage<logEventFilterType>(
      "log_event_filter",
      DEFAULT_LOG_EVENT_FILTER
    );
  useEffect(() => {
    updateLogEvents();
    setLogEventCurrentPage(0);
    // update GroupTable if logEventFilter change
    isLogEventGrouped && updateLogEventGrouped();
    return () => {
      cancelled = true;
    };
  }, [logEventFilters]);
  // if localStorage filter values not in condition fix
  useEffect(() => {
    if (
      !(
        Object.keys(logEventFilters).join() ===
        Object.keys(DEFAULT_LOG_EVENT_FILTER).join()
      )
    ) {
      setLogEventFilters(DEFAULT_LOG_EVENT_FILTER);
      window.localStorage.setItem(
        "log_event_filter",
        JSON.stringify(DEFAULT_LOG_EVENT_FILTER)
      );
    }
  }, []);

  //event
  const [userDirsOptions, setUserDirsOptions] = useState(
    [] as Array<optionType>
  );

  const [propertyDirsOptions, setPropertyDirsOptions] = useState(
    [] as Array<optionType>
  );

  const [levelDirsOptions, setLevelDirsOptions] = useState(
    [] as Array<optionType>
  );
  const updateDirectoryOptions = async () => {
    setLoading(true);

    const firstLineOptions = [
      {
        key: 0,
        value: 0,
        text: "Все",
      },
    ];
    await Promise.all([getDirectory(1), getDirectory(2), getDirectory(4)]).then(
      (values) => {
        const hasNoUserDirs = [
          {
            key: -1,
            value: -1,
            text: "Системное событие",
          },
        ];
        setUserDirsOptions([
          ...firstLineOptions,
          ...hasNoUserDirs,
          ...values[0].rows.map((d: DirectoryType) => ({
            key: d.id_dir,
            value: d.id_dir,
            text: d.name_dir,
          })),
        ]);

        setPropertyDirsOptions([
          ...firstLineOptions,
          ...values[1].rows.map((d: DirectoryType) => ({
            key: d.id_dir,
            value: d.id_dir,
            text: d.name_dir,
          })),
        ]);

        setLevelDirsOptions([
          ...firstLineOptions,
          ...values[2].rows.map((d: DirectoryType) => ({
            key: d.id_dir,
            value: d.id_dir,
            text: d.name_dir,
          })),
        ]);
      }
    );
  };

  const [events, setEvents] = useState([] as Array<EventType>);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getEvents(defaultEventFilter);
      if (result.success) {
        setEvents(result.rows);
        await updateDirectoryOptions();
      }
      setLoading(false);
    })();
  }, []);

  // log_events
  const [logEventGrouped, setlogEventGrouped] = useState(
    [] as Array<logEventGroupedType>
  );
  const [isLogEventGrouped, setIsLogEventGrouped] = useState(false);
  useEffect(() => {
    (async () => {
      if (isLogEventGrouped) {
        // reset setValueComponents what unnecessary with grouping
        logEventFilters.ids_event = [];
        logEventFilters.id_type_user = null;

        await updateLogEventGrouped();
      } else {
        setlogEventGrouped([]);
      }
    })();
  }, [isLogEventGrouped]);

  const updateLogEventGrouped = async () => {
    setLoading(true);
    const result = await getGroupByClasses(logEventFilters);
    setLoading(false);
    if (result.success) {
      setLogEventRows([]);
      setlogEventGrouped(result.rows);
    }
  };

  const logEventPageSize = calcPageSize();
  const [logEventCurrentPage, setLogEventCurrentPage] = useState(0);
  const [logEventRows, setLogEventRows] = useState([] as Array<logEventType>);

  const [logEventSorting, setLogEventSorting] = useLocalStorage<sortingType>(
    "log_event_sorting",
    [{columnName: "input_date", direction: "desc"}]
  );

  const [logEventTotalCount, setLogEventTotalCount] = useState(0);

  const updateLogEvents = async () => {
    const {direction, columnName} = logEventSorting[0];

    setLoading(true);
    const result = await getLogEvents(
      logEventCurrentPage + 1,
      logEventPageSize,
      columnName,
      direction == "desc",
      logEventFilters
    );
    if (cancelled) {
      return;
    }
    setLoading(false);

    if (result.success) {
      setLogEventRows(result.rows);
      setLogEventTotalCount(result.count);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLogEventGrouped ? (
          <LogEventGroupedTable
            loading={loading}
            isLogEventGrouped={isLogEventGrouped}
            setIsLogEventGrouped={setIsLogEventGrouped}
            logEventGrouped={logEventGrouped}
            updateLogEventGrouped={updateLogEventGrouped}
            filters={logEventFilters}
            setFilters={setLogEventFilters}
            events={events}
            userDirsOptions={userDirsOptions}
            propertyDirsOptions={propertyDirsOptions}
            levelDirsOptions={levelDirsOptions}
            logEventRows={logEventRows}
          />
        ) : (
          <LogEventTable
            loading={loading}
            isLogEventGrouped={isLogEventGrouped}
            setIsLogEventGrouped={setIsLogEventGrouped}
            setLoading={setLoading}
            rows={logEventRows}
            update={updateLogEvents}
            currentPage={logEventCurrentPage}
            setCurrentPage={setLogEventCurrentPage}
            pageSize={logEventPageSize}
            sorting={logEventSorting}
            setSorting={setLogEventSorting}
            totalCount={logEventTotalCount}
            events={events}
            filters={logEventFilters}
            setFilters={setLogEventFilters}
            userDirsOptions={userDirsOptions}
            propertyDirsOptions={propertyDirsOptions}
            levelDirsOptions={levelDirsOptions}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LogEvents;
