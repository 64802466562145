import {useEffect, useState} from "react";
import {Dialog, DialogContent} from "@mui/material";
import {TabPanel, tabProps, Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {PrimaryButton} from "../../common/buttons/PrimaryButton";
import {StyledTab, StyledTabs} from "../../common/Tabs";
import {makeStyles} from "tss-react/mui";
import DeviceVideosTable from "./DeviceVideo/DeviceVideosTable";
import DeviceScreenshotsTable from "./DeviceScreenshot/DeviceScreenshotsTable";

const useStyles = makeStyles()((theme) => ({
  scrollPaper: {
    alignItems: "flex-start",
  },
  dialogContent: {
    paddingTop: theme.spacing(0),
  },
}));

const DeviceMediaFiles = (props: {device: string; className: string}) => {
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const defaultTab = "videos";
  const [tab, setTab] = useState(defaultTab);
  const {device} = props;
  const {classes: localClasses} = useStyles();

  useEffect(() => setTab(defaultTab), []);

  return (
    <>
      <PrimaryButton
        className={props.className}
        onClick={() => setMediaDialogOpen(true)}
      >
        Медиа файлы
      </PrimaryButton>
      {mediaDialogOpen && (
        <Dialog
          open={true}
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
        >
          <ClosableDialogTitle
            onClose={() => {
              setMediaDialogOpen(false);
              setTab(defaultTab);
            }}
          >
            Медиа файлы
          </ClosableDialogTitle>
          <DialogContent className={localClasses.dialogContent}>
            <StyledTabs
              value={tab}
              onChange={(_, tab) => setTab(tab)}
              aria-label="wrapped label tabs"
            >
              <StyledTab label="Видеозаписи" {...tabProps("videos")} />
              <StyledTab label="Скриншоты" {...tabProps("screenshots")} />
            </StyledTabs>
            <TabPanel value={tab} index="videos">
              <DeviceVideosTable device={device} />
            </TabPanel>
            {tab === "screenshots" && device && (
              <TabPanel value={tab} index="screenshots">
                <DeviceScreenshotsTable device={device} />
              </TabPanel>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DeviceMediaFiles;
