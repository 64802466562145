import {getUsername} from "../../getUsername";
import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";
import {ColumnPropsForDownloadType} from "../../../components/common/buttons/DownloadMenuButton";

const downloadReport = async (
  id_user_report: number,
  file_type: string,
  title: string,
  field_props_for_download: ColumnPropsForDownloadType
): Promise<{success: boolean; file?: File; errors: errorType}> =>
  new Promise((resolve) =>
    apiClient({
      responseType: "arraybuffer",
      method: "post",
      url: `/user_report/${id_user_report}/download/`,
      data: {
        username: getUsername(),
        file_type,
        title,
        field_props_for_download: JSON.stringify(field_props_for_download),
      },
    })
      .then((response) =>
        resolve({
          success: true,
          file: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          file: undefined,
          errors: errors,
        });
      })
  );

export default downloadReport;
