import {useEffect, useState} from "react";

const Timer = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = (fromDateMs: number) => {
    const timeMs = Date.now() - fromDateMs;
    setHours(Math.floor(timeMs / 1000 / 60 / 60));
    setMinutes(Math.floor(timeMs / 1000 / 60) % 60);
    setSeconds(Math.floor((timeMs / 1000) % 60));
  };

  const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };

  useEffect(() => {
    const fromDate = Date.now();
    const interval = setInterval(() => getTime(fromDate), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
    </>
  );
};

export default Timer;
