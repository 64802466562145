import * as React from "react";
import {useState} from "react";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import FirmwareColumns from "./FirmwareColumns";
import {FirmwaresFilter} from "./FirmwaresFilter";
import {useTrigger} from "../../common/customHooks";
import getFirmwares, {
  FirmwareFilterType,
  FirmwareType,
} from "../../common/APIRequests/firmware/getFirmwares";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import deleteFirmwares from "../../common/APIRequests/firmware/deleteFirmwares";
import AddFirmwareDialog from "./AddFirmwareDialog";
import {AddNewButton} from "../common/buttons/ToolbarButtons";

export const FirmwaresTable = () => {
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [refreshTrigger, refresh] = useTrigger();

  const updateRows: UpdateRowsType<FirmwareType, FirmwareFilterType> = ({
    filters,
  }) => getFirmwares(filters);

  return (
    <>
      {isAddDialogOpen && (
        <AddFirmwareDialog
          onClose={() => setAddDialogOpen(false)}
          update={refresh}
        />
      )}
      <CoreTable
        columns={FirmwareColumns}
        getRowId={(row) => row.id_firmware}
        name={"firmwares"}
        updateRows={updateRows}
        updateTrigger={refreshTrigger}
        defaultSorting={{columnName: "name", direction: "desc"}}
        ToolBarFilterComponent={FirmwaresFilter}
        integratedSearch
        selectionEnabled
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddDialogOpen(true)}
            key="firmware-add_button"
          />,
        ]}
        selectionButton={(selection, update) => (
          <MenuDeleteAction
            selection={selection}
            update={update}
            action={(ids) => deleteFirmwares(ids)}
            checkPin
          />
        )}
      />
    </>
  );
};
