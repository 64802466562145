import ReactEcharts from "echarts-for-react";
import React from "react";
import {EChartOption} from "echarts";

type StackDataType = {
  name: string;
  quantity: number;
};

export type BarStackCategoryType = {
  category_name: string;
  data: Array<StackDataType>;
};

type Props = {
  data: Array<BarStackCategoryType>;
  color?: Array<string>;
};

const BarStackChart = (props: Props) => {
  const defaultColor = [
    "#c23531",
    "#2f4554",
    "#61a0a8",
    "#d48265",
    "#91c7ae",
    "#749f83",
    "#ca8622",
    "#bda29a",
    "#6e7074",
    "#546570",
    "#c4ccd3",
  ];
  const {data, color = defaultColor} = props;

  const option: EChartOption = {
    color,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: data[0].data.map((stack: StackDataType) => stack.name),
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      data: data.map(
        (category: BarStackCategoryType) => category.category_name
      ),
    },
    series: data[0].data.map((stack: StackDataType) => ({
      name: stack.name,
      type: "bar",
      stack: "stack",
      label: {
        show: false,
      },
      data: data.map(
        (category: BarStackCategoryType) =>
          category.data.filter((s: StackDataType) => stack.name == s.name)[0]
            .quantity
      ),
    })),
  };

  return <ReactEcharts notMerge={true} lazyUpdate={true} option={option} />;
};

export default BarStackChart;
