import {basePatchRequest} from "../base/basePatchRequest";
import {UserReport} from "./getUserReports";

const patchUserReport = async (
  id_user_report: number,
  userReport: UserReport
) =>
  basePatchRequest<UserReport>(`/user_report/${id_user_report}/`, userReport);

export default patchUserReport;
