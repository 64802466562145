import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type projectApplicationType = {
  id_pr_app_link: number;
  id_app: number;
  app_name: string;
  app_uid: string;
  app_description: string;
  name_project: string;
  is_inherited: 1 | 0;
  id_project: number;
  id_prof: number;
  prof_name: string;
};

const getProjectApplications = async (project_id: number) =>
  baseGetRowsRequest<projectApplicationType>(
    `/project/${project_id}/application/`
  );

export default getProjectApplications;
