import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {SortingState} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import React, {useEffect, useState} from "react";
import getReportLastConnect, {
  reportLastConnectType,
} from "../../common/APIRequests/report/getReportLastConnect";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {
  columnType,
  FRONT_DATETIME_FORMAT,
  sortingType,
} from "../../common/types";
import {exportToCSV} from "../../common/utils/files";
import {StyledVirtualTable} from "../common/grid/cell";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../common/components";

const columns: columnType = [
  {name: "id_device", title: "ID"},
  {name: "serial_number", title: "Серийный номер"},
  {name: "code_division", title: "Код подразделения"},
  {name: "name_project", title: "Наименование проекта"},
  {name: "ldap_login", title: "Логин пользователя"},
  {name: "display_name", title: "Имя пользователя"},
  {name: "email", title: "E-mail"},
  {
    name: "last_seen_time",
    title: "Время последней связи",
    getCellValue: (row: reportLastConnectType) =>
      row.last_seen_time
        ? moment(row.last_seen_time).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
];
const columnExtensions = [
  {columnName: "id_device", width: 100},
  {columnName: "serial_number", width: 250},
  {columnName: "code_division", width: 200},
  {columnName: "name_project", width: 200},
  {columnName: "ldap_login", width: 200},
  {columnName: "display_name", width: 200},
  {columnName: "email", width: 200},
  {columnName: "last_seen_time", width: 200},
];

const ReportsLastSeen = (props: {
  projectId: number;
  includeChild: 0 | 1;
  children?: any;
  loading: boolean;
  setLoading: Function;
}) => {
  const {projectId, includeChild, children, loading, setLoading} = props;

  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("report_last_seen_column", ["id_device", "name_project"]);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "report_last_seen_sorting",
    [{columnName: "id_device", direction: "asc"}]
  );

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "report_last_seen_ordering",
    columns.map((row) => row.name)
  );

  const updateRows = async () => {
    const {direction, columnName} = sorting[0];

    setLoading(true);
    const result = await getReportLastConnect(
      projectId,
      columnName,
      direction == "desc",
      includeChild ? 1 : 0
    );
    if (cancelled) {
      return;
    }
    setLoading(false);

    result.success && setRows(result.rows);
  };

  const [rows, setRows] = useState([] as Array<reportLastConnectType>);

  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  useEffect(() => {
    projectId && updateRows();
  }, [projectId, includeChild, sorting]);

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      <Grid
        columns={columns}
        rows={rows}
        getRowId={(row) => `${row.id_device}_${row.app_code}`}
      >
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          {children}
          <ToolbarItem>
            <Button
              variant="text"
              color="secondary"
              startIcon={<GetAppIcon />}
              disabled={loading || rows.length === 0}
              onClick={() => exportToCSV<reportLastConnectType>(rows, columns)}
              id="report_download_btn"
            >
              Скачать
            </Button>
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        <StyledVirtualTable
          height="79vh"
          messages={{
            noData: !projectId || loading ? "Выберите проект" : "Нет данных",
          }}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="reports"
          autoWidthColumnName={"last_seen_time"}
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default ReportsLastSeen;
