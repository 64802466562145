import {default as React, useContext, useEffect, useState} from "react";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import patchDevice from "../../../common/APIRequests/device/patchDevice";
import {errorType} from "../../../common/types";
import theme from "../../../theme";
import {AppContext} from "../../App-context";
import FormButtons from "../../common/buttons/FormButtons";
import StringField from "../../common/formFields/StringField";

const DeviceForm = (props: {
  handleClose: () => void;
  update: Function;
  device?: deviceType;
  loading: boolean;
  setLoading: Function;
}) => {
  const {handleClose, update, device, loading, setLoading} = props;

  const [values, setValues] = useState<deviceType | undefined>(device);

  useEffect(() => {
    setValues(device);
  }, [device]);

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [errors, setErrors] = useState({} as errorType);

  const handleChange = (name: string) => (value: string) => {
    if (values) {
      setValues({...values, [name]: value});
    }
  };

  const handleSubmit = async () => {
    if (Object.keys(errors).length || loading || !values) {
      return;
    }

    setErrors({});
    setLoading(true);
    let result = await patchDevice(values.id_device, values);
    setLoading(false);

    if (result.success) {
      setAlert({
        severity: "success",
        message: ["Описание изменено"],
      });
      handleClose();
      update();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <React.Fragment>
      <StringField
        disabled={!values}
        setValue={handleChange("comment_text")}
        value={values?.comment_text || ""}
        name="comment_text"
        label="Описание"
        errors={errors}
        setErrors={setErrors}
        multiline
        style={{marginTop: theme.spacing(2)}}
      />
      <FormButtons
        disabled={!values}
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitMessage="Сохранить"
      />
    </React.Fragment>
  );
};

export default DeviceForm;
