import * as React from "react";
import SaveIcon from "@mui/icons-material/Save";
import {Button} from "@mui/material";

type UploadApkButtonProps = {
  upload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  extension?: string;
  title: string;
};
const UploadButton = (props: UploadApkButtonProps) => {
  return (
    <>
      <input
        type="file"
        style={{display: "none"}}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.upload(e)}
        alt={props.title}
        id="uploaded_file"
        name="uploaded_file"
        accept={props.extension}
        disabled={props.disabled}
        value=""
      />
      <label htmlFor="uploaded_file">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<SaveIcon />}
          component="span"
          disabled={props.disabled}
          id="apps_upload_btn"
        >
          {props.title}
        </Button>
      </label>
    </>
  );
};

export default UploadButton;
