import * as React from "react";
import {useContext, useState} from "react";
import SelectSingleField from "../../../../../common/formFields/SelectSingleField";
import {ApplicationType} from "../../../../../../common/APIRequests/application/getApplications";
import {ReportFilterProps} from "../eventFilters/EventIdReportFilter";
import {AppContext} from "../../../../../App-context";

const AppIdReportFilter = (props: ReportFilterProps) => {
  const [errors, setErrors] = useState({});
  const {apps} = useContext(AppContext);

  return (
    <SelectSingleField
      options={apps.map((app: ApplicationType) => ({
        key: app.id_app,
        value: app.id_app,
        text: app.name,
      }))}
      setValue={props.setValue}
      value={props.value ?? ""}
      name="value"
      label={props.name}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default AppIdReportFilter;
