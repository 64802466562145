import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";
import {deviceCommandType} from "../deviceСommand/getDeviceCommands";
import {DeviceFilterType} from "../device/getDevices";

const postCommand = async (
  tag: string,
  ids: Array<string | number>,
  addition_data: string | null = null,
  is_only_one_project: number = 0,
  filters?: DeviceFilterType
): Promise<{
  success: boolean;
  rows: Array<deviceCommandType>;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "post",
      url: `/command/`,
      data: {
        tag,
        ids,
        addition_data,
        is_only_one_project,
      },
      params: {
        filters,
      },
    })
      .then((response) =>
        resolve({
          success: true,
          rows: response.data as Array<deviceCommandType>,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          rows: [],
          errors: errors,
        });
      })
  );

export default postCommand;
