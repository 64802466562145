import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

const downloadDeviceLogFile = async (
  id_device: number,
  id_logfile: number
): Promise<{
  success: boolean;
  file: any;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      responseType: "arraybuffer",
      method: "get",
      url: `/device/${id_device}/logfile/${id_logfile}/download/`,
    })
      .then((response) =>
        resolve({
          success: true,
          file: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          file: undefined,
          errors: errors,
        });
      })
  );

export default downloadDeviceLogFile;
