import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React, {CSSProperties} from "react";
import {optionType} from "../../../common/types";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

type SelectSingleFilterType<ValueType> = {
  options: Array<optionType>;
  label?: string;
  name: string;
  id?: string;
  value: ValueType | null;
  update: (value: ValueType | null) => void;
  includeAllOption?: boolean;
  onFocus?: () => void;
  disabled?: boolean;
  focus?: string;
  style?: CSSProperties;
};

const SelectSingleFilterField = <ValueType,>(
  props: SelectSingleFilterType<ValueType>
) => {
  const {options, label, name, value, disabled, update, onFocus, focus, style} =
    props;

  const handleChange = (e: SelectChangeEvent<ValueType | string>) => {
    const {value} = e.target;
    update(value !== EMPTY_ITEM ? (value as ValueType) : null);
  };

  const EMPTY_ITEM = "null-option-code";

  return (
    <FormControl
      fullWidth
      focused={focus === name}
      style={{minWidth: 200, ...style}}
      onFocus={onFocus}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        id={props.id}
        inputProps={{name: name, id: name}}
        value={
          value !== undefined && value !== null
            ? value
            : props.includeAllOption
            ? EMPTY_ITEM
            : ""
        }
        onChange={handleChange}
        disabled={disabled}
      >
        {props.includeAllOption && (
          <MenuItem key="null-option-key" value={EMPTY_ITEM}>
            Все
          </MenuItem>
        )}
        {options.map((option: optionType) => (
          <MenuItem key={option.key || option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSingleFilterField;
