export const defaultErrorMessage =
  "Внутренняя ошибка. Свяжитесь с разработчиком системы";

const parseDBError = (errorData?: string[]): string => {
  let result;
  const errorMessage = errorData?.[0];
  if (Array.isArray(errorData) && typeof errorMessage === "string") {
    const firstLine = errorMessage.split("\n")?.[0];

    const parsed = /ORA-(\d*): (.*)/g.exec(firstLine || "");
    const errorCode = parsed?.[1] || "";
    if (errorCode.startsWith("20")) {
      // custom db error codes have range from 20000 to 20999
      result = parsed?.[2];
    } else if (errorCode) {
      // there is an oracle error code but it's not custom
      result = defaultErrorMessage;
    } else {
      result = errorMessage;
    }
  }

  return result || defaultErrorMessage;
};

export default parseDBError;
