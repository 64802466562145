import {Button} from "@mui/material";
import React, {useContext, useState} from "react";
import postCommand from "../../../common/APIRequests/command/postCommand";
import {AppContext} from "../../App-context";
import {ConfirmDialog} from "../../common/buttons/ConfirmDialog";

const SingleCommand = (props: {
  command: string;
  dialogTitle: string;
  buttonTitle: string;
  selection: Array<string | number>;
  update?: Function;
}) => {
  const {command, dialogTitle, buttonTitle, selection, update} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [loading, setLoading] = useState(false);

  const [commandReady, setCommandReady] = useState(false);

  const sendCommand = async () => {
    setLoading(true);
    const result = await postCommand(command, selection);
    setLoading(false);

    if (result.success) {
      setCommandReady(false);
      setAlert({
        severity: "success",
        message: ["Команда отправлена"],
      });
      update && update();
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCommandReady(true)}
        disabled={!selection.length}
      >
        {buttonTitle}
      </Button>
      {commandReady && (
        <ConfirmDialog
          loading={loading}
          handleClose={() => setCommandReady(false)}
          handleConfirm={sendCommand}
          message={dialogTitle}
        />
      )}
    </React.Fragment>
  );
};

export default SingleCommand;
