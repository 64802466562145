import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  CustomPaging,
  IntegratedSelection,
  PagingState,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import getDevices, {
  DeviceFilterType,
  deviceType,
} from "../../common/APIRequests/device/getDevices";
import {
  useEffectOnlyOnUpdate,
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {
  columnType,
  FRONT_DATETIME_FORMAT,
  sortingType,
} from "../../common/types";
import {RefreshButton} from "../common/buttons/ToolbarButtons";
import {StyledTableSelection, StyledVirtualTable} from "../common/grid/cell";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import {DeviceStatusProvider} from "../common/grid/providers";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import {paginationMessages} from "../common/pagination";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import {calcPageSize} from "../Tables/helpers";
import MenuCommandsAction from "./Commands/MenuCommandsAction";
import DeviceFilter from "./DeviceFilter";
import DeviceDialog from "./Dialogs/DeviceDialog";
import {LoadingIndicator} from "../common/components";
import {MenuFilter} from "../Tables/MenuFilter";
import DeviceDialogFilter from "./DeviceDialogFilter";
import {SecondaryButton} from "../common/buttons/SecondaryButton";

const columns: columnType = [
  {name: "id_device", title: "№"},
  {name: "graphical_status", title: "Статус"}, // calculated on backend field
  {name: "id_model", title: "ID модели"},
  {
    name: "calculated_last_seen_time_from_now", // calculated on frontend field
    title: "Последняя синхронизация",
    getCellValue: (row: deviceType) =>
      row.last_seen_time_from_now !== undefined
        ? moment.duration(row.last_seen_time_from_now, "seconds").humanize(true)
        : undefined,
  },
  {
    name: "name",
    title: "Наименование",
    getCellValue: (row: deviceType) =>
      `${row.code_division} ${row.user_ldap_login} android ${
        row.version_code || ""
      } ${row.serial_number || ""}`,
  },
  {
    name: "device_registration_status",
    title: "Статус регистрации",
    getCellValue: (row: deviceType) =>
      row.device_registration_status ? "Зарегистрирован" : "Не зарегистрирован",
  },
  {name: "id_user", title: "ID Пользователя"},
  {name: "user_display_name", title: "Имя пользователя"},
  {name: "project_name", title: "Проект"},
  {name: "user_email", title: "Email пользователя"},
  {name: "model_name", title: "Модель"},
  {name: "device_uid", title: "Идентификатор"},
  {name: "firmware", title: "Прошивка"},
  {
    name: "code_division",
    title: "Код подразделения",
    getCellValue: (row: deviceType) =>
      row.code_division ?? "Отсутствует код ТО",
  },
  {name: "serial_number", title: "Серийный номер"},
  {name: "imei", title: "IMEI"},
  {name: "mac", title: "Wi-Fi MAC"},
  {name: "lan_mac", title: "LAN MAC"},
  {name: "id_project", title: "ID Проекта"},
  {name: "user_ldap_login", title: "Логин пользователя"},
  {
    name: "device_registration_time",
    title: "Дата регистрации",
    getCellValue: (row: deviceType) =>
      row.device_registration_time
        ? moment(row.device_registration_time).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
  {
    name: "last_seen_time",
    title: "Синхронизировано",
    getCellValue: (row: deviceType) =>
      row.last_seen_time
        ? moment(row.last_seen_time).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
  {
    name: "type_device_name",
    title: "Тип",
    getCellValue: (row: deviceType) => {
      if (!row.type_device_name) return undefined;
      if (row.type_device_name == "corp") return "Корпоративное";
      if (row.type_device_name == "private") return "Личное";
    },
  },
  {name: "comment_text", title: "Описание"},
  {name: "version_code", title: "Версия Android"},
];
const columnExtensions = [
  {columnName: "id_device", width: 80},
  {columnName: "graphical_status", width: 100},
  {columnName: "id_model", width: 120},
  {columnName: "calculated_last_seen_time_from_now", width: 230},
  {columnName: "name", width: 300},
  {columnName: "device_registration_status", width: 180},
  {columnName: "id_user", width: 200},
  {columnName: "user_display_name", width: 200},
  {columnName: "user_ldap_login", width: 200},
  {columnName: "user_email", width: 200},
  {columnName: "model_name", width: 200},
  {columnName: "device_uid", width: 300},
  {columnName: "firmware", width: 300},
  {columnName: "code_division", width: 200},
  {columnName: "serial_number", width: 200},
  {columnName: "imei", width: 200},
  {columnName: "mac", width: 200},
  {columnName: "lan_mac", width: 200},
  {columnName: "id_project", width: 130},
  {columnName: "project_name", width: 200},
  {columnName: "device_registration_time", width: 200},
  {columnName: "last_seen_time", width: 200},
  {columnName: "type_device_name", width: 200},
  {columnName: "comment_text", width: 200},
  {columnName: "version_code", width: 200},
];
const columnAlignExtension = [
  {columnName: "graphical_status", align: "center"},
];
const sortingStateColumnExtensions = [
  {columnName: "name", sortingEnabled: false},
];

const Devices = () => {
  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const {code_division, id_project} = useParams<{
    code_division: string;
    id_project: string;
  }>();

  const [loading, setLoading] = useState(false);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("devices_columns", [
    "id_device",
    "id_model",
    "id_user",
    "user_display_name",
    "user_email",
    "device_uid",
    "id_project",
    "type_device_name",
    "comment_text",
    "version_code",
    "lan_mac",
    "last_seen_time",
  ]);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "devices_sorting",
    [{columnName: "calculated_last_seen_time_from_now", direction: "desc"}]
  );

  const pageSize = calcPageSize();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [filters, setFilters] = useState<DeviceFilterType>({
    code_division,
    id_project: id_project ? parseInt(id_project) : null,
  });
  useEffect(() => {
    setCurrentPage(0);
    updateAndReset();
  }, [filters]);

  const [selection, setSelection] = useState([] as Array<string | number>);
  const [rowDataSelection, setRowDataSelection] = useState(
    [] as Array<deviceType>
  );
  const [rows, setRows] = useState([] as Array<deviceType>);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "device_ordering",
    columns.map((row) => row.name)
  );

  const [instanceToModify, setInstanceToModify] = useState(
    undefined as deviceType | undefined
  );

  const update = () => {
    const {direction, columnName} = sorting[0];
    const columnNameReplaced =
      columnName == "calculated_last_seen_time_from_now"
        ? "last_seen_time"
        : columnName;

    setLoading(true);
    getDevices(
      currentPage + 1,
      pageSize,
      columnNameReplaced,
      direction == "desc",
      filters
    )
      .then((result) => {
        if (cancelled) {
          return;
        }
        if (result.success) {
          setRows(result.rows);
          setTotalCount(result.count);
        }
      })
      .finally(() => setLoading(false));
  };

  const updateAndReset = () => {
    update();
    setSelection([]);
    setRowDataSelection([]);
  };

  useEffectOnlyOnUpdate(updateAndReset, [pageSize, sorting]);
  useEffectOnlyOnUpdate(update, [currentPage]);

  const handleSelectionChange = (selection: Array<string | number>) => {
    setSelection(selection);
    // Step 1: Store the selected devices on current page
    const selectedDevicesOnPage = rows.filter((row) =>
      selection.includes(row.id_device)
    );
    // Step 2: Combine the previously selected devices with the ones on the current page
    const combinedSelectedDevicesWithDuplicates = rowDataSelection.concat(
      selectedDevicesOnPage
    );
    // Step 3: Remove duplicates and the unselected devices
    const selectedDevicesClean = selection.map(
      (device_id) =>
        combinedSelectedDevicesWithDuplicates.find(
          (row) => row.id_device == device_id
        )!
    );
    setRowDataSelection(selectedDevicesClean);
  };

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      {instanceToModify && (
        <DeviceDialog
          onClose={() => setInstanceToModify(undefined)}
          device={instanceToModify}
          updateTable={updateAndReset}
        />
      )}
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_device}>
        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              onClick={() => setInstanceToModify(row)}
              className={classes.gridRow}
              data-type="devices_row"
              data-id={row.id_device}
            />
          )}
          columnExtensions={columnAlignExtension}
          pagination
        />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem id="devices_btn_commands">
            <MenuCommandsAction
              selection={selection}
              rowDataSelection={rowDataSelection}
              filters={filters}
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateAndReset}
              id="devices_refresh"
            />
          </ToolbarItem>
          <ToolbarItem>
            <MenuFilter
              FilterComponent={DeviceDialogFilter}
              filters={filters}
              updateFilters={setFilters}
              loading={loading}
              maxDialogWidth={"lg"}
            />
          </ToolbarItem>
          <TemplatePlaceholder />
          <DeviceFilter
            filters={filters}
            loading={loading}
            update={setFilters}
          />
          <SecondaryButton
            title={"Очистить все фильтры"}
            onClick={() => setFilters({id_project: null})}
          />
        </Template>

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingStateColumnExtensions}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          defaultColumnWidths={columnExtensions}
          columnExtensions={columnExtensions}
          localStorageName="devices"
          autoWidthColumnName={"name"}
        />

        {/*Row Selection*/}
        <SelectionState
          selection={selection}
          onSelectionChange={handleSelectionChange}
        />
        <IntegratedSelection />
        <StyledTableSelection showSelectAll />

        <DeviceStatusProvider for={["graphical_status"]} />

        {/*Pagination*/}
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
        />
        <CustomPaging totalCount={totalCount} />
        <PagingPanel messages={paginationMessages} />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default Devices;
