import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";
import {FirmwareType} from "./getFirmwares";

const postFirmware = async (
  data: FormData
): Promise<{
  success: boolean;
  data: FirmwareType;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "post",
      url: `/firmware/upload/`,
      data,
    })
      .then((response) =>
        resolve({
          success: true,
          data: response.data as FirmwareType,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          data: {} as FirmwareType,
          errors: errors,
        });
      })
  );

export default postFirmware;
