import {Box} from "@mui/material";
import IconLogo from "../../../common/images/iconLogo";
import TextLogo from "../../../common/images/textLogo";
import {Spacer} from "../components";

type LeftPanelLogoProps = {
  leftPanelOpen: boolean;
};

const LeftPanelLogo = (props: LeftPanelLogoProps) => {
  return (
    <Box display="flex" flexDirection="row" paddingBottom={"10px"}>
      <div
        style={{
          width: props.leftPanelOpen ? 80 : 28,
          transition: "width 300ms",
        }}
      />
      <IconLogo />
      {props.leftPanelOpen && <Spacer width={10} />}
      <TextLogo
        style={{
          height: props.leftPanelOpen ? 20 : 0,
          opacity: props.leftPanelOpen ? 1 : 0,
          transition: "height 300ms, opacity 300ms",
        }}
      />
    </Box>
  );
};

export default LeftPanelLogo;
