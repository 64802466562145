import {useEffect} from "react";
import {RemoteControlProxy} from "../../../settings";
import {Grid} from "@mui/material";
import DeviceScreen from "./DeviceScreen";
import {useWebsocket} from "./Hooks/useWebsocket";
import DeviceNavigationControls from "./DeviceNavigationControls";
import FullScreenDialog from "../../common/dialog/FullScreenDialog";
import DeviceInitialization from "./DeviceInitialization";
import DeviceQualityControls from "./DeviceQualityControls";
import DeviceVideoRecorder from "./DeviceVideo/DeviceVideoRecorder";
import DeviceMediaFiles from "./DeviceMediaFiles";
import DeviceScreenshotTaker from "./DeviceScreenshot/DeviceScreenshotTaker";
import DeviceCommandLine from "./DeviceCommandLine";
import {PrimaryButton} from "../../common/buttons/PrimaryButton";
import {makeStyles} from "tss-react/mui";

export type RemoteControlActionType = {
  actionType: string;
  value?: number;
  button?: string;
  cursorPosition?: {xPosition: string; yPosition: string};
  cmd?: {id: number; text?: string};
  keyOfKeyboard?: {
    shiftKey: boolean;
    ctrlKey: boolean;
    altKey: boolean;
    keyDown: string;
  };
};

const useStyles = makeStyles()(() => ({
  buttons: {
    minWidth: "200px",
  },
  controls: {
    minWidth: "250px",
  },
}));

const DeviceRemoteControl = (props: {
  uidDevice: string;
  open: boolean;
  setOpen: Function;
}) => {
  const [
    socket,
    streamImageData,
    sessionOpened,
    streamAvailable,
    streamError,
    streamMessage,
    startStream,
    stopStream,
  ] = useWebsocket(RemoteControlProxy, props.uidDevice);
  const {classes: localClasses} = useStyles();

  // implicitly stop the stream and close the session before closing
  // the tab, otherwise the mobile device keeps sending the frames
  useEffect(() => {
    const handleTabClose = async (event: Event) => {
      event.preventDefault();
      await stopStream();
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    startStream();
    return () => {
      stopStream();
    };
  }, []);

  const handleCommand = (command: RemoteControlActionType) => {
    socket.send(JSON.stringify(command));
  };

  return (
    <FullScreenDialog
      title="Удаленное управление"
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <Grid container>
        {!streamAvailable && (
          <Grid item container justifyContent="center" alignContent="center">
            <DeviceInitialization
              sessionOpened={sessionOpened}
              startStream={startStream}
              streamError={streamError}
              stopStream={stopStream}
            />
          </Grid>
        )}
        {streamAvailable && (
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            spacing={2}
            sx={{pt: "70px", pb: "20px"}}
          >
            <Grid
              item
              container
              spacing={4}
              xs={3.5}
              alignContent="center"
              justifyContent="center"
            >
              <Grid
                item
                container
                spacing={2}
                xs={6}
                className={localClasses.controls}
              >
                <Grid item>
                  <DeviceCommandLine
                    className={localClasses.buttons}
                    response={streamMessage}
                    handleCommand={handleCommand}
                  />
                </Grid>
                <Grid item>
                  <DeviceVideoRecorder
                    className={localClasses.buttons}
                    device={props.uidDevice}
                  />
                </Grid>
                <Grid item>
                  <DeviceScreenshotTaker
                    className={localClasses.buttons}
                    device={props.uidDevice}
                  />
                </Grid>
                <Grid item>
                  <DeviceMediaFiles
                    className={localClasses.buttons}
                    device={props.uidDevice}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    className={localClasses.buttons}
                    onClick={() =>
                      handleCommand({
                        actionType: "hardwareButton",
                        button: "power",
                      })
                    }
                  >
                    Блокировка экрана
                  </PrimaryButton>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    className={localClasses.buttons}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    Отключиться
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignContent="center" justifyContent="center" xs="auto">
              <DeviceScreen
                screenData={streamImageData}
                handleCommand={handleCommand}
              />
              <DeviceNavigationControls handleCommand={handleCommand} />
            </Grid>
            <Grid
              item
              container
              spacing={4}
              xs={3.5}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item container xs={6} className={localClasses.controls}>
                <DeviceQualityControls handleCommand={handleCommand} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </FullScreenDialog>
  );
};

export default DeviceRemoteControl;
