import * as React from "react";
import {useState} from "react";
import {Grid} from "@mui/material";
import StringFilterField from "../../common/filterFields/StringFilterField";
import {FilterComponentProps} from "../../Tables/CoreTable";

export type SearchFilterType = {
  search: string;
};

type SearchFilterProps = {
  label?: string;
} & FilterComponentProps<SearchFilterType>;

const SearchFilter = (props: SearchFilterProps) => {
  const [search, setSearch] = useState(props.filters.search || "");

  return (
    <Grid container>
      <StringFilterField
        label="Искать..."
        name={"search"}
        value={search}
        onChange={setSearch}
        onEnterPress={(value: string) =>
          props.updateFilters({search: value.trim()})
        }
        onResetClick={() => {
          setSearch("");
          props.updateFilters({search: ""});
        }}
      />
    </Grid>
  );
};

export default SearchFilter;
