import {Button} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import postCommand from "../../../common/APIRequests/command/postCommand";
import {AppContext} from "../../App-context";
import MenuCommandsItems from "./MenuCommandsItems";
import {DeviceFilterType} from "../../../common/APIRequests/device/getDevices";

const complexCommands = [
  "CHANGEPINCODE",
  "GETADBLOG",
  "MESSAGE",
  "CHANGE_DIV_CODE",
  "CHANGE_CURRENT_PROJECT",
];

export type dataType = {
  additionalData?: string;
  isOnlyOneProject?: number;
};

const MenuCommandsAction = (props: {
  selection: Array<string | number>;
  rowDataSelection: Array<object>;
  filters: DeviceFilterType;
  update?: () => void;
}) => {
  const {selection, rowDataSelection, filters, update} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [commandCode, setCommandCode] = useState("");
  const [additionData, setAdditionData] = useState<string | null>(null);
  const [isOnlyOneProject, setIsOnlyOneProject] = useState(0);
  const [commandReady, setCommandReady] = useState(false);
  useEffect(() => {
    setCommandReady(
      Boolean(commandCode) && !complexCommands.includes(commandCode)
    );
  }, [commandCode]);

  const sendCommand = async (data?: dataType) => {
    setLoading(true);
    const result = await postCommand(
      commandCode,
      selection,
      data?.additionalData || additionData,
      data?.isOnlyOneProject || isOnlyOneProject,
      filters
    );
    setLoading(false);

    if (result.success) {
      setAdditionData(null);
      setCommandCode("");
      setIsOnlyOneProject(0);
      setAlert({
        severity: "success",
        message: ["Команда отправлена"],
      });
      setAnchorEl(null);
      update && update();
    }
  };

  return (
    <React.Fragment>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
        disabled={!selection.length && !filters?.id_project}
      >
        Управление
      </Button>
      <MenuCommandsItems
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        commandReady={commandReady}
        setCommandCode={setCommandCode}
        sendCommand={sendCommand}
        commandCode={commandCode}
        rowDataSelection={rowDataSelection}
        loading={loading}
        setCommandReady={setCommandReady}
        setAdditionData={setAdditionData}
        projectIdFilter={filters?.id_project}
      />
    </React.Fragment>
  );
};

export default MenuCommandsAction;
