import * as React from "react";
import {TaskProgressType} from "../../../common/APIRequests/task/getTaskProgress";
import {Description} from "../../common/Descriptions";

type GeneralInfoSectionProps = {
  taskDetails?: TaskProgressType;
};

const GeneralInfoSection = (props: GeneralInfoSectionProps) => {
  return (
    <>
      <Description
        title="Приложение"
        value={props.taskDetails?.app_version_description}
      />
      <Description
        title="Количество устройств в задании"
        value={props.taskDetails?.device_count}
      />
    </>
  );
};

export default GeneralInfoSection;
