import {Box, Button, Fab, Grid} from "@mui/material";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import CircularProgress from "@mui/material/CircularProgress";

const DeviceInitialization = (props: {
  sessionOpened: boolean;
  startStream: () => Promise<void>;
  streamError: null | string;
  stopStream: () => Promise<void>;
}) => {
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Box sx={{width: "250px", textAlign: "center", position: "relative"}}>
        {props.sessionOpened && !props.streamError && (
          <>
            <Grid item>
              <Fab color="secondary">
                <TabletAndroidIcon />
              </Fab>
              <CircularProgress
                size={75}
                sx={{
                  position: "absolute",
                  top: -10,
                  left: 0,
                  right: 0,
                  margin: "0 auto",
                  zIndex: 1,
                }}
              />
            </Grid>
            <Grid item sx={{mt: 3}}>
              Подключение к устройству...
            </Grid>
            <Grid item sx={{mt: 3}}>
              <Button
                variant="outlined"
                color="primary"
                onClick={props.stopStream}
              >
                Отмена
              </Button>
            </Grid>
          </>
        )}
        {!props.sessionOpened && !props.streamError && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.startStream}
            >
              Начать трансляцию
            </Button>
          </Grid>
        )}
        {!props.sessionOpened && props.streamError && (
          <Grid item>
            <Fab color="error">
              <TabletAndroidIcon />
            </Fab>
            <Grid item sx={{mt: 3}}>
              {props.streamError}
            </Grid>
            <Grid item sx={{mt: 3}}>
              <Button
                variant="outlined"
                color="primary"
                onClick={props.startStream}
              >
                Повторить попытку
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default DeviceInitialization;
