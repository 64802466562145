import {Dialog, DialogContent} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import * as React from "react";
import {useEffect, useState} from "react";
import getDevice from "../../../common/APIRequests/device/getDevice";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import {
  LoadingIndicator,
  TabPanel,
  tabProps,
  Transition,
} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {StyledTab, StyledTabs} from "../../common/Tabs";
import Commands from "../Commands/Commands";
import DeviceApplicationsTab from "../DeviceApplications/DeviceApplicationsTab";
import DeviceForm from "../DeviceInfo/DeviceForm";
import DeviceInfo from "../DeviceInfo/DeviceInfo";
import DeviceNetwork from "../DeviceInfo/DeviceNetwork";
import DeviceProfilesTab from "../DeviceProfiles/DeviceProfilesTab";
import Locations from "../Locations/Locations";
import LogFiles from "../LogFiles/LogFiles";
import Logs from "../Logs/Logs";
import Screenshots from "../Screenshots/Screenshots";
import {DeviceFirmwaresTab} from "../DeviceFirmwares/DeviceFirmwaresTab";

const useStyles = makeStyles()((theme) => ({
  scrollPaper: {
    alignItems: "flex-start",
  },
  dialogContent: {
    paddingTop: theme.spacing(0),
  },
}));

type DeviceDialogProps = {
  onClose: () => void;
  id_device?: number;
  device?: deviceType;
  defaultTab?: string;
  updateTable: () => void;
};
const DeviceDialog = (props: DeviceDialogProps) => {
  const defaultTab = props.defaultTab || "device";
  const [tab, setTab] = useState(defaultTab);
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState(props.device);

  useEffect(() => {
    if (props.id_device && device === undefined) {
      setLoading(true);
      getDevice(props.id_device)
        .then((result) => {
          if (result.success) {
            setDevice(result.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => setTab(defaultTab), [props.device]);

  const {classes: localClasses} = useStyles();

  return (
    <Dialog
      open={true}
      onClose={() => !loading && props.onClose()}
      keepMounted
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
      classes={{
        scrollPaper: localClasses.scrollPaper,
      }}
    >
      <ClosableDialogTitle onClose={() => !loading && props.onClose()}>
        Устройство пользователя: {device?.user_ldap_login}, серийный номер:{" "}
        {device?.serial_number}
      </ClosableDialogTitle>
      <DialogContent className={localClasses.dialogContent}>
        <StyledTabs
          value={tab}
          onChange={(_, tab) => setTab(tab)}
          aria-label="wrapped label tabs"
        >
          <StyledTab
            label="Устройство"
            {...tabProps("device")}
            disabled={loading}
          />
          <StyledTab
            label="Приложения"
            {...tabProps("apps")}
            disabled={loading}
          />
          <StyledTab
            label="Прошивки"
            {...tabProps("device_firmwares")}
            disabled={loading}
          />
          <StyledTab
            label="Профили"
            {...tabProps("profiles")}
            disabled={loading}
          />
          <StyledTab
            label="Местоположение"
            {...tabProps("location")}
            disabled={loading}
          />
          <StyledTab label="Сеть" {...tabProps("network")} disabled={loading} />
          <StyledTab label="Логи" {...tabProps("logs")} disabled={loading} />
          <StyledTab
            label="Лог-файлы"
            {...tabProps("logfiles")}
            disabled={loading}
          />
          <StyledTab
            label="Скриншоты"
            {...tabProps("screenshots")}
            disabled={loading}
          />
          <StyledTab
            label="Команды"
            {...tabProps("commands")}
            disabled={loading}
          />
        </StyledTabs>
        <TabPanel value={tab} index="device">
          <DeviceInfo device={device} />
          <DeviceForm
            handleClose={() => props.onClose()}
            update={props.updateTable}
            device={device}
            loading={loading}
            setLoading={setLoading}
          />
          {loading && <LoadingIndicator />}
        </TabPanel>
        {tab === "apps" && device && (
          <TabPanel value={tab} index="apps">
            <DeviceApplicationsTab
              device={device}
              loading={loading}
              setLoading={setLoading}
            />
          </TabPanel>
        )}
        {tab === "device_firmwares" && device && (
          <TabPanel value={tab} index="device_firmwares">
            <DeviceFirmwaresTab
              device={device}
              loading={loading}
              setLoading={setLoading}
            />
          </TabPanel>
        )}
        {tab === "profiles" && device && (
          <TabPanel value={tab} index="profiles">
            <DeviceProfilesTab
              device={device}
              loading={loading}
              setLoading={setLoading}
            />
          </TabPanel>
        )}
        {tab === "network" && device && (
          <TabPanel value={tab} index="network">
            <DeviceNetwork device={device} />
          </TabPanel>
        )}
        {tab === "location" && device && (
          <TabPanel value={tab} index="location">
            <Locations
              loading={loading}
              setLoading={setLoading}
              id_device={device.id_device}
            />
          </TabPanel>
        )}
        {tab === "logs" && device && (
          <TabPanel value={tab} index="logs">
            <Logs
              loading={loading}
              setLoading={setLoading}
              id_device={device.id_device}
            />
          </TabPanel>
        )}
        {tab === "logfiles" && device && (
          <TabPanel value={tab} index="logfiles">
            <LogFiles
              loading={loading}
              setLoading={setLoading}
              id_device={device.id_device}
            />
          </TabPanel>
        )}
        {tab === "commands" && device && (
          <TabPanel value={tab} index="commands">
            <Commands
              loading={loading}
              setLoading={setLoading}
              device={device}
            />
          </TabPanel>
        )}
        <TabPanel value={tab} index="screenshots">
          {tab === "screenshots" && device && (
            <Screenshots
              loading={loading}
              setLoading={setLoading}
              id_device={device.id_device}
            />
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default DeviceDialog;
