import Column from "../Tables/Column";
import {BooleanTypeProvider} from "../common/grid/providers";
import {parseGeoFullName} from "../common/functions";

const TasksColumns: Array<Column> = [
  new Column("id_template", "ID", 100, {hiddenByDefault: true}),
  new Column("name", "Наименование шаблона", 200, {auto: true}),
  new Column("id_project", "ID проекта", 100, {hiddenByDefault: true}),
  new Column("name_project", "Наименование проекта", 250),
  new Column("id_app_version", "ID версии приложения", 200, {
    hiddenByDefault: true,
  }),
  new Column("app_version_name", "Наименование версии", 200),
  new Column("app_version_description", "Описание версии", 300),
  new Column("geo_net", "Сеть", 150, {
    getCellValue: (row) => parseGeoFullName(row.geo_fullname).net,
  }),
  new Column("geo_branch", "Филиал", 150, {
    getCellValue: (row) => parseGeoFullName(row.geo_fullname).branch,
  }),
  new Column("geo_division", "Подразделение", 150, {
    getCellValue: (row) => parseGeoFullName(row.geo_fullname).division,
  }),
  new Column("children", "Включать подпроекты", 200, {
    align: "center",
    provider: BooleanTypeProvider,
  }),
];

export default TasksColumns;
