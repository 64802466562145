import Column from "../Tables/Column";
import {BooleanTypeProvider} from "../common/grid/providers";

const NotificationColumns = [
  new Column("id_notify", "ID", 100, {hiddenByDefault: true}),
  new Column("name", "Наименование", 600, {auto: true}),
  new Column("is_active", "Активно", 200, {provider: BooleanTypeProvider}),
];

export default NotificationColumns;
