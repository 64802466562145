import {createUseStyles} from "react-jss";
import {red} from "@mui/material/colors";
import {Theme} from "@mui/material/styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useGlobalStyles = createUseStyles((theme: Theme) => ({
  // style color tablegrid row
  rowColorRed: {
    color: `${red.A700}!important`,
  },
  // loaders
  tableProgress: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 1,
  },

  // table grid
  gridRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  gridRowSelected: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  gridRowChanged: {
    cursor: "pointer",
    backgroundColor: "rgba(232, 240, 254, 1)",
  },

  // expansion panel
  panelSummary: {
    minHeight: "44px!important",
  },
  panelSummaryContent: {
    margin: "10px 0!important",
  },
  panelDetail: {
    borderTop: "2px solid #eee",
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
}));
