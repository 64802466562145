import {alertType} from "../../../common/types";
import {Dialog} from "@mui/material";
import {Alert, AlertTitle} from "@mui/material";
import React from "react";
import {Spacer} from "../components";
import {makeStyles} from "tss-react/mui";
import SnackbarAlert from "./SnackbarAlert";

const useStyles = makeStyles()({
  alertContainer: {
    padding: "14px 20px",
  },
});

const AlertNotification = (props: {alert: alertType; onClose: () => void}) => {
  const {classes} = useStyles();
  return props.alert.severity === "error" ? (
    <Dialog open={true}>
      <Alert
        severity={props.alert.severity}
        onClose={props.onClose}
        className={classes.alertContainer}
      >
        <AlertTitle>Ошибка</AlertTitle>
        <Spacer height={10} />
        {props.alert.message}
      </Alert>
    </Dialog>
  ) : (
    <SnackbarAlert {...props} />
  );
};

export default AlertNotification;
