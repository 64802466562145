import * as React from "react";
import {PropsWithChildren, useContext, useState} from "react";
import withStyles from "@mui/styles/withStyles";
import {SPACING} from "../../../theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  IconComponentType,
  LeftPanelGroupItem,
  LeftPanelGroupListItem,
  LeftPanelListItem,
  leftPaneStyleParams,
} from "./LeftPanelItems";
import {useLocation} from "react-router-dom";
import {useLocalStorageCategory} from "../../../common/customHooks";
import {LeftPanelSubMenu} from "./LeftPanelSubMenu";
import {List} from "@mui/material";
import {AppContext} from "../../App-context";
import hasPermissions from "../viewPermissions";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
      borderTop: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    paddingTop: leftPaneStyleParams.accordion.paddingHor,
    paddingBottom: leftPaneStyleParams.accordion.paddingHor,
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    justifyContent: "flex-start",

    marginBottom: -1,
    minHeight: 0,
    "&$expanded": {
      minHeight: 0,
    },
    padding: "0 8px 0 8px",
  },

  content: {
    "&$expanded": {
      margin: "0px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    display: "inherit",
  },
}))(MuiAccordionDetails);

export type LinkInfoType = {url: string; name: string; title: string};

type LeftPanelGroupProps = {
  title: string;
  links: LinkInfoType[];
  icon: IconComponentType;
  leftPanelOpen: boolean;
};

export const LeftPanelGroup = (
  props: PropsWithChildren<LeftPanelGroupProps>
) => {
  const [expanded, setExpanded] = useLocalStorageCategory(
    props.title,
    "left_panel_groups_expanded",
    true
  );
  const [subMenuAnchor, setSubMenuAnchor] = useState<null | HTMLElement>(null);

  const location = useLocation();
  const activated = props.links
    .map((link) => link.url)
    .includes(location.pathname);

  const handleHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!props.leftPanelOpen) setSubMenuAnchor(event.currentTarget);
  };

  const {permissions} = useContext(AppContext);

  const listItems = props.links.filter((link) =>
    hasPermissions(permissions.user_type, link.url)
  );

  if (listItems.length == 0) return null;
  if (listItems.length == 1)
    return (
      <LeftPanelGroupListItem
        {...listItems[0]}
        icon={props.icon}
        leftPanelOpen={props.leftPanelOpen}
      />
    );

  return (
    <>
      {subMenuAnchor && (
        <LeftPanelSubMenu
          links={props.links}
          anchor={subMenuAnchor}
          onClose={() => setSubMenuAnchor(null)}
        />
      )}
      <Accordion
        square
        expanded={expanded && props.leftPanelOpen}
        onChange={(event, isExpanded) =>
          props.leftPanelOpen && setExpanded(isExpanded)
        }
        onClick={handleHover}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            transition: "height 300ms ",
            height: props.leftPanelOpen
              ? leftPaneStyleParams.groupIcon.width -
                leftPaneStyleParams.groupIcon.marginTop
              : leftPaneStyleParams.groupIconWrapped.height -
                parseInt(leftPaneStyleParams.accordion.paddingHor) * 2,
          }}
        >
          <LeftPanelGroupItem activated={activated} {...props} />
        </AccordionSummary>
        <AccordionDetails>
          <List disablePadding>
            {listItems.map((link) => (
              <LeftPanelListItem
                {...link}
                key={link.name}
                style={{
                  paddingLeft: leftPaneStyleParams.groupIcon.width + SPACING,
                }}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
