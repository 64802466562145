import {Button} from "@mui/material";
import React, {useContext, useState} from "react";
import postCommand from "../../../common/APIRequests/command/postCommand";
import {AppContext} from "../../App-context";
import CommandsLogForm from "./CommandsLogForm";
import {dataType} from "./MenuCommandsAction";

const SingleLogCommand = (props: {
  command: string;
  buttonTitle: string;
  selection: Array<string | number>;
  update?: Function;
}) => {
  const {command, buttonTitle, selection, update} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [loading, setLoading] = useState(false);

  const [commandReady, setCommandReady] = useState(false);

  const sendCommand = async (data?: dataType) => {
    setLoading(true);
    const result = await postCommand(command, selection, data?.additionalData);
    setLoading(false);

    if (result.success) {
      setCommandReady(false);
      update && update();
      setAlert({
        severity: "success",
        message: ["Команда отправлена"],
      });
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCommandReady(true)}
        disabled={!selection.length}
      >
        {buttonTitle}
      </Button>
      {commandReady && (
        <CommandsLogForm
          loading={loading}
          sendCommand={sendCommand}
          onClose={() => setCommandReady(false)}
        />
      )}
    </React.Fragment>
  );
};

export default SingleLogCommand;
