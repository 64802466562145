import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type applicationVersionType = {
  id_app: number;
  app_uid: string;
  id_app_version: number;
  file_path: string;
  name: string;
  code: number;
  description: string;
  is_release: 1 | 0;
  file_size: number;
  signature: string;
  check_signature: 1 | 0;
  file_hash: string;
};

const getApplicationVersions = async (id_app: number) =>
  baseGetRowsRequest<applicationVersionType>(`/application/${id_app}/version/`);

export default getApplicationVersions;
