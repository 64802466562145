import {basePostRequest} from "../base/basePostRequest";
import {NestedPolicyType} from "../policy/types";

const postNestedPolicies = async <PolicyType extends NestedPolicyType>(
  id_link: number,
  resource: "project" | "profile",
  rows: Array<PolicyType>
) => {
  const policiesWithNulls = rows.map((policy: PolicyType) => {
    return {
      ...policy,
      value: policy.value ? policy.value : null,
    };
  });
  return basePostRequest<PolicyType>(
    `/${resource}/${id_link}/policy/`,
    policiesWithNulls
  );
};

export default postNestedPolicies;
