import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export const APP_PROFILE_TYPE = 1;
export const DEVICE_PROFILE_TYPE = 2;

export type profileTypes = 1 | 2;

export type profileFilterType = {id_type_profile: number};

export type profileType = {
  id_prof: number;
  id_app?: number;
  name: string;
  specification: string;
  priority: number | undefined;
  id_type_profile: profileTypes;
};

const getProfiles = async (filters: profileFilterType) =>
  baseGetRowsRequest<profileType>(`/profile/`, {filters});

export default getProfiles;
