import * as React from "react";
import {useContext, useState} from "react";
import SelectSingleField from "../../../../../common/formFields/SelectSingleField";
import {ReportFilterProps} from "./EventIdReportFilter";
import {DirectoryType} from "../../../../../../common/APIRequests/directory/getDirectory";
import {AppContext} from "../../../../../App-context";

const ClassificatorReportFilter = (props: ReportFilterProps) => {
  const [errors, setErrors] = useState({});

  const {directories} = useContext(AppContext);

  return (
    <SelectSingleField
      name="id_type_user"
      value={props.value}
      setValue={props.setValue}
      errors={errors}
      setErrors={setErrors}
      options={directories.map((d: DirectoryType) => ({
        key: d.id_dir,
        value: d.id_dir,
        text: d.name_dir,
      }))}
      label={props.name}
    />
  );
};

export default ClassificatorReportFilter;
