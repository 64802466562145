import {Box, Dialog, DialogContent} from "@mui/material";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {SecondaryButton} from "../../common/buttons/SecondaryButton";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {BottomToolbarWrapper, ToolbarSeparator} from "../../common/Toolbar";
import CompareTreesTable from "./CompareTreesTable";
import {BaseGetRowsResponse} from "../../../common/APIRequests/base/baseGetRowsRequest";
import {comparedTreesType} from "../../../common/APIRequests/policy/postComparePolicyTreeWithCsv";

type CompareTreesDialogType = {
  currentProject: projectType;
  destinationProject?: projectType;
  fileName?: string;
  updateTable: (
    currentIdProject: number,
    destinationIdProject?: number
  ) => BaseGetRowsResponse<comparedTreesType>;
  onClose: () => void;
};

const CompareTreesDialog = (props: CompareTreesDialogType) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
    >
      <ClosableDialogTitle onClose={props.onClose}>
        {props.destinationProject ? (
          <span>
            Сравнение настроек проекта {`${props.currentProject.project_name} `}
            c{` ${props.destinationProject.project_name}`}
          </span>
        ) : (
          <span>
            Сравнение настроек проекта
            {` "${props.currentProject.project_name}"`} с загруженным файлом
          </span>
        )}
      </ClosableDialogTitle>
      <DialogContent>
        <CompareTreesTable
          close={props.onClose}
          currentProject={props.currentProject}
          destinationProject={props.destinationProject}
          update={props.updateTable}
          fileName={props.fileName}
        />
        <BottomToolbarWrapper>
          <ToolbarSeparator />
          <Box>
            <SecondaryButton fullWidth onClick={props.onClose}>
              Закрыть
            </SecondaryButton>
          </Box>
        </BottomToolbarWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default CompareTreesDialog;
