import {useContext, useState} from "react";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import {AddNewButton} from "../common/buttons/ToolbarButtons";
import getQrCodes, {
  QrCodeFilterType,
  QrCodeType,
} from "../../common/APIRequests/qrCode/getQrCodes";
import {useTrigger} from "../../common/customHooks";
import {QrCodesFilter} from "./QrCodesFilter";
import QrCodeColumns from "./QrCodeColumns";
import postQrCode from "../../common/APIRequests/qrCode/postQrCode";
import QrCodeImageDialog from "./Dialogs/QrCodeImageDialog";
import {AppContext} from "../App-context";
import SelectProjectDialog from "./Dialogs/SelectProjectDialog";

export const QrCodesTable = () => {
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [refreshTrigger, refresh] = useTrigger();
  const [loading, setLoading] = useState(false);
  const [rowClicked, setRowClicked] = useState(
    undefined as QrCodeType | undefined
  );
  const {setAlert} = useContext(AppContext);

  const updateRows: UpdateRowsType<QrCodeType, QrCodeFilterType> = ({
    sorting,
    paging,
    filters,
  }) =>
    getQrCodes(
      paging?.page,
      paging?.page_size,
      sorting?.sort_field,
      sorting?.sort_desc,
      filters
    );

  return (
    <>
      {isAddDialogOpen && (
        <SelectProjectDialog
          onClose={() => setAddDialogOpen(false)}
          update={async (id_project: number) => {
            setLoading(true);
            const result = await postQrCode(id_project);
            if (result.success) {
              setAlert({
                severity: "success",
                message: ["QR-код успешно создан"],
              });
              refresh();
              setLoading(false);
            } else if (result.errors) {
              setAlert({
                severity: "error",
                message: ["Ошибка при создании QR-кода"],
              });
            }
          }}
          loading={loading}
        />
      )}
      {rowClicked && (
        <QrCodeImageDialog
          data={rowClicked}
          onClose={() => setRowClicked(undefined)}
        />
      )}
      <CoreTable
        columns={QrCodeColumns}
        getRowId={(row) => row.token}
        name={"firmwares"}
        updateRows={updateRows}
        onRowClick={setRowClicked}
        updateTrigger={refreshTrigger}
        defaultSorting={{columnName: "create_date", direction: "desc"}}
        ToolBarFilterComponent={QrCodesFilter}
        sortingEnabled
        pagingEnabled
        height="60vh"
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddDialogOpen(true)}
            key="qr-code-add_button"
          />,
        ]}
      />
    </>
  );
};
