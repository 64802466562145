import Container from "@mui/material/Container";
import {makeStyles} from "tss-react/mui";
import React, {ReactNode} from "react";
import {useLocalStorage} from "../common/customHooks";
import LoginForm from "./authorization/LoginForm";
import LeftPanel from "./common/leftPanel/LeftPanel";
import MenuPanel from "./common/topPanel/MenuPanel";
import {loginType} from "../common/APIRequests/login/postSession";
import {Theme} from "@mui/material";

//Unexpected value type of MemberExpression.
const useStyles = makeStyles()((theme: Theme) => ({
  appBarSpacer: {minHeight: 64},
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    padding: theme.spacing(2),
  },
  containerXl: {
    maxWidth: "100vw",
  },
}));

export type ContentProps = {
  barContent?: ReactNode;
  Cont?: React.ComponentType;
  isAuth: boolean;
  userLogin: (login: loginType) => void;
  userLogout: () => void;
};

const Content = (props: ContentProps) => {
  const {barContent, Cont, isAuth, userLogin, userLogout} = props;

  const [sidebarLeftVisible, setSidebarLeftVisible] = useLocalStorage<boolean>(
    "sidebarLeftVisible",
    true
  );

  const {classes} = useStyles();

  return (
    <React.Fragment>
      {isAuth && (
        <MenuPanel
          leftSidebarOpen={sidebarLeftVisible}
          openLeftSidebar={() => setSidebarLeftVisible(true)}
          userLogout={userLogout}
        >
          {barContent}
        </MenuPanel>
      )}

      {isAuth && (
        <LeftPanel
          open={sidebarLeftVisible}
          handleClose={() => setSidebarLeftVisible(false)}
        />
      )}

      <main className={classes.content}>
        {isAuth && Cont ? (
          <>
            <div className={classes.appBarSpacer} />
            <Container
              maxWidth="xl"
              className={classes.container}
              classes={{maxWidthXl: classes.containerXl}}
            >
              <Cont />
            </Container>
          </>
        ) : (
          <LoginForm userLogin={userLogin} />
        )}
      </main>
    </React.Fragment>
  );
};

export default Content;
