import {useCallback, useEffect, useState} from "react";
import {PrimaryButton} from "../../common/buttons/PrimaryButton";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {Box, Dialog, DialogContent, Paper, PaperProps} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import Draggable from "react-draggable";
import {RemoteControlActionType} from "./DeviceRemoteControl";
import {StreamMessageType} from "./Hooks/useWebsocket";
import Terminal, {TerminalOutput} from "react-terminal-ui";

const useStyles = makeStyles()((theme) => ({
  scrollPaper: {
    alignItems: "flex-start",
  },
  dialogContent: {
    paddingTop: theme.spacing(0),
    height: "450px",
    width: "900px",
  },
  dialog: {
    top: "11%",
    left: "3%",
    height: "fit-content",
    width: "fit-content",
  },
  terminal: {
    "& .terminal-hidden-input": {
      background: "transparent",
      border: "transparent",
      color: "transparent",
      outline: "transparent",
      "::placeholder": {
        color: "transparent",
      },
      pointerEvents: "none",
    },
    marginBottom: "10px",
  },
}));

const DeviceCommandLine = (props: {
  response: StreamMessageType;
  className: string;
  handleCommand: (command: RemoteControlActionType) => void;
}) => {
  const [commandLineOpen, setCommandLineOpen] = useState(false);
  const {classes: localClasses} = useStyles();
  const [terminalLineData, setTerminalLineData] = useState([] as JSX.Element[]);
  const [inputBlocked, setInputBlocked] = useState(false);
  const {response} = props;

  useEffect(() => {
    if (response?.responseText === "command_finished") {
      setInputBlocked(false);
    } else if (response?.responseText) {
      setTerminalLineData((prevState) => [
        ...prevState,
        <TerminalOutput key={prevState.length + 1}>
          {response.responseText}
        </TerminalOutput>,
      ]);
    }
  }, [response]);

  const handleOpen = () => {
    setCommandLineOpen(true);
  };
  const handleCommand = (command: string) => {
    if (!command.length || inputBlocked) return;
    setInputBlocked(true);
    setTerminalLineData((prevState) => [
      ...prevState,
      <TerminalOutput key={prevState.length + 1}>{command}</TerminalOutput>,
    ]);
    props.handleCommand({
      actionType: "runCommand",
      cmd: {id: 0, text: command},
    });
  };
  const handleStop = () => {
    props.handleCommand({actionType: "stopCommand", cmd: {id: 0}});
  };

  const PaperComponent = useCallback((props: PaperProps) => {
    return <Paper {...props} style={{margin: 0, maxHeight: "100%"}} />;
  }, []);

  return (
    <Box>
      {commandLineOpen && (
        <Draggable
          handle={'[class*="MuiDialog-root"]'}
          cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Dialog
            open={true}
            maxWidth="lg"
            className={localClasses.dialog}
            hideBackdrop
            PaperComponent={PaperComponent}
            disableEnforceFocus
            TransitionComponent={Transition}
          >
            <ClosableDialogTitle
              style={{cursor: "move", userSelect: "none"}}
              onClose={() => {
                setCommandLineOpen(false);
              }}
            >
              Командная строка
            </ClosableDialogTitle>
            <DialogContent className={localClasses.dialogContent}>
              <Box className={localClasses.terminal}>
                <Terminal height="270px" onInput={handleCommand}>
                  {terminalLineData}
                </Terminal>
              </Box>
              <PrimaryButton onClick={handleStop}>Остановить</PrimaryButton>
            </DialogContent>
          </Dialog>
        </Draggable>
      )}
      <PrimaryButton className={props.className} onClick={handleOpen}>
        Командная строка
      </PrimaryButton>
    </Box>
  );
};

export default DeviceCommandLine;
