import * as React from "react";
import {Chip} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

type ApplicationLinkProfileChipProps = {
  onClick: () => void;
  onDelete: () => void;
  value?: string;
};

const ApplicationLinkProfileChip = (props: ApplicationLinkProfileChipProps) => {
  return props.value ? (
    <Chip label={props.value} clickable onDelete={props.onDelete} />
  ) : (
    <Chip
      label={"Привязать профиль"}
      color="secondary"
      onClick={props.onClick}
      clickable
      deleteIcon={<LinkIcon />}
    />
  );
};

export default ApplicationLinkProfileChip;
