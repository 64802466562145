import * as React from "react";
import {FilterComponentProps} from "../Tables/CoreTable";
import {ConsoleLogSourceType} from "../../common/APIRequests/consoleLog/getConsoleLogs";
import {StyledMenuFilterGrid} from "../common/grid/StyledFilterGrid";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import {consoleLogSourceTitles} from "./ConsoleLogColumns";
import {Grid} from "@mui/material";
import {ConsoleLogsFilterType} from "./ConsoleLogsFilter";

const ConsoleLogRequiredFilter = (
  props: FilterComponentProps<ConsoleLogsFilterType>
) => {
  return (
    <Grid container>
      <StyledMenuFilterGrid item xs={12}>
        <SelectSingleFilterField
          name="console_log_source"
          label="Источник *"
          value={props.filters.source}
          update={(value) => {
            props.updateFilters({...props.filters, source: value});
          }}
          disabled={props.loading}
          options={Object.keys(consoleLogSourceTitles).map((value) => {
            const valueNumber = parseInt(value);
            return {
              value: valueNumber,
              text: consoleLogSourceTitles[valueNumber as ConsoleLogSourceType],
            };
          })}
        />
      </StyledMenuFilterGrid>
    </Grid>
  );
};

export default ConsoleLogRequiredFilter;
