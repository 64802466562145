import {errorType} from "../../../../../common/types";
import {rcApiClient} from "../rcApiClient";

export function baseGetRowsRequestWithCount<T>(
  url: string,
  params?: any
): Promise<{
  success: boolean;
  rows: Array<T>;
  errors: errorType;
  count: number;
}> {
  return new Promise((resolve) =>
    rcApiClient
      .get(url, {params: params})
      .then((response) => {
        resolve({
          success: true,
          rows: response.data.files.map((file: T) => {
            return {...file, baseUrl: response.data.baseDownloadUrl};
          }),
          count: response.data.filesCount,
          errors: {},
        });
      })
      .catch((errors) => {
        resolve({
          success: false,
          rows: [],
          count: 0,
          errors: errors,
        });
      })
  );
}
