import {Box, FormControl, Grid} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {makeStyles} from "tss-react/mui";
import getDashboard, {
  dashboardType,
} from "../../common/APIRequests/dashboard/getDashboard";
import {projectType} from "../../common/APIRequests/project/getProjects";
import {optionType} from "../../common/types";
import {AppContext} from "../App-context";
import {RefreshButton} from "../common/buttons/ToolbarButtons";
import {LoadingIndicator} from "../common/components";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import {generateProjectOptions} from "../common/functions";
import {ToolbarItem, ToolbarSeparator} from "../common/Toolbar";
import Chart from "./charts/Chart";
import BarChart from "./chartTypes/BarChart";
import BarStackChart from "./chartTypes/BarStackChart";
import BarVerticalChart from "./chartTypes/BarVerticalChart";

const useStyles = makeStyles()((theme) => ({
  media: {
    height: "inherit",
  },
  title: {
    padding: theme.spacing(1),
  },
  chartWrapper: {
    height: "82vh",
  },
  chart: {
    minHeight: "39vh",
    padding: theme.spacing(3),
  },
  toolbar: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    position: "relative",
    minHeight: "82vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  toolbarWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: "5vh",
    marginBottom: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const appContext = useContext(AppContext);
  const {projects, updateProjects} = appContext;
  const options = useMemo(() => {
    const result: Array<optionType> = [];
    projects.forEach((project: projectType) =>
      generateProjectOptions(project, result)
    );
    return result;
  }, [projects]);

  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const loading = dashboardLoading || projectsLoading;
  const [values, setValues] = useState({
    id_project: null,
  } as {id_project: number | null});

  const updatePage = () => {
    updateDashboard();
    updateProjectsFilter();
  };

  const updateDashboard = () => {
    setDashboardLoading(true);
    getDashboard(values.id_project).then((result) => {
      if (cancelled) {
        return;
      }
      setDashboardLoading(false);

      result.success && setData(result.rows);
    });
  };

  const updateProjectsFilter = () => {
    setProjectsLoading(true);
    updateProjects().then(() => {
      if (cancelled) {
        return;
      }
      setProjectsLoading(false);
    });
  };

  useEffect(updateDashboard, [values]);

  const [data, setData] = useState({} as dashboardType);

  const handleChange = (name: string) => (value: number | null) => {
    setValues({...values, [name]: value});
  };

  const {classes: localClasses} = useStyles();

  return (
    <React.Fragment>
      <Box className={localClasses.toolbarWrapper}>
        <ToolbarItem>
          <RefreshButton
            disabled={loading}
            update={updatePage}
            id="dashboard_refresh"
          />
        </ToolbarItem>
        <ToolbarSeparator />
        <ToolbarItem>
          <FormControl>
            <SelectSingleFilterField
              name="id_project"
              label="Отфильтровать по проекту"
              id="dashboard_filter_project"
              value={values.id_project || null}
              update={handleChange("id_project")}
              disabled={loading}
              options={options}
              includeAllOption
            />
          </FormControl>
        </ToolbarItem>
      </Box>
      <Box className={localClasses.root}>
        <Grid container className={localClasses.chartWrapper} spacing={2}>
          {data.os_version && (
            <Grid container item sm={12} md={6} lg={6} xl={6}>
              <Chart
                name="Версии Android"
                Chart={BarChart}
                data={
                  data.os_version.filter((dCoV) => dCoV.count_os > 0).length
                    ? data.os_version.map((aV) => ({
                        name: `${aV.name_os} ${aV.version_os}`,
                        quantity: aV.count_os,
                      }))
                    : []
                }
              />
            </Grid>
          )}
          {data.activity && (
            <Grid container item sm={12} md={6} lg={6} xl={6}>
              <Chart
                name="Активные за последние сутки"
                Chart={BarVerticalChart}
                data={
                  data.activity?.active_count > 0 ||
                  data.activity?.inactive_count > 0
                    ? [
                        {
                          name: "Активные",
                          quantity: data.activity?.active_count,
                        },
                        {
                          name: "Неактивные",
                          quantity: data.activity?.inactive_count,
                        },
                      ]
                    : []
                }
              />
            </Grid>
          )}

          {data.days && (
            <Grid container item sm={12} md={6} lg={6} xl={6}>
              <Chart
                name="График активности устройств"
                Chart={BarChart}
                data={
                  data.days.filter((dCd) => dCd.quantity > 0).length
                    ? data.days.map((cD) => ({
                        name: cD.name,
                        quantity: cD.quantity,
                      }))
                    : []
                }
              />
            </Grid>
          )}
          {data.model && (
            <Grid container item sm={12} md={6} lg={6} xl={6}>
              <Chart
                name="Модели устройств"
                Chart={BarChart}
                data={
                  data.model.filter((row) => row.model_sum > 0).length
                    ? data.model.map((row) => ({
                        name: row.model_name,
                        quantity: row.model_sum,
                      }))
                    : []
                }
              />
            </Grid>
          )}
          {data.task_progress && (
            <Grid container item sm={12} md={12} lg={12} xl={12}>
              <Chart
                name="График выполнения заданий"
                Chart={BarStackChart}
                data={data.task_progress}
              />
            </Grid>
          )}
          {loading && <LoadingIndicator />}
        </Grid>
      </Box>
    </React.Fragment>
  );
};
export default Dashboard;
