import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";
import {geographyType} from "./getBranch";

// For geo with a parent (branches and divisions)
const getGeo = async (
  id_geo: string | undefined,
  page?: number,
  page_size?: number,
  sort_field?: string,
  sort_desc?: boolean,
  search?: string
) =>
  baseGetRowsRequestWithCount<geographyType>(`/geo/`, {
    id_geo,
    page,
    page_size,
    sort_field,
    sort_desc,
    search,
  });

export default getGeo;
