import {Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {TabPanel, tabProps, Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {StyledTab, StyledTabs} from "../../common/Tabs";
import CompareWithFileButton from "./CompareWithFileButton";
import CompareWithProjectForm from "./CompareWithProjectForm";

type CompareDialogProps = {
  idProject: number;
  defaultTab?: string;
  onClose: () => void;
};

const CompareDialog = (props: CompareDialogProps) => {
  const [loading, setLoading] = useState(false);
  const defaultTab = props.defaultTab || "project";
  const [tab, setTab] = useState(defaultTab);

  useEffect(() => setTab(defaultTab), [props.idProject]);

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Выбрать проект или загрузить файл с настройками
      </ClosableDialogTitle>
      <DialogContent>
        <StyledTabs
          value={tab}
          onChange={(_, tab) => setTab(tab)}
          aria-label="wrapped label tabs"
        >
          <StyledTab
            label="Проект"
            {...tabProps("project")}
            disabled={loading}
          />
          <StyledTab label="Файл" {...tabProps("file")} disabled={loading} />
        </StyledTabs>
        <TabPanel value={tab} index="project">
          <CompareWithProjectForm
            idProject={props.idProject}
            setLoading={setLoading}
            loading={loading}
            onClose={props.onClose}
          />
        </TabPanel>
        {tab === "file" && (
          <TabPanel value={tab} index="file">
            <CompareWithFileButton
              idProject={props.idProject}
              setLoading={setLoading}
              loading={loading}
              onClose={props.onClose}
            />
          </TabPanel>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CompareDialog;
