import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";

export type QrCodeType = {
  qr_code: string;
  create_date: string;
  expire_date: string;
  login: string;
  id_project: number;
  project_name: string;
  server: string;
  token: string;
};

export type QrCodeFilterType = {
  id_project: number | null;
  login: string | null;
};

const getQrCodes = async (
  page?: number,
  page_size?: number,
  sort_field?: string,
  sort_desc?: boolean,
  filters?: QrCodeFilterType
) =>
  baseGetRowsRequestWithCount<QrCodeType>(`/qr_code/`, {
    page,
    page_size,
    sort_field,
    sort_desc,
    filters,
  });

export default getQrCodes;
