import * as React from "react";
import {useState} from "react";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import getNotificationRecipients, {
  NotificationRecipientType,
} from "../../../common/APIRequests/notifyRecipient/getNotifyRecipient";
import {NotificationType} from "../../../common/APIRequests/notify/getNotification";
import NotificationRecipientColumns from "./NotificationRecipientColumns";
import {AddNewButton} from "../../common/buttons/ToolbarButtons";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import AddRecipientDialog from "./AddRecipientDialog";
import {BooleanTypeProvider} from "../../common/grid/providers";
import {useTrigger} from "../../../common/customHooks";
import deleteNotificationRecipient from "../../../common/APIRequests/notifyRecipient/deleteNotificationRecipient";

type NotificationRecipientsTableProps = {
  notification: NotificationType;
};

const NotificationRecipientsTable = (
  props: NotificationRecipientsTableProps
) => {
  const updateRows: UpdateRowsType<NotificationRecipientType> = () =>
    getNotificationRecipients(props.notification.id_notify);

  const [addOpen, setAddOpen] = useState(false);
  const [trigger, refresh] = useTrigger();

  return (
    <>
      {addOpen && (
        <AddRecipientDialog
          onClose={() => setAddOpen(false)}
          update={refresh}
          notification={props.notification}
        />
      )}
      <CoreTable
        name="notification_recipients"
        columns={NotificationRecipientColumns}
        getRowId={(row) => row.id_channel}
        updateRows={updateRows}
        integratedSearch
        selectionEnabled
        updateTrigger={trigger}
        defaultSorting={{columnName: "name_type_channel", direction: "asc"}}
        selectionButton={(selection, update) => (
          <MenuDeleteAction
            selection={selection}
            update={update}
            action={(ids) =>
              deleteNotificationRecipient(props.notification.id_notify, ids)
            }
          />
        )}
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddOpen(true)}
            key="add_new_notification_button"
          />,
        ]}
        plugins={[
          <BooleanTypeProvider
            for={["is_deleted"]}
            key="is_deleted_notification_provider"
          />,
        ]}
      />
    </>
  );
};

export default NotificationRecipientsTable;
