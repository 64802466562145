import {comparedTreesType} from "./postComparePolicyTreeWithCsv";
import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

const getComparePolicyTreesOfTwoProjects = async (
  id_project: number,
  destination_id?: number
) =>
  baseGetRowsRequest<comparedTreesType>(
    `/project/${id_project}/compare_policy_trees_of_two_projects/`,
    {destination_id}
  );

export default getComparePolicyTreesOfTwoProjects;
