import Column from "../../Tables/Column";
import {GmsValues} from "../../../common/APIRequests/firmware/getFirmwares";
import {DeviceFirmwareType} from "../../../common/APIRequests/deviceFirmware/getDeviceFirmware";
import {BooleanTypeProvider} from "../../common/grid/providers";

const DeviceFirmwareColumns = [
  new Column("id_firmware", "№", 100, {hiddenByDefault: true}),
  new Column("name", "Наименование", 350),
  new Column("has_gms", "Наличие GMS", 150, {
    getCellValue: (row: DeviceFirmwareType) => GmsValues[row.has_gms as number],
  }),
  new Column("comment_text", "Комментарий", 300, {
    sortingEnabled: false,
  }),
  new Column("file_size", "Размер", 150),
  new Column("is_target", "Релизная", 200, {
    align: "center",
    provider: BooleanTypeProvider,
    auto: true,
  }),
];

export default DeviceFirmwareColumns;
