import {Button} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import {RemoteControlActionType} from "./DeviceRemoteControl";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  button: {
    flex: "auto",
    backgroundColor: "black",
    padding: "15px 0",
  },
}));

const DeviceNavigationControls = (props: {
  handleCommand: (command: RemoteControlActionType) => void;
}) => {
  const {classes} = useStyles();
  return (
    <div style={{display: "flex"}}>
      <Button
        onClick={() =>
          props.handleCommand({
            actionType: "hardwareButton",
            button: "unlock",
          })
        }
        variant="contained"
        className={classes.button}
        sx={{borderRadius: "0 0 0 36px"}}
      >
        <MenuIcon />
      </Button>
      <Button
        onClick={() =>
          props.handleCommand({actionType: "hardwareButton", button: "home"})
        }
        variant="contained"
        className={classes.button}
        sx={{borderRadius: "0"}}
      >
        <HomeIcon />
      </Button>
      <Button
        onClick={() =>
          props.handleCommand({
            actionType: "hardwareButton",
            button: "recentapps",
          })
        }
        variant="contained"
        className={classes.button}
        sx={{borderRadius: "0"}}
      >
        <CropSquareIcon />
      </Button>
      <Button
        onClick={() =>
          props.handleCommand({actionType: "hardwareButton", button: "back"})
        }
        variant="contained"
        className={classes.button}
        sx={{borderRadius: "0 0 36px 0"}}
      >
        <ReplyIcon />
      </Button>
    </div>
  );
};

export default DeviceNavigationControls;
