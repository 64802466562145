import * as React from "react";
import {useState} from "react";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import {DialogContent} from "@mui/material";
import {StyledTab, StyledTabs} from "../common/Tabs";
import {TabPanel, tabProps} from "../common/components";
import UserReportForm from "./UserReportForm";
import {CustomDialog} from "../common/dialog/CustomDialog";
import {UserReport} from "../../common/APIRequests/user_report/getUserReports";
import UserReportFiltersTable from "./Filters/UserReportFiltersTable";
import UserReportFieldsTable from "./Fields/UserReportFieldsTable";
import UserReportFile from "./UserReportFile";

type UserReportOnSelectedDialogProps = {
  onClose: () => void;
  open: boolean;
  userReport?: UserReport;
};

const UserReportOnSelectedDialog = (props: UserReportOnSelectedDialogProps) => {
  const [tab, setTab] = useState("common");

  const onClose = () => {
    setTab("common");
    props.onClose();
  };

  return (
    <CustomDialog
      onClose={onClose}
      keepMounted={false}
      open={props.open}
      maxWidth={tab === "common" ? "sm" : "lg"}
    >
      <ClosableDialogTitle id="modify-user_report-modal" onClose={onClose}>
        Изменить пользовательский отчет
      </ClosableDialogTitle>
      <DialogContent>
        <StyledTabs
          value={tab}
          onChange={(_, tabValue) => setTab(tabValue)}
          aria-label="wrapped label tabs"
        >
          <StyledTab label="Общая информация" {...tabProps("common")} />
          <StyledTab label="Фильтры" {...tabProps("filters")} />
          <StyledTab label="Поля" {...tabProps("fields")} />
          <StyledTab label="Файл" {...tabProps("file")} />
        </StyledTabs>
        {props.userReport && (
          <TabPanel value={tab} index="common">
            <UserReportForm userReport={props.userReport} onClose={onClose} />
          </TabPanel>
        )}
        {tab === "filters" && props.userReport && (
          <TabPanel value={tab} index="filters">
            <UserReportFiltersTable userReport={props.userReport} />
          </TabPanel>
        )}
        {tab === "fields" && props.userReport && (
          <TabPanel value={tab} index="fields">
            <UserReportFieldsTable userReport={props.userReport} />
          </TabPanel>
        )}
        {tab === "file" && props.userReport && (
          <TabPanel value={tab} index="file">
            <UserReportFile userReport={props.userReport} />
          </TabPanel>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default UserReportOnSelectedDialog;
