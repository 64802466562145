import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type AndroidVersionType = {
  id_dev_version: number; // Идентификатор
  code: string; // Версия
};

const getAndroidVersions = async () =>
  baseGetRowsRequest<AndroidVersionType>(`/android_version/`, {});

export default getAndroidVersions;
