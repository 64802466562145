import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type tasksSettingType = {
  id: number;
  name: string;
  display_name: string;
  value_type: "int";
  value: string;
};

const getTasksSettings = async () =>
  baseGetRowsRequest<tasksSettingType>("tasks_setting/");

export default getTasksSettings;
