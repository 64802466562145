import {
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import Typography from "@mui/material/Typography";
import {ArrowDownward} from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import moment from "moment";
import React, {useEffect, useState} from "react";
import downloadScreenshotFile from "../../../common/APIRequests/screenshot/downloadScreenshotFile";
import {screenshotType} from "../../../common/APIRequests/screenshot/getScreenshots";
import {downloadFile} from "../../../common/utils/files";
import {StyledCard, Transition} from "../../common/components";
import {FRONT_DATETIME_FORMAT} from "../../../common/types";

const ScreenshotImage = (props: {
  screenshot: screenshotType;
  id_device: number;
  setSelected: Function;
}) => {
  const {id_device, screenshot, setSelected} = props;

  const useStyles = makeStyles()((theme) => ({
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginLeft: "-12px",
      marginTop: "-12px",
    },
    card: {
      margin: theme.spacing(0, 3, 3, 0),
      position: "relative",
      boxShadow: "none",
    },
    cardContent: {
      display: "flex",
      height: "400px",
    },
    img: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
      cursor: "zoom-in",
    },
    fullImgCover: {
      paddingBottom: theme.spacing(3),
      textAlign: "center",
    },
    fullImg: {
      maxWidth: "100%",
    },
    notFound: {
      margin: "auto",
      textAlign: "center",
    },
    title: {
      fontSize: "0.8rem",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));

  const [previewData, setPreviewData] = useState("");

  const [fileData, setFileData] = useState(undefined as any);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleDownloadFile = async () => {
    setLoading(true);
    const result = await downloadScreenshotFile(
      id_device,
      screenshot.id_screenshot
    );
    setLoading(false);

    if (result.success) {
      setFileData(new File([result.file], screenshot.screenshot_name));
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewData(reader.result as string);
      };
      reader.readAsDataURL(result.file);
    }
  };

  const handleSaveFile = async () => {
    downloadFile(fileData, screenshot.screenshot_name);
  };

  useEffect(() => {
    handleDownloadFile();
  }, []);

  const {classes} = useStyles();

  return (
    <React.Fragment>
      <StyledCard
        style={{width: screenshot.cols * 285}}
        className={classes.card}
        data-type="screenshot_row"
        data-id={screenshot.id_screenshot}
      >
        <CardHeader
          title={screenshot.screenshot_name}
          subheader={moment(screenshot.create_date).format(
            FRONT_DATETIME_FORMAT
          )}
          classes={{title: classes.title, subheader: classes.title}}
        />
        <CardContent className={classes.cardContent}>
          {previewData ? (
            <img
              src={previewData}
              alt={screenshot.screenshot_name}
              className={classes.img}
              onClick={() => setPreviewOpen(true)}
            />
          ) : (
            !loading && (
              <div className={classes.notFound}>
                Скриншот <br /> не найден
              </div>
            )
          )}
          {loading && <CircularProgress className={classes.progress} />}
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            aria-label={`zoom_${screenshot.id_screenshot}`}
            disabled={!previewData}
            onClick={() => setPreviewOpen(true)}
            title="Увеличить"
            size="large"
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            aria-label={`remove_${screenshot.id_screenshot}`}
            onClick={() => setSelected(screenshot)}
            title="Удалить"
            size="large"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            aria-label={`download_${screenshot.id_screenshot}`}
            disabled={!fileData}
            onClick={() => handleSaveFile()}
            title="Скачать"
            size="large"
          >
            <ArrowDownward />
          </IconButton>
        </CardActions>
        {previewOpen && (
          <Dialog
            open={true}
            onClose={() => setPreviewOpen(false)}
            keepMounted
            maxWidth="lg"
            TransitionComponent={Transition}
          >
            <DialogTitle className={classes.dialogTitle}>
              <Typography component="h2" variant="h5">
                {screenshot.screenshot_name}
              </Typography>
              <IconButton onClick={() => setPreviewOpen(false)} size="large">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{root: classes.fullImgCover}}>
              <img
                src={previewData}
                alt={screenshot.screenshot_name}
                className={classes.fullImg}
              />
            </DialogContent>
          </Dialog>
        )}
      </StyledCard>
    </React.Fragment>
  );
};

export default ScreenshotImage;
