import Column from "../../../Tables/Column";
import {UserReportFilterTypeProvider} from "../../../common/grid/providers";

const UserReportFilterColumns = [
  new Column("id_report_filter_user", "ID", 100, {hiddenByDefault: true}),
  new Column("name", "Наименование", 600, {auto: true}),
  new Column("system_name", "Системное наименование", 400, {
    hiddenByDefault: true,
  }),
  new Column("value_type", "Тип", 200, {
    provider: UserReportFilterTypeProvider,
  }),
];

export default UserReportFilterColumns;
