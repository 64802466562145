import {
  Table,
  TableGroupRow,
  TableHeaderRow,
  TableHeaderRowProps,
  TableSelection,
  TableSelectionProps,
  TableTreeColumn,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import {useWindowDimensions} from "../../../common/customHooks";
import theme from "../../../theme";
import {TableHeaderRow as TableHeaderRowBase} from "@devexpress/dx-react-grid";

const styles = {
  borderLeft: "1px solid rgba(224, 224, 224, 1)",
  paddingTop: "9px",
  paddingBottom: "9px",
  "&:last-child": {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  "&:first-child": {
    paddingLeft: theme.spacing(1),
  },
};
const stylesHeader = {
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  background: "#F8F8F8",
};
const stylesSelection = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  "&:first-child": {
    paddingLeft: 0,
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: theme.palette.primary.main,
  },
};
const stylesWrap = {
  whiteSpace: "normal",
  wordBreak: "break-all",
};

export const StyledTableHeaderCell = withStyles({
  root: {...styles, ...stylesHeader},
})(TableHeaderRow.Cell);

export const StyledTableHeaderRow = ({...props}: TableHeaderRowProps) => {
  return (
    <TableHeaderRow
      {...props}
      cellComponent={({...restProps}: TableHeaderRowBase.CellProps) => {
        return <StyledTableHeaderCell {...restProps} />;
      }}
    />
  );
};

const StyledVirtualTableCell = withStyles({
  root: {...styles},
})(VirtualTable.Cell);

const StyledVirtualTableCellWrap = withStyles({
  root: {...styles, ...stylesWrap} as any,
})(VirtualTable.Cell);

export const StyledStubCell = withStyles({
  root: {...styles},
})(VirtualTable.StubCell);

const StyledHeaderStubCell = withStyles({
  root: {...styles, ...stylesHeader},
})(VirtualTable.StubHeaderCell);

const StyledNoDataCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(VirtualTable.NoDataCell);

export const StyledVirtualTable = ({wrap, noDataText, ...props}: any) => {
  const {pagination, height} = props;
  const {height: windowHeight} = useWindowDimensions();
  let tableHeight = 0;
  pagination
    ? (tableHeight = windowHeight - 226)
    : (tableHeight = windowHeight - 186);
  return (
    <VirtualTable
      cellComponent={(props: Table.DataCellProps) => {
        if (wrap) {
          return <StyledVirtualTableCellWrap {...props} />;
        } else {
          let title = "";

          //exclude title from some fields
          if (
            props.value &&
            ![
              "status",
              "graphical_status",
              "is_project",
              "is_profile_device",
              "is_profile_app",
              "is_inherited",
              "is_active",
              "is_release",
              "devices",
            ].includes(props.column.name)
          ) {
            title = props.value;
          }
          return <StyledVirtualTableCell {...props} title={title} />;
        }
      }}
      stubCellComponent={(props: TableGroupRow.StubCellProps) => {
        return <StyledStubCell {...props} />;
      }}
      stubHeaderCellComponent={(props: TableGroupRow.StubCellProps) => {
        return <StyledHeaderStubCell {...props} />;
      }}
      noDataCellComponent={(props: Table.NoDataCellProps) => {
        return <StyledNoDataCell {...props} />;
      }}
      {...props}
      messages={{noData: noDataText ?? "Нет данных"}}
      height={height ? height : tableHeight}
    />
  );
};

export const StyledSelectionCell = withStyles({
  root: {...styles, ...stylesSelection},
})(TableSelection.Cell);

export const StyledSelectionCellDisabled = withStyles({
  root: {...styles, ...stylesSelection},
})(TableSelection.HeaderCell);

export const StyledHeaderSelectionCell = withStyles({
  root: {
    ...styles,
    ...stylesHeader,
    ...stylesSelection,
  },
})(TableSelection.HeaderCell);

type StyledTableSelectionProps = {
  selectionAllowedRowIds?: (string | number)[];
  onHeaderSelectionClick?: (select?: boolean) => void;
  allSelected?: boolean;
} & TableSelectionProps;

export const StyledTableSelection = (props: StyledTableSelectionProps) => {
  return (
    <TableSelection
      cellComponent={(cellProps) => {
        if (
          cellProps.tableRow.rowId &&
          props.selectionAllowedRowIds?.includes(cellProps.tableRow.rowId)
        )
          return <StyledSelectionCell {...cellProps} />;
        else if (props.selectionAllowedRowIds) return <StyledStubCell />;
        return <StyledSelectionCell {...cellProps} />;
      }}
      headerCellComponent={(cellProps) => {
        if (props.onHeaderSelectionClick)
          return (
            <StyledHeaderSelectionCell
              {...cellProps}
              onToggle={props.onHeaderSelectionClick}
              allSelected={props.allSelected}
            />
          );
        return <StyledHeaderSelectionCell {...cellProps} />;
      }}
      {...props}
      showSelectAll
    />
  );
};

const StyledTreeCell = withStyles({
  root: {
    ...styles,
    padding: 0,
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
})(TableTreeColumn.Cell);

export const StyledTableTreeColumn = ({...props}: any) => {
  return (
    <TableTreeColumn
      cellComponent={(props: any) => {
        return <StyledTreeCell {...props} />;
      }}
      {...props}
    />
  );
};

const StyledGroupCell = withStyles({
  root: {...styles, padding: 0},
})(TableGroupRow.Cell);

export const StyledTableGroupRow = ({...props}: any) => {
  return (
    <TableGroupRow
      cellComponent={(props: any) => {
        return <StyledGroupCell {...props} />;
      }}
      {...props}
    />
  );
};
