import {useContext, useEffect, useMemo, useState} from "react";
import {Dialog} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {Transition} from "../../common/components";
import FormButtons from "../../common/buttons/FormButtons";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {AppContext} from "../../App-context";
import SelectSingleField from "../../common/formFields/SelectSingleField";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import {
  flattenArrayWithChildren,
  generateProjectOptions,
  groupBy,
} from "../../common/functions";
import {errorType, optionType} from "../../../common/types";
import StringField from "../../common/formFields/StringField";
import theme from "../../../theme";
import {dataType} from "./MenuCommandsAction";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import AlertNotification from "../../common/alert/AlertNotification";

const CommandsChangeCurrentProject = (props: {
  loading: boolean;
  rowDataSelection: Array<{}>;
  handleClose: () => void;
  sendCommand: (data?: dataType) => void;
  projectIdFilter: number | null;
}) => {
  const {loading, handleClose, sendCommand, rowDataSelection, projectIdFilter} =
    props;
  const {projects} = useContext(AppContext);
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [targetProjectId, setTargetProjectId] = useState("");
  const [errors, setErrors] = useState({} as errorType);

  const options = useMemo(() => {
    let result: Array<optionType> = [{key: 0, value: 0, text: ""}];
    projects.forEach((project: projectType) =>
      generateProjectOptions(project, result)
    );
    return result;
  }, [projects]);

  useEffect(() => {
    const allProjects = flattenArrayWithChildren<projectType>(projects);

    if (rowDataSelection.length) {
      const selectedDevices = rowDataSelection as Array<deviceType>;
      const projectsOfSelectedDevices = groupBy(selectedDevices, (device) =>
        device.id_project?.toString()
      );
      if (projectsOfSelectedDevices.size > 1) {
        setErrors({
          ...errors,
          project_name: ["Необходимо выбрать устройства из одного проекта"],
        });
      } else {
        const projectId = projectsOfSelectedDevices.keys().next().value;
        if (projectId)
          setCurrentProjectName(
            allProjects.find(
              (project) => project.id_project === parseInt(projectId)
            )!.project_name
          );
      }
    } else {
      if (projectIdFilter)
        setCurrentProjectName(
          allProjects.find((project) => project.id_project === projectIdFilter)!
            .project_name
        );
    }
  }, []);

  const handleSubmit = () => {
    sendCommand({
      additionalData: targetProjectId.toString(),
      isOnlyOneProject: 1,
    });
  };

  return (
    <>
      {!!currentProjectName && (
        <Dialog
          open={true}
          onClose={() => handleClose()}
          keepMounted
          fullWidth
          maxWidth="xs"
          TransitionComponent={Transition}
        >
          <ClosableDialogTitle onClose={() => handleClose()}>
            Сменить проект
          </ClosableDialogTitle>
          <DialogContent>
            <StringField
              disabled={true}
              setValue={() => {}}
              value={currentProjectName ? currentProjectName : "Нет проекта"}
              name={"project_name"}
              label={""}
              errors={errors}
              style={{marginBottom: theme.spacing(2)}}
            />
            <SelectSingleField
              setValue={(value: string) => setTargetProjectId(value)}
              value={targetProjectId}
              name={"project_list"}
              label={"Список проектов"}
              errors={errors}
              disabled={Object.keys(errors).length > 0}
              options={options}
            />
            <FormButtons
              loading={loading}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              submitMessage="Отправить"
              disabled={Object.keys(errors).length > 0 || !targetProjectId}
            />
          </DialogContent>
        </Dialog>
      )}
      {!currentProjectName && (
        <AlertNotification
          alert={{severity: "error", message: ["Устройства без проекта"]}}
          onClose={() => handleClose()}
        />
      )}
    </>
  );
};

export default CommandsChangeCurrentProject;
