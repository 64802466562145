import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type projectProfileType = {
  id_prof: number;
  name: string;
  specification: string;
  is_inherited: 1 | 0;
  id_project: number;
  name_project: string;
};

const getProjectProfiles = async (id_project: number) =>
  baseGetRowsRequest<projectProfileType>(`/project/${id_project}/profile/`);

export default getProjectProfiles;
