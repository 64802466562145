import {useCallback, useEffect, useRef} from "react";

export const useIdleTimer = (onTimeout: () => void, timeout: number) => {
  const timerRef = useRef<NodeJS.Timeout>();

  const handleUserActivity = useCallback(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      onTimeout();
    }, timeout);
  }, [onTimeout, timeout]);

  useEffect(() => {
    document.addEventListener("mousemove", handleUserActivity, {passive: true});
    document.addEventListener("mousedown", handleUserActivity, {passive: true});
    document.addEventListener("keydown", handleUserActivity, {passive: true});
    document.addEventListener("touchstart", handleUserActivity, {
      passive: true,
    });
    handleUserActivity();

    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("mousedown", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("touchstart", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);
};
