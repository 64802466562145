import * as React from "react";
import {useState} from "react";
import {FilterComponentProps} from "../Tables/CoreTable";
import StringFilterField from "../common/filterFields/StringFilterField";
import {Grid} from "@mui/material";
import {StyledFilterGrid} from "../common/grid/StyledFilterGrid";

export type UserFilterType = {
  ldap_login?: string;
  display_name?: string;
  comment_text?: string;
};

export const UsersFilter = (props: FilterComponentProps<UserFilterType>) => {
  const [ldapLogin, setLdapLogin] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [commentText, setCommentText] = useState("");

  const handleEnterPress = (fieldName: string) => (value: string) => {
    props.updateFilters({...props.filters, [fieldName]: value.trim()});
  };

  return (
    <Grid container wrap="nowrap">
      <StyledFilterGrid item xs={6}>
        <StringFilterField
          name="ldap_login"
          label="Логин"
          value={ldapLogin}
          onChange={setLdapLogin}
          onEnterPress={handleEnterPress("ldap_login")}
          onResetClick={() => {
            setLdapLogin("");
            props.updateFilters({...props.filters, ldap_login: ""});
          }}
        />
      </StyledFilterGrid>
      <StyledFilterGrid item xs={6}>
        <StringFilterField
          name="display_name"
          label="Имя"
          value={displayName}
          onChange={setDisplayName}
          onEnterPress={handleEnterPress("display_name")}
          onResetClick={() => {
            setDisplayName("");
            props.updateFilters({...props.filters, display_name: ""});
          }}
        />
      </StyledFilterGrid>
      <StyledFilterGrid item xs={6}>
        <StringFilterField
          name="comment_text"
          label="Описание"
          value={commentText}
          onChange={setCommentText}
          onEnterPress={handleEnterPress("comment_text")}
          onResetClick={() => {
            setCommentText("");
            props.updateFilters({...props.filters, comment_text: ""});
          }}
        />
      </StyledFilterGrid>
    </Grid>
  );
};
