import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";
import {UserFilterType} from "../../../components/Users/UsersFilter";

export type UserType = {
  id_user: number;
  display_name: string;
  email?: string;
  comment_text?: string;
  ldap_login: string;
  authorized_devices_count?: number;
  is_admin: string;
  projects?: string;
};

const getUsers = async (
  page?: number,
  page_size?: number,
  sort_field?: string,
  sort_desc?: boolean,
  filters?: UserFilterType
) =>
  baseGetRowsRequestWithCount<UserType>(`/user/`, {
    page,
    page_size,
    sort_field,
    sort_desc,
    filters,
  });

export default getUsers;
