import FormHelperText from "@mui/material/FormHelperText";
import {useState} from "react";
import getUserPin from "../../../common/APIRequests/user_pin/getUserPin";
import {errorType} from "../../../common/types";
import FormButtons from "../buttons/FormButtons";
import PinField from "../formFields/PinField";

type Props = {
  login: string;
  close: () => void;
  submit: Function;
};

const PinForm = (props: Props) => {
  const {login, close, submit} = props;

  const [loading, setLoading] = useState(false);

  const [pinValue, setPinValue] = useState("");

  const [errors, setErrors] = useState<errorType | null>(null);

  const handleSubmit = async () => {
    if (loading) return;

    setErrors(null);
    setLoading(true);

    const result = await getUserPin(login, pinValue);

    if (result.success && result.rows.length) {
      await submit();
      close();
    } else {
      setErrors({console_pin: ["Некорректный пин"]});
    }

    setLoading(false);
  };

  return (
    <>
      <PinField
        value={pinValue}
        onChange={setPinValue}
        onEnterPressed={handleSubmit}
        error={!!errors}
      />
      {errors && (
        <FormHelperText error={true} id="component-error-pin">
          {errors.console_pin}
        </FormHelperText>
      )}
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={close}
        submitMessage="Подтвердить"
      />
    </>
  );
};

export default PinForm;
