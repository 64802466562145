import {Dialog, DialogContent, FormControl, TextField} from "@mui/material";
import * as React from "react";
import {useContext, useState} from "react";
import {ApplicationType} from "../../common/APIRequests/application/getApplications";
import patchApplication from "../../common/APIRequests/application/patchApplication";
import {alertType, errorType} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import {Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import StringField from "../common/formFields/StringField";

const ApplicationForm = (props: {
  handleClose: () => void;
  update: Function;
  application: ApplicationType;
}) => {
  const {handleClose, update, application} = props;

  const [values, setValues] = useState(application);

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
  };

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({} as errorType);

  const handleSubmit = async () => {
    setLoading(true);
    let result = await patchApplication(application.id_app, values);
    setLoading(false);

    if (result.success) {
      setErrors({});
      handleClose();
      setAlert({
        severity: "success",
        message: ["Описание изменено"],
      } as alertType);
      update();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => !loading && handleClose()}
      TransitionComponent={Transition}
    >
      <ClosableDialogTitle onClose={() => !loading && handleClose()}>
        Информация о {application.name}
      </ClosableDialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            fullWidth
            label="Наименование"
            placeholder="Наименование"
            value={values.name}
            disabled={true}
          />
        </FormControl>
        <StringField
          setValue={handleChange("description")}
          value={values.description}
          name="description"
          label="Описание"
          errors={errors}
          setErrors={setErrors}
          multiline
          style={{marginTop: theme.spacing(2)}}
          disabled={loading}
        />
        <FormButtons
          loading={loading}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          submitMessage="Сохранить"
        />
      </DialogContent>
    </Dialog>
  );
};

export default ApplicationForm;
