import {basePostRequest} from "../base/basePostRequest";
import {deviceProfileType} from "./getDeviceProfiles";

const toggleDeviceProfiles = async (
  id_device: number,
  ids: Array<number | string>,
  is_active: 0 | 1
) =>
  basePostRequest<deviceProfileType>(`/device/${id_device}/profile/toggle/`, {
    ids,
    is_active,
  });

export default toggleDeviceProfiles;
