import * as React from "react";
import PopupDialog from "../../common/PopupDialog";
import TaskSettingForm from "../TasksSettingForm";

type TasksSettingsDialogProps = {
  onClose: () => void;
};
const TasksSettingsDialog = (props: TasksSettingsDialogProps) => {
  return (
    <PopupDialog
      onClose={props.onClose}
      title={"Общие настройки для заданий"}
      maxWidth="sm"
      fullWidth
    >
      <TaskSettingForm formClose={props.onClose} />
    </PopupDialog>
  );
};

export default TasksSettingsDialog;
