import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";
import {applicationVersionType} from "../applicationVersion/getApplicationVersions";
import {ApplicationType} from "./getApplications";

type uploadType = {
  application: ApplicationType;
  application_version: applicationVersionType;
};

const postUploadAPK = async (
  data: FormData
): Promise<{
  success: boolean;
  data: uploadType;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "post",
      url: `/application/upload_apk/`,
      data,
    })
      .then((response) =>
        resolve({
          success: true,
          data: response.data as uploadType,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          data: {} as uploadType,
          errors: errors,
        });
      })
  );

export default postUploadAPK;
