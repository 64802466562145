import * as React from "react";
import {Dialog, DialogContent} from "@mui/material";
import {Transition} from "../../../common/components";
import ClosableDialogTitle from "../../../common/dialog/ClosableDialogTitle";
import AddNewUserReportFiltersTable from "./AddNewUserReportFiltersTable";
import {UserReport} from "../../../../common/APIRequests/user_report/getUserReports";

type AddNewUserReportFilerDialogProps = {
  onClose: () => void;
  open: boolean;
  userReport: UserReport;
};

const AddNewUserReportFilterDialog = (
  props: AddNewUserReportFilerDialogProps
) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      maxWidth="md"
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Добавить фильтр
      </ClosableDialogTitle>
      <DialogContent>
        <AddNewUserReportFiltersTable userReport={props.userReport} />
      </DialogContent>
    </Dialog>
  );
};

export default AddNewUserReportFilterDialog;
