import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type ApplicationType = {
  id_app: number;
  name: string;
  app_uid: string;
  description: string;
  visible: 1 | 0;
};

const getApplications = async () =>
  baseGetRowsRequest<ApplicationType>(`/application/`);

export default getApplications;
