import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

const getPolicyTree = async (
  project_id: number
): Promise<{
  success: boolean;
  columns: {[key: string]: string};
  rows: Array<{[key: string]: string}>;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "get",
      url: `/project/${project_id}/policy_tree/`,
    })
      .then((response) =>
        resolve({
          success: true,
          columns: response.data.columns,
          rows: response.data.rows,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          columns: {},
          rows: [],
          errors: errors,
        });
      })
  );

export default getPolicyTree;
