import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";
import {graphicalStatusType} from "../device/getDevices";

export type taskDeviceType = {
  id_task_device: number;
  id_device: number;
  ldap_login: string;
  is_compromised: "0" | "1";
  policy_code: 0 | 1 | -100;
  last_seen_time: string;
  code_division: string;
  code: string;
  serial_number: string | null;
  device_registration_status: number;
  id_project: number;
  name_project: string;
  name_model: string;
  status: taskDeviceStatusType;
  app_status: taskDeviceAppStatusType;
  graphical_status: graphicalStatusType;
};

export type taskDeviceStatusType = "0" | "1" | "2" | "3";

export type taskDeviceAppStatusType =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7";

export type taskDeviceFilterType = {
  id_app_version: number | null;
  app_status: taskDeviceAppStatusType | null;
  code_division: string | null;
  serial_number: string | null;
};

const getTaskDevices = async (
  id_task: number,
  page?: number,
  page_size?: number,
  sort_field?: string,
  sort_desc?: boolean,
  filters?: taskDeviceFilterType
) =>
  baseGetRowsRequestWithCount<taskDeviceType>(`/task/${id_task}/device/`, {
    page,
    page_size,
    sort_field,
    sort_desc,
    filters,
  });

export default getTaskDevices;
