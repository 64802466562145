import Column from "../../Tables/Column";
import {NotificationMessageType} from "../../../common/APIRequests/notifyMessage/getNotificationMessages";
import moment from "moment";
import {
  NotificationMessageIsActiveProvider,
  NotificationMessageStatusProvider,
} from "./NotificationMessageProviders";
import {FRONT_DATETIME_FORMAT} from "../../../common/types";

const NotificationMessageColumns = [
  new Column("id_queue", "ID", 80, {hiddenByDefault: true}),
  new Column("channel_name", "Канал", 130),
  new Column("notify_name", "Наименование", 200, {auto: true}),
  new Column("is_active", "Активность", 130, {
    provider: NotificationMessageIsActiveProvider,
  }),
  new Column("event_date", "Дата", 230, {
    getCellValue: (row: NotificationMessageType) =>
      row.event_date
        ? moment(row.event_date).format(FRONT_DATETIME_FORMAT)
        : undefined,
  }),
  new Column("status", "Статус", 100, {
    provider: NotificationMessageStatusProvider,
  }),
  new Column("status_message", "Детали", 200),
  new Column("recipient", "Получатель", 200),
];

export default NotificationMessageColumns;
