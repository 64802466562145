import * as React from "react";
import {useState} from "react";
import FilterIconButton from "../common/buttons/FilterIconButton";
import {Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import {Dialog, DialogContent} from "@mui/material";
import {FilterComponentProps} from "./CoreTable";

type MenuFilterProps<FilterType> = {
  FilterComponent: React.ComponentType<FilterComponentProps<FilterType>>;
  maxDialogWidth?: "xs" | "sm" | "md" | "lg" | "xl";
} & FilterComponentProps<FilterType>;

export const MenuFilter = <FilterType,>(props: MenuFilterProps<FilterType>) => {
  const [open, setOpen] = useState(false);

  const {FilterComponent, ...filterProps} = props;

  return (
    <>
      <FilterIconButton onClick={() => setOpen(true)} />
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          keepMounted
          disablePortal
          maxWidth={props.maxDialogWidth || "md"}
          fullWidth
          TransitionComponent={Transition}
          data-testid="dialog_wrapper"
        >
          <ClosableDialogTitle onClose={() => setOpen(false)}>
            Фильтр
          </ClosableDialogTitle>
          <DialogContent>
            <FilterComponent
              {...filterProps}
              onClose={() => {
                setOpen(false);
                props.onClose?.();
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
