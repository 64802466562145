import React, {useContext, useState} from "react";
import {AppContext} from "../../App-context";
import RequestPinDialog from "../pin/RequestPinDialog";
import {Switch, SwitchProps} from "@mui/material";

type SwitchWithPinProps = {
  onChange: () => void;
  checked: boolean;
  disabled?: boolean;
  checkPin?: boolean;
  name?: string;
  inputProps?: SwitchProps;
};

const SwitchWithPin = (props: SwitchWithPinProps) => {
  const {disabled, onChange, checked, checkPin, name, inputProps} = props;

  const appContext = useContext(AppContext);
  const {pinStatus} = appContext;

  const [pinOpen, setPinOpen] = useState(false);

  const submit = () => {
    if (checkPin && pinStatus) {
      setPinOpen(true);
    } else {
      onChange();
    }
  };

  return (
    <React.Fragment>
      {pinOpen && checkPin && (
        <RequestPinDialog
          onClose={() => setPinOpen(false)}
          onSubmit={onChange}
        />
      )}
      <Switch
        checked={checked}
        color="primary"
        onClick={submit}
        disabled={disabled}
        name={name ? name : undefined}
        {...inputProps}
      />
    </React.Fragment>
  );
};

export default SwitchWithPin;
