import * as React from "react";
import {Tab, Tabs, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import Content, {ContentProps} from "../content";
import TasksTable from "./TasksTable";
import TaskTemplatesTable from "../TaskTemplates/TaskTemplatesTable";
import withStyles from "@mui/styles/withStyles";

const TitleTypography = (props: {title: string; isSelected: boolean}) => {
  const {classes} = makeStyles()((theme) => ({
    title: {
      textTransform: "none",
      color: theme.palette.common.white,
    },
  }))();
  return (
    <Typography
      component="h1"
      variant="h6"
      noWrap
      className={classes.title}
      style={{opacity: props.isSelected ? 1 : 0.7}}
    >
      {props.title}
    </Typography>
  );
};

const StyledTab = withStyles({
  root: {
    height: 64,
    paddingLeft: 16,
    paddingRight: 16,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
})(Tab);

const StyledTabs = withStyles({
  root: {
    flexGrow: 1,
  },
  indicator: {
    backgroundColor: "white",
  },
})(Tabs);

const TasksPage = (props: ContentProps) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) =>
    setValue(newValue);

  return (
    <Content
      {...props}
      Cont={value === 0 ? TasksTable : TaskTemplatesTable}
      barContent={
        <React.Fragment>
          <StyledTabs value={value} onChange={handleChange}>
            <StyledTab
              label={
                <TitleTypography
                  title={"Журнал заданий"}
                  isSelected={value == 0}
                />
              }
              disableRipple
            />
            <StyledTab
              label={
                <TitleTypography
                  title={"Шаблоны заданий"}
                  isSelected={value == 1}
                />
              }
              disableRipple
            />
          </StyledTabs>
        </React.Fragment>
      }
    />
  );
};

export default TasksPage;
