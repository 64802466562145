import CoreTable, {UpdateRowsType} from "../../../Tables/CoreTable";
import DeviceScreenshotColumns from "./DeviceScreenshotColumns";
import {ScreenshotFileType} from "./DeviceScreenshotTypes";
import {getScreenshots} from "../API/httpCommands";

const DeviceScreenshotsTable = (props: {device: string}) => {
  const updateRows: UpdateRowsType<ScreenshotFileType> = ({paging}) =>
    getScreenshots(props.device, paging?.page, paging?.page_size);
  return (
    <CoreTable
      columns={DeviceScreenshotColumns}
      name="videos"
      getRowId={(row) => row.name + row.datetime}
      updateRows={updateRows}
      pagingEnabled
      sortingEnabled
    />
  );
};

export default DeviceScreenshotsTable;
