import {
  reportTypeName,
  UserReportType,
} from "../../common/APIRequests/user_report/getUserReports";
import Column from "../Tables/Column";
import {BooleanTypeProvider} from "../common/grid/providers";

const UserReportColumns = [
  new Column("id_user_report", "ID", 100, {hiddenByDefault: true}),
  new Column("name_report", "Наименование", 800, {auto: true}),
  new Column("is_private", "Приватный", 200, {provider: BooleanTypeProvider}),
  new Column("report_type", "Тип отчета", 200, {
    getCellValue: (row) =>
      row.report_type
        ? reportTypeName[row.report_type as UserReportType]
        : null,
  }),
];

export default UserReportColumns;
