import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";

export type logEventType = {
  id_log_event: number;
  type_user_name: string;
  type_property_name: string;
  type_by_name: string;
  type_level_name: string;
  app_version_name: string;
  app_version_code: string;
  name_app: string;
  name_object: string;
  input_date: string;
  event_time: string;
  text_message: string;
  device_serial_number: string;
  device_code_division: string;
  device_user_ldap_login: string;
  device_dev_version_code: string;
  event_sent: number;
};

export type logEventFilterType = {
  start_event_time: string | null;
  end_event_time: string | null;
  start_input_date: string | null;
  end_input_date: string | null;
  ids_event: Array<number>;
  serial_number: string | null;
  code_division: string;
  app_version_name: string;
  name_app: string;
  name_object: string;
  id_type_user: null | number;
  id_type_property: null | number;
  id_type_by: null | number;
  id_type_level: null | number;
};

const getLogEvents = async (
  page: number,
  page_size: number,
  sort_field: string,
  sort_desc: boolean,
  filters: logEventFilterType
) =>
  baseGetRowsRequestWithCount<logEventType>(`/log_event/`, {
    page,
    page_size,
    sort_field,
    sort_desc,
    filters,
  });

export default getLogEvents;
