import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type NotificationEventType = {
  id_event: number;
  event_name: string;
};

const getNotificationEvents = async (id_notify: number) =>
  baseGetRowsRequest<NotificationEventType>(`/notify/${id_notify}/event/`);

export default getNotificationEvents;
