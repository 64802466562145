import {Dialog} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {useState} from "react";
import {errorType} from "../../../common/types";
import FormButtons from "../../common/buttons/FormButtons";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {sanitizeAlphanumeric} from "../../common/formFields/sanitizers";
import StringField from "../../common/formFields/StringField";
import {dataType} from "./MenuCommandsAction";

const ChangeDivision = (props: {
  loading: boolean;
  handleClose: () => void;
  sendCommand: (data?: dataType) => void;
}) => {
  const {loading, handleClose, sendCommand} = props;

  const [newCode, setNewCode] = useState("");

  const [errors, setErrors] = useState({} as errorType);

  const validate = async () => {
    if (!newCode) {
      setErrors({new_code: ["Обязательное поле"]});
      return;
    }
    sendCommand({additionalData: newCode});
  };

  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      keepMounted
      fullWidth
      maxWidth="xs"
      TransitionComponent={Transition}
    >
      <ClosableDialogTitle onClose={() => handleClose()}>
        Изменить код подразделения
      </ClosableDialogTitle>
      <DialogContent>
        <StringField
          setValue={setNewCode}
          value={newCode}
          name="new_code"
          label="Новый код подразделения"
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 10}}
          disabled={loading}
          sanitizer={sanitizeAlphanumeric}
        />
        <FormButtons
          loading={loading}
          handleSubmit={validate}
          handleClose={handleClose}
          submitMessage="Отправить"
          checkPin
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChangeDivision;
