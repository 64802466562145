import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type UserReport = {
  id_user_report: number;
  name_report: string;
  is_private: string;
  report_type: UserReportType | null;
};

export type UserReportType = 1 | 2 | 3;

export const reportTypeName = {
  1: "Устройства",
  2: "Приложения",
  3: "События",
};

const getUserReports = async () =>
  baseGetRowsRequest<UserReport>(`/user_report/`);

export default getUserReports;
