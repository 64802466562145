import {useContext, useEffect, useState} from "react";
import {alertType, errorType, optionType} from "../../common/types";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import StringField from "../common/formFields/StringField";
import {
  FirmwareType,
  GmsValues,
} from "../../common/APIRequests/firmware/getFirmwares";
import getModels from "../../common/APIRequests/firmware/getModels";
import getAndroidVersions from "../../common/APIRequests/firmware/getAndroidVersions";
import SelectSingleField from "../common/formFields/SelectSingleField";
import theme from "../../theme";
import FileSelectButton from "../common/buttons/FileSelectButton";
import postFirmware from "../../common/APIRequests/firmware/postFirmware";

const FirmwareForm = (props: {handleClose: () => void; update: () => void}) => {
  const {handleClose, update} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [errors, setErrors] = useState({} as errorType);
  const [values, setValues] = useState({
    id_model: "",
    id_dev_version: "",
    has_gms: "",
    comment_text: "",
  } as FirmwareType);
  const [loading, setLoading] = useState(false);

  const [optionsAreLoading, setOptionsAreLoading] = useState(false);
  const [modelOptions, setModelOptions] = useState([] as optionType[]);
  const [androidVersionOptions, setAndroidVersionOptions] = useState(
    [] as optionType[]
  );

  const [selectedFile, setSelectedFile] = useState<string | Blob>("");

  useEffect(() => {
    setOptionsAreLoading(true);
    Promise.all([getModels(), getAndroidVersions()])
      .then((results) => {
        const models = results[0];
        setModelOptions(
          models.rows.map((element) => {
            return {value: element.id_model, text: element.name} as optionType;
          })
        );
        const androidVersions = results[1];
        setAndroidVersionOptions(
          androidVersions.rows.map((element) => {
            return {
              value: element.id_dev_version,
              text: element.code,
            } as optionType;
          })
        );
      })
      .finally(() => setOptionsAreLoading(false));
  }, []);

  const handleSubmit = async () => {
    if (Object.keys(errors).length || loading) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id_model", values.id_model as string);
    formData.append("id_dev_version", values.id_dev_version as string);
    formData.append("has_gms", values.has_gms as string);
    formData.append("comment_text", values.comment_text);
    const result = await postFirmware(formData);
    setLoading(false);

    if (result.success) {
      setErrors({});
      handleClose();
      setAlert({
        severity: "success",
        message: ["Прошивка сохранена"],
      });
      update();
    } else {
      setErrors(result.errors);
    }
  };

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
  };

  return (
    <>
      <SelectSingleField
        options={modelOptions}
        setValue={handleChange("id_model")}
        value={values.id_model as string}
        name="id_model"
        label="Модель"
        errors={errors}
        setErrors={setErrors}
        disabled={loading || optionsAreLoading}
        style={{marginBottom: theme.spacing(2)}}
      />
      <SelectSingleField
        options={androidVersionOptions}
        setValue={handleChange("id_dev_version")}
        value={values.id_dev_version as string}
        name="id_dev_version"
        label="Версия Андроид"
        errors={errors}
        setErrors={setErrors}
        disabled={loading || optionsAreLoading}
        style={{marginBottom: theme.spacing(2)}}
      />
      <SelectSingleField
        options={Object.keys(GmsValues).map((key) => {
          return {value: key, text: GmsValues[parseInt(key)]};
        })}
        setValue={handleChange("has_gms")}
        value={values.has_gms as string}
        name="has_gms"
        label="GMS сервисы"
        errors={errors}
        setErrors={setErrors}
        disabled={loading || optionsAreLoading}
        style={{marginBottom: theme.spacing(2)}}
      />
      <StringField
        setValue={handleChange("comment_text")}
        value={values.comment_text}
        name="comment_text"
        label="Комментарий"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 300}}
        disabled={loading || optionsAreLoading}
        style={{marginBottom: theme.spacing(2)}}
      />
      <FileSelectButton
        onFileSelectSuccess={(file: File) => setSelectedFile(file)}
        onFileSelectError={(error: alertType) => setAlert(error)}
        extension={".zip"}
        limitMb={2000}
        title="Обзор"
      />

      <FormButtons
        loading={loading}
        disabled={
          !selectedFile ||
          !values.id_model ||
          !values.id_dev_version ||
          !values.has_gms
        }
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitMessage="Сохранить"
      />
    </>
  );
};

export default FirmwareForm;
