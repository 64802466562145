import {DataTypeProvider} from "@devexpress/dx-react-grid";
import React, {useContext, useEffect, useState} from "react";
import getApplications, {
  ApplicationType,
} from "../../common/APIRequests/application/getApplications";
import patchApplication from "../../common/APIRequests/application/patchApplication";
import postUploadAPK from "../../common/APIRequests/application/postUploadAPK";
import {useTrigger} from "../../common/customHooks";
import {maxAPKsize} from "../../settings";
import {AppContext} from "../App-context";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import TableRowSwitch from "../Tables/TableRowSwitch";
import UploadButton from "../common/buttons/UploadButton";
import {LoadingDialog} from "../common/dialog/LoadingDialog";
import {getExtension} from "../common/functions";
import {useGlobalStyles} from "../common/styles";
import ApplicationColumns from "./ApplicationColumns";
import ApplicationForm from "./ApplicationForm";

type ApplicationTableProps = {
  onAppSelect: (app: ApplicationType) => void;
  appSelected?: ApplicationType;
  refreshTrigger?: boolean;
};

const ApplicationTable = (props: ApplicationTableProps) => {
  const [apkLoading, setApkLoading] = useState(false);
  const [rowTwiceClicked, setRowTwiceClicked] = useState(
    undefined as ApplicationType | undefined
  );

  const [refreshTrigger, refresh] = useTrigger();

  const update: UpdateRowsType<ApplicationType> = async () => {
    const result = await getApplications();

    if (
      props.appSelected === undefined &&
      result.success &&
      result.rows.length >= 1
    )
      props.onAppSelect(result.rows[0]);

    return result;
  };

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const upload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const {files} = e.target;
    if (!files) {
      return;
    }
    if (!["apk"].includes(getExtension(files[0].name))) {
      setAlert({
        severity: "error",
        message: ["Разрешается загружать только файлы с расширением .apk"],
      });
      return;
    }
    if (files[0].size > maxAPKsize * Math.pow(2, 20)) {
      setAlert({
        severity: "error",
        message: [`Максимальный размер файла: ${maxAPKsize}МБ`],
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", files[0]);

    setApkLoading(true);
    const result = await postUploadAPK(formData);
    if (cancelled) {
      return;
    }
    setApkLoading(false);

    if (result.success) {
      const {application} = result.data;

      setAlert({
        severity: "success",
        message: ["Приложение успешно загружено"],
      });
      props.onAppSelect(application);
      refresh();
    }
  };

  const handleRowClick = (row: ApplicationType) =>
    rowTwiceClicked && props.appSelected?.id_app === row.id_app
      ? setRowTwiceClicked(row)
      : props.onAppSelect(row);

  const updateRow = (row: ApplicationType) => () =>
    patchApplication(row.id_app, {
      ...row,
      visible: row.visible ? 0 : 1,
    }).then(refresh);

  const classes = useGlobalStyles();

  return (
    <>
      {rowTwiceClicked && (
        <ApplicationForm
          handleClose={() => setRowTwiceClicked(undefined)}
          update={refresh}
          application={rowTwiceClicked}
        />
      )}
      {apkLoading && <LoadingDialog />}
      <CoreTable
        name={"application"}
        columns={ApplicationColumns}
        updateRows={update}
        getRowId={(row: ApplicationType) => row.id_app || ""}
        updateTrigger={[refreshTrigger, props.refreshTrigger]}
        defaultSorting={{columnName: "name", direction: "asc"}}
        onRowClick={handleRowClick}
        integratedSearch
        rowStyle={(row) =>
          row.id_app === props.appSelected?.id_app
            ? classes.gridRowSelected
            : classes.gridRow
        }
        toolBarLeftItems={[
          <UploadButton
            upload={upload}
            key="upload_apk_button"
            extension={".apk"}
            title="Добавить APK"
          />,
        ]}
        plugins={[
          <DataTypeProvider
            key="application-plugin"
            for={["visible"]}
            formatterComponent={(props: {row?: ApplicationType}) =>
              props.row ? (
                <TableRowSwitch
                  onCheck={updateRow(props.row)}
                  checked={Boolean(props.row.visible)}
                />
              ) : null
            }
          />,
        ]}
      />
    </>
  );
};

export default ApplicationTable;
