import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, {useContext, useMemo, useState} from "react";
import {projectType} from "../../common/APIRequests/project/getProjects";
import {optionType} from "../../common/types";
import {AppContext} from "../App-context";
import {generateProjectOptions} from "../common/functions";
import {ToolbarItem} from "../common/Toolbar";
import ReportsLastSeen from "./ReportsLastSeen";
import ReportsVersions from "./ReportsVersions";

const Reports = (props: {}) => {
  const appContext = useContext(AppContext);
  const {projects} = appContext;
  const options = useMemo(() => {
    let result: Array<optionType> = [{key: 0, value: 0, text: ""}];
    projects.forEach((project: projectType) =>
      generateProjectOptions(project, result)
    );
    return result;
  }, [projects]);

  const [loading, setLoading] = useState(false);

  const [reportType, setReportType] = useState(
    "versions" as "versions" | "last_connect"
  );
  const [projectId, setProjectId] = useState(0);

  const [includeChild, setIncludeChild] = useState(0 as 0 | 1);

  const ReportGrid =
    reportType === "versions" ? ReportsVersions : ReportsLastSeen;

  return (
    <ReportGrid
      projectId={projectId}
      includeChild={includeChild}
      loading={loading}
      setLoading={setLoading}
    >
      <ToolbarItem>
        <FormControl style={{width: "250px"}}>
          <InputLabel htmlFor="status">Тип отчета</InputLabel>
          <Select
            id="status"
            value={reportType}
            onChange={(e: any) => {
              setReportType(e.target.value);
            }}
            disabled={loading}
          >
            <MenuItem key="versions" value="versions">
              Версии приложений
            </MenuItem>
            <MenuItem key="last_connect" value="last_connect">
              Давно не подключались
            </MenuItem>
          </Select>
        </FormControl>
      </ToolbarItem>
      <ToolbarItem>
        <FormControl style={{width: "200px"}}>
          <InputLabel htmlFor="project">Проект</InputLabel>
          <Select
            id="project"
            value={projectId || ""}
            onChange={(e: any) => {
              setProjectId(e.target.value);
            }}
            disabled={loading}
          >
            {options.map((option: optionType) => (
              <MenuItem key={option.key} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ToolbarItem>
      <ToolbarItem>
        <FormControl fullWidth style={{width: "250px", marginBottom: "-10px"}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeChild === 1}
                id="includeChild"
                name="includeChild"
                onChange={(e: any) =>
                  setIncludeChild(e.target.checked === true ? 1 : 0)
                }
                disabled={loading || !projectId}
              />
            }
            label="Включая подпроекты"
          />
        </FormControl>
      </ToolbarItem>
    </ReportGrid>
  );
};

export default Reports;
