import React from "react";
import {Theme} from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "tss-react/mui";
import LinearProgress from "@mui/material/LinearProgress";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const StyledLinearProgress = () => {
  const {classes} = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress variant="query" color="secondary" />
    </div>
  );
};

export default StyledLinearProgress;
