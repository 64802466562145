import {Divider, ListItemIcon, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import EmailIcon from "@mui/icons-material/Email";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import RoomIcon from "@mui/icons-material/Room";
import SyncIcon from "@mui/icons-material/Sync";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import {ReactElement, useContext, useEffect, useState} from "react";
import getCommands, {
  commandType,
} from "../../../common/APIRequests/command/getCommands";
import {AppContext} from "../../App-context";
import {ConfirmDialog} from "../../common/buttons/ConfirmDialog";
import ChangeDivision from "./ChangeDivision";
import CommandsChangePinForm from "./CommandsChangePinForm";
import CommandsLogForm from "./CommandsLogForm";
import CommandsMessage from "./CommandsMessage";
import FindPhoneCommandIcon from "../../../common/images/findPhoneCommangIcon";
import CommandsChangeCurrentProject from "./CommandsChangeCurrentProject";
import {dataType} from "./MenuCommandsAction";

const DISABLED_COMMANDS_TAGS = [
  "WIPEPINCODE",
  "CHANGEPINCODE",
  "GEOPOSITION",
  "UNENROLL",
  "INSTALL_CERT",
];

const DIVIDER_AFTER = ["MESSAGE", "GETADBLOG"];

const COMMAND_SORT = [
  "FORCESYNC",
  "MESSAGE",
  "SCREENSHOT",
  "GETADBLOG",
  "CHANGE_DIV_CODE",
  "RELOADDEVICE",
  "SYSTEMEXIT",
  "AUTHRESET",
  "FULLERASE",
  "FACTORYRESET",
  "UPDATE_FIRMWARE",
  "TURNSOUNDON",
  "DELETE_WORK_PROFILE",
  "CHANGE_CURRENT_PROJECT",
];

const COMMAND_ICONS: {[s: string]: ReactElement} = {
  MESSAGE: <EmailIcon fontSize="small" />,
  GETADBLOG: <DescriptionIcon fontSize="small" />,
  CHANGEPINCODE: <EditIcon fontSize="small" />,
  SCREENSHOT: <WallpaperIcon fontSize="small" />,
  GEOPOSITION: <RoomIcon fontSize="small" />,
  CHANGE_DIV_CODE: <EditLocationIcon fontSize="small" />,
  RELOADDEVICE: <SyncIcon fontSize="small" />,
  SYSTEMEXIT: <MeetingRoomIcon fontSize="small" />,
  AUTHRESET: <ExitToAppIcon fontSize="small" />,
  FULLERASE: <ClearAllIcon fontSize="small" />,
  FACTORYRESET: <MobileOffIcon fontSize="small" />,
  FORCESYNC: <SyncAltIcon fontSize="small" />,
  ENTERPRISEWIPE: <DeleteIcon fontSize="small" />,
  TURNSOUNDON: <FindPhoneCommandIcon />,
  UPDATE_FIRMWARE: <SystemUpdateAltIcon fontSize="small" />,
  DELETE_WORK_PROFILE: <DeleteForeverIcon fontSize="small" />,
  CHANGE_CURRENT_PROJECT: <SwapHorizIcon fontSize="small" />,
};

export const sortCommandList = (
  commands: Array<commandType>,
  commandsOrder: Array<string>
) => {
  let copiedCommands = JSON.parse(JSON.stringify(commands));
  const hasInListOfStrings = commandsOrder.filter(
    (s: string) => copiedCommands.filter((l: commandType) => l.tag == s).length
  );

  let result: Array<commandType> = [];
  hasInListOfStrings.forEach((s: string) => {
    const elem = copiedCommands.filter((l: commandType) => l.tag == s);
    if (elem.length) {
      result = [...result, elem[0]];
    }
  });

  //remove hasInList from commands
  commands.forEach((l: commandType) => {
    if (commandsOrder.includes(l.tag)) {
      copiedCommands = copiedCommands.filter(
        (c: commandType) => c.tag !== l.tag
      );
    }
  });
  result = [...result, ...copiedCommands];
  return result;
};

const StyledListItemIcon = withStyles(ListItemIcon, (theme) => ({
  root: {
    minWidth: "24px",
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: (a: HTMLElement | null) => void;
  setCommandCode: (t: string) => void;
  sendCommand: (data?: dataType) => void;
  commandReady: boolean;
  setCommandReady: (r: boolean) => void;
  setAdditionData: (aD: string) => void;
  rowDataSelection: Array<{}>;
  commandCode: string;
  loading: boolean;
  projectIdFilter: number | null;
};

const MenuCommandsItems = (props: Props) => {
  const {
    anchorEl,
    setAnchorEl,
    commandReady,
    setCommandReady,
    setCommandCode,
    sendCommand,
    setAdditionData,
    rowDataSelection,
    commandCode,
    loading,
    projectIdFilter,
  } = props;

  const appContext = useContext(AppContext);
  const {pinStatus} = appContext;

  const [commandMessages, setCommandMessages] = useState<Array<commandType>>(
    []
  );
  useEffect(() => {
    getCommands().then((result) => {
      if (result.success) {
        let commands = result.rows.filter(
          (c: commandType) => !DISABLED_COMMANDS_TAGS.includes(c.tag)
        );
        commands = sortCommandList(commands, COMMAND_SORT);
        setCommandMessages(commands);
      }
    });
  }, []);

  const commandsWithPin = [
    "RELOADDEVICE",
    "SYSTEMEXIT",
    "FULLERASE",
    "AUTHRESET",
    "FACTORYRESET",
  ];

  return (
    <>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {commandMessages.map((c: commandType) => (
          <span key={c.tag}>
            <MenuItem
              onClick={() => setCommandCode(c.tag)}
              id={`${c.tag}_message`}
              key={c.id_com}
            >
              <StyledListItemIcon>{COMMAND_ICONS[c.tag]}</StyledListItemIcon>
              <ListItemText primary={c.description} />
            </MenuItem>
            {DIVIDER_AFTER.includes(c.tag) ? <Divider /> : undefined}
          </span>
        ))}
      </Menu>
      {commandCode && commandReady && (
        <ConfirmDialog
          loading={loading}
          handleClose={() => setCommandCode("")}
          handleConfirm={() => sendCommand(undefined)}
          message={
            commandMessages.filter((c) => c.tag === commandCode)[0].description
          }
          checkPin={!!(commandsWithPin.includes(commandCode) && pinStatus)}
        />
      )}
      {commandCode === "CHANGEPINCODE" && (
        <CommandsChangePinForm
          setCommandReady={setCommandReady}
          setCommandCode={setCommandCode}
          setAdditionData={setAdditionData}
        />
      )}
      {commandCode === "GETADBLOG" && (
        <CommandsLogForm
          loading={loading}
          sendCommand={sendCommand}
          onClose={() => setCommandCode("")}
        />
      )}
      {commandCode === "MESSAGE" && (
        <CommandsMessage
          loading={loading}
          handleClose={() => !loading && setCommandCode("")}
          sendCommand={sendCommand}
        />
      )}
      {commandCode === "CHANGE_DIV_CODE" && (
        <ChangeDivision
          loading={loading}
          handleClose={() => !loading && setCommandCode("")}
          sendCommand={sendCommand}
        />
      )}
      {commandCode === "CHANGE_CURRENT_PROJECT" && (
        <CommandsChangeCurrentProject
          loading={loading}
          handleClose={() => !loading && setCommandCode("")}
          sendCommand={sendCommand}
          rowDataSelection={rowDataSelection}
          projectIdFilter={projectIdFilter}
        />
      )}
    </>
  );
};

export default MenuCommandsItems;
