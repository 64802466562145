import * as React from "react";
import {useContext, useState} from "react";
import CoreTable from "../../../Tables/CoreTable";
import AddNewUserReportFilterColumns from "./AddNewUserReportFilterColumns";
import getReportFilterTypes, {
  UserReportFilterType,
} from "../../../../common/APIRequests/reportFilterType/getReportFilterTypes";
import {UserReport} from "../../../../common/APIRequests/user_report/getUserReports";
import SetUserReportFilterValueDialog from "./SetUserReportFilterValueDialog";
import TableContext, {TableContextType} from "../../../Tables/TableContext";
import {UserReportFilter} from "../../../../common/APIRequests/reportFilterUser/getUserReportFilters";

type AddNewUserReportFilterTableProps = {
  userReport: UserReport;
  onFilterValueSet?: () => void;
};

const AddNewUserReportFiltersTable = (
  props: AddNewUserReportFilterTableProps
) => {
  const [rowClicked, setRowClicked] = useState<UserReportFilterType>();

  const {rows, updateRows} = useContext(
    TableContext
  ) as TableContextType<UserReportFilter>;

  const alreadySetFiltersIds = rows.map((row) => row.id_type_filter);

  const getAvailableFilters = async () => {
    return getReportFilterTypes({
      report_type: props.userReport.report_type,
    }).then((result) => ({
      success: result.success,
      rows: result.rows.filter(
        (row) => !alreadySetFiltersIds.includes(row.id_type_filter)
      ),
      errors: {},
    }));
  };

  return (
    <CoreTable
      name={"add_new_user_report_filters"}
      columns={AddNewUserReportFilterColumns}
      getRowId={(row) => row.id_type_filter}
      updateRows={getAvailableFilters}
      onRowClick={setRowClicked}
      height={"60vh"}
      defaultSorting={{columnName: "name", direction: "asc"}}
      updateTrigger={alreadySetFiltersIds.toString()}
      dialog={
        <SetUserReportFilterValueDialog
          onClose={() => setRowClicked(undefined)}
          reportFilterType={rowClicked}
          userReport={props.userReport}
          onValueSet={updateRows}
        />
      }
    />
  );
};

export default AddNewUserReportFiltersTable;
