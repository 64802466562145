import {baseGetRowsRequest} from "../base/baseGetRowsRequest";
import {logEventFilterType} from "./getLogEvents";

export type logEventGroupedType = {
  id_type_user: number;
  type_user_name: string;
  count: number;
};

const getLogEventGrouped = async (filters: logEventFilterType) =>
  baseGetRowsRequest<logEventGroupedType>(`/log_event/log_event_grouped/`, {
    filters,
  });

export default getLogEventGrouped;
