import * as React from "react";
import {IntegratedSorting} from "@devexpress/dx-react-grid";
import moment from "moment";
import Column from "../Tables/Column";
import {FRONT_DATETIME_FORMAT} from "../../common/types";
import {compareVersion} from "../../common/utils/utils";

type IntegratedSortingWithDatetimeProps = {
  datetimeFields: string[];
  columns: Column[];
};

const CustomIntegratedSorting = (props: IntegratedSortingWithDatetimeProps) => {
  const compareDates = (a: string, b: string) => {
    if (a === b) return 0;

    return moment(a, FRONT_DATETIME_FORMAT).isBefore(
      moment(b, FRONT_DATETIME_FORMAT)
    )
      ? -1
      : 1;
  };

  const isNan = (a: unknown) => a === undefined || a === null;
  const versionNumberPattern = /^[0-9]+(\.[0-9]+)?(\.[0-9]+)?$/;

  const compare = (a: unknown, b: unknown) => {
    if (a === b) return 0;
    if (typeof a === "string" && typeof b === "string") {
      if (versionNumberPattern.test(a) && versionNumberPattern.test(b)) {
        return compareVersion(a, b);
      }
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    } else if (typeof a === "number" && typeof b === "number") {
      return a > b ? 1 : -1;
    } else if (typeof a === "boolean" && typeof b === "boolean") {
      return a > b ? 1 : -1;
    } else if (isNan(a) && !isNan(b)) {
      return 1;
    } else if (!isNan(a) && isNan(b)) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    <IntegratedSorting
      columnExtensions={props.columns.map((column) => ({
        columnName: column.columnName,
        compare: props.datetimeFields.includes(column.columnName)
          ? compareDates
          : compare,
      }))}
    />
  );
};

export default CustomIntegratedSorting;
