import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type userPinType = {
  login: string;
  console_pin: string | null;
};

const getUserPin = async (login: string, console_pin: string) =>
  baseGetRowsRequest<userPinType>(`/user_pin/`, {
    filters: {
      login,
      console_pin,
    },
  });

export default getUserPin;
