import {FormControl} from "@mui/material";
import React, {useContext, useMemo} from "react";
import {DeviceFilterType} from "../../common/APIRequests/device/getDevices";
import {projectType} from "../../common/APIRequests/project/getProjects";
import {optionType} from "../../common/types";
import {AppContext} from "../App-context";
import {generateProjectOptions} from "../common/functions";
import {ToolbarItem} from "../common/Toolbar";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import StringFilter from "../common/filterFields/StringFilter";

const DeviceFilter = (props: {
  update: (filters: DeviceFilterType) => void;
  loading: boolean;
  filters: DeviceFilterType;
}) => {
  const {filters, update, loading} = props;

  const appContext = useContext(AppContext);
  const {projects} = appContext;
  const options = useMemo(() => {
    const result: Array<optionType> = [];
    projects.forEach((project: projectType) =>
      generateProjectOptions(project, result)
    );
    return result;
  }, [projects]);

  const handleEnter = (name: string) => (value: string) => {
    update({...filters, [name]: value.trim()});
  };
  return (
    <React.Fragment>
      <ToolbarItem>
        <FormControl style={{width: "200px"}}>
          <SelectSingleFilterField
            name="device_project"
            label={"Проект"}
            value={filters.id_project}
            update={(value) => {
              update({...filters, id_project: value});
            }}
            disabled={loading}
            includeAllOption
            options={options}
          />
        </FormControl>
      </ToolbarItem>
      <ToolbarItem>
        <StringFilter
          name="serial_number"
          label="Серийный номер"
          onSubmit={handleEnter}
          submittedValue={filters.serial_number}
        />
      </ToolbarItem>
      <ToolbarItem>
        <StringFilter
          name="code_division"
          label="Код подразделения"
          onSubmit={handleEnter}
          submittedValue={filters.code_division}
        />
      </ToolbarItem>
    </React.Fragment>
  );
};

export default DeviceFilter;
