import {Box, Divider} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, {useState} from "react";
import deleteSession from "../../../common/APIRequests/login/deleteSession";
import {loginType} from "../../../common/APIRequests/login/postSession";
import deleteUserPin from "../../../common/APIRequests/user_pin/deleteUserPin";
import getLoginInfo from "../../../common/utils/getLoginInfo";
import {VersionDescription} from "../Descriptions";
import QrCodesDialog from "../../QrCodes/Dialogs/QrCodesDialog";

const LoginMenu = (props: {userLogout: () => void}) => {
  const {userLogout} = props;

  const [anchorElUser, setAnchorElUser] = useState(null as Element | null);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);

  const handleLogout = async (): Promise<void> => {
    await deleteSession();
    userLogout();
  };

  const handlePinReset = async (): Promise<void> => {
    const result = await deleteUserPin();
    result.success && userLogout();
  };

  const loginInfo = getLoginInfo() as loginType;
  return (
    <React.Fragment>
      {loginInfo && loginInfo.login && (
        <Button
          aria-label="Пользователь"
          aria-controls="profile-menu"
          aria-haspopup="true"
          variant="outlined"
          color="inherit"
          id="login_menu_button"
          onClick={(e) => setAnchorElUser(e.currentTarget)}
        >
          {loginInfo.login}
        </Button>
      )}
      {qrDialogOpen && <QrCodesDialog onClose={() => setQrDialogOpen(false)} />}
      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        id="profile-menu"
        keepMounted
        transformOrigin={{vertical: "top", horizontal: "right"}}
        open={Boolean(anchorElUser)}
        onClose={() => {
          setAnchorElUser(null);
        }}
        elevation={1}
      >
        <MenuItem onClick={handleLogout}>Выйти</MenuItem>
        <MenuItem onClick={handlePinReset}>Сбросить пин-код</MenuItem>
        <MenuItem onClick={() => setQrDialogOpen(true)}>
          Создать QR-код
        </MenuItem>

        <Divider style={{marginTop: 10, marginBottom: 10}} />

        <Box marginBottom={"10px"} marginTop={"10px"}>
          <VersionDescription />
        </Box>
      </Menu>
    </React.Fragment>
  );
};

export default LoginMenu;
