import ReactEcharts from "echarts-for-react";
import React from "react";
import {EChartOption} from "echarts";
import theme from "../../../theme";

export type barDataType = {
  name: string;
  quantity: number;
};

type Props = {
  data: Array<barDataType>;
  color?: Array<string>;
};

const BarChart = (props: Props) => {
  const {data, color = [theme.palette.primary.main]} = props;

  const option: EChartOption = {
    color,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "5%",
      bottom: "3%",
      containLabel: true,
    },
    yAxis: [
      {
        type: "category",
        data: data.map((dA: barDataType) => dA.name),
      },
    ],
    xAxis: [{type: "value"}],
    series: [
      {
        type: "bar",
        barWidth: "60%",
        data: data.map((dA: barDataType) => dA.quantity),
        label: {
          show: true,
          position: "right",
          formatter: (data: any) => (data.value ? `${data.value}` : ``),
          fontWeight: "bold",
        },
      },
    ],
  };

  return <ReactEcharts notMerge={true} lazyUpdate={true} option={option} />;
};

export default BarChart;
