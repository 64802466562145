import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import React from "react";

export type ColumnPropsForDownloadType = Array<{
  name: string;
  title: string;
  width: number;
}>;

type DownloadMenuButtonProps = {
  actionOptions: {onClick: () => void; label: string}[];
  disabled?: boolean;
};

const DownloadMenuButton = (props: DownloadMenuButtonProps) => {
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event?: React.MouseEvent | Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      e.preventDefault();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  return (
    <React.Fragment>
      <div>
        <Button
          disabled={props.disabled}
          startIcon={<GetAppIcon />}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="secondary"
          variant="text"
        >
          Скачать
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{zIndex: 1000}}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {props.actionOptions.map((action) => (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          action.onClick();
                        }}
                        key={`menu_download_button_${action.label}`}
                      >
                        {action.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </React.Fragment>
  );
};

export default DownloadMenuButton;
