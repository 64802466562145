import {useCallback, useEffect, useRef, useState} from "react";
import {getHeight, getWidth} from "../DeviceScreen";
import {startSession, stopSession} from "../API/httpCommands";
import {
  RC_WS_CONN_TIMEOUT_MS,
  RC_SESSION_TIMEOUT_MS,
  WebSocketProtocolPrefix,
} from "../../../../settings";
import {useIdleTimer} from "./useIdleTimer";

export type StreamImageDataType = {
  height: number;
  width: number;
  dpi: number;
  image: any;
};

export type StreamMessageType = {
  id?: number;
  responseText: string;
};

export const useWebsocket = (remoteControlProxy: string, device: string) => {
  const socketRef = useRef({} as WebSocket);
  const connectingTimerRef = useRef<NodeJS.Timeout>();
  const sessionUIdRef = useRef<string>();
  const streamAvailableRef = useRef(false);
  const [streamAvailable, setSessionAvailable] = useState(false);
  const [sessionOpened, setSessionOpened] = useState(false);
  const [error, setError] = useState(null as string | null);
  const [streamImageData, setStreamImageData] = useState(
    {} as StreamImageDataType
  );
  const [streamMessage, setStreamMessage] = useState(
    null as StreamMessageType | null
  );

  const TIMEOUT_MESSAGE =
    "Ваша сессия удаленного управления была автоматически закрыта по причине бездействия";
  useIdleTimer(async () => {
    if (error?.length) return;
    setError(TIMEOUT_MESSAGE);
    await stopStream();
  }, RC_WS_CONN_TIMEOUT_MS);

  useEffect(() => {
    return () => {
      if (connectingTimerRef.current) clearTimeout(connectingTimerRef.current);
    };
  }, []);

  useEffect(() => {
    streamAvailableRef.current = streamAvailable;
  }, [streamAvailable]);

  const startStream = async () => {
    setSessionOpened(true);
    setError(null);
    const result = await startSession(device);
    const sessionId = result.row?.sessionUId;
    if (result.success) {
      sessionUIdRef.current = sessionId as string;
    } else if (result.errors) {
      setError("Невозможно открыть сессию для устройства");
      setSessionOpened(false);
      return;
    }
    await startWebSocket();
  };

  const startWebSocket = async () => {
    const socket = new WebSocket(
      `${WebSocketProtocolPrefix}://${remoteControlProxy}/interaction/${sessionUIdRef.current}`
    );
    socketRef.current = socket;
    socket.onopen = () => {
      resetAndStartConnectionTimer();
      console.log("WebSocket connection opened");
    };

    socket.onmessage = (event) => {
      const data = event.data;
      if (data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const arrayBuffer = reader.result;
            const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer);
            const type = uint8Array[0];
            switch (type) {
              case 0:
                setSessionAvailable(false);
                resetAndStartConnectionTimer();
                break;
              case 1:
                setSessionAvailable(false);
                break;
              case 2:
                setSessionAvailable(true);
                let height = (uint8Array[1] << 8) | uint8Array[2];
                let width = (uint8Array[3] << 8) | uint8Array[4];
                const dpi = (uint8Array[5] << 8) | uint8Array[6];
                const rotation = (uint8Array[7] << 8) | uint8Array[8];

                const image = await createImageBitmap(event.data.slice(9), {
                  resizeWidth: getWidth(width, height),
                  resizeHeight: getHeight(width, height),
                });
                setStreamImageData({height, width, dpi, image});
                break;
              case 3:
                let id = (uint8Array[1] << 8) | uint8Array[4];
                let responseText = await event.data.slice(5).text();
                setStreamMessage({id, responseText});
                break;
              case 4:
                setStreamMessage({responseText: "command_finished"});
                break;
            }
          } catch {
            return;
          }
        };
        reader.readAsArrayBuffer(data);
      }
    };

    socket.onerror = (error) => {
      stopStream();
      setError("Ошибка сервера");
      console.error("WebSocket error: ", error);
    };

    socket.onclose = () => {
      setSessionAvailable(false);
      console.log("WebSocket connection closed");
    };
  };

  const stopStream = async () => {
    try {
      if (sessionUIdRef.current) {
        await stopSession(sessionUIdRef.current as string);
      }
      setSessionOpened(false);
    } catch {
      console.log("The session has already been closed");
    }
    socketRef.current.close?.();
  };

  const resetAndStartConnectionTimer = useCallback(() => {
    if (connectingTimerRef.current) clearTimeout(connectingTimerRef.current);
    connectingTimerRef.current = setTimeout(async () => {
      if (!streamAvailableRef.current) {
        setError(
          "Устройство не отвечает, так как оно находится не в сети или выключено"
        );
        await stopStream();
      }
    }, RC_SESSION_TIMEOUT_MS);
  }, []);

  return [
    socketRef.current,
    streamImageData,
    sessionOpened,
    streamAvailable,
    error,
    streamMessage,
    startStream,
    stopStream,
  ] as [
    WebSocket,
    StreamImageDataType,
    boolean,
    boolean,
    string,
    StreamMessageType,
    () => Promise<void>,
    () => Promise<void>
  ];
};
