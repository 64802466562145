import React from "react";
import {applicationVersionType} from "../../../common/APIRequests/applicationVersion/getApplicationVersions";
import {ToolbarItem} from "../../common/Toolbar";
import {ApplicationType} from "../../../common/APIRequests/application/getApplications";
import ButtonWithPin from "../../common/buttons/ButtonWithPin";

type Props = {
  loading: boolean;
  selected: applicationVersionType | undefined;
  selectedApplication: ApplicationType | undefined;
  installApplication: Function;
  appVersionRows: Array<applicationVersionType>;
  checkPin: boolean;
};

const ConfirmApplications = (props: Props) => {
  const {
    loading,
    selected,
    selectedApplication,
    installApplication,
    appVersionRows,
    checkPin,
  } = props;

  const handleInstallSelected = async () => {
    selectedApplication &&
      selected &&
      installApplication(selectedApplication, selected);
  };

  const handleInstallVersion = async () => {
    selectedApplication &&
      installApplication(
        selectedApplication,
        appVersionRows.filter((row: applicationVersionType) =>
          Boolean(row.is_release)
        )[0]
      );
  };

  return (
    <React.Fragment>
      <ToolbarItem>
        <ButtonWithPin
          submitMessage="Установить выбранную версию"
          loading={loading}
          handleSubmit={handleInstallSelected}
          disabled={loading || !selected}
          checkPin={checkPin}
          submitId="device_apps_install_chosen"
        />
      </ToolbarItem>
      <ToolbarItem>
        <ButtonWithPin
          submitMessage="Установить релизную версию"
          loading={loading}
          handleSubmit={handleInstallVersion}
          disabled={
            loading || !selectedApplication || appVersionRows.length === 0
          }
          checkPin={checkPin}
          submitId="device_apps_install_release"
        />
      </ToolbarItem>
    </React.Fragment>
  );
};

export default ConfirmApplications;
