import {Dialog, DialogContent} from "@mui/material";
import {getUsername} from "../../../common/getUsername";
import {Transition} from "../components";
import ClosableDialogTitle from "../dialog/ClosableDialogTitle";
import PinForm from "./PinForm";

type RequestPinDialogProps = {
  onClose: () => void;
  onSubmit: () => void;
};

const RequestPinDialog = (props: RequestPinDialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      id="request_pin_dialog"
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Для подтверждения введите пин-код
      </ClosableDialogTitle>
      <DialogContent>
        <PinForm
          login={getUsername()}
          close={props.onClose}
          submit={props.onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default RequestPinDialog;
