// @flow
import * as React from "react";
import {Dialog} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Transition} from "../components";
import {DialogProps} from "@mui/material/Dialog/Dialog";
import theme from "../../../theme";

type CustomDialogProps = {
  open?: boolean;
} & Pick<DialogProps, Exclude<keyof DialogProps, "open">>;

const useStyles = makeStyles()({
  dialogPaper: {
    alignSelf: "flex-start",
    transition: theme.transitions.create("max-width", {
      duration: theme.transitions.duration.standard,
    }),
  },
});

export const CustomDialog = (props: CustomDialogProps) => {
  const {open, maxWidth, keepMounted, TransitionComponent, ...otherProps} =
    props;

  const {classes} = useStyles();

  return (
    <Dialog
      open={open !== undefined ? open : true}
      maxWidth={maxWidth !== undefined ? maxWidth : "lg"}
      keepMounted={keepMounted !== undefined ? keepMounted : true}
      TransitionComponent={TransitionComponent || Transition}
      PaperProps={{className: classes.dialogPaper}}
      {...otherProps}
    />
  );
};
