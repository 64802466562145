import {Box, Button} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {useContext, useState} from "react";
import postComparePolicyTreeWithCsv, {
  comparedTreesType,
} from "../../../common/APIRequests/policy/postComparePolicyTreeWithCsv";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {AppContext} from "../../App-context";
import {SecondaryButton} from "../../common/buttons/SecondaryButton";
import {getExtension, smashTree} from "../../common/functions";
import {BottomToolbarWrapper} from "../../common/Toolbar";
import CompareTreesDialog from "./CompareTreesDialog";

type CompareWithFileButtonType = {
  idProject: number;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  onClose: () => void;
};

const CompareWithFileButton = (props: CompareWithFileButtonType) => {
  const appContext = useContext(AppContext);
  const {setAlert, projects} = appContext;

  const smashedProjects: Array<projectType> = [];
  projects.forEach((project: projectType) =>
    smashTree(project, smashedProjects)
  );

  const currentProject = smashedProjects.find(
    (p: projectType) => p.id_project === props.idProject
  );

  const [compareRows, setCompareRows] = useState<Array<comparedTreesType>>([]);
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = new FormData();
    const {files} = e.target;
    if (!files) {
      return;
    }
    if (!["csv"].includes(getExtension(files[0].name))) {
      setAlert({
        severity: "error",
        message: ["Разрешается загружать файлы только с расширением .csv"],
      });
      return;
    }
    setFileName(files[0].name);
    data.append("file", files[0]);
    props.setLoading(true);
    postComparePolicyTreeWithCsv(props.idProject, data)
      .then((result) => {
        if (result.success) {
          setCompareRows(result.data);
          setCompareResultOpen(true);
        } else if (result.errors) {
          setAlert({severity: "error", message: result.errors["file"]});
        }
      })
      .finally(() => props.setLoading(false));
  };

  const [compareResultOpen, setCompareResultOpen] = useState<boolean>(false);

  const updateRows = () =>
    Promise.resolve({success: true, rows: compareRows, errors: {}});

  return (
    <>
      {compareResultOpen && currentProject && (
        <CompareTreesDialog
          currentProject={currentProject}
          destinationProject={undefined}
          updateTable={updateRows}
          onClose={() => setCompareResultOpen(false)}
          fileName={fileName}
        />
      )}
      <input
        type="file"
        style={{display: "none"}}
        onChange={handleUpload}
        alt="Файл для сравнения"
        id="compare_with_file"
        name="compare_with_file"
        accept={".csv"}
        disabled={props.loading}
        value=""
      />
      <label htmlFor="compare_with_file">
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          component="span"
          disabled={props.loading}
          id="compare_with_file_btn"
        >
          Файл для сравнения
        </Button>
      </label>
      <BottomToolbarWrapper>
        <Box>
          <SecondaryButton
            title="Отменить"
            id="formClose"
            fullWidth
            onClick={props.onClose}
          />
        </Box>
      </BottomToolbarWrapper>
    </>
  );
};

export default CompareWithFileButton;
