import * as React from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {Transition} from "../components";
import StyledLinearProgress from "../StyledLinearProgress";

export const LoadingDialog = () => {
  return (
    <Dialog open={true} keepMounted TransitionComponent={Transition}>
      <DialogTitle>Идет загрузка...</DialogTitle>
      <DialogContent>
        <StyledLinearProgress />
      </DialogContent>
    </Dialog>
  );
};
