import * as React from "react";
import {ApplicationType} from "../common/APIRequests/application/getApplications";
import {DirectoryType} from "../common/APIRequests/directory/getDirectory";
import {EventType} from "../common/APIRequests/event/getEvents";
import {geographyType} from "../common/APIRequests/geography/getBranch";
import {userPermsType} from "../common/APIRequests/login/postSession";
import {projectType} from "../common/APIRequests/project/getProjects";
import {alertType} from "../common/types";

type AppContextType = {
  setAlert: (alert: alertType) => void;
  projects: Array<projectType>;
  updateProjects: () => Promise<void>;
  geoNets: Array<geographyType>;
  events: Array<EventType>;
  updateEvents: () => void;
  directories: Array<DirectoryType>;
  eventTypes: Array<DirectoryType>;
  apps: Array<ApplicationType>;
  updateApps: () => void;
  loading: boolean;

  permissions: {user_type: userPermsType};
  pinStatus: number;
  version: string | undefined;
};

export const AppContextProps: AppContextType = {
  setAlert: () => {},
  projects: [],
  updateProjects: () => Promise.resolve(),
  geoNets: [],
  events: [],
  updateEvents: () => {},
  directories: [],
  eventTypes: [],
  apps: [],
  updateApps: () => {},
  loading: true,
  permissions: {user_type: 0},
  pinStatus: 0,
  version: undefined,
};

export const AppContext = React.createContext(AppContextProps);
