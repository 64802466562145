import * as React from "react";
import {useRef, useState} from "react";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import getNotificationEvents, {
  NotificationEventType,
} from "../../../common/APIRequests/notifyEvent/getNotificationEvents";
import {NotificationType} from "../../../common/APIRequests/notify/getNotification";
import NotificationEventColumns from "./NotificationEventColumns";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import deleteNotificationEvents from "../../../common/APIRequests/notifyEvent/deleteNotificationEvents";
import AddNotificationEventDialogTable from "./AddNotificationEvents/AddNotificationEventDialogTable";
import {useTrigger} from "../../../common/customHooks";
import {AddNewButton} from "../../common/buttons/ToolbarButtons";

type NotificationEventsTableProps = {
  notification: NotificationType;
};

const NotificationEventsTable = (props: NotificationEventsTableProps) => {
  const notificationEventIds = useRef<number[]>([]);

  const updateRows: UpdateRowsType<NotificationEventType> = async () => {
    const result = await getNotificationEvents(props.notification.id_notify);
    if (result.success)
      notificationEventIds.current = result.rows.map((r) => r.id_event);
    return result;
  };

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [trigger, refresh] = useTrigger();

  return (
    <>
      {addDialogOpen && (
        <AddNotificationEventDialogTable
          onClose={() => setAddDialogOpen(false)}
          notificationEventIds={notificationEventIds.current}
          notification={props.notification}
          onSubmit={refresh}
        />
      )}
      <CoreTable
        name="notification_events"
        columns={NotificationEventColumns}
        getRowId={(row) => row.id_event}
        updateRows={updateRows}
        integratedSearch
        defaultSorting={{columnName: "event_name", direction: "desc"}}
        selectionEnabled
        updateTrigger={trigger}
        selectionButton={(selection, update) => (
          <MenuDeleteAction
            selection={selection}
            update={update}
            action={(ids) =>
              deleteNotificationEvents(props.notification.id_notify, ids)
            }
          />
        )}
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddDialogOpen(true)}
            key="notification-events-open-add-dialog-button"
          />,
        ]}
      />
    </>
  );
};

export default NotificationEventsTable;
