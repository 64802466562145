import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type reportVersionsType = {
  id_device: number;
  serial_number: string;
  code_division: string;
  name_project: string;
  ldap_login: string;
  display_name: string;
  email: string;
  app_name: string;
  app_version: string;
  app_code: string;
  date_inst: string;
};

const getReportVersions = async (
  id_project: number,
  id_app?: number,
  include_child?: 1 | 0
) =>
  baseGetRowsRequest<reportVersionsType>(`/report/versions/`, {
    id_project,
    id_app,
    include_child,
  });

export default getReportVersions;
