import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type FirmwareType = {
  id_firmware: number;
  name: string;
  id_model: number | string;
  model: string;
  producer: string;
  id_dev_version: number | string;
  version_code: string;
  has_gms: number | string;
  file_size: number | null;
  file_path: string;
  comment_text: string;
};

export type FirmwareFilterType = {
  id_model?: number | null;
  id_dev_version?: number | null;
  has_gms?: number | null;
};

export const GmsValues: {[s: number]: string} = {
  0: "Нет",
  1: "Да",
};

const getFirmwares = async (filters?: FirmwareFilterType) =>
  baseGetRowsRequest<FirmwareType>(`/firmware/`, {
    filters,
  });

export default getFirmwares;
