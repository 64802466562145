import Column from "../Tables/Column";
import {
  FirmwareType,
  GmsValues,
} from "../../common/APIRequests/firmware/getFirmwares";

const FirmwareColumns = [
  new Column("id_firmware", "№", 100, {hiddenByDefault: true}),
  new Column("name", "Наименование", 350),
  new Column("model", "Модель", 150),
  new Column("version_code", "Версия Android", 150),
  new Column("has_gms", "Наличие GMS", 150, {
    getCellValue: (row: FirmwareType) => GmsValues[row.has_gms as number],
  }),
  new Column("file_size", "Размер (Мб)", 150, {
    getCellValue: (row: FirmwareType) => {
      if (row.file_size)
        // convert bytes into Mb
        return (row.file_size / Math.pow(1024, 2)).toFixed(1);
    },
  }),
  new Column("file_path", "Путь", 250),
  new Column("comment_text", "Комментарий", 300, {
    auto: true,
    sortingEnabled: false,
  }),
];

export default FirmwareColumns;
