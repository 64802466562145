import CloseIcon from "@mui/icons-material/Close";
import {useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import {AppBar, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";

const FullScreenDialog = (props: {
  children: any;
  title: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [open, setOpen] = useState(props.open || false);
  return (
    <Dialog
      fullScreen
      open={open}
      sx={{"& .MuiPaper-root": {flexDirection: "row"}}}
    >
      <AppBar>
        <Toolbar>
          <Typography variant="h6" component="div">
            {props.title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(false);
              props.onClose();
            }}
            aria-label="close"
            sx={{position: "fixed", right: "10px", top: "10px"}}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {props.children}
    </Dialog>
  );
};

export default FullScreenDialog;
