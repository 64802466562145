import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {ApplicationType} from "../../../common/APIRequests/application/getApplications";
import getApplicationVersions, {
  applicationVersionType,
} from "../../../common/APIRequests/applicationVersion/getApplicationVersions";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../../common/customHooks";
import {columnType, sortingType} from "../../../common/types";
import {StyledVirtualTable} from "../../common/grid/cell";
import {BooleanTypeProvider} from "../../common/grid/providers";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../../common/styles";
import {
  BottomToolbarWrapper,
  ToolbarItem,
  ToolbarRoot,
  ToolbarSeparator,
} from "../../common/Toolbar";
import ConfirmApplications from "./ConfirmApplications";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../../common/components";

const columns: columnType = [
  {name: "id_app_version", title: "ID"},
  {name: "name", title: "Android версия"},
  {name: "code", title: "Android код версии"},
  {name: "file_path", title: "Путь до APK"},
  {name: "description", title: "Описание"},
  {name: "is_release", title: "Релизная"},
];
const columnExtensions = [
  {columnName: "id_app_version", width: 70},
  {columnName: "name", width: 200},
  {columnName: "code", width: 200},
  {columnName: "file_path", width: 500},
  {columnName: "description", width: 200},
  {columnName: "is_release", width: 150},
];
const columnAlignExtension = [{columnName: "is_release", align: "center"}];
const hiddenColumnNames = ["file_path"];

const DeviceApplicationVersions = (props: {
  selectedApplication: ApplicationType | undefined;
  selected: applicationVersionType | undefined;
  setSelected: Function;
  handleClose: () => void;
  installApplication: Function;
  loading: boolean;
  setLoading: Function;
  checkPin: boolean;
}) => {
  const {
    selectedApplication,
    selected,
    setSelected,
    handleClose,
    installApplication,
    loading,
    setLoading,
  } = props;

  const [rows, setRows] = useState([] as Array<applicationVersionType>);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "device_app_sorting",
    [{columnName: "name", direction: "asc"}]
  );

  const updateRows = async () => {
    if (!selectedApplication) {
      return;
    }
    setSelected(undefined);

    setLoading(true);
    const result = await getApplicationVersions(selectedApplication.id_app);
    setLoading(false);

    result.success && setRows(result.rows);
  };

  useEffect(() => {
    updateRows();
  }, [selectedApplication]);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "device_application_version_ordering",
    columns.map((row) => row.name)
  );

  const classes = useGlobalStyles();

  return (
    <div style={{position: "relative"}}>
      <Grid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id_app_version}
      >
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <h3 style={{margin: 0}}>Версии</h3>
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              onClick={() => setSelected(row)}
              className={
                selected && row.id_app_version === selected.id_app_version
                  ? classes.gridRowSelected
                  : classes.gridRow
              }
              data-type="app_versions_row"
              data-id={row.id_app_version}
            />
          )}
          height="30vh"
          columnExtensions={columnAlignExtension}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="device_application_version"
          autoWidthColumnName={"description"}
        />

        <BooleanTypeProvider for={["is_release"]} />

        {loading && <LoadingIndicator />}
      </Grid>
      <BottomToolbarWrapper>
        <ToolbarSeparator />
        <ConfirmApplications
          loading={loading}
          selected={selected}
          selectedApplication={selectedApplication}
          installApplication={installApplication}
          appVersionRows={rows}
          checkPin={props.checkPin}
        />
        <Box>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
            disabled={loading}
            id="device_apps_close"
          >
            Закрыть
          </Button>
        </Box>
      </BottomToolbarWrapper>
    </div>
  );
};
export default DeviceApplicationVersions;
