import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type UserReportColumn = {
  id_report_user_column: number;
  id_report_column_list: number;
  id_user_report: number;
  name_column: string;
  system_name: string;
};

const getReportColumnUser = async (id_user_report: number) =>
  baseGetRowsRequest<UserReportColumn>("/report_column_user/", {
    filters: {id_user_report},
  });

export default getReportColumnUser;
