import {BarStackCategoryType} from "../../../components/Dashboard/chartTypes/BarStackChart";
import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

export type dashboardType = {
  activity: {
    active_count: number;
    inactive_count: number;
  };
  os_version: Array<{
    name_os: string;
    version_os: string;
    count_os: number;
  }>;
  days: Array<{
    order: number;
    name: string;
    quantity: number;
  }>;
  model: Array<{
    model_name: string;
    model_sum: number;
  }>;
  task_progress: Array<BarStackCategoryType>;
};

const getDashboard = async (
  id_project: number | null
): Promise<{
  success: boolean;
  rows: dashboardType;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "get",
      url: id_project ? `/dashboard/${id_project}/` : `/dashboard/`,
    })
      .then((response) =>
        resolve({
          success: true,
          rows: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          rows: {} as dashboardType,
          errors: errors,
        });
      })
  );

export default getDashboard;
