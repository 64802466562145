import {basePostRequest} from "../base/basePostRequest";
import {deviceProfileType} from "./getDeviceProfiles";

const postDeviceProfiles = async (
  id_device: number,
  ids: Array<number | string>
) =>
  basePostRequest<deviceProfileType>(`/device/${id_device}/profile/`, {
    ids: ids,
  });

export default postDeviceProfiles;
