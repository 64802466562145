import {VideoRecordingFileType} from "../DeviceVideo/DeviceVideoTypes";
import {ScreenshotFileType} from "../DeviceScreenshot/DeviceScreenshotTypes";
import {baseGetRowsRequestWithCount} from "./base/baseGetRowsRequestWithCount";
import {basePostRequest} from "./base/basePostRequest";
import {baseDeleteRequest} from "./base/baseDeleteRequest";

export const startSession = (id_device: string) =>
  basePostRequest<{sessionUId: string}>(`/session/${id_device}`);
export const stopSession = (id_device: string) =>
  baseDeleteRequest(`/session/${id_device}`);
export const startVideoRecording = (id_device: string) =>
  basePostRequest(`/video/${id_device}/start`);
export const stopVideoRecording = (id_device: string) =>
  basePostRequest(`/video/${id_device}/stop`);
export const takeScreenshot = (id_device: string) =>
  basePostRequest(`/screenshot/${id_device}`);

export const getVideoRecordings = async (
  id_device: string,
  page?: number,
  pageSize?: number
) =>
  baseGetRowsRequestWithCount<VideoRecordingFileType>(`/video/${id_device}`, {
    page,
    pageSize,
  });

export const getScreenshots = async (
  id_device: string,
  page?: number,
  pageSize?: number
) =>
  baseGetRowsRequestWithCount<ScreenshotFileType>(`/screenshot/${id_device}`, {
    page,
    pageSize,
  });
