import {ColumnPropsForDownloadType} from "../../../components/common/buttons/DownloadMenuButton";
import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";
import {logEventFilterType} from "./getLogEvents";

const downloadLogEventCsv = async (
  event_name: string,
  sort_field: string,
  sort_desc: boolean,
  filters: logEventFilterType,
  columnPropsForDownload: ColumnPropsForDownloadType
): Promise<{success: boolean; file?: File; errors: errorType}> =>
  new Promise((resolve) =>
    apiClient({
      responseType: "arraybuffer",
      method: "post",
      url: `/log_event/download_csv/`,
      data: {
        event_name,
        sort_field,
        sort_desc,
        field_props_for_download: JSON.stringify(columnPropsForDownload),
        filters: JSON.stringify(filters),
      },
    })
      .then((response) =>
        resolve({
          success: true,
          file: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          file: undefined,
          errors: errors,
        });
      })
  );

export default downloadLogEventCsv;
