import {Grid, IconButton, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useContext, useEffect, useState} from "react";
import deleteDirectory from "../../common/APIRequests/directory/deleteDirectory";
import {DirectoryType} from "../../common/APIRequests/directory/getDirectory";
import {EventType} from "../../common/APIRequests/event/getEvents";
import patchEvent from "../../common/APIRequests/event/patchEvent";
import postEvent from "../../common/APIRequests/event/postEvent";
import {errorType, optionType} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import IconButtonWithPin from "../common/buttons/IconButtonWithPin";
import SelectSingleField from "../common/formFields/SelectSingleField";
import StringField from "../common/formFields/StringField";
import {defaultEvent} from "./EventTable";

const DIRECTORY_INPUT_WIDTH = 9;
const DIRECTORY_INPUT_WIDTH_WITHOUT_SELECTED = 11;

type Props = {
  handleClose: () => void;
  update: Function;
  event?: EventType;
  loading: boolean;
  setLoading: (l: boolean) => void;
  updateDirectoryOptions: () => void;
  userDirsOptions: Array<optionType>;
  propertyDirsOptions: Array<optionType>;
  levelDirsOptions: Array<optionType>;
  setDirectoryFormOpen: Function;
  setDirectoryModifyFormOpen: Function;
  userDirs: Array<DirectoryType>;
  setSelectedUserDir: Function;
  selectedUserDir: DirectoryType | undefined;
  values: EventType;
  setValues: Function;
  checkPin?: boolean;
};

const EventForm = (props: Props) => {
  const {
    handleClose,
    update,
    event,
    loading,
    setLoading,
    userDirsOptions,
    propertyDirsOptions,
    levelDirsOptions,
    setDirectoryFormOpen,
    setDirectoryModifyFormOpen,
    userDirs,
    setSelectedUserDir,
    selectedUserDir,
    values,
    setValues,
    checkPin,
  } = props;

  const appContext = useContext(AppContext);
  const {setAlert, updateEvents} = appContext;

  const [errors, setErrors] = useState({} as errorType);

  useEffect(() => {
    !event && setValues(defaultEvent);
  }, [event]);

  const handleChange = (name: string) => (value: number) => {
    if (value === 0) {
      setValues({...values, [name]: undefined});
    } else {
      setValues({...values, [name]: value});
    }
    if (name === "id_type_user") {
      const dir = userDirs.filter(
        (dir: DirectoryType) => dir.id_dir === value
      )[0];
      setSelectedUserDir(dir);
    }
  };

  const handleDeleteDirectory = async () => {
    if (selectedUserDir) {
      await deleteDirectory(selectedUserDir.id_dir);
      setSelectedUserDir(undefined);
      setValues({...values, id_type_user: "0"});
      await props.updateDirectoryOptions();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    let result;
    if (event && event.id_event) {
      result = await patchEvent(values.id_event, values);
    } else {
      result = await postEvent(values);
    }

    if (result.success) {
      setErrors({});
      handleClose();
      setAlert({
        severity: "success",
        message: [event ? "Событие изменено" : "Событие добавлено"],
      });
      updateEvents();
      await update();
    } else {
      const {errors} = result;
      if (errors.event) {
        setAlert({
          severity: "error",
          message: ["Событие с данным сочетанием значений уже существует."],
        });
        errors.id_type_user = [""];
        errors.id_type_property = [""];
        errors.id_type_level = [""];
      }
      setErrors(errors);
    }

    setLoading(false);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={
            selectedUserDir
              ? DIRECTORY_INPUT_WIDTH
              : DIRECTORY_INPUT_WIDTH_WITHOUT_SELECTED
          }
        >
          <SelectSingleField
            options={userDirsOptions.map((o: optionType) =>
              o.key === 0
                ? {
                    key: o.key,
                    value: o.value,
                    text: "-----",
                  }
                : o
            )}
            setValue={handleChange("id_type_user")}
            value={values.id_type_user ? values.id_type_user.toString() : "0"}
            name="id_type_user"
            label="Классификатор"
            errors={errors}
            setErrors={setErrors}
            style={{marginBottom: theme.spacing(2)}}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Добавить">
            <>
              <IconButton
                aria-label="add"
                onClick={() => setDirectoryFormOpen(true)}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </>
          </Tooltip>
        </Grid>
        {selectedUserDir && (
          <>
            <Grid item xs={1}>
              <Tooltip title="Изменить">
                <>
                  <IconButton
                    aria-label="modify"
                    onClick={() => setDirectoryModifyFormOpen(true)}
                    size="large"
                  >
                    <CreateIcon />
                  </IconButton>
                </>
              </Tooltip>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Удалить">
                <>
                  <IconButtonWithPin
                    onClick={handleDeleteDirectory}
                    checkPin
                    loading={loading}
                    setLoading={setLoading}
                  >
                    <DeleteIcon />
                  </IconButtonWithPin>
                </>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
      <SelectSingleField
        options={propertyDirsOptions.filter((o: optionType) => o.key !== 0)}
        setValue={handleChange("id_type_property")}
        value={
          values.id_type_property ? values.id_type_property.toString() : "0"
        }
        name="id_type_property"
        label="Тип события"
        errors={errors}
        setErrors={setErrors}
        style={{marginBottom: theme.spacing(2)}}
        disabled={loading}
      />
      <SelectSingleField
        options={levelDirsOptions.map((o: optionType) =>
          o.key === 0
            ? {
                key: o.key,
                value: o.value,
                text: "-----",
              }
            : o
        )}
        setValue={handleChange("id_type_level")}
        value={values.id_type_level ? values.id_type_level.toString() : "0"}
        name="id_type_level"
        label="Уровень хранения"
        errors={errors}
        setErrors={setErrors}
        style={{marginBottom: theme.spacing(2)}}
        disabled={loading}
      />
      <StringField
        value={values.event_name ? values.event_name : ""}
        setValue={handleChange("event_name")}
        name="event_name"
        label="Наименование"
        errors={errors}
        setErrors={setErrors}
        style={{marginBottom: theme.spacing(2)}}
        disabled={loading}
      />
      <StringField
        value={values.event_code}
        setValue={handleChange("event_code")}
        name="event_code"
        label="Код"
        errors={errors}
        setErrors={setErrors}
        style={{marginBottom: theme.spacing(2)}}
        disabled={loading}
      />
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitMessage="Сохранить"
        checkPin={checkPin}
      />
    </React.Fragment>
  );
};

export default EventForm;
