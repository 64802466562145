import {useContext, useState} from "react";
import getComparePolicyTreesOfTwoProjects from "../../../common/APIRequests/policy/getComparePolicyTreesOfTwoProjects";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {errorType, optionType} from "../../../common/types";
import {AppContext} from "../../App-context";
import FormButtons from "../../common/buttons/FormButtons";
import SelectSingleField from "../../common/formFields/SelectSingleField";
import {generateProjectOptions, smashTree} from "../../common/functions";
import CompareTreesDialog from "./CompareTreesDialog";

type CompareWithProjectFormType = {
  idProject: number;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  onClose: () => void;
};

const CompareWithProjectForm = (props: CompareWithProjectFormType) => {
  const appContext = useContext(AppContext);
  const {projects} = appContext;

  const smashedProjects: Array<projectType> = [];
  projects.forEach((project: projectType) =>
    smashTree(project, smashedProjects)
  );

  // exclude current project to prevent equals with self
  // & makeOptions
  let projectOptions: optionType[] = [];
  projects.forEach((p: projectType) =>
    generateProjectOptions(p, projectOptions)
  );
  projectOptions = projectOptions.filter(
    (p: optionType) => p.value !== props.idProject
  );

  const currentProject = smashedProjects.find(
    (p: projectType) => p.id_project === props.idProject
  );

  const [errors, setErrors] = useState({} as errorType);

  const [destProjectId, setDestProjectId] = useState<undefined | number>(
    undefined
  );
  const destProject = destProjectId
    ? smashedProjects.find((p: projectType) => p.id_project === destProjectId)
    : undefined;

  const [compareResultOpen, setCompareResultOpen] = useState<boolean>(false);

  const compare = (currentIdProject: number, destinationIdProject?: number) =>
    getComparePolicyTreesOfTwoProjects(currentIdProject, destinationIdProject);

  return (
    <>
      {compareResultOpen && currentProject && (
        <CompareTreesDialog
          currentProject={currentProject}
          destinationProject={destProject ? destProject : undefined}
          updateTable={compare}
          onClose={() => setCompareResultOpen(false)}
        />
      )}
      <SelectSingleField
        setValue={(value: number) => setDestProjectId(value)}
        options={projectOptions}
        value={destProjectId?.toString()}
        name="compare_project"
        label="Проект"
        errors={errors}
        setErrors={setErrors}
        disabled={props.loading}
      />
      <FormButtons
        submitMessage="Сравнить"
        disabled={!destProjectId}
        loading={props.loading}
        handleSubmit={() => setCompareResultOpen(true)}
        handleClose={() => props.onClose()}
      />
    </>
  );
};

export default CompareWithProjectForm;
