import * as React from "react";
import {useState} from "react";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import getNotification, {
  NotificationType,
} from "../../common/APIRequests/notify/getNotification";
import NotificationColumns from "./NotificationColumns";
import {AddNewButton} from "../common/buttons/ToolbarButtons";
import AddNotificationDialog from "./AddNotificationDialog";
import {useTrigger} from "../../common/customHooks";
import EditNotificationDialog from "./EditNotificationDialog";

const NotificationsTable = () => {
  const updateRows: UpdateRowsType<NotificationType> = getNotification;

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [trigger, refresh] = useTrigger();
  const [rowClicked, setRowClicked] = useState<NotificationType | undefined>(
    undefined
  );

  return (
    <>
      {addDialogOpen && (
        <AddNotificationDialog
          onClose={() => setAddDialogOpen(false)}
          onSubmit={refresh}
        />
      )}
      {rowClicked && (
        <EditNotificationDialog
          notification={rowClicked}
          onClose={() => setRowClicked(undefined)}
          update={refresh}
        />
      )}
      <CoreTable
        name={"notifications"}
        columns={NotificationColumns}
        getRowId={(row) => row.id_notify}
        updateRows={updateRows}
        updateTrigger={trigger}
        onRowClick={setRowClicked}
        defaultSorting={{columnName: "name", direction: "desc"}}
        integratedSearch
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddDialogOpen(true)}
            key="notification-open-add-dialog-button"
          />,
        ]}
      />
    </>
  );
};

export default NotificationsTable;
