import * as React from "react";
import {useContext, useState} from "react";
import {ReportFilterProps} from "./EventIdReportFilter";
import SelectSingleField from "../../../../../common/formFields/SelectSingleField";
import theme from "../../../../../../theme";
import {DirectoryType} from "../../../../../../common/APIRequests/directory/getDirectory";
import {AppContext} from "../../../../../App-context";

const EventTypeReportFilter = (props: ReportFilterProps) => {
  const [errors, setErrors] = useState({});
  const {eventTypes} = useContext(AppContext);

  return (
    <SelectSingleField
      options={eventTypes.map((d: DirectoryType) => ({
        key: d.id_dir,
        value: d.id_dir,
        text: d.name_dir,
      }))}
      setValue={props.setValue}
      value={props.value ?? ""}
      name="id_type_property"
      label="Тип события"
      errors={errors}
      setErrors={setErrors}
      style={{marginBottom: theme.spacing(2)}}
    />
  );
};

export default EventTypeReportFilter;
