import React, {useState} from "react";
import getTaskDevices, {
  taskDeviceFilterType,
  taskDeviceType,
} from "../../common/APIRequests/task/getTaskDevices";
import {taskType} from "../../common/APIRequests/task/getTasks";
import DeviceDialog from "../Devices/Dialogs/DeviceDialog";
import {taskDeviceColumns} from "./taskDeviceColumns";
import TaskDeviceFilter from "./TaskDeviceFilter";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import {useTrigger} from "../../common/customHooks";

export const DEFAULT_TASK_DEVICE_FILTER: taskDeviceFilterType = {
  id_app_version: null,
  app_status: null,
  serial_number: null,
  code_division: null,
};

const TaskDeviceTable = (props: {task: taskType}) => {
  const {task} = props;

  const [rowClicked, setRowClicked] = useState(
    undefined as taskDeviceType | undefined
  );

  const [refreshTrigger, refresh] = useTrigger();

  const updateTaskDevice: UpdateRowsType<
    taskDeviceType,
    taskDeviceFilterType
  > = ({sorting, paging, filters}) =>
    getTaskDevices(
      task.id_task as number,
      paging?.page,
      paging?.page_size,
      sorting?.sort_field,
      sorting?.sort_desc,
      filters || DEFAULT_TASK_DEVICE_FILTER
    );

  return (
    <>
      {rowClicked && (
        <DeviceDialog
          onClose={() => setRowClicked(undefined)}
          id_device={rowClicked.id_device}
          updateTable={refresh}
        />
      )}
      <CoreTable
        ToolBarFilterComponent={TaskDeviceFilter}
        name={"task_devices"}
        columns={taskDeviceColumns}
        pagingEnabled
        sortingEnabled
        updateRows={updateTaskDevice}
        updateTrigger={refreshTrigger}
        defaultSorting={{columnName: "id_task_device", direction: "asc"}}
        getRowId={(row: taskDeviceType) => row.id_task_device || ""}
        height={"60vh"}
        onRowClick={setRowClicked}
        noDataMessage={
          task.status === "0"
            ? "Формируется список устройств задания"
            : undefined
        }
      />
    </>
  );
};

export default TaskDeviceTable;
