import {getToken} from "../../utils/apiClient";
import {basePatchRequest} from "../base/basePatchRequest";
import {policyType} from "./getPolicies";

const patchPolicy = async (id_pol: number, policy: policyType) =>
  basePatchRequest<policyType>(`/policy/${id_pol}/`, {
    ...policy,
    token: getToken(),
  });

export default patchPolicy;
