import {useGlobalStyles} from "../../common/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

export type AppVersionViewType = {
  name: string;
  description: string;
  file_path: string;
};

const SelectedApplicationView = (props: {app_version: AppVersionViewType}) => {
  const classes = useGlobalStyles();

  return (
    <Accordion expanded>
      <AccordionSummary
        aria-controls="app-version"
        id="app-version"
        classes={{
          root: classes.panelSummary,
          content: classes.panelSummaryContent,
        }}
      >
        <Typography>Выбрано приложение</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetail}>
        <Typography>
          <span>
            <b>Описание:</b> {props.app_version.description}
          </span>
          <br />
          <span>
            <b>Версия:</b> {props.app_version.name}
          </span>
          <br />
          <span>
            <b>Путь до apk:</b> {props.app_version.file_path}
          </span>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default SelectedApplicationView;
