import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type UserReportFilterType = {
  id_type_filter: number;
  name: string;
  system_name: string;
  value_type: "string" | "boolean" | "int" | "date";
  interval_type: number | null;
};

const getReportFilterTypes = async (filters: {report_type: number | null}) =>
  baseGetRowsRequest<UserReportFilterType>("/report_filter_type/", {filters});

export default getReportFilterTypes;
