import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";

export type NotificationMessageStatusType = 0 | 1 | 2 | 3;

export type NotificationMessageType = {
  id_queue: number;
  channel_name: string;
  notify_name: string;
  is_active: number;
  event_date: string;
  status: NotificationMessageStatusType;
  status_message: string;
  recipient: string;
};

export type NotificationMessageFilterType = {
  id_notify: number;
  status: NotificationMessageStatusType | null;
};

const getNotificationMessages = async (
  page?: number,
  page_size?: number,
  sort_field?: string,
  sort_desc?: boolean,
  filters?: NotificationMessageFilterType
) =>
  baseGetRowsRequestWithCount<NotificationMessageType>(`/notify_message/`, {
    page,
    page_size,
    sort_field,
    sort_desc,
    filters,
  });

export default getNotificationMessages;
