import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {Typography} from "@mui/material";
import moment from "moment";
import React, {useEffect} from "react";
import {EventType} from "../../common/APIRequests/event/getEvents";
import {
  logEventFilterType,
  logEventType,
} from "../../common/APIRequests/logEvent/getLogEvents";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
  useMounted,
} from "../../common/customHooks";
import {
  columnExtensionsType,
  columnType,
  FRONT_DATETIME_FORMAT,
  optionType,
  sortingType,
} from "../../common/types";
import {RefreshButton} from "../common/buttons/ToolbarButtons";
import {StyledVirtualTable} from "../common/grid/cell";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import {paginationMessages} from "../common/pagination";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import GroupSwitch from "./GroupSwitch";
import LogEventFilter from "./LogEventFilter";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import DownloadEventLogsFileButton from "./DownloadEventLogsFileButton";
import {ColumnPropsForDownloadType} from "../common/buttons/DownloadMenuButton";
import {LoadingIndicator} from "../common/components";

const CheckFormatter = (data: DataTypeProvider.ValueFormatterProps) =>
  data.value ? (
    <span style={{color: "green"}}>
      <Typography variant="body2" gutterBottom>
        Да
      </Typography>
    </span>
  ) : (
    <span style={{color: "red"}}>
      <Typography variant="body2" gutterBottom color="inherit">
        Нет
      </Typography>
    </span>
  );

const columns: columnType = [
  {name: "id_log_event", title: "ID"},
  {name: "type_user_name", title: "Классификатор"},
  {name: "type_property_name", title: "Тип"},
  {name: "type_level_name", title: "Хранение"},
  {name: "name_app", title: "Имя приложения"},
  {name: "name_object", title: "Имя объекта"},
  {name: "app_version_name", title: "Версия"},
  {name: "app_version_code", title: "Код версии"},
  {
    name: "event_time",
    title: "Время возникновения",
    getCellValue: (row: logEventType) =>
      row.event_time
        ? moment(row.event_time).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
  {
    name: "input_date",
    title: "Время поступления",
    getCellValue: (row: logEventType) =>
      row.input_date
        ? moment(row.input_date).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
  {name: "device_code_division", title: "Код подразделения"},
  {name: "device_user_ldap_login", title: "Пользователь"},
  {name: "device_dev_version_code", title: "Версия Android"},
  {name: "device_serial_number", title: "Сер. номер"},
  {name: "event_sent", title: "Обработано"},
  {name: "text_message", title: "Сообщение"},
];
const columnExtensions: columnExtensionsType = [
  {columnName: "id_log_event", width: 130},
  {columnName: "type_user_name", width: 300},
  {columnName: "type_property_name", width: 150},
  {columnName: "type_level_name", width: 160},
  {columnName: "app_version_name", width: 130},
  {columnName: "app_version_code", width: 130},
  {columnName: "name_app", width: 160},
  {columnName: "name_object", width: 215},
  {columnName: "event_time", width: 200},
  {columnName: "input_date", width: 200},
  {columnName: "device_code_division", width: 215},
  {columnName: "device_user_ldap_login", width: 215},
  {columnName: "device_dev_version_code", width: 215},
  {columnName: "device_serial_number", width: 200},
  {columnName: "device_name", width: 240},
  {columnName: "event_sent", width: 130},
  {columnName: "text_message", width: 600},
];

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  rows: Array<logEventType>;
  update: () => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  pageSize: number;
  sorting: sortingType;
  setSorting: Function;
  totalCount: number;
  filters: logEventFilterType;
  setFilters: Function;
  events: Array<EventType>;
  userDirsOptions: Array<optionType>;
  propertyDirsOptions: Array<optionType>;
  levelDirsOptions: Array<optionType>;
  isLogEventGrouped: boolean;
  setIsLogEventGrouped: (value: boolean) => void;
};

const LogEventTab = (props: Props) => {
  const {
    loading,
    setLoading,
    rows,
    update,
    currentPage,
    setCurrentPage,
    pageSize,
    sorting,
    setSorting,
    totalCount,
    filters,
    setFilters,
    events,
    userDirsOptions,
    propertyDirsOptions,
    levelDirsOptions,
    isLogEventGrouped,
    setIsLogEventGrouped,
  } = props;

  const isMounted = useMounted();
  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("log_event_columns", [
    "id_log_event",
    "type_property_name",
    "type_level_name",
  ]);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "log_event_ordering",
    columns.map((row) => row.name)
  );

  useEffect(() => {
    if (isMounted) {
      setCurrentPage(0);

      // if current page already 0, update rows
      currentPage === 0 && update();
    }
  }, [pageSize]);

  useEffect(() => {
    update();
  }, [currentPage, sorting]);

  const classes = useGlobalStyles();

  const eventName =
    filters.ids_event.length && events.length
      ? (() => {
          const event = events.filter(
            (e: EventType) => e.id_event === filters.ids_event[0]
          )[0];
          if (event) {
            return `${event.event_name ? event.event_name : ""}`;
          } else {
            return "";
          }
        })()
      : "Все";

  const getFieldPropsForDownload = (): ColumnPropsForDownloadType => {
    const visibleColumns = columns.filter(
      (c) => hiddenColumnNames.indexOf(c.name) == -1
    );

    const visibleColumnsExtensions = columnExtensions.filter(
      (c) => hiddenColumnNames.indexOf(c.columnName) == -1
    );

    return visibleColumns.map((c, index) => ({
      name: c.name,
      title: c.title,
      width: visibleColumnsExtensions[index].width / 10,
    }));
  };

  return (
    <Grid columns={columns} rows={rows} getRowId={(row) => row.id_log_event}>
      <StyledVirtualTable height="74vh" />

      {/** Toolbar */}
      <Toolbar rootComponent={ToolbarRoot} />
      <Template name="toolbarContent">
        <ToolbarItem>
          <LogEventFilter
            isLogEventGrouped={isLogEventGrouped}
            setFilters={setFilters}
            filters={filters}
            loading={loading}
            events={events}
            userDirsOptions={userDirsOptions}
            propertyDirsOptions={propertyDirsOptions}
            levelDirsOptions={levelDirsOptions}
            rows={rows}
          />
        </ToolbarItem>
        <ToolbarItem>
          <GroupSwitch
            checked={isLogEventGrouped}
            onChange={setIsLogEventGrouped}
          />
        </ToolbarItem>
        <ToolbarItem>
          <RefreshButton disabled={loading} update={update} />
        </ToolbarItem>
        <ToolbarItem>
          <DownloadEventLogsFileButton
            filters={filters}
            sorting={sorting}
            eventName={eventName}
            setLoading={setLoading}
            columnPropsForDownload={getFieldPropsForDownload()}
          />
        </ToolbarItem>
        <TemplatePlaceholder />
      </Template>

      {/** Sorting */}
      <SortingState
        sorting={sorting}
        onSortingChange={(data: sortingType) => setSorting(data)}
      />

      <StyledVirtualTable pagination={true} />

      {/** Column Reordering */}
      <DragDropProvider />
      <TableColumnReordering
        order={columnOrder}
        onOrderChange={setColumnOrder}
      />

      {/** Column Visibility*/}
      <TableColumnVisibility
        hiddenColumnNames={hiddenColumnNames}
        onHiddenColumnNamesChange={setHiddenColumnNames}
      />
      <StyledColumnChooser />

      {/** Column Resizing */}
      <ColumnResizingHeader
        resizingMode="widget"
        columnExtensions={columnExtensions}
        localStorageName="log_event"
        autoWidthColumnName={"device_serial_number"}
      />

      {/** Pagination */}
      <PagingState
        currentPage={currentPage}
        onCurrentPageChange={setCurrentPage}
        pageSize={pageSize}
      />
      <CustomPaging totalCount={totalCount} />
      <PagingPanel messages={paginationMessages} />

      <DataTypeProvider
        for={["event_sent"]}
        formatterComponent={CheckFormatter}
      />

      {loading && <LoadingIndicator />}
    </Grid>
  );
};

export default LogEventTab;
