import * as React from "react";
import {useState} from "react";
import {ReportFilterProps} from "../eventFilters/EventIdReportFilter";
import SelectGeographyForm, {
  GeosType,
} from "../../../../../TaskTemplates/Forms/SelectGeographyForm";
import {convertGeoToArr} from "../../../../../TaskTemplates/Forms/TaskTemplateForm";

const GeoReportFilter = (props: ReportFilterProps) => {
  const [geos, setGeos] = useState<GeosType>({} as GeosType);

  return (
    <SelectGeographyForm
      geos={geos}
      setGeos={(geos) => {
        setGeos(geos);
        const geoIds = convertGeoToArr(geos);
        const strGeoIds = geoIds.join(", ");
        props.setValue(strGeoIds);
      }}
    />
  );
};

export default GeoReportFilter;
