import {GetCellValueFn} from "@devexpress/dx-react-grid";
import {CSSProperties, RefObject} from "react";

// For formatting with moment only
export const BACKEND_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
export const FRONT_DATETIME_FORMAT = "DD.MM.YYYY HH:mm:ss";

export type columnType = Array<{
  name: string;
  title: string;
  width?: string;
  getCellValue?: GetCellValueFn;
  sortingEnabled?: boolean;
}>;

export type columnExtensionsType = Array<{
  columnName: string;
  width: number;
  minWidth?: number;
}>;

export type errorType = {[s: string]: Array<string>};

export type sortingType = Array<{
  columnName: string;
  direction: "asc" | "desc";
}>;

export type optionType = {
  key?: number | string;
  value: number | string;
  text: string;
};

export type alertType = {
  severity: "error" | "warning" | "info" | "success";
  message: string[];
};

export type fieldProps = {
  setValue: Function;
  value: string | undefined;
  name: string;
  label: string;
  errors: errorType;
  setErrors?: (errors: errorType) => void;
  sanitizer?: (source: string) => string;
  chipAddValidator?: "email" | "sms";
  inputProps?: {
    maxLength?: number;
  };
  multiline?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  helperText?: string;
  inputRef?: RefObject<HTMLFormElement> | null;
  ref?: RefObject<HTMLFormElement> | null;
  autoFocus?: boolean;
  type?: string;
};
