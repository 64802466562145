import {useState} from "react";
import {Dialog, Slider} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {Transition} from "../../common/components";
import moment from "moment";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import FormButtons from "../../common/buttons/FormButtons";
import {dataType} from "./MenuCommandsAction";

const CommandsLogForm = (props: {
  loading: boolean;
  sendCommand: (data?: dataType) => void;
  onClose: () => void;
}) => {
  const {loading, sendCommand, onClose} = props;

  const [markIndex, setMarkIndex] = useState(0);

  const handleSubmit = () => {
    const deltaSeconds = marks[markIndex] * 60;
    const startTime = moment(Date.now());
    const endTime = moment(Date.now()).add(deltaSeconds, "seconds");

    // data is duplicated for backward compatibility
    const data = JSON.stringify({
      // not updated devices use startTime and endTime
      startTime: startTime.format("X"),
      endTime: endTime.format("X"),
      // updated devices use interval parameter
      interval: deltaSeconds.toString(),
    });

    sendCommand({additionalData: data});
  };

  const intervalToLabel = (mins: number) =>
    mins / 60 >= 1 ? `${Math.round(mins / 60)}ч` : `${mins}мин`;

  // prettier-ignore
  const marks = [5, 10, 15, 30, 60, 2 * 60, 4 * 60, 6 * 60, 10 * 60, 16 * 60, 24 * 60];

  return (
    <Dialog
      open={true}
      maxWidth={"md"}
      fullWidth
      onClose={() => onClose()}
      keepMounted
      TransitionComponent={Transition}
    >
      <ClosableDialogTitle onClose={() => onClose()}>
        Запрос adb-лога
      </ClosableDialogTitle>
      <DialogContent style={{marginLeft: 10, marginRight: 10}}>
        <p>{`Собирать логи в течение ${intervalToLabel(marks[markIndex])}`}</p>
        <Slider
          min={0}
          max={marks.length - 1}
          marks={marks.map((minutes, index) => ({
            value: index,
            label: intervalToLabel(minutes),
          }))}
          onChange={(event, value) => setMarkIndex(value as number)}
          value={markIndex}
          scale={(value) => marks[value]}
          step={1}
        />
        <FormButtons
          loading={loading}
          handleSubmit={handleSubmit}
          handleClose={() => onClose()}
          submitMessage="Запросить"
        />
      </DialogContent>
    </Dialog>
  );
};

export default CommandsLogForm;
