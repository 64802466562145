import {FormControlLabel, Switch} from "@mui/material";
import React from "react";

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
};

const GroupSwitch = (props: Props) => {
  const {checked, onChange} = props;
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          name="isLogEventGrouped"
          size="medium"
        />
      }
      label="Группировать"
    />
  );
};

export default GroupSwitch;
