import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  CustomPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {Link, Tooltip} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import getBranch, {
  geographyType,
} from "../../common/APIRequests/geography/getBranch";
import getGeo from "../../common/APIRequests/geography/getGeo";
import {
  useEffectOnlyOnUpdate,
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {columnType, errorType, sortingType} from "../../common/types";
import {AppContext} from "../App-context";
import {RefreshButton} from "../common/buttons/ToolbarButtons";
import {StyledVirtualTable} from "../common/grid/cell";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import {paginationMessages} from "../common/pagination";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import GeographyFilter from "./GeographyFilter";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import {calcPageSize} from "../Tables/helpers";
import {LoadingIndicator} from "../common/components";

export type geoFilterType = {
  idLevelOne: string | null;
  idLevelTwo: string | null;
  searchValue: string;
};

const columns: columnType = [
  {name: "id_geo", title: "ID"},
  {name: "name_geo", title: "Наименование"},
  {name: "sum_device", title: "Кол-во устройств"},
  {name: "code_geo", title: "Код подразделения"},
  {name: "net", title: "Сеть"},
  {name: "branch", title: "Филиал"},
  {
    name: "devices",
    title: "Список устройств",
    getCellValue: (row: geographyType) => (
      <Tooltip
        title={row.sum_device ? "Перейти к списку устройств" : "Нет устройств"}
        aria-label={row.code_geo}
      >
        {row.sum_device ? (
          <Link
            href={row.sum_device ? `/devices/${row.code_geo}/` : "#"}
            underline="none"
          >
            Перейти к устройствам
          </Link>
        ) : (
          <span>Нет устройств</span>
        )}
      </Tooltip>
    ),
  },
];
const columnExtensions = [
  {columnName: "id_geo", width: 100},
  {columnName: "name_geo", width: 300},
  {columnName: "sum_device", width: 150},
  {columnName: "code_geo", width: 150},
  {columnName: "net", width: 150},
  {columnName: "branch", width: 200},
  {columnName: "devices", width: 200},
];
const sortingStateColumnExtensions = [
  {columnName: "devices", sortingEnabled: false},
];

const Geography = () => {
  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as errorType);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("geography_columns", []);
  const [sorting, setSorting] = useLocalStorage<sortingType>("geo_sorting", [
    {columnName: "name_geo", direction: "asc"},
  ]);

  const pageSize = calcPageSize();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [filters, setFilters] = useState<geoFilterType>({
    idLevelOne: "",
    idLevelTwo: "",
    searchValue: "",
  });

  const [levelOneList, setLevelOneList] = useState([] as geographyType[]);
  useEffect(() => {
    (async () => {
      setErrors({});
      const result = await getBranch();
      if (cancelled) {
        return;
      }

      result.success && setLevelOneList(result.rows);
    })();
  }, []);

  const [levelTwoList, setLevelTwoList] = useState([] as geographyType[]);
  useEffect(() => {
    (async () => {
      if (filters.idLevelOne) {
        setErrors({});
        setRows([]);
        setCurrentPage(0);
        setTotalCount(0);
        setLevelTwoList([]);

        setLoading(true);
        const result = await getBranch(filters.idLevelOne);
        if (cancelled) {
          return;
        }
        setLoading(false);

        result.success
          ? setLevelTwoList(result.rows)
          : setErrors(result.errors);
      }
    })();
  }, [filters.idLevelOne]);

  const [rows, setRows] = useState([] as geographyType[]);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "geography_ordering",
    columns.map((row) => row.name)
  );

  const updateGeo = () => {
    const {idLevelTwo, searchValue} = filters;
    const {direction, columnName} = sorting[0];

    if (idLevelTwo || searchValue) {
      setLoading(true);
      getGeo(
        idLevelTwo || undefined,
        currentPage + 1,
        pageSize,
        columnName,
        direction == "desc",
        searchValue
      )
        .then((result) => {
          if (cancelled) {
            return;
          }
          if (result.success) {
            setTotalCount(result.count);
            setRows(result.rows);
            result.count === 0 &&
              setAlert({
                severity: "error",
                message: ["Подразделения отсутствуют"],
              });
          } else {
            setErrors(result.errors);
          }
        })
        .finally(() => setLoading(false));
    }
    if (!idLevelTwo && !searchValue) {
      setRows([]);
      setCurrentPage(0);
      setTotalCount(0);
    }
  };

  useEffectOnlyOnUpdate(() => {
    if (currentPage === 0) {
      updateGeo();
    } else {
      // this will automatically call updateGeo()
      setCurrentPage(0);
    }
  }, [filters]);

  useEffect(updateGeo, [pageSize, currentPage, sorting]);

  return (
    <React.Fragment>
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_geo}>
        <StyledVirtualTable
          messages={{
            noData:
              !filters.idLevelOne || !filters.idLevelTwo || loading
                ? "Выберите сеть и филиал"
                : "Нет данных",
          }}
          pagination={true}
        />

        {/* Toolbar */}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          {rows.length > 0 && (
            <ToolbarItem>
              <RefreshButton disabled={loading} update={updateGeo} />
            </ToolbarItem>
          )}
          <TemplatePlaceholder />
          <GeographyFilter
            filters={filters}
            levelOneList={levelOneList}
            levelTwoList={levelTwoList}
            loading={loading}
            errors={errors}
            update={setFilters}
          />
        </Template>

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingStateColumnExtensions}
        />
        <IntegratedSorting />

        {/* Column Visibility */}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/* Column Resizing */}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="geography"
          autoWidthColumnName={"devices"}
        />

        {/* Pagination */}
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
        />
        <CustomPaging totalCount={totalCount} />
        <PagingPanel messages={paginationMessages} />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default Geography;
