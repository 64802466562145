import React from "react";
import {
  ColumnChooser,
  ColumnChooserProps,
} from "@devexpress/dx-react-grid-material-ui";
import {IconButton} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import {ColumnChooser as ColumnChooserBase} from "@devexpress/dx-react-grid";

const ToggleButton = (props: ColumnChooserBase.ToggleButtonProps) => (
  <IconButton
    onClick={props.onToggle}
    ref={(ref) => {
      ref && props.buttonRef(ref);
    }}
    title="Скрыть/показать столбцы"
    size="large"
  >
    <DehazeIcon />
  </IconButton>
);

const StyledColumnChooser = (props: ColumnChooserProps) => {
  return (
    <ColumnChooser
      {...props}
      messages={{
        showColumnChooser: "Скрыть/показать столбцы ",
      }}
      toggleButtonComponent={ToggleButton}
    />
  );
};

export default StyledColumnChooser;
