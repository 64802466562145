import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  IntegratedFiltering,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import MaterialGrid from "@mui/material/Grid";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Map, Marker, Popup, TileLayer} from "react-leaflet";
import getDeviceLocations, {
  deviceLocationType,
} from "../../../common/APIRequests/location/getDeviceLocations";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../../common/customHooks";
import {
  columnType,
  FRONT_DATETIME_FORMAT,
  sortingType,
} from "../../../common/types";
import {RefreshButton} from "../../common/buttons/ToolbarButtons";
import {StyledVirtualTable} from "../../common/grid/cell";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../../common/styles";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../../common/components";

const columns: columnType = [
  {name: "number", title: "№"},
  {name: "id_device", title: "ID устройства"},
  {name: "id_pos", title: "ID местоположения"},
  {
    name: "pos_date",
    title: "Время фиксации",
    getCellValue: (deviceLocation: deviceLocationType) =>
      moment(deviceLocation.pos_date).format(FRONT_DATETIME_FORMAT),
  },
  {name: "width", title: "Широта"},
  {name: "length", title: "Долгота"},
  {name: "information", title: "Информация"},
  {name: "precision", title: "Точность"},
];
const columnExtensions = [
  {columnName: "number", width: 70},
  {columnName: "id_device", width: 130},
  {columnName: "id_pos", width: 130},
  {columnName: "pos_date", width: 170},
  {columnName: "width", width: 100},
  {columnName: "length", width: 100},
  {columnName: "information", width: 200},
  {columnName: "precision", width: 130},
];

const sortingStateColumnExtensions = [
  {columnName: "number", sortingEnabled: false},
];

const hiddenColumnNames = [
  "id_device",
  "id_pos",
  "precision",
  "width",
  "length",
];

const Locations = (props: {
  loading: boolean;
  setLoading: Function;
  id_device: number;
}) => {
  const {loading, setLoading, id_device} = props;

  const [selected, setSelected] = useState(
    undefined as deviceLocationType | undefined
  );

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "device_locations_sorting",
    [{columnName: "pos_date", direction: "desc"}]
  );

  const [rows, setRows] = useState([] as Array<deviceLocationType>);

  useEffect(() => {
    updateRows();
  }, [sorting]);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "location_ordering",
    columns.map((row) => row.name)
  );

  const updateRows = async () => {
    const {columnName, direction} = sorting[0];

    setLoading(true);
    const result = await getDeviceLocations(
      id_device,
      columnName,
      direction == "desc"
    );
    setLoading(false);

    if (result.success) {
      setRows(result.rows);
      setSelected(result.rows[0]);
    }
  };

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      <MaterialGrid container spacing={2}>
        <MaterialGrid item xs={6}>
          {selected && (
            <Map
              center={[selected.width, selected.length]}
              zoom={14}
              style={{height: "100%"}}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='АО "Тандер'
              />
              <Marker position={[selected.width, selected.length]}>
                <Popup>{`${selected.number}. ${moment(selected.pos_date).format(
                  FRONT_DATETIME_FORMAT
                )} ${selected.information}`}</Popup>
              </Marker>
            </Map>
          )}
        </MaterialGrid>
        <MaterialGrid item xs={6}>
          <Grid columns={columns} rows={rows} getRowId={(row) => row.id_pos}>
            {/*Sorting*/}
            <SortingState
              sorting={sorting}
              onSortingChange={setSorting}
              columnExtensions={sortingStateColumnExtensions}
            />

            {/*Toolbar*/}
            <Toolbar rootComponent={ToolbarRoot} />
            <Template name="toolbarContent">
              <ToolbarItem>
                <RefreshButton
                  disabled={loading}
                  update={updateRows}
                  id="locations_refresh"
                />
              </ToolbarItem>
              <TemplatePlaceholder />
            </Template>

            {/*Search*/}
            <SearchState />
            <StyledSearchPanel />
            <IntegratedFiltering />

            <StyledVirtualTable
              rowComponent={({row, ...restProps}: any) => (
                <VirtualTable.Row
                  {...restProps}
                  key={row.id}
                  onClick={() => setSelected(row)}
                  className={
                    selected && row.id_pos === selected.id_pos
                      ? classes.gridRowSelected
                      : classes.gridRow
                  }
                  data-type="locations_row"
                  data-id={row.id_pos}
                />
              )}
              height="68vh"
            />

            {/*Column Reordering*/}
            <DragDropProvider />
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={setColumnOrder}
            />

            {/*Column Visibility*/}
            <TableColumnVisibility
              defaultHiddenColumnNames={hiddenColumnNames}
            />
            <StyledColumnChooser />

            {/*Column Resizing*/}
            <ColumnResizingHeader
              resizingMode="widget"
              columnExtensions={columnExtensions}
              localStorageName="device_location"
              autoWidthColumnName={"information"}
            />

            {loading && <LoadingIndicator />}
          </Grid>
        </MaterialGrid>
      </MaterialGrid>
    </React.Fragment>
  );
};

export default Locations;
