import {Card, CircularProgressProps, Slide, Typography} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import React, {CSSProperties} from "react";
import {TransitionProps} from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";

export const tabProps = (index: string) => ({
  value: index,
  id: `wrapped-tab-${index}`,
  "aria-controls": `wrapped-tabpanel=${index}`,
});

export const TabPanel = (props: any) => {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children: React.ReactElement<any, any>},
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

export const StyledCard = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
  },
})(Card);

export const Spacer = (props: {height?: number; width?: number}) => (
  <div
    style={{
      height: props.height || "inherit",
      width: props.width || "inherit",
    }}
  />
);

export const LoadingIndicator = (
  props: CircularProgressProps & {styleProps?: CSSProperties}
) => {
  const {styleProps, ...progressProps} = props;
  return (
    <CircularProgress
      id="loading-indicator"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: 1,
        ...styleProps,
      }}
      {...progressProps}
    />
  );
};

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    left: "50%",
    marginLeft: "-12px",
  },
}));

export const ButtonLoadingIndicator = (
  props: CircularProgressProps & {styleProps?: CSSProperties}
) => {
  const classes = useStyles();
  return (
    <CircularProgress
      id="button-loading-indicator"
      size={props.size ?? 28}
      className={classes.buttonProgress}
    />
  );
};
