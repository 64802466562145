import {Dialog, DialogContent} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {QrCodesTable} from "../QrCodesTable";

const useStyles = makeStyles()((theme) => ({
  scrollPaper: {
    alignItems: "flex-start",
  },
  dialogContent: {
    paddingTop: theme.spacing(0),
  },
}));

type QrCodesDialogProps = {
  onClose: () => void;
};

const QrCodesDialog = (props: QrCodesDialogProps) => {
  const {classes: localClasses} = useStyles();

  return (
    <Dialog
      open={true}
      onClose={() => props.onClose()}
      keepMounted
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
      classes={{
        scrollPaper: localClasses.scrollPaper,
      }}
    >
      <ClosableDialogTitle onClose={() => props.onClose()}>
        Информация о QR-кодах проекта
      </ClosableDialogTitle>
      <DialogContent className={localClasses.dialogContent}>
        <QrCodesTable />
      </DialogContent>
    </Dialog>
  );
};

export default QrCodesDialog;
