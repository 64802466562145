/**
 * @description gets username from localstorage
 * @function
 * @returns {string|undefined} name of user or undefined
 */
export const getUsername: Function = (): string | undefined => {
  const authStorage = localStorage.getItem("auth");

  if (authStorage) {
    const auth = JSON.parse(authStorage);

    if ("login" in auth) {
      return auth.login;
    }
  }
};
