import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type deviceLogFileType = {
  id_device: number;
  id_logfile: number;
  logfile_name: string;
  date_start: string;
  date_end: string;
};

const getDeviceLogFiles = async (
  id_device: number,
  date: string | undefined,
  sort_field: string,
  sort_desc: boolean
) =>
  baseGetRowsRequest<deviceLogFileType>(`/device/${id_device}/logfile/`, {
    id_device,
    date,
    sort_field,
    sort_desc,
  });

export default getDeviceLogFiles;
