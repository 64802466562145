import * as React from "react";
import downloadLogEventCsv from "../../common/APIRequests/logEvent/downloadLogEventCsv";
import {transliterate} from "transliteration";
import {downloadFile} from "../../common/utils/files";
import downloadLogEventXls from "../../common/APIRequests/logEvent/downloadLogEventXls";
import DownloadMenuButton, {
  ColumnPropsForDownloadType,
} from "../common/buttons/DownloadMenuButton";
import {logEventFilterType} from "../../common/APIRequests/logEvent/getLogEvents";
import {sortingType} from "../../common/types";

type DownloadEventLogsFileButtonProps = {
  setLoading: (loading: boolean) => void;
  filters: logEventFilterType;
  sorting: sortingType;
  eventName: string;
  columnPropsForDownload: ColumnPropsForDownloadType;
};

const DownloadEventLogsFileButton = (
  props: DownloadEventLogsFileButtonProps
) => {
  const handleDownLoadCSV = () => {
    props.setLoading(true);
    downloadLogEventCsv(
      transliterate(props.eventName),
      props.sorting[0].columnName,
      props.sorting[0].direction === "desc",
      props.filters,
      props.columnPropsForDownload
    )
      .then((result) => {
        if (result.success) {
          downloadFile(
            result.file,
            `log_event_${transliterate(props.eventName)}.csv`
          );
        }
      })
      .finally(() => props.setLoading(false));
  };

  const handleDownloadXLS = () => {
    props.setLoading(true);
    downloadLogEventXls(
      transliterate(props.eventName),
      props.sorting[0].columnName,
      props.sorting[0].direction === "desc",
      props.filters,
      props.columnPropsForDownload
    )
      .then((result) => {
        if (result.success) {
          downloadFile(
            result.file,
            `log_event_${transliterate(props.eventName)}.xlsx`
          );
        }
      })
      .finally(() => props.setLoading(false));
  };

  return (
    <DownloadMenuButton
      actionOptions={[
        {
          onClick: handleDownLoadCSV,
          label: "CSV",
        },
        {
          onClick: handleDownloadXLS,
          label: "XLS",
        },
      ]}
    />
  );
};

export default DownloadEventLogsFileButton;
