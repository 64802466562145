import {List, Menu} from "@mui/material";
import theme from "../../../theme";
import {LinkInfoType} from "./LeftPanelGroup";
import {LeftPanelListItem, leftPaneStyleParams} from "./LeftPanelItems";

type Props = {
  links: LinkInfoType[];
  anchor: HTMLElement;
  onClose: () => void;
};
export const LeftPanelSubMenu = (props: Props) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={props.anchor}
      keepMounted
      open={true}
      style={{
        marginLeft: leftPaneStyleParams.panelWrapped.width - 16,
      }}
      onClose={props.onClose}
    >
      <List disablePadding>
        {props.links.map((link) => (
          <LeftPanelListItem {...link} key={link.name} />
        ))}
      </List>
    </Menu>
  );
};
