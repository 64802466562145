import React, {useContext, useState} from "react";
import {AppContext} from "../../App-context";
import {ConfirmDialog} from "./ConfirmDialog";
import {errorType} from "../../../common/types";
import {SecondaryButton} from "./SecondaryButton";

type deleteFunctionType = (
  ids: Array<number | string>
) => Promise<{success: boolean; errors: errorType}>;

type Props = {
  id?: string;
  selection: Array<number | string>;
  update: () => void;
  action?: deleteFunctionType;
  checkPin?: boolean;
};

const MenuDeleteAction = (props: Props) => {
  const {selection, update, action, id, checkPin} = props;

  const context = useContext(AppContext);
  const {setAlert} = context;

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (!selection || !action) {
      return;
    }

    setLoading(true);
    const result = await action(selection);
    setLoading(false);

    if (result.success) {
      setDeleteOpen(false);
      setAlert({
        severity: "success",
        message: [selection.length > 1 ? "Записи удалены" : "Запись удалена"],
      });
      update();
    }
    setDeleteOpen(false);
  };

  return (
    <React.Fragment>
      <SecondaryButton
        title={"Удалить"}
        onClick={() => setDeleteOpen(true)}
        disabled={!selection.length}
        id={id}
      />
      {deleteOpen && (
        <ConfirmDialog
          loading={loading}
          handleClose={() => setDeleteOpen(false)}
          handleConfirm={handleDelete}
          checkPin={checkPin}
          message={
            selection.length > 1
              ? "Удалить выбранные записи"
              : "Удалить выбранную запись"
          }
        />
      )}
    </React.Fragment>
  );
};

export default MenuDeleteAction;
