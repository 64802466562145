import {Dialog, DialogContent} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import QRCode from "react-qr-code";
import {QrCodeType} from "../../../common/APIRequests/qrCode/getQrCodes";

const useStyles = makeStyles()((theme) => ({
  scrollPaper: {
    alignItems: "center",
    textAlign: "center",
  },
  dialogContent: {
    paddingTop: theme.spacing(0),
  },
}));

type QrCodeImageDialogProps = {
  onClose: () => void;
  data: QrCodeType;
};

const QrCodeImageDialog = (props: QrCodeImageDialogProps) => {
  const {classes: localClasses} = useStyles();

  return (
    <Dialog
      open={true}
      onClose={() => props.onClose()}
      keepMounted
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition}
      classes={{
        scrollPaper: localClasses.scrollPaper,
      }}
    >
      <ClosableDialogTitle onClose={() => props.onClose()} />
      <DialogContent className={localClasses.dialogContent}>
        <QRCode
          value={JSON.stringify({
            login: props.data.login,
            server: props.data.server,
            token: props.data.token,
          })}
        />
      </DialogContent>
    </Dialog>
  );
};

export default QrCodeImageDialog;
