import {DataTypeProvider} from "@devexpress/dx-react-grid";
import {Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, {useContext} from "react";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {UserReportFilter} from "../../../common/APIRequests/reportFilterUser/getUserReportFilters";
import {AppContext} from "../../App-context";
import {smashTree} from "../functions";
import moment from "moment";
import {
  BACKEND_DATETIME_FORMAT,
  FRONT_DATETIME_FORMAT,
} from "../../../common/types";

export type CustomDataTypeProviderProps = {
  for: Array<string>;
};

export const DeviceStatusFormatter = (
  data: DataTypeProvider.ValueFormatterProps
) => {
  const colors: {[s: string]: string} = {
    unenrolled: "darkgray",
    ok: "green",
    initializing: "#E8C547", //yellow
    discredit: "red",
  };
  const titles: {[s: string]: string} = {
    unenrolled: "Устройство выведено из Mmobile пользователем",
    ok: "Устройство введено и получило все политики",
    initializing: "Устройство на стадии инициализации",
    discredit: "Устройство выведено из Mmobile из-за компрометации",
  };
  return (
    <Tooltip title={titles[data.value]}>
      <div
        className="status-circle"
        style={{
          backgroundColor: colors[data.value],
        }}
      />
    </Tooltip>
  );
};

export const DeviceStatusProvider = (props: CustomDataTypeProviderProps) => {
  return (
    <DataTypeProvider formatterComponent={DeviceStatusFormatter} {...props} />
  );
};

export const deviceAppStatusTitles: {[s: string]: string} = {
  1: "Установлено",
  2: "Отправлена команда на установку",
  3: "Ошибка загрузки файла",
  4: "Установка приложения",
  5: "Отправлена команда на удаление",
  6: "В очереди",
  7: "Исключено",
  0: "Возникла проблема при установке",
};

export const DeviceAppStatusFormatter = (
  data: DataTypeProvider.ValueFormatterProps
) => {
  const colors: {[s: string]: string} = {
    1: "green",
    2: "steelblue",
    3: "lightcoral",
    4: "aqua",
    5: "black",
    0: "red",
  };

  return (
    <Tooltip title={deviceAppStatusTitles[data.value]}>
      <div
        className="status-circle"
        style={{
          backgroundColor: colors[data.value],
        }}
      />
    </Tooltip>
  );
};

export const DeviceAppStatusProvider = (props: CustomDataTypeProviderProps) => {
  return (
    <DataTypeProvider
      formatterComponent={DeviceAppStatusFormatter}
      {...props}
    />
  );
};

const BooleanFormatter = (data: DataTypeProvider.ValueFormatterProps) => {
  let result = <React.Fragment />;
  +data.value === 1 &&
    (result = (
      <CheckIcon
        style={{
          color: "green",
          fontSize: "1em",
          alignItems: "self-center",
        }}
      />
    ));
  return result;
};

export const BooleanTypeProvider = (props: CustomDataTypeProviderProps) => {
  return <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />;
};

const UserReportFilterTypeFormatter = (
  data: DataTypeProvider.ValueFormatterProps
) => {
  const reportFilter: UserReportFilter = data.row;
  if (reportFilter.system_name == "id_project") {
    return "Объект";
  } else if (reportFilter.system_name == "id_event") {
    return "Объект";
  } else if (data.value == "int") {
    return "Число";
  } else if (data.value == "string") {
    return "Строка";
  } else if (data.value == "boolean") {
    return "Булевое";
  } else if (data.value == "date") {
    return "Дата";
  } else return data.value;
};

export const UserReportFilterTypeProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={UserReportFilterTypeFormatter}
    {...props}
  />
);

const UserReportFilterValueFormatter = (
  data: DataTypeProvider.ValueFormatterProps
) => {
  const {projects, events, directories, eventTypes, apps} =
    useContext(AppContext);

  const reportFilter: UserReportFilter = data.row;
  switch (reportFilter.system_name) {
    case "id_project": {
      const smashedProjects = [] as Array<projectType>;
      projects.forEach((p: projectType) => {
        smashTree(p, smashedProjects);
      });
      const result = smashedProjects.find(
        (p: projectType) =>
          p.id_project ===
          (reportFilter.value !== null ? parseInt(reportFilter.value) : 0)
      );
      return result?.project_name ?? reportFilter.value;
    }
    case "id_event":
      return (
        events.find(
          (e) =>
            e.id_event ===
            (reportFilter.value !== null ? parseInt(reportFilter.value) : "")
        )?.event_name ?? reportFilter.value
      );
    case "id_dir":
      return (
        directories.find(
          (d) =>
            d.id_dir ===
            (reportFilter.value !== null ? parseInt(reportFilter.value) : "")
        )?.name_dir ?? reportFilter.value
      );
    case "id_type_dir":
      return (
        eventTypes.find(
          (d) =>
            d.id_dir ===
            (reportFilter.value !== null ? parseInt(reportFilter.value) : "")
        )?.name_dir ?? reportFilter.value
      );
    case "id_app":
      return (
        apps.find(
          (app) =>
            app.id_app ===
            (reportFilter.value !== null ? parseInt(reportFilter.value) : "")
        )?.name ?? reportFilter.value
      );
  }

  switch (reportFilter.value_type) {
    case "boolean":
      return reportFilter.value === "1" ? "Да" : "Нет";
    case "date":
      return moment(reportFilter.value, BACKEND_DATETIME_FORMAT).format(
        FRONT_DATETIME_FORMAT
      );
    default:
      return reportFilter.value ?? "";
  }
};

export const UserReportFilterValueProvider = (
  props: CustomDataTypeProviderProps
) => (
  <DataTypeProvider
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    formatterComponent={UserReportFilterValueFormatter}
    {...props}
  />
);
