import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, {useEffect, useRef, useState} from "react";
import {geographyType} from "../../common/APIRequests/geography/getBranch";
import {errorType} from "../../common/types";
import {ToolbarItem} from "../common/Toolbar";
import {geoFilterType} from "./Geography";
import StringFilterField from "../common/filterFields/StringFilterField";

const GeographyFilter = (props: {
  filters: geoFilterType;
  levelOneList: Array<geographyType>;
  levelTwoList: Array<geographyType>;
  loading: boolean;
  update: (filters: geoFilterType) => void;
  errors: errorType;
}) => {
  const {filters, update, loading, levelOneList, levelTwoList, errors} = props;

  const searchValueRef = useRef(null as null | HTMLFormElement);
  const [searchValue, setSearchValue] = useState<string>(filters.searchValue);

  const [filterFocus, setFilterFocus] = useState("" as "searchValue" | "");

  useEffect(() => {
    if (filterFocus === "searchValue") searchValueRef.current?.focus();
    else setFilterFocus("");
  }, [!loading]);

  const handleUpdateSearch = (value: string) => {
    update({...filters, searchValue: value.trim()});
    setFilterFocus("searchValue");
  };

  return (
    <React.Fragment>
      {levelOneList.length > 0 && (
        <React.Fragment>
          <ToolbarItem>
            <FormControl
              error={errors.idLevelOne && true}
              disabled={loading}
              style={{width: "200px"}}
            >
              <InputLabel htmlFor="idLevelOne">Сеть</InputLabel>
              <Select
                id="idLevelOne"
                name="idLevelOne"
                value={(levelOneList.length && filters.idLevelOne) || ""}
                onChange={(e) =>
                  update({
                    ...filters,
                    idLevelOne: e.target.value as string,
                    idLevelTwo: "",
                  })
                }
                disabled={loading}
                endAdornment={
                  <IconButton
                    onClick={() => {
                      update({
                        ...filters,
                        idLevelOne: "",
                        idLevelTwo: "",
                      });
                    }}
                    size="small"
                    disabled={loading || !filters.idLevelOne}
                    style={{marginRight: "24px"}}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                onFocus={() => setFilterFocus("")}
              >
                {levelOneList.map((geo: geographyType) => (
                  <MenuItem key={geo.id_geo} value={geo.id_geo}>
                    {geo.name_geo}
                  </MenuItem>
                ))}
              </Select>
              {errors.idLevelOne && (
                <FormHelperText id="component-error-idLevelOne">
                  {errors.idLevelOne}
                </FormHelperText>
              )}
            </FormControl>
          </ToolbarItem>
          <ToolbarItem>
            <FormControl
              error={errors.idLevelTwo && true}
              disabled={loading}
              style={{width: "200px"}}
            >
              <InputLabel htmlFor="idLevelTwo">Филиал</InputLabel>
              <Select
                id="idLevelTwo"
                name="idLevelTwo"
                value={(levelTwoList.length && filters.idLevelTwo) || ""}
                onChange={(e) =>
                  update({...filters, idLevelTwo: e.target.value as string})
                }
                disabled={!filters.idLevelOne || loading}
                endAdornment={
                  <IconButton
                    onClick={() => {
                      update({
                        ...filters,
                        idLevelTwo: "",
                      });
                    }}
                    size="small"
                    disabled={loading || !filters.idLevelTwo}
                    style={{marginRight: "24px"}}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                onFocus={() => setFilterFocus("")}
              >
                {levelTwoList.map((geo: geographyType) => (
                  <MenuItem key={geo.id_geo} value={geo.id_geo}>
                    {geo.name_geo}
                  </MenuItem>
                ))}
              </Select>
              {errors.idLevelTwo && (
                <FormHelperText id="component-error-idLevelTwo">
                  {errors.idLevelTwo}
                </FormHelperText>
              )}
            </FormControl>
          </ToolbarItem>
          <ToolbarItem>
            <FormControl fullWidth={true}>
              <StringFilterField
                name="searchValue"
                label="Наименование"
                value={searchValue}
                onChange={setSearchValue}
                onEnterPress={handleUpdateSearch}
                disabled={
                  !(filters.idLevelOne && filters.idLevelTwo) &&
                  Boolean(loading)
                }
                inputRef={searchValueRef}
                onResetClick={() => {
                  setSearchValue("");
                  setFilterFocus("");
                  update({...filters, searchValue: ""});
                }}
              />
            </FormControl>
          </ToolbarItem>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default GeographyFilter;
