import * as React from "react";
import {useState} from "react";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {Dialog, DialogContent} from "@mui/material";
import {geographyType} from "../../../common/APIRequests/geography/getBranch";
import Column from "../../Tables/Column";
import ChipMultiSelectField from "./ChipMultiSelectField";
import {AddMoreButton} from "./buttons";
import theme from "../../../theme";
import getGeo from "../../../common/APIRequests/geography/getGeo";
import {geoType} from "../../../common/APIRequests/taskTemplate/getTemplate";
import SearchFilter, {SearchFilterType} from "./SearchFilter";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import FormButtons from "../../common/buttons/FormButtons";

const GeoColumns: Array<Column> = [
  new Column("id_geo", "ID", 100),
  new Column("name_geo", "Наименование", 200, {auto: true}),
  new Column("sum_device", "Количество устройств", 200),
  new Column("code_geo", "Код", 200),
];

type SelectBranchesDialogProps = {
  id_higher_geo: string;
  selected: geoType[];
  fieldLabel: string;
  setSelected: (values: geoType[]) => void;
  tableIdentifier: string;
  dialogTitle: string;
  disabled?: boolean;
};
const SelectSubLevelGeoField = (props: SelectBranchesDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const updateRows: UpdateRowsType<geographyType, SearchFilterType> = ({
    sorting,
    paging,
    filters,
  }) =>
    getGeo(
      props.id_higher_geo,
      paging?.page,
      paging?.page_size,
      sorting?.sort_field,
      sorting?.sort_desc,
      filters?.search
    );

  const onSubmit = (rows: geographyType[]) => {
    props.setSelected(rows);
    onClose();
  };
  const onClose = () => setDialogOpen(false);

  return (
    <React.Fragment>
      {dialogOpen && (
        <Dialog
          open={true}
          onClose={onClose}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="lg"
        >
          <ClosableDialogTitle id="add-project-modal" onClose={onClose}>
            {props.dialogTitle}
          </ClosableDialogTitle>
          <DialogContent>
            <CoreTable
              name={props.tableIdentifier}
              columns={GeoColumns}
              updateRows={updateRows}
              getRowId={(row: geographyType) => row.id_geo || ""}
              pagingEnabled
              sortingEnabled
              selectionEnabled
              defaultSorting={{columnName: "name_geo", direction: "asc"}}
              defaultSelection={props.selected}
              selectRowsMode
              ToolBarFilterComponent={SearchFilter}
              renderWithState={(state) => (
                <FormButtons
                  loading={state.loading}
                  disabled={state.selectedRows.length === 0}
                  handleSubmit={() => onSubmit(state.selectedRows)}
                  handleClose={onClose}
                />
              )}
              height={"60vh"}
            />
          </DialogContent>
        </Dialog>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <ChipMultiSelectField
          handleDelete={(deleted?: string) => {
            deleted
              ? props.setSelected(
                  props.selected.filter((s) => s.id_geo.toString() !== deleted)
                )
              : props.setSelected([]);
          }}
          selected={props.selected.map((row) => ({
            value: row.id_geo.toString(),
            text: row.name_geo,
          }))}
          label={props.fieldLabel}
          onClick={() => setDialogOpen(true)}
          disabled={props.disabled}
        />
        <AddMoreButton
          onClick={() => setDialogOpen(true)}
          style={{
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(1),
            marginRight: 0,
          }}
          disabled={props.disabled}
        />
      </div>
    </React.Fragment>
  );
};

export default SelectSubLevelGeoField;
