import {basePostRequest} from "../base/basePostRequest";
import {NotificationEventType} from "./getNotificationEvents";

const postNotifyEvents = async (
  id_notify: number,
  event_ids: Array<number | string>
) =>
  basePostRequest<NotificationEventType>(`/notify/${id_notify}/event/`, {
    ids: event_ids,
  });

export default postNotifyEvents;
