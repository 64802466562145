import * as React from "react";
import {useState} from "react";
import CoreTable from "../Tables/CoreTable";
import getUserReports, {
  UserReport,
} from "../../common/APIRequests/user_report/getUserReports";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import deleteUserReports from "../../common/APIRequests/user_report/deleteUserReports";
import UserReportOnSelectedDialog from "./UserReportOnSelectedDialog";
import UserReportColumns from "./UserReportColumns";
import {AddNewButton} from "../common/buttons/ToolbarButtons";
import AddNewUserReportDialog from "./AddNewUserReportDialog";

const UserReportsTable = () => {
  const [rowClicked, setRowClicked] = useState<UserReport>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  return (
    <CoreTable
      name={"user_reports"}
      columns={UserReportColumns}
      getRowId={(row) => row.id_user_report}
      updateRows={getUserReports}
      defaultSorting={{columnName: "name_report", direction: "desc"}}
      onRowClick={setRowClicked}
      selectionEnabled
      selectionButton={(selection, update) => (
        <MenuDeleteAction
          selection={selection}
          update={update}
          action={(ids) => deleteUserReports(ids)}
        />
      )}
      toolBarLeftItems={[
        <AddNewButton
          onClick={() => setAddDialogOpen(true)}
          key="user_reports_add_button"
        />,
      ]}
      dialog={
        <>
          <UserReportOnSelectedDialog
            onClose={() => setRowClicked(undefined)}
            open={!!rowClicked}
            userReport={rowClicked}
          />
          <AddNewUserReportDialog
            onClose={() => setAddDialogOpen(false)}
            open={addDialogOpen}
            onCreate={(userReport) => setRowClicked(userReport)}
          />
        </>
      }
    />
  );
};

export default UserReportsTable;
