import {Plugin} from "@devexpress/dx-react-core";
import {
  DataTypeProvider,
  IntegratedSelection,
  SelectionState,
} from "@devexpress/dx-react-grid";
import React, {useRef, useState} from "react";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import deleteProjectApplications from "../../../common/APIRequests/projectApplication/deleteProjectApplications";
import getProjectApplications, {
  projectApplicationType,
} from "../../../common/APIRequests/projectApplication/getProjectApplications";
import deleteProjectApplicationsProfile from "../../../common/APIRequests/projectApplicationProfile/deleteProjectApplicationsProfile";
import {useTrigger} from "../../../common/customHooks";
import {ConfirmDialog} from "../../common/buttons/ConfirmDialog";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import {AddNewButton} from "../../common/buttons/ToolbarButtons";
import {StyledTableSelection} from "../../common/grid/cell";
import {useGlobalStyles} from "../../common/styles";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import AddApplicationProfileDialogTable from "./AddApplicationProfileDialogTable";
import AddApplicationDialogTable from "./AddApplicationsDialogTable";
import ApplicationLinkUnlinkProfileChip from "./ApplicationLinkProfileChip";
import ProjectApplicationColumns from "./ProjectApplicationColumns";

type ProjectApplicationTableProps = {
  project: projectType;
  onClose: () => void;
  loading: boolean;
  setLoading: (l: boolean) => void;
};

const ProjectApplicationsTable = (props: ProjectApplicationTableProps) => {
  const projectApplicationIds = useRef<number[]>([]);

  const updateRows: UpdateRowsType<projectApplicationType> = async () => {
    const result = await getProjectApplications(props.project.id_project);
    if (result.success) {
      projectApplicationIds.current = result.rows.map((r) => r.id_app);
      setSelectionAllowedRowIds(
        result.rows
          .filter((pA: projectApplicationType) => !pA.is_inherited)
          .map((pA) => pA.id_pr_app_link)
      );
    }
    return result;
  };

  const [addAppDialogOpen, setAddAppDialogOpen] = useState(false);
  const [addProfileOpen, setAddProfileOpen] = useState(false);
  const [deleteProfileOpen, setDeleteProfileOpen] = useState(false);
  const [trigger, refresh] = useTrigger();

  const [selection, setSelection] = useState<(string | number)[]>([]);
  const [selectionAllowedRowIds, setSelectionAllowedRowIds] = useState<
    (string | number)[]
  >([]);
  const [selected, setSelected] = useState(
    undefined as projectApplicationType | undefined
  );

  const onHeaderSelectionClick = () => {
    if (selectionAllowedRowIds.length === 0) return;

    if (selectionAllowedRowIds.length === selection.length) {
      setSelection([]);
    } else {
      setSelection(selectionAllowedRowIds);
    }
  };

  const handleDeleteConfirm = (prApp: projectApplicationType) => {
    props.setLoading(true);
    deleteProjectApplicationsProfile(props.project.id_project, prApp.id_app)
      .then((result) => {
        if (result.success) {
          setDeleteProfileOpen(false);
          refresh();
        }
      })
      .finally(() => props.setLoading(false));
  };

  const classes = useGlobalStyles();

  const getRowStyle = (row: projectApplicationType) =>
    row.is_inherited === 1 ? classes.gridRowSelected : classes.gridRow;

  return (
    <>
      {addAppDialogOpen && (
        <AddApplicationDialogTable
          onClose={() => setAddAppDialogOpen(false)}
          projectApplicationIds={projectApplicationIds.current}
          project={props.project}
          onSubmit={refresh}
        />
      )}
      {addProfileOpen && selected && (
        <AddApplicationProfileDialogTable
          onClose={() => setAddProfileOpen(false)}
          projectApplication={selected}
          onSubmit={refresh}
        />
      )}
      {deleteProfileOpen && selected && (
        <ConfirmDialog
          loading={props.loading}
          handleClose={() => setDeleteProfileOpen(false)}
          handleConfirm={() => handleDeleteConfirm(selected)}
          message="Отвязать профиль?"
          checkPin
        />
      )}
      <CoreTable
        name="project_applications"
        columns={ProjectApplicationColumns}
        getRowId={(row: projectApplicationType) => row.id_pr_app_link}
        updateRows={updateRows}
        integratedSearch
        defaultSorting={{columnName: "app_name", direction: "asc"}}
        updateTrigger={trigger}
        rowStyle={getRowStyle}
        selectionButton={(tableSelection, update) => (
          <MenuDeleteAction
            selection={selection}
            update={update}
            action={() => {
              setSelection([]);
              return deleteProjectApplications(
                props.project.id_project,
                selection
              );
            }}
            checkPin
          />
        )}
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddAppDialogOpen(true)}
            key="project-application-open-add-dialog-button"
          />,
        ]}
        plugins={[
          <Plugin key="project-application-selection-plugin">
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <IntegratedSelection />
            <StyledTableSelection
              selectionAllowedRowIds={selectionAllowedRowIds}
              onHeaderSelectionClick={onHeaderSelectionClick}
              allSelected={
                selectionAllowedRowIds.length === selection.length &&
                selection.length !== 0
              }
            />
            <DataTypeProvider
              key="project-application-link-profile"
              for={["prof_name"]}
              formatterComponent={(props: {row?: projectApplicationType}) =>
                props.row?.is_inherited ? (
                  <>{props.row?.prof_name}</>
                ) : (
                  <ApplicationLinkUnlinkProfileChip
                    onClick={() => {
                      setAddProfileOpen(true);
                      setSelected(props.row);
                    }}
                    onDelete={() => {
                      setDeleteProfileOpen(true);
                      setSelected(props.row);
                    }}
                    value={props.row?.prof_name}
                  />
                )
              }
            />
          </Plugin>,
        ]}
      />
    </>
  );
};

export default ProjectApplicationsTable;
