import {getVideoRecordings} from "../API/httpCommands";
import CoreTable, {UpdateRowsType} from "../../../Tables/CoreTable";
import DeviceVideoColumns from "./DeviceVideoColumns";
import {VideoRecordingFileType} from "./DeviceVideoTypes";

const DeviceVideosTable = (props: {device: string}) => {
  const updateRows: UpdateRowsType<VideoRecordingFileType> = ({paging}) =>
    getVideoRecordings(props.device, paging?.page, paging?.page_size);
  return (
    <CoreTable
      columns={DeviceVideoColumns}
      name="videos"
      getRowId={(row) => row.name + row.datetime}
      updateRows={updateRows}
      pagingEnabled
      sortingEnabled
    />
  );
};

export default DeviceVideosTable;
