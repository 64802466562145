import * as React from "react";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import {TaskGeoProgressType} from "../../../common/APIRequests/task/getTaskProgress";
import Column from "../../Tables/Column";
import {formatGeoFullName} from "../../common/functions";

const GeoProgressColumns = [
  new Column("geo_fullname", "Наименование", 100, {
    auto: true,
    getCellValue: (row) => formatGeoFullName(row.geo_fullname),
  }),
  new Column("devices_count", "Количество устройств", 220),
  new Column("errors_count", "Количество ошибок", 200),
  new Column("success_count", "Количество успешных установок", 280),
  new Column("progress_percent", "% установки", 150, {
    getCellValue: (row) => `${row.progress_percent}%`,
  }),
];

type DetailedGeoViewProps = {
  progressByGeo?: TaskGeoProgressType[];
};
const DetailedGeoView = (props: DetailedGeoViewProps) => {
  const updateRows: UpdateRowsType<TaskGeoProgressType> = async () => {
    return {
      success: !!props.progressByGeo,
      rows: props.progressByGeo || [],
      errors: {},
    };
  };
  return (
    <CoreTable
      name={"geo_progress"}
      columns={GeoProgressColumns}
      updateTrigger={props.progressByGeo}
      defaultSorting={{columnName: "devices_count", direction: "asc"}}
      getRowId={(row) => row.geo_fullname}
      updateRows={updateRows}
      disableToolBar
      height={"30vh"}
    />
  );
};

export default DetailedGeoView;
