import React, {useState} from "react";
import PoliciesTree from "../../Policies/NestedPolicies/PoliciesTree";
import getPolicyTree from "../../../common/APIRequests/policy/getPolicyTree";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {PrimaryButton} from "../../common/buttons/PrimaryButton";
import PoliciesCoreTable from "../../NestedPolicies/PoliciesCoreTable";
import ProjectPolicyColumns from "./ProjectPolicyColumns";
import {ProjectPolicy} from "../../../common/APIRequests/policy/types";

const ProjectPoliciesTable = (props: {
  project: projectType;
  onClose: () => void;
  notifyHasChanges: (hasChanges: boolean) => void;
}) => {
  const {project, ...tableProps} = props;
  const [treeOpen, setTreeOpen] = useState(false);

  return (
    <>
      {treeOpen && (
        <PoliciesTree
          handleClose={() => setTreeOpen(false)}
          caption={`Дерево проекта ${props.project.project_name}`}
          action={() => getPolicyTree(props.project.id_project)}
          id_project={props.project.id_project}
        />
      )}
      <PoliciesCoreTable<ProjectPolicy>
        name={"project"}
        columns={ProjectPolicyColumns}
        parentId={project.id_project}
        getRowId={(row) => row.id_proj_pol}
        {...tableProps}
        openPoliciesTree={
          <PrimaryButton
            title="Дерево политик"
            onClick={() => setTreeOpen(true)}
            id="projects_policy_tree_open"
          />
        }
      />
    </>
  );
};

export default ProjectPoliciesTable;
