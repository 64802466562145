import {basePatchRequest} from "../base/basePatchRequest";
import {applicationVersionType} from "./getApplicationVersions";

const patchApplicationVersion = async (appVersion: applicationVersionType) =>
  basePatchRequest<applicationVersionType>(
    `/application/${appVersion.id_app}/version/${appVersion.id_app_version}/`,
    appVersion
  );

export default patchApplicationVersion;
