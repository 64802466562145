import React, {useState} from "react";
import deleteProfiles from "../../common/APIRequests/profile/deleteProfiles";
import getProfiles, {
  DEVICE_PROFILE_TYPE,
  profileType,
} from "../../common/APIRequests/profile/getProfiles";
import {useTrigger} from "../../common/customHooks";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import {AddNewButton} from "../common/buttons/ToolbarButtons";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import AddProfileDialog from "./Dialogs/AddProfileDialog";
import ProfileOnSelectedDialog from "./Dialogs/ProfileOnSelectedDialog";
import ProfileDeviceColumns from "./ProfileDeviceColumns";

const ProfileDevicesTable = () => {
  const [refreshTrigger, refresh] = useTrigger();

  const [rowClicked, setRowClicked] = useState(
    undefined as profileType | undefined
  );

  const filters = {id_type_profile: DEVICE_PROFILE_TYPE};

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const updateTasks: UpdateRowsType<profileType> = () => getProfiles(filters);

  return (
    <>
      {rowClicked && (
        <ProfileOnSelectedDialog
          onClose={() => setRowClicked(undefined)}
          update={refresh}
          selected={rowClicked}
          id_type_profile={DEVICE_PROFILE_TYPE}
        />
      )}
      {isAddDialogOpen && (
        <AddProfileDialog
          onClose={() => setAddDialogOpen(false)}
          update={refresh}
          id_type_profile={DEVICE_PROFILE_TYPE}
        />
      )}
      <CoreTable
        name={"profiles_devices"}
        columns={ProfileDeviceColumns}
        updateRows={updateTasks}
        updateTrigger={refreshTrigger}
        defaultSorting={{columnName: "name", direction: "asc"}}
        integratedSearch
        onRowClick={setRowClicked}
        getRowId={(row: profileType) => row.id_prof || ""}
        toolBarLeftItems={[
          <AddNewButton
            onClick={() => setAddDialogOpen(true)}
            key="profile_devices-add_button"
          />,
        ]}
        selectionEnabled
        selectionButton={(selection, update) => (
          <MenuDeleteAction
            selection={selection}
            update={update}
            action={(ids) => deleteProfiles(ids)}
            checkPin
          />
        )}
      />
    </>
  );
};

export default ProfileDevicesTable;
