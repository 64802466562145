export const parseVersion = (gitTag: string) => {
  return /version_((\d*\.?)*)(-?)(.*)/g.exec(gitTag)?.[1];
};

export const compareVersion = (a: string, b: string) => {
  const s1 = a.split(".");
  const s2 = b.split(".");

  const k = Math.min(s1.length, s2.length);

  for (let i = 0; i < k; ++i) {
    const n1 = parseInt(s1[i], 10);
    const n2 = parseInt(s2[i], 10);
    if (n1 > n2) return 1;
    if (n1 < n2) return -1;
  }
  return s1.length == s2.length ? 0 : s1.length < s2.length ? -1 : 1;
};
