import {Grid, Theme} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useRef, useState} from "react";
import {
  taskDeviceAppStatusType,
  taskDeviceFilterType,
} from "../../common/APIRequests/task/getTaskDevices";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";
import StringFilterField from "../common/filterFields/StringFilterField";
import {deviceAppStatusTitles} from "../common/grid/providers";
import {FilterComponentProps} from "../Tables/CoreTable";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: "62vh",
    zIndex: 1000,
  },
  filterItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
}));

const taskDeviceOptions: Array<taskDeviceAppStatusType> = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
];

const TaskDeviceFilter = (
  props: FilterComponentProps<taskDeviceFilterType>
) => {
  const {filters, updateFilters} = props;

  const [filterFocus, setFilterFocus] = useState<
    "code_division" | "serial_number" | ""
  >("");

  const codeDivisionRef = useRef<null | HTMLFormElement>(null);
  const [codeDivision, setCodeDivision] = useState<string | null>(
    filters.code_division
  );

  const serialNumberRef = useRef<null | HTMLFormElement>(null);
  const [serialNumber, setSerialNumber] = useState<string | null>(
    filters.serial_number
  );

  const [focus, setFocus] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (filterFocus) {
      filterFocus === "code_division" &&
        codeDivisionRef.current &&
        codeDivisionRef.current.focus();
      filterFocus === "serial_number" &&
        serialNumberRef.current &&
        serialNumberRef.current.focus();
    } else {
      setFilterFocus("");
    }
  }, []);

  const {classes: localClasses} = useStyles();

  return (
    <Grid container wrap="nowrap">
      <Grid item xs={6} className={localClasses.filterItem}>
        <SelectSingleFilterField
          name="app_status"
          includeAllOption
          options={taskDeviceOptions.map((tDo: taskDeviceAppStatusType) => ({
            key: tDo.toString(),
            value: tDo.toString(),
            text: deviceAppStatusTitles[tDo],
          }))}
          label="Статус установки"
          value={filters.app_status}
          onFocus={() => setFocus("app_status")}
          focus={focus}
          update={(value: string | null) =>
            updateFilters({
              ...filters,
              app_status: value as taskDeviceAppStatusType,
            })
          }
          style={{width: "300px"}}
        />
      </Grid>
      <Grid item xs={4} className={localClasses.filterItem}>
        <StringFilterField
          label="Код подразделения"
          name="code_division"
          value={codeDivision || ""}
          onChange={setCodeDivision}
          onFocus={() => setFocus("code_division")}
          onEnterPress={(value: string) => {
            updateFilters({...filters, code_division: value.trim()});
            setFilterFocus("code_division");
          }}
          onResetClick={() => {
            setCodeDivision("");
            updateFilters({...filters, code_division: ""});
            setFilterFocus("code_division");
          }}
          inputRef={codeDivisionRef}
          style={{width: "200px"}}
        />
      </Grid>
      <Grid item xs={4} className={localClasses.filterItem}>
        <StringFilterField
          label="Серйный номер"
          name="serial_number"
          value={serialNumber || ""}
          onChange={setSerialNumber}
          onFocus={() => setFocus("serial_number")}
          onEnterPress={(value: string) => {
            updateFilters({...filters, serial_number: value.trim()});
            setFilterFocus("serial_number");
          }}
          onResetClick={() => {
            setSerialNumber("");
            updateFilters({...filters, serial_number: ""});
            setFilterFocus("serial_number");
          }}
          inputRef={serialNumberRef}
          style={{width: "200px"}}
        />
      </Grid>
    </Grid>
  );
};

export default TaskDeviceFilter;
