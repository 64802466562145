import {NotificationType} from "./getNotification";
import {basePatchRequest} from "../base/basePatchRequest";

const patchNotification = async (notification: NotificationType) =>
  basePatchRequest<NotificationType>(
    `/notify/${notification.id_notify}/`,
    notification
  );

export default patchNotification;
