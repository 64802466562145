import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";
import {getUsername} from "../../getUsername";
import {ConsoleLogsFilterType} from "../../../components/ConsoleLogs/ConsoleLogsFilter";
import {ColumnPropsForDownloadType} from "../../../components/common/buttons/DownloadMenuButton";

const downloadConsoleLogsFile = async (
  filters: ConsoleLogsFilterType,
  fieldPropsForDownload: ColumnPropsForDownloadType,
  fileExt: "xlsx" | "csv",
  sort_field?: string,
  sort_desc?: boolean
): Promise<{success: boolean; file?: File; errors: errorType}> =>
  new Promise((resolve) =>
    apiClient({
      responseType: "arraybuffer",
      method: "post",
      url: `/console_log/download/`,
      data: {
        username: getUsername(),
        sort_field,
        sort_desc,
        file_extension: fileExt,
        field_props_for_download: JSON.stringify(fieldPropsForDownload),
        filters: JSON.stringify(filters),
      },
    })
      .then((response) =>
        resolve({
          success: true,
          file: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          file: undefined,
          errors: errors,
        });
      })
  );

export default downloadConsoleLogsFile;
