import {errorType} from "../../../../../common/types";
import {rcApiClient} from "../rcApiClient";

export type BasePostResponse<T> = Promise<{
  success: boolean;
  row: T | undefined;
  errors: errorType;
}>;

export function basePostRequest<T>(
  url: string,
  data?: any
): BasePostResponse<T> {
  return new Promise((resolve) =>
    rcApiClient({
      method: "post",
      url: url,
      data: data,
    })
      .then((response) =>
        resolve({
          success: true,
          row: response.data ? (response.data as T) : undefined,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          row: undefined,
          errors: errors,
        });
      })
  );
}
