import * as React from "react";
import {Dialog, DialogContent} from "@mui/material";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import NotificationRecipientForm from "./NotificationRecipientForm";
import {NotificationType} from "../../../common/APIRequests/notify/getNotification";

type AddRecipientDialogProps = {
  onClose: () => void;
  update: () => void;
  notification: NotificationType;
};

const AddRecipientDialog = (props: AddRecipientDialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <ClosableDialogTitle
        id="add-notify-recipient-modal"
        onClose={props.onClose}
      >
        Добавить получателей к оповещению {props.notification.name}
      </ClosableDialogTitle>
      <DialogContent>
        <NotificationRecipientForm
          onClose={props.onClose}
          update={props.update}
          notification={props.notification}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddRecipientDialog;
