import LockOutlined from "@mui/icons-material/LockOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Theme,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import React, {useContext, useState} from "react";
import {makeStyles} from "tss-react/mui";
import postSession, {
  loginType,
} from "../../common/APIRequests/login/postSession";
import checkUserHasPin from "../../common/APIRequests/user_pin/checkUserHasPin";
import {userPinType} from "../../common/APIRequests/user_pin/getUserPin";
import postUserPin from "../../common/APIRequests/user_pin/postUserPin";
import {getUsername} from "../../common/getUsername";
import Logo from "../../common/images/logo";
import {errorType} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import {Spacer} from "../common/components";
import {VersionDescription} from "../common/Descriptions";
import PinField from "../common/formFields/PinField";

const StyledProgress = withStyles({
  root: {
    position: "absolute",
    left: "50%",
    marginLeft: "-12px",
  },
})(CircularProgress);

const useStyles = makeStyles()((theme: Theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: "20vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  button: {
    margin: 0,
    padding: theme.spacing(1.3),
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  userLogin: (login: loginType) => void;
};

const PIN_MIN_LEN = 4;

const LoginForm = (props: Props) => {
  const {userLogin} = props;
  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [values, setValues] = useState({
    username: "",
    password: "",
    console_pin: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as errorType);
  const [hasPin, setHasPin] = useState(undefined as boolean | undefined);

  const handleChange =
    (name: string) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const _errors = {...errors};
      delete _errors[name];
      setErrors(_errors);
      setValues({...values, [name]: e.target.value});
    };

  const handleSubmit = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();

    let errs = {};
    if (!values.username) {
      errs = {...errs, username: "Не заполнено имя пользователя"};
    }
    if (!values.password) {
      errs = {...errs, password: "Не заполнен пароль"};
    }
    if (hasPin == false && !values.console_pin) {
      errs = {...errs, console_pin: "Не заполнен пин-код"};
    } else if (hasPin == false && values.console_pin.length < PIN_MIN_LEN) {
      errs = {
        ...errs,
        console_pin: `Пин-код должен быть не менее ${PIN_MIN_LEN} символов`,
      };
    }

    setErrors(errs);

    if (Object.keys(errs).length > 0) {
      return;
    }

    setLoading(true);
    postSession(values)
      .then((result) => {
        if (result.success) {
          localStorage.setItem("auth", JSON.stringify(result.row));
          if (hasPin == undefined) {
            const login = getUsername();
            if (login) {
              checkUserHasPin(login).then((pinResult) => {
                if (pinResult.data) {
                  setHasPin(true);
                  result.row && userLogin(result.row);
                } else {
                  setHasPin(false);
                  localStorage.removeItem("auth");
                }
              });
            }
          } else {
            const userPin: userPinType = {
              login: values.username,
              console_pin: values.console_pin,
            };
            postUserPin(userPin).then((pinResult) => {
              if (pinResult.success) {
                result.row && userLogin(result.row);
              } else {
                errs = pinResult.errors;
                localStorage.removeItem("auth");
              }
            });
          }
        }
      })
      .finally(() => setLoading(false));

    setErrors(errs);
  };

  const passwordRef: React.RefObject<HTMLInputElement> = React.createRef();
  const [buttonRef, setButtonRef] = useState(null as HTMLElement | null);

  const handleEnter =
    (name: string) => (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        if (name === "login") {
          passwordRef.current && passwordRef.current.focus();
        }
        if (name === "password") {
          buttonRef && buttonRef.focus();
          buttonRef && buttonRef.click();
        }
        if (name === "pin") {
          buttonRef && buttonRef.focus();
          buttonRef && buttonRef.click();
        }
      }
    };

  const {classes: localClasses} = useStyles();

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" padding="16px">
        <Logo />
      </Box>
      <Divider color={theme.palette.info.main} />
      <Container component="main" maxWidth="xs">
        <div className={localClasses.paper}>
          <Avatar className={localClasses.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5" style={{textAlign: "center"}}>
            Добро пожаловать в EMM Mmobile!
          </Typography>
          <Typography
            component="h1"
            variant="subtitle1"
            style={{textAlign: "center"}}
          >
            Для начала работы вам необходимо авторизоваться
          </Typography>
          <form
            className={localClasses.form}
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl error={!!errors.username} fullWidth>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Имя пользователя"
                    name="username"
                    autoComplete="username"
                    placeholder="Ваше имя пользователя"
                    value={values.username}
                    error={!!errors.username}
                    aria-describedby="component-error-username"
                    disabled={loading}
                    onChange={handleChange("username")}
                    onKeyPress={handleEnter("login")}
                  />
                  {errors.username && (
                    <FormHelperText id="component-error-username">
                      {errors.username}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={!!errors.password} fullWidth>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Пароль"
                    name="password"
                    inputProps={{
                      ref: passwordRef,
                    }}
                    autoComplete="password"
                    placeholder="Ваш пароль"
                    type="password"
                    value={values.password}
                    error={!!errors.password}
                    aria-describedby="component-error-password"
                    disabled={loading}
                    onChange={handleChange("password")}
                    onKeyPress={handleEnter("password")}
                  />
                  {errors.password && (
                    <FormHelperText id="component-error-password">
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {hasPin == false && (
                <>
                  <Spacer height={20} />
                  <Typography
                    component="h1"
                    variant="subtitle1"
                    style={{textAlign: "center"}}
                  >
                    Необходимо установить пин-код для подтверждения действий
                    администратора системы (от 4 до 8 цифр)
                  </Typography>
                  <Grid item xs={12}>
                    <PinField
                      value={values.console_pin}
                      onChange={(pin) =>
                        setValues({...values, console_pin: pin})
                      }
                      onEnterPressed={() => handleEnter("console_pin")}
                      error={!!errors.console_pin}
                    />
                    {errors.console_pin && (
                      <FormHelperText error={true} id="component-error-pin">
                        {errors.console_pin}
                      </FormHelperText>
                    )}
                  </Grid>
                </>
              )}

              <FormHelperText
                id="component-error-text"
                className={theme.palette.error.main}
                error={!!errors.auth_error}
              >
                {errors.auth_error}
              </FormHelperText>
              <Grid item xs={12}>
                <Button
                  id="login-button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={localClasses.button}
                  disabled={loading}
                  onClick={(e) =>
                    loading ? e.preventDefault() : handleSubmit(e)
                  }
                  type="submit"
                  ref={(element) => setButtonRef(element)}
                >
                  Авторизироваться
                  {loading && <StyledProgress size={28} />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      <Box
        position="absolute"
        bottom={0}
        marginBottom={"10px"}
        width={"calc(100% - 32px)"} // exclude parent component paddings
      >
        <VersionDescription />
      </Box>
    </React.Fragment>
  );
};

export default LoginForm;
