import {apiClient} from "../../utils/apiClient";

const postImportFromCsv = async (
  id_project: number,
  data: FormData
): Promise<{
  success: boolean;
  errors: Array<string>;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "post",
      url: `/project/${id_project}/policy/import_from_csv/`,
      data,
    })
      .then(() =>
        resolve({
          success: true,
          errors: [],
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          errors: errors,
        });
      })
  );

export default postImportFromCsv;
