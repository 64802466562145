import {errorType} from "../../../../common/types";
import {AxiosInstance} from "axios";

const downloadExternalFile = async (
  url: string,
  apiClient: AxiosInstance
): Promise<{
  success: boolean;
  file: any;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      responseType: "blob",
      method: "get",
      url: url,
    })
      .then((response) =>
        resolve({
          success: true,
          file: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          file: undefined,
          errors: errors,
        });
      })
  );

export default downloadExternalFile;
