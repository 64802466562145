import * as React from "react";
import {useState} from "react";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {Dialog, DialogContent} from "@mui/material";
import DeviceApplications from "../../Devices/DeviceApplications/DeviceApplications";
import DeviceApplicationVersions from "../../Devices/DeviceApplications/DeviceApplicationVersions";
import {ApplicationType} from "../../../common/APIRequests/application/getApplications";
import {applicationVersionType} from "../../../common/APIRequests/applicationVersion/getApplicationVersions";

type SelectApplicationDialogProps = {
  onClose: () => void;
  setSelected: (app_version: applicationVersionType) => void;
};
const SelectApplicationDialog = (props: SelectApplicationDialogProps) => {
  const [loading, setLoading] = useState(false);

  const [selectedApplication, setSelectedApplication] = useState(
    undefined as undefined | ApplicationType
  );

  const [selectedApplicationVersion, setSelectedApplicationVersion] = useState(
    undefined as undefined | applicationVersionType
  );

  return (
    <Dialog
      open={true}
      onClose={() => !loading && props.onClose()}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="lg"
    >
      <ClosableDialogTitle
        id="add-project-modal"
        onClose={() => !loading && props.onClose()}
      >
        Выбор приложения для задания
      </ClosableDialogTitle>
      <DialogContent>
        <DeviceApplications
          selected={selectedApplication}
          setSelected={setSelectedApplication}
        />
        <DeviceApplicationVersions
          selectedApplication={selectedApplication}
          selected={selectedApplicationVersion}
          setSelected={setSelectedApplicationVersion}
          handleClose={() => !loading && props.onClose()}
          installApplication={(
            app: ApplicationType,
            app_version: applicationVersionType
          ) => {
            props.onClose();
            props.setSelected(app_version);
          }}
          loading={loading}
          setLoading={setLoading}
          checkPin={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SelectApplicationDialog;
