import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import getDirectory, {
  DirectoryType,
} from "../../common/APIRequests/directory/getDirectory";
import getEvents, {
  defaultEventFilter,
  EventType,
} from "../../common/APIRequests/event/getEvents";
import {useLocalStorage} from "../../common/customHooks";
import {optionType} from "../../common/types";
import EventTable, {filterType} from "./EventTable";

const Events = () => {
  const [rows, setRows] = useState<EventType[]>([]);
  const [loading, setLoading] = useState(false);

  const [isSystemShowed, setIsSystemShowed] = useLocalStorage<boolean>(
    "event_system_is_showed",
    false
  );

  //events
  const [userDirs, setUserDirs] = useState([] as Array<DirectoryType>);

  const [userDirsOptions, setUserDirsOptions] = useState(
    [] as Array<optionType>
  );

  const [propertyDirsOptions, setPropertyDirsOptions] = useState(
    [] as Array<optionType>
  );

  const [byDirsOptions, setByDirsOptions] = useState([] as Array<optionType>);

  const [levelDirsOptions, setLevelDirsOptions] = useState(
    [] as Array<optionType>
  );

  const [selection, setSelection] = useState([] as Array<string | number>);

  const [filters, setFilters] = useState<filterType>(defaultEventFilter);

  const updateEventsAndSelection = () => {
    setSelection([]);
    setLoading(true);
    getEvents(filters)
      .then((result) => {
        if (result.success) {
          setRows(result.rows);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(updateEventsAndSelection, [filters]);

  const updateDirectoryOptions = async () => {
    setLoading(true);

    const firstLineOptions = [
      {
        key: 0,
        value: 0,
        text: "Все",
      },
    ];
    await Promise.all([
      getDirectory(1),
      getDirectory(2),
      getDirectory(3),
      getDirectory(4),
    ]).then((values) => {
      setUserDirsOptions([
        ...firstLineOptions,
        ...values[0].rows.map((d: DirectoryType) => ({
          key: d.id_dir,
          value: d.id_dir,
          text: d.name_dir,
        })),
      ]);
      setUserDirs(values[0].rows);

      setPropertyDirsOptions([
        ...firstLineOptions,
        ...values[1].rows.map((d: DirectoryType) => ({
          key: d.id_dir,
          value: d.id_dir,
          text: d.name_dir,
        })),
      ]);

      setByDirsOptions([
        ...firstLineOptions,
        ...values[2].rows.map((d: DirectoryType) => ({
          key: d.id_dir,
          value: d.id_dir,
          text: d.name_dir,
        })),
      ]);
      setLevelDirsOptions([
        ...firstLineOptions,
        ...values[3].rows.map((d: DirectoryType) => ({
          key: d.id_dir,
          value: d.id_dir,
          text: d.name_dir,
        })),
      ]);
    });
    setLoading(false);
  };
  useEffect(() => {
    updateDirectoryOptions();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EventTable
            loading={loading}
            rows={rows}
            isSystemShowed={isSystemShowed}
            setIsSystemShowed={setIsSystemShowed}
            update={updateEventsAndSelection}
            setLoading={setLoading}
            userDirsOptions={userDirsOptions}
            propertyDirsOptions={propertyDirsOptions}
            byDirsOptions={byDirsOptions}
            levelDirsOptions={levelDirsOptions}
            updateDirectoryOptions={updateDirectoryOptions}
            filters={filters}
            setFilters={setFilters}
            userDirs={userDirs}
            selection={selection}
            setSelection={setSelection}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Events;
