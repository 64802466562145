import {withStyles} from "tss-react/mui";
import {Grid} from "@mui/material";

export const StyledFilterGrid = withStyles(Grid, (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
}));

export const StyledMenuFilterGrid = withStyles(Grid, (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));
