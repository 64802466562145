import React, {useContext, useState} from "react";
import {
  reportTypeName,
  UserReport,
  UserReportType,
} from "../../common/APIRequests/user_report/getUserReports";
import patchUserReport from "../../common/APIRequests/user_report/patchUserReport";
import postUserReport from "../../common/APIRequests/user_report/postUserReport";
import {errorType} from "../../common/types";
import theme from "../../theme";
import FormButtons from "../common/buttons/FormButtons";
import BooleanField from "../common/formFields/BooleanField";
import StringField from "../common/formFields/StringField";
import SelectSingleField from "../common/formFields/SelectSingleField";
import TableContext, {TableContextType} from "../Tables/TableContext";
import {Box} from "@mui/material";

type UserReportFormProps = {
  userReport?: UserReport;
  onClose: () => void;
  onCreate?: (userReport: UserReport) => void;
};

const emptyUserReport: UserReport = {
  id_user_report: 0,
  name_report: "",
  is_private: "0",
  report_type: null,
};

const UserReportForm = (props: UserReportFormProps) => {
  const {onClose, userReport} = props;

  const {updateRows} = useContext(TableContext) as TableContextType<UserReport>;

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState(
    userReport ? userReport : (emptyUserReport as UserReport)
  );

  const [errors, setErrors] = useState({} as errorType);

  const handleChange = (name: string) => (value: number) => {
    setValues({...values, [name]: value});
  };

  const handleSubmit = () => {
    setLoading(true);
    if (values.id_user_report) {
      patchUserReport(values.id_user_report, values)
        .then((result) => {
          if (result.success) {
            updateRows?.();
            onClose();
          } else {
            setErrors(result.errors);
          }
        })
        .finally(() => setLoading(false));
    } else {
      postUserReport(values)
        .then((result) => {
          if (result.success) {
            updateRows?.();
            onClose();
            result.row && props.onCreate?.(result.row);
          } else {
            setErrors(result.errors);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <React.Fragment>
      <StringField
        value={values.name_report}
        setValue={handleChange("name_report")}
        name="name_report"
        label="Наименование"
        errors={errors}
        setErrors={setErrors}
        style={{marginBottom: theme.spacing(2)}}
      />
      <Box width="100%" paddingBottom="16px">
        <SelectSingleField
          disabled={!!values.id_user_report}
          setValue={handleChange("report_type")}
          value={values.report_type?.toString() ?? ""}
          name="report_type"
          label="Тип отчета"
          errors={errors}
          options={Object.keys(reportTypeName).map((value) => {
            const valueNumber = parseInt(value);
            return {
              value: valueNumber,
              text: reportTypeName[valueNumber as UserReportType],
            };
          })}
        />
      </Box>
      <BooleanField
        value={values.is_private.toString()}
        setValue={handleChange("is_private")}
        name="is_private"
        label="Приватный"
        disabled={loading}
        errors={errors}
        setErrors={setErrors}
      />
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={onClose}
        submitMessage="Сохранить"
      />
    </React.Fragment>
  );
};

export default UserReportForm;
