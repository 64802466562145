import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type TaskTemplateType = {
  id_template: number;
  name: string;
  id_project?: number;
  name_project?: string;
  children: "0" | "1";
  id_app_version?: number;
  app_version_name?: string;
  geo_fullname: string;
  geo_count: number;
};

const getTaskTemplates = async () =>
  baseGetRowsRequest<TaskTemplateType>(`/task_template/`);

export default getTaskTemplates;
