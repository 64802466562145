import axios from "axios";
import {Proxy} from "../../settings";

export const getToken = (): string => {
  const authStorage = localStorage.getItem("auth");
  if (!authStorage) {
    return "";
  }
  try {
    const auth = JSON.parse(authStorage);
    return auth.session_uid || "";
  } catch (error) {
    return "";
  }
};

export const apiClient = axios.create({
  baseURL: Proxy,
});
apiClient.defaults.headers.common["accept-language"] = "locale";

// add token to request
apiClient.interceptors.request.use((config) => {
  config.headers = {...config.headers, Authorization: getToken()};
  return config;
});
