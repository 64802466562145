import {basePostRequest} from "../base/basePostRequest";
import {projectProfileType} from "./getProjectProfiles";

const toggleProjectProfiles = async (
  id_project: number,
  ids: Array<number | string>,
  is_active: 0 | 1
) =>
  basePostRequest<projectProfileType>(
    `/project/${id_project}/profile/toggle/`,
    {ids, is_active}
  );

export default toggleProjectProfiles;
