import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import React, {useEffect, useState} from "react";
import getApplications, {
  ApplicationType,
} from "../../../common/APIRequests/application/getApplications";
import {useLocalStorage} from "../../../common/customHooks";
import {columnType, sortingType} from "../../../common/types";
import {StyledVirtualTable} from "../../common/grid/cell";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../../common/styles";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../../common/components";

const columns: columnType = [
  {name: "id_app", title: "ID"},
  {name: "name", title: "Наименование"},
  {name: "app_uid", title: "Идентификатор Android"},
  {name: "description", title: "Описание"},
];
const columnExtensions = [
  {columnName: "id_app", width: 100},
  {columnName: "name", width: 200},
  {columnName: "app_uid", width: 200},
  {columnName: "description", width: 200},
];
const hiddenColumnNames = ["app_uid"];

const DeviceApplications = (props: {
  selected: ApplicationType | undefined;
  setSelected: (app: ApplicationType | undefined) => void;
}) => {
  const {selected, setSelected} = props;

  const [rows, setRows] = useState([] as Array<ApplicationType>);

  const [columnOrder, setColumnOrder] = useLocalStorage<Array<string>>(
    "device_application_ordering",
    columns.map((row) => row.name)
  );

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "device_app_sorting",
    [{columnName: "name", direction: "asc"}]
  );

  const [loading, setLoading] = useState(false);

  const updateRows = async () => {
    setSelected(undefined);

    setLoading(true);
    const result = await getApplications();
    setLoading(false);

    result.success && setRows(result.rows);
  };

  useEffect(() => {
    updateRows();
  }, []);

  const classes = useGlobalStyles();

  return (
    <div style={{position: "relative"}}>
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_app}>
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <h3 style={{margin: 0}}>Приложения</h3>
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => {
            return (
              <VirtualTable.Row
                {...restProps}
                onClick={() => setSelected(row)}
                className={
                  selected && row.id_app === selected.id_app
                    ? classes.gridRowSelected
                    : classes.gridRow
                }
                data-type="apps_row"
                data-id={row.id_app}
              />
            );
          }}
          height="30vh"
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="device_application_select"
          autoWidthColumnName={"description"}
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </div>
  );
};
export default DeviceApplications;
