import * as React from "react";
import {useState} from "react";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import ConsoleLogColumns from "./ConsoleLogColumns";
import getConsoleLogs, {
  ConsoleLogType,
} from "../../common/APIRequests/consoleLog/getConsoleLogs";
import {ConsoleLogsFilter, ConsoleLogsFilterType} from "./ConsoleLogsFilter";
import {DownloadConsoleLogsFileButton} from "./DownloadConsoleLogsFileButton";
import {LoadingIndicator} from "../common/components";
import ConsoleLogRequiredFilter from "./ConsoleLogRequiredFilter";
import ConsoleLogRefreshButton from "./ConsoleLogRefreshButton";

export const ConsoleLogsTable = () => {
  const updateRows: UpdateRowsType<ConsoleLogType, ConsoleLogsFilterType> = ({
    sorting,
    paging,
    filters,
  }) => {
    if (!filters?.source) {
      return Promise.resolve({success: true, rows: [], count: 0, errors: {}});
    } else
      return getConsoleLogs(
        paging?.page,
        paging?.page_size,
        sorting?.sort_field,
        sorting?.sort_desc,
        filters
      );
  };

  const [loading, setLoading] = useState(false);
  return (
    <>
      <CoreTable
        name={"console_log"}
        columns={ConsoleLogColumns}
        getRowId={(row) =>
          `${row.id_console_log}_${row.action}_${row.source}_${row.ldap_login}_${row.log_date}`
        }
        updateRows={updateRows}
        sortingEnabled
        defaultSorting={{columnName: "log_date", direction: "desc"}}
        pagingEnabled
        toolBarLeftItems={[
          <DownloadConsoleLogsFileButton
            setLoading={setLoading}
            key="console-log-download-button"
          />,
        ]}
        MenuFilterComponent={ConsoleLogsFilter}
        ToolBarFilterComponent={ConsoleLogRequiredFilter}
        RefreshButton={ConsoleLogRefreshButton}
      />
      {loading && <LoadingIndicator />}
    </>
  );
};
