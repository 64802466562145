import {baseDeleteRequest} from "../base/baseDeleteRequest";

const deleteNotificationEvents = async (
  id_notify: number,
  event_ids: Array<number | string>
) =>
  baseDeleteRequest(`/notify/${id_notify}/event/`, {
    ids: event_ids,
  });

export default deleteNotificationEvents;
