import * as React from "react";
import {useContext} from "react";
import {errorType, optionType} from "../../../common/types";
import theme from "../../../theme";
import SelectSingleField from "../../common/formFields/SelectSingleField";
import {ClearButton} from "../Components/buttons";
import SelectSubLevelGeoField from "../Components/SelectSubLevelGeoField";
import {AppContext} from "../../App-context";
import {geoType} from "../../../common/APIRequests/taskTemplate/getTemplate";

export type GeosType = {
  net?: number;
  branches?: geoType[];
  divisions?: geoType[];
};

const SelectGeographyForm = (props: {
  errors?: errorType;
  geos: GeosType;
  setGeos: (geos: GeosType) => void;
  disabled?: boolean;
}) => {
  const {geos, setGeos} = props;

  const appContext = useContext(AppContext);
  const {geoNets} = appContext;
  const netOptions: optionType[] = geoNets.map((row) => ({
    key: row.id_geo.toString(),
    value: row.id_geo,
    text: row.name_geo,
  }));

  return (
    <React.Fragment>
      <SelectSingleField
        options={netOptions}
        setValue={(value: string) => {
          const net_id = parseInt(value);

          setGeos({
            net: net_id,
            ...(net_id !== geos.net ? {branches: [], divisions: []} : {}),
          });
        }}
        value={geos.net?.toString() || ""}
        name="geo"
        label="Сеть"
        errors={props.errors ?? {}}
        style={{marginTop: theme.spacing(2)}}
        variant="outlined"
        disabled={props.disabled || false}
        endAdornment={
          <ClearButton
            onClick={() =>
              setGeos({net: undefined, branches: [], divisions: []})
            }
          />
        }
      />
      {geos.net && (
        <SelectSubLevelGeoField
          fieldLabel="Филиалы"
          dialogTitle="Выбрать филиалы"
          tableIdentifier="select_branches_task_template"
          id_higher_geo={geos.net.toString()}
          selected={geos.branches || []}
          disabled={props.disabled || false}
          setSelected={(branches) => {
            const canSaveDivisions =
              branches.length === 1 &&
              geos.branches?.length === 1 &&
              branches[0].id_geo === geos.branches[0].id_geo;
            const divisions = canSaveDivisions ? geos.divisions : [];
            setGeos({
              ...geos,
              branches,
              divisions,
            });
          }}
        />
      )}
      {geos.branches && geos.branches.length > 0 && (
        <SelectSubLevelGeoField
          fieldLabel="Подразделения"
          dialogTitle="Выбрать подразделения"
          tableIdentifier="select_divisions_task_template"
          id_higher_geo={geos.branches[0].id_geo.toString()}
          selected={geos.divisions || []}
          setSelected={(divisions) => setGeos({...geos, divisions})}
          disabled={geos.branches.length !== 1 || props.disabled}
        />
      )}
    </React.Fragment>
  );
};

export default SelectGeographyForm;
