import * as React from "react";
import {ReportFilterProps} from "../eventFilters/EventIdReportFilter";
import PickerDateTime from "../../../../../common/formFields/PickerDateTime";

const DateReportFilter = (props: ReportFilterProps) => {
  return (
    <PickerDateTime
      label={props.name}
      name={props.name}
      setValue={(value) => {
        props.setValue(value ?? undefined);
      }}
      onError={() => props.setValue(undefined)}
      value={props.value ?? null}
    />
  );
};

export default DateReportFilter;
