import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type geographyType = {
  id_geo: number;
  name_geo: string;
  sum_device?: number;
  code_geo?: string;
};

const getBranch = async (id_geo?: string) =>
  baseGetRowsRequest<geographyType>(id_geo ? `/branch/${id_geo}/` : `/branch/`);

export default getBranch;
