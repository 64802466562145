import React from "react";
import {NotificationType} from "../../common/APIRequests/notify/getNotification";
import {Dialog, DialogContent} from "@mui/material";
import {Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import AddNotificationForm from "./AddNotificationForm";

type AddNotificationDialogProps = {
  notification?: NotificationType;
  onClose: () => void;
  onSubmit: () => void;
};

const AddNotificationDialog = (props: AddNotificationDialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Добавить оповещение
      </ClosableDialogTitle>
      <DialogContent>
        <AddNotificationForm onClose={props.onClose} update={props.onSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default AddNotificationDialog;
