import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Button, Dialog, DialogContent} from "@mui/material";
import React, {useEffect, useState} from "react";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import getDevicePolicyTree from "../../../common/APIRequests/devicePolicyTree/getDevicePolicyTree";
import deleteDeviceProfiles from "../../../common/APIRequests/deviceProfile/deleteDeviceProfiles";
import getDeviceProfiles, {
  deviceProfileType,
} from "../../../common/APIRequests/deviceProfile/getDeviceProfiles";
import toggleDeviceProfiles from "../../../common/APIRequests/deviceProfile/toggleDeviceProfiles";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../../common/customHooks";
import {columnType, sortingType} from "../../../common/types";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import SwitchWithPin from "../../common/buttons/SwitchWithPin";
import {AddNewButton, RefreshButton} from "../../common/buttons/ToolbarButtons";
import {LoadingIndicator, Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {
  StyledSelectionCell,
  StyledSelectionCellDisabled,
  StyledTableSelection,
  StyledVirtualTable,
} from "../../common/grid/cell";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import {BooleanTypeProvider} from "../../common/grid/providers";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../../common/styles";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import PoliciesTree from "../../Policies/NestedPolicies/PoliciesTree";
import DeviceProfiles from "./DeviceProfiles";

const columns: columnType = [
  {name: "id_prof_dev_link", title: "ID"},
  {name: "id_prof", title: "ID профиля"},
  {name: "name_profile", title: "Наименование"},
  {name: "specification_profile", title: "Описание"},
  {name: "is_inherited", title: "Наследуется"},
  {name: "id_project", title: "ID проекта"},
  {name: "name_project", title: "Наименование проекта"},
  {name: "is_active", title: "Состояние"},
  {name: "priority", title: "Приоритет"},
];
const columnExtensions = [
  {columnName: "id_prof_dev_link", width: 100},
  {columnName: "id_prof", width: 100},
  {columnName: "name_profile", width: 200},
  {columnName: "specification_profile", width: 200},
  {columnName: "is_inherited", width: 200},
  {columnName: "id_project", width: 200},
  {columnName: "name_project", width: 200},
  {columnName: "is_active", width: 150},
  {columnName: "priority", width: 150},
];
const columnAlignExtension = [{columnName: "is_inherited", align: "center"}];
const hiddenColumnNames = [
  "id_prof",
  "is_inherited",
  "id_project",
  "name_project",
];

const DeviceProfilesTab = (props: {
  device: deviceType;
  loading: boolean;
  setLoading: Function;
}) => {
  const {device, loading, setLoading} = props;

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "device_profiles_tab_sorting",
    [{columnName: "name_profile", direction: "asc"}]
  );

  const [selection, setSelection] = useState([] as Array<string | number>);

  const [rows, setRows] = useState([] as Array<deviceProfileType>);

  useEffect(() => {
    updateRows();
  }, []);

  const updateRows = async () => {
    setSelection([]);

    setLoading(true);
    const result = await getDeviceProfiles(device.id_device);
    setLoading(false);

    result.success && setRows(result.rows);
  };

  const [addOpen, setAddOpen] = useState(false);

  const [treeOpen, setTreeOpen] = useState(false as boolean);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "device_profile_tab_ordering",
    columns.map((row) => row.name)
  );

  const ActivityFormatter = (data: any) => {
    return (
      <SwitchWithPin
        onChange={() => toggleActive(data.row)}
        checked={Boolean(data.row.is_active)}
        disabled={data.row.is_inherited == 1}
        checkPin
      />
    );
  };
  const ActivityProvider = (props: any) => (
    <DataTypeProvider formatterComponent={ActivityFormatter} {...props} />
  );

  const toggleActive = async (current_row: deviceProfileType) => {
    if (loading) {
      return;
    }
    const is_active = current_row.is_active ? 0 : 1;
    const old_rows = rows;
    setRows(
      rows.map((row) =>
        row.id_prof === current_row.id_prof
          ? {...row, is_active: is_active}
          : row
      )
    );
    setLoading(true);
    const result = await toggleDeviceProfiles(
      device.id_device,
      [current_row.id_prof],
      is_active
    );
    setLoading(false);
    if (!result.success) {
      setRows(old_rows);
    }
  };

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      {addOpen && (
        <Dialog
          open={true}
          onClose={() => setAddOpen(false)}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="lg"
        >
          <ClosableDialogTitle
            id="add-project-modal"
            onClose={() => setAddOpen(false)}
          >
            Добавить профиль к устройству пользователя: {device.user_ldap_login}
            , серийный номер: {device.serial_number}
          </ClosableDialogTitle>
          <DialogContent>
            <DeviceProfiles
              handleClose={() => setAddOpen(false)}
              update={updateRows}
              installed={rows.reduce(
                (result: Array<number>, row) =>
                  row.is_inherited === 0 ? [...result, row.id_prof] : result,
                []
              )}
              device={device}
            />
          </DialogContent>
        </Dialog>
      )}
      {treeOpen && (
        <PoliciesTree
          handleClose={() => setTreeOpen(false)}
          caption={`Дерево политик устройства: ${device.user_ldap_login}, серийный номер: ${device.serial_number}`}
          action={() => getDevicePolicyTree(device.id_device)}
        />
      )}
      <Grid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id_prof_dev_link}
      >
        {/*Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <AddNewButton
              onClick={() => setAddOpen(true)}
              id="device_profiles_add"
            />
          </ToolbarItem>
          <ToolbarItem id="device_profiles_delete">
            <MenuDeleteAction
              selection={selection}
              update={() => updateRows()}
              action={(ids: Array<number | string>) =>
                deleteDeviceProfiles(device.id_device, ids)
              }
              checkPin
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateRows}
              id="device_profiles_refresh"
            />
          </ToolbarItem>
          {Boolean(device.id_project) && (
            <ToolbarItem>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => setTreeOpen(true)}
                id="device_policy_tree_open"
              >
                Дерево политик
              </Button>
            </ToolbarItem>
          )}
          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              className={
                row.is_inherited === 1
                  ? classes.gridRowSelected
                  : classes.gridRow
              }
              data-type="device_profiles_row"
              data-id={row.id_prof_dev_link}
            />
          )}
          columnExtensions={columnAlignExtension}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="device_profile"
          autoWidthColumnName={"specification_profile"}
        />

        <ActivityProvider for={["is_active"]} />
        <BooleanTypeProvider for={["is_inherited"]} />

        {/*Row Selection*/}
        <SelectionState
          selection={selection}
          onSelectionChange={(selection: Array<string | number>) => {
            setSelection(
              rows.reduce(
                (result: Array<string | number>, row) =>
                  selection.includes(row.id_prof_dev_link)
                    ? [...result, row.id_prof_dev_link]
                    : result,
                []
              )
            );
          }}
        />
        <IntegratedSelection />
        <StyledTableSelection
          cellComponent={({row, ...restProps}: any) =>
            row.is_inherited === 1 ? (
              <StyledSelectionCellDisabled {...restProps} disabled />
            ) : (
              <StyledSelectionCell {...restProps} />
            )
          }
          showSelectAll
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default DeviceProfilesTab;
