import * as React from "react";
import {useState} from "react";
import {taskType} from "../../../common/APIRequests/task/getTasks";
import PopupDialog from "../../common/PopupDialog";
import TaskDeviceTable from "../TaskDeviceTable";
import {StyledTab, StyledTabs} from "../../common/Tabs";
import {TabPanel, tabProps} from "../../common/components";
import TaskProgressView from "../ProgressTab/TaskProgressView";

type TasksOnSelectedDialogProps = {
  onClose: () => void;
  selectedRow: taskType;
};

const TasksOnSelectedDialog = (props: TasksOnSelectedDialogProps) => {
  const title = `Задание для проекта "${props.selectedRow.name_project}"${
    props.selectedRow.name_geo ? `, в  "${props.selectedRow.name_geo}"` : ""
  } и
            приложения ${props.selectedRow.app_version_description}`;

  const [tab, setTab] = useState("devices");
  return (
    <PopupDialog onClose={props.onClose} title={title} maxWidth="lg" fullWidth>
      <>
        <StyledTabs
          value={tab}
          onChange={(e, selectedTab) => setTab(selectedTab)}
          aria-label="wrapped label tabs"
          style={{marginBottom: 0}}
        >
          <StyledTab label="Устройства" {...tabProps("devices")} />
          <StyledTab label="Прогресс установки" {...tabProps("progress")} />
        </StyledTabs>
        <TabPanel value={tab} index="devices">
          {tab === "devices" && props.selectedRow.id_task && (
            <TaskDeviceTable task={props.selectedRow} />
          )}
        </TabPanel>
        <TabPanel value={tab} index="progress">
          {tab === "progress" && props.selectedRow.id_task && (
            <TaskProgressView id_task={props.selectedRow.id_task} />
          )}
        </TabPanel>
      </>
    </PopupDialog>
  );
};

export default TasksOnSelectedDialog;
