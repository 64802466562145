import * as React from "react";
import SaveIcon from "@mui/icons-material/Save";
import {Button, Typography} from "@mui/material";
import {useState} from "react";
import {getExtension} from "../functions";
import {alertType} from "../../../common/types";

type ButtonProps = {
  onFileSelectSuccess: (file: File) => void;
  onFileSelectError: (error: alertType) => void;
  disabled?: boolean;
  extension?: string;
  title: string;
  limitMb: number;
};
const FileSelectButton = (props: ButtonProps) => {
  const [file, setFile] = useState({} as File);

  const selectFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;

    if (!files) {
      return;
    }

    if (![props.extension].includes(`.${getExtension(files[0].name)}`)) {
      props.onFileSelectError({
        severity: "error",
        message: [
          `Разрешается загружать только файлы с расширением ${props.extension}`,
        ],
      });
      return;
    }
    if (files[0].size > props.limitMb * Math.pow(2, 20)) {
      props.onFileSelectError({
        severity: "error",
        message: [`Максимальный размер файла: ${props.limitMb}МБ`],
      });
      return;
    }

    setFile(files[0]);
    props.onFileSelectSuccess(files[0]);
  };

  return (
    <>
      <input
        type="file"
        style={{display: "none"}}
        onChange={selectFileHandler}
        alt={props.title}
        id="uploaded_file"
        name="uploaded_file"
        accept={props.extension}
        disabled={props.disabled || file.size > 0}
        value=""
      />
      <label htmlFor="uploaded_file">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<SaveIcon />}
          component="span"
          disabled={props.disabled || file.size > 0}
          id="apps_upload_btn"
        >
          {props.title}
        </Button>
      </label>
      {file.size > 0 && (
        <Typography>
          <span>Выбран файл</span>
          <br />
          <span>
            <strong>Выбран файл: </strong>
            {file.name}
          </span>
        </Typography>
      )}
    </>
  );
};

export default FileSelectButton;
