import * as React from "react";
import {ReactText} from "react";
import {SelectionState, SelectionStateProps} from "@devexpress/dx-react-grid";
import {Plugin, TemplateConnector} from "@devexpress/dx-react-core";

type RowSelectionStateProps<RowType> = {
  selectedRows: RowType[];
  onSelectedRowsChanged: (selection: {
    ids: ReactText[];
    rows: RowType[];
  }) => void;
} & SelectionStateProps;

const RowSelectionState = <RowType,>(
  props: RowSelectionStateProps<RowType>
) => {
  return (
    <Plugin name="RowSelectionState" dependencies={[{name: "Table"}]}>
      <TemplateConnector>
        {({rows, getRowId}) => {
          return (
            <SelectionState
              selection={props.selectedRows.map((row) => getRowId(row))}
              onSelectionChange={(selectedIds) =>
                props.onSelectedRowsChanged({
                  ids: selectedIds,
                  rows: selectedIds.map(
                    (id) =>
                      props.selectedRows.find((row) => getRowId(row) === id) ||
                      rows.find((row: RowType) => getRowId(row) === id)
                  ),
                })
              }
            />
          );
        }}
      </TemplateConnector>
    </Plugin>
  );
};

export default RowSelectionState;
