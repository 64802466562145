import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {DirectoryType} from "../../common/APIRequests/directory/getDirectory";
import patchDirectory from "../../common/APIRequests/directory/patchDirectory";
import postDirectory from "../../common/APIRequests/directory/postDirectory";
import {EventType} from "../../common/APIRequests/event/getEvents";
import {errorType} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import StringField from "../common/formFields/StringField";

type Props = {
  handleClose: () => void;
  eventValues: EventType;
  setEventValues: Function;
  update: Function;
  updateEvents: Function;
  directory?: DirectoryType;
  loading: boolean;
  setLoading: Function;
  open: boolean;
  setSelectedUserDir: (d: DirectoryType) => void;
};

const defaultDirectory = {
  name_dir: "",
  id_type_dir: 1,
  editable: 1,
  status: "1",
};

const DirectoryForm = (props: Props) => {
  const {
    handleClose,
    update,
    updateEvents,
    directory,
    loading,
    setLoading,
    open,
    eventValues,
    setEventValues,
    setSelectedUserDir,
  } = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const nameInputRef = useRef(null as null | HTMLFormElement);
  useEffect(() => {
    if (open) {
      nameInputRef.current && nameInputRef.current.focus();
    }
  }, [open]);

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState(
    directory && directory.id_dir
      ? directory
      : (defaultDirectory as DirectoryType)
  );

  const handleChange = (name: string) => (value: number) =>
    setValues({...values, [name]: value});

  const handleSubmit = async () => {
    setLoading(true);

    let result;
    if (directory && directory.id_dir) {
      result = await patchDirectory(values.id_dir, values);
    } else {
      result = await postDirectory(values);
    }

    if (result.success && result.row) {
      setErrors({});
      handleClose();
      setAlert({
        severity: "success",
        message: [
          directory ? "Классификатор изменен" : "Классификатор добавлен",
        ],
      });
      await update();
      await updateEvents();
      setEventValues({...eventValues, id_type_user: result.row.id_dir});
      setSelectedUserDir(result.row);
    } else {
      setErrors(result.errors);
    }

    setLoading(false);
  };

  return (
    <Fragment>
      <StringField
        value={values.name_dir}
        setValue={handleChange("name_dir")}
        name="name_dir"
        label="Наименование"
        errors={errors}
        setErrors={setErrors}
        inputRef={nameInputRef}
        style={{marginBottom: theme.spacing(2)}}
      />
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitMessage="Сохранить"
      />
    </Fragment>
  );
};

export default DirectoryForm;
