import {Box, FormControl, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {default as React, ReactNode, useRef, useState} from "react";
import {errorType, fieldProps} from "../../common/types";
import StringField from "../common/formFields/StringField";
import SelectSingleField from "../common/formFields/SelectSingleField";
import BooleanField from "../common/formFields/BooleanField";
import SelectMultipleField from "../common/formFields/SelectMultipleField";
import ChipTextField from "../common/formFields/ChipTextField";
import theme from "../../theme";
import {
  BottomToolbarWrapper,
  ToolbarItem,
  ToolbarSeparator,
} from "../common/Toolbar";
import {NestedPolicyType} from "../../common/APIRequests/policy/types";
import {sanitizeFloat, sanitizeInt} from "../common/formFields/sanitizers";

const NestedPolicyForm = <PolicyType extends NestedPolicyType>(props: {
  handleClose: () => void;
  update: (row: PolicyType) => void;
  nestedPolicy: PolicyType;
}) => {
  const {handleClose, update, nestedPolicy} = props;

  const jsonParsedRef = useRef(false);
  const compactString = (policy: PolicyType) => {
    if (policy.value_type !== "string") return policy;
    return {
      ...policy,
      value: policy.value.replaceAll("\n", "").replaceAll("\t", ""),
    };
  };
  const tryJson = (policy: PolicyType) => {
    if (policy.value_type !== "string") return policy;
    try {
      const updatedPolicy = {
        ...policy,
        value: JSON.stringify(JSON.parse(policy.value), null, "\t"),
      };
      jsonParsedRef.current = true;
      return updatedPolicy;
    } catch {
      return policy;
    }
  };

  const [values, setValues] = useState(tryJson(nestedPolicy as PolicyType));

  const handleChangeValue = (name: string) => (value: string) =>
    setValues({...values, [name]: value});

  const [errors, setErrors] = useState({} as errorType);

  const handleSubmit = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    const policy = jsonParsedRef.current ? compactString(values) : values;
    update({...policy, affiliation: 1});
    handleClose();
  };

  let valueField: ReactNode;

  const valueProps: fieldProps = {
    setValue: handleChangeValue("value"),
    value: values.value,
    name: "value",
    label: "Значение",
    errors: errors,
    setErrors: setErrors,
    inputProps: {maxLength: 2000},
    style: {marginTop: theme.spacing(2)},
  };
  if (
    ["int", "float", "string"].includes(values.value_type) &&
    values.possible_values
  ) {
    valueField = (
      <SelectSingleField
        {...valueProps}
        options={values.possible_values.split(",")}
      />
    );
  } else if (values.value_type === "int") {
    valueField = <StringField {...valueProps} sanitizer={sanitizeInt} />;
  } else if (values.value_type === "float") {
    valueField = <StringField {...valueProps} sanitizer={sanitizeFloat} />;
  } else if (values.value_type === "string") {
    valueField = <StringField {...valueProps} multiline={true} />;
  } else if (values.value_type === "boolean") {
    valueField = <BooleanField {...valueProps} />;
  } else if (values.value_type === "list" && values.possible_values) {
    valueField = (
      <SelectMultipleField
        {...valueProps}
        options={values.possible_values.split(",")}
      />
    );
  } else {
    valueField = <ChipTextField {...valueProps} />;
  }

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <TextField
          variant="outlined"
          fullWidth
          label="Системное наименование"
          placeholder="Системное наименование"
          value={values.policy_name || ""}
          disabled={true}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          variant="outlined"
          fullWidth
          label="Описание"
          placeholder="Описание"
          value={values.description || ""}
          disabled={true}
          maxRows="6"
          style={{marginTop: theme.spacing(2)}}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          variant="outlined"
          fullWidth
          label="Тип значения"
          placeholder="Тип значения"
          value={values.value_type || ""}
          disabled={true}
          style={{marginTop: theme.spacing(2)}}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          variant="outlined"
          fullWidth
          label="Возможные значения"
          placeholder="Возможные значения"
          value={values.possible_values || ""}
          disabled={true}
          style={{marginTop: theme.spacing(2)}}
        />
      </FormControl>
      <React.Fragment>{valueField}</React.Fragment>
      <BottomToolbarWrapper>
        <ToolbarSeparator />
        <ToolbarItem>
          <Button
            variant="contained"
            color="primary"
            disabled={Object.keys(errors).length > 0}
            onClick={handleSubmit}
            id="nested_policy_form_save"
          >
            Сохранить
          </Button>
        </ToolbarItem>
        <Box>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
            id="nested_policy_form_close"
          >
            Закрыть
          </Button>
        </Box>
      </BottomToolbarWrapper>
    </React.Fragment>
  );
};
export default NestedPolicyForm;
