import {
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import React, {useState} from "react";
import {EventType} from "../../common/APIRequests/event/getEvents";
import {
  logEventFilterType,
  logEventType,
} from "../../common/APIRequests/logEvent/getLogEvents";
import {BACKEND_DATETIME_FORMAT, optionType} from "../../common/types";
import FormButtons from "../common/buttons/FormButtons";
import {Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import PickerDateTime from "../common/formFields/PickerDateTime";
import {DEFAULT_LOG_EVENT_FILTER} from "./LogEvents";
import FilterIconButton from "../common/buttons/FilterIconButton";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: "62vw",
    zIndex: 1000,
  },
  filterItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
}));

type Props = {
  isLogEventGrouped: boolean;
  setFilters: Function;
  filters: logEventFilterType;
  loading: boolean;
  events: Array<EventType>;
  userDirsOptions: Array<optionType>;
  propertyDirsOptions: Array<optionType>;
  levelDirsOptions: Array<optionType>;
  rows: Array<logEventType>;
};

const logEventStringFilters = [
  {label: "Имя объекта", name: "name_object"},
  {label: "Имя приложения", name: "name_app"},
  {label: "Версия приложения", name: "app_version_name"},
  {label: "Код подразделения", name: "code_division"},
  {label: "Серийный номер", name: "serial_number"},
];

const Filter = (props: Props) => {
  const {
    isLogEventGrouped,
    loading,
    events,
    userDirsOptions,
    propertyDirsOptions,
    levelDirsOptions,
  } = props;

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({...props.filters});

  const handleChange = (name: string, value: number) => {
    if (value === 0) {
      setFilters({...filters, [name]: undefined});
    } else {
      setFilters({...filters, [name]: value});
    }
  };

  const {classes} = useStyles();

  return (
    <React.Fragment>
      <FilterIconButton onClick={() => setOpen(true)} />
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          keepMounted
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          classes={{
            scrollPaper: classes.scrollPaper,
          }}
        >
          <ClosableDialogTitle onClose={() => setOpen(false)}>
            Фильтр
          </ClosableDialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  {!isLogEventGrouped && (
                    <Grid item xs={2} className={classes.filterItem}>
                      {events.length > 0 && (
                        <FormControl fullWidth>
                          <InputLabel htmlFor="event">Событие</InputLabel>
                          <Select
                            id="event"
                            fullWidth
                            value={
                              filters.ids_event.length
                                ? filters.ids_event[0].toString()
                                : "0"
                            }
                            onChange={(e: any) => {
                              setFilters({
                                ...filters,
                                ids_event:
                                  e.target.value === "0"
                                    ? []
                                    : [parseInt(e.target.value)],
                              });
                            }}
                            disabled={loading}
                          >
                            <MenuItem key="0" value="0">
                              Все
                            </MenuItem>
                            {events.map((e: EventType) => (
                              <MenuItem key={e.id_event} value={e.id_event}>
                                {e.event_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  )}
                  {!isLogEventGrouped && (
                    <Grid item xs={2} className={classes.filterItem}>
                      {userDirsOptions.length > 0 && (
                        <FormControl fullWidth>
                          <InputLabel htmlFor="id_type_user">
                            Классификатор
                          </InputLabel>
                          <Select
                            id="id_type_user"
                            name="id_type_user"
                            fullWidth
                            value={
                              filters.id_type_user
                                ? filters.id_type_user.toString()
                                : "0"
                            }
                            onChange={(e: any) =>
                              handleChange("id_type_user", e.target.value)
                            }
                            disabled={loading}
                          >
                            {userDirsOptions.map((e: optionType) => (
                              <MenuItem key={e.key} value={e.value}>
                                {e.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={2} className={classes.filterItem}>
                    {propertyDirsOptions.length > 0 && (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="id_type_property">
                          Тип события
                        </InputLabel>

                        <Select
                          id="id_type_property"
                          name="id_type_property"
                          fullWidth
                          value={
                            filters.id_type_property
                              ? filters.id_type_property.toString()
                              : "0"
                          }
                          onChange={(e: any) =>
                            handleChange("id_type_property", e.target.value)
                          }
                          disabled={loading}
                        >
                          {propertyDirsOptions.map((e: optionType) => (
                            <MenuItem key={e.key} value={e.value}>
                              {e.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.filterItem}>
                    {levelDirsOptions.length > 0 && (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="id_type_level">
                          Уровень хранения
                        </InputLabel>
                        <Select
                          id="id_type_level"
                          name="id_type_level"
                          fullWidth
                          value={
                            filters.id_type_level
                              ? filters.id_type_level.toString()
                              : "0"
                          }
                          onChange={(e: any) =>
                            handleChange("id_type_level", e.target.value)
                          }
                          disabled={loading}
                        >
                          {levelDirsOptions.map((e: optionType) => (
                            <MenuItem key={e.key} value={e.value}>
                              {e.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {logEventStringFilters.map((filterParams) => (
                    <Grid item xs={2} className={classes.filterItem}>
                      <TextField
                        id={filterParams.name}
                        name={filterParams.name}
                        label={filterParams.label}
                        value={
                          filters[
                            filterParams.name as keyof logEventFilterType
                          ] ?? ""
                        }
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            [filterParams.name]: e.target.value,
                          });
                        }}
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => {
                                setFilters({
                                  ...filters,
                                  [filterParams.name]: "",
                                });
                              }}
                              size="small"
                              disabled={
                                loading ||
                                !filters[
                                  filterParams.name as keyof logEventFilterType
                                ]
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={2}
                    className={classes.filterItem}
                    style={{paddingRight: 0}}
                  >
                    <PickerDateTime
                      setValue={(value) =>
                        setFilters({
                          ...filters,
                          start_event_time: value
                            ? moment(value).format(BACKEND_DATETIME_FORMAT)
                            : null,
                        })
                      }
                      value={filters.start_event_time}
                      label="От (возникновения)"
                      name="start_event_time"
                      disabled={loading}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.filterItem}
                    style={{paddingLeft: 0}}
                  >
                    <PickerDateTime
                      setValue={(value) =>
                        setFilters({
                          ...filters,
                          end_event_time: value
                            ? moment(value).format(BACKEND_DATETIME_FORMAT)
                            : null,
                        })
                      }
                      value={filters.end_event_time}
                      label="До (возникновения)"
                      name="end_event_time"
                      disabled={loading}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.filterItem}
                    style={{paddingRight: 0}}
                  >
                    <PickerDateTime
                      setValue={(value) =>
                        setFilters({
                          ...filters,
                          start_input_date: value,
                        })
                      }
                      value={filters.start_input_date}
                      label="От (поступления)"
                      name="start_input_date"
                      disabled={loading}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.filterItem}>
                    <PickerDateTime
                      setValue={(value) =>
                        setFilters({
                          ...filters,
                          end_input_date: value,
                        })
                      }
                      value={filters.end_input_date}
                      label="До (поступления)"
                      name="end_input_date"
                      disabled={loading}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FormButtons
              loading={loading}
              handleSubmit={() => {
                props.setFilters(filters);
              }}
              handleClose={() => {
                setFilters(DEFAULT_LOG_EVENT_FILTER);
                props.setFilters(DEFAULT_LOG_EVENT_FILTER);
              }}
              submitMessage="Показать"
              cancelMessage="Сбросить все"
            />
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default Filter;
