import {projectType} from "../../common/APIRequests/project/getProjects";
import {optionType} from "../../common/types";

export const smashTree = (obj: projectType, result: Array<projectType>) => {
  result.push(obj);
  if ("children" in obj) {
    obj.children?.forEach((child: projectType) => smashTree(child, result));
  }
};

export const generateProjectOptions = (
  obj: projectType,
  result: Array<optionType>,
  prefix = ""
) => {
  result.push({
    key: obj.id_project,
    value: obj.id_project,
    text: prefix + obj.project_name,
  });
  obj.children &&
    obj.children.forEach((child: projectType) =>
      generateProjectOptions(child, result, prefix + "- ")
    );
};

export const generateProjectOptionsForEachProject = (
  projects: projectType[]
) => {
  let result: Array<optionType> = [{key: 0, value: 0, text: ""}];
  projects.forEach((project: projectType) =>
    generateProjectOptions(project, result)
  );
  return result;
};

export const getExtension = (filename: string) => {
  const parts = filename.split(".");
  return parts[parts.length - 1];
};

/**
 * @description first grouped=groupBy(pets, pet => pet.type) when grouped.get('Dog')
 */
export function groupBy<T>(
  list: Array<T>,
  keyGetter: (row: T) => string | undefined
) {
  const map = new Map();
  list.forEach((item: T) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const formatGeoFullName = (fullname?: string, count?: number) =>
  fullname
    ? `${fullname.replaceAll("•", ", ")}${
        count && count > 1 ? `... +${count - 1}` : ""
      }`
    : "";

export const parseGeoFullName = (fullname?: string, count?: number) => {
  const parsed = fullname?.split("•");
  const result = {
    net: parsed?.[0],
    branch: parsed?.[1],
    division: parsed?.[2],
  };

  if (count && count > 1) {
    if (result.division) {
      result.division += `,... +${count - 1}`;
    } else {
      result.branch += `,... +${count - 1}`;
    }
  }
  return result;
};

export const flattenArrayWithChildren = <T extends {children?: Array<T>}>(
  array: Array<T>
) => {
  let flattenedArray: Array<T> = [];
  array.forEach((item) => {
    flattenedArray.push(item);
    if (item.children && item.children.length > 0) {
      const children = flattenArrayWithChildren(item.children);
      flattenedArray = flattenedArray.concat(children);
    }
  });
  return flattenedArray;
};
