import * as React from "react";
import {CSSProperties} from "react";

const Logo = (props: {style?: CSSProperties}) => {
  return (
    <img
      src={process.env.PUBLIC_URL + "/images/logo.svg"}
      height={`${props.style?.height || 20}px`}
      alt="logo image"
      style={props.style}
    />
  );
};

export default Logo;
