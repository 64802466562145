import * as React from "react";
import {useContext, useState} from "react";
import {ReportFilterProps} from "../eventFilters/EventIdReportFilter";
import SelectSingleField from "../../../../../common/formFields/SelectSingleField";
import {AppContext} from "../../../../../App-context";
import {optionType} from "../../../../../../common/types";
import {projectType} from "../../../../../../common/APIRequests/project/getProjects";
import {generateProjectOptions} from "../../../../../common/functions";

const ProjectReportFilter = (props: ReportFilterProps) => {
  const [errors, setErrors] = useState({});
  const {projects} = useContext(AppContext);

  const projectOptions: Array<optionType> = [];
  projects.forEach((project: projectType) =>
    generateProjectOptions(project, projectOptions)
  );

  return (
    <SelectSingleField
      options={projectOptions}
      setValue={props.setValue}
      value={props.value}
      name="value"
      label={props.name}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default ProjectReportFilter;
