import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {IntegratedSorting, SortingState} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import React, {useEffect} from "react";
import {EventType} from "../../common/APIRequests/event/getEvents";
import {logEventGroupedType} from "../../common/APIRequests/logEvent/getLogEventGrouped";
import {
  logEventFilterType,
  logEventType,
} from "../../common/APIRequests/logEvent/getLogEvents";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {columnType, optionType, sortingType} from "../../common/types";
import {RefreshButton} from "../common/buttons/ToolbarButtons";
import {StyledVirtualTable} from "../common/grid/cell";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import GroupSwitch from "./GroupSwitch";
import LogEventFilter from "./LogEventFilter";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../common/components";

const columnExtensions = [
  {columnName: "id_type_user", width: 130},
  {columnName: "type_user_name", width: 200},
  {columnName: "count", width: 300},
];

type Props = {
  isLogEventGrouped: boolean;
  setIsLogEventGrouped: (value: boolean) => void;
  logEventGrouped: Array<logEventGroupedType>;
  updateLogEventGrouped: () => void;
  filters: logEventFilterType;
  setFilters: Function;
  events: Array<EventType>;
  userDirsOptions: Array<optionType>;
  propertyDirsOptions: Array<optionType>;
  levelDirsOptions: Array<optionType>;
  logEventRows: Array<logEventType>;
  loading: boolean;
};

const columns: columnType = [
  {name: "id_type_user", title: "ID"},
  {
    name: "type_user_name",
    title: "Классификатор",
  },
  {name: "count", title: "Количество"},
];
const hiddenColumnNames = ["id_type_user"];

const LogEventGroupedTable = (props: Props) => {
  const {
    isLogEventGrouped,
    setIsLogEventGrouped,
    updateLogEventGrouped,
    logEventGrouped,
    filters,
    setFilters,
    events,
    userDirsOptions,
    propertyDirsOptions,
    levelDirsOptions,
    logEventRows,
    loading,
  } = props;

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "log_event_group_sorting",
    [{columnName: "type_user_name", direction: "asc"}]
  );

  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "log_event_grouped_ordering",
    columns.map((row) => row.name)
  );

  const handleGoToGroup = (logEventGrouped: logEventGroupedType) => {
    if (logEventGrouped.id_type_user) {
      setFilters({...filters, id_type_user: logEventGrouped.id_type_user});
    } else {
      setFilters({...filters, id_type_user: -1});
    }
    setIsLogEventGrouped(false);
  };

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      <Grid
        columns={columns}
        rows={logEventGrouped}
        getRowId={(row) => row.id_type_user}
      >
        {/** Sorting */}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <LogEventFilter
              isLogEventGrouped={isLogEventGrouped}
              setFilters={setFilters}
              filters={filters}
              loading={loading}
              events={events}
              userDirsOptions={userDirsOptions}
              propertyDirsOptions={propertyDirsOptions}
              levelDirsOptions={levelDirsOptions}
              rows={logEventRows}
            />
          </ToolbarItem>
          <ToolbarItem>
            <GroupSwitch
              checked={isLogEventGrouped}
              onChange={setIsLogEventGrouped}
            />
          </ToolbarItem>

          <ToolbarItem>
            <RefreshButton disabled={loading} update={updateLogEventGrouped} />
          </ToolbarItem>

          <TemplatePlaceholder />
        </Template>

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              onClick={(e: any) => handleGoToGroup(row)}
              className={classes.gridRow}
            />
          )}
          height="74vh"
        />
        {/** Column Reordering */}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/** Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/** Column Resizing */}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="log_event_grouped"
          autoWidthColumnName={"type_user_name"}
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default LogEventGroupedTable;
