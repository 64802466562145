import * as React from "react";
import EventIdReportFilter, {
  ReportFilterProps,
} from "./eventFilters/EventIdReportFilter";
import ClassificatorReportFilter from "./eventFilters/ClassificatorReportFilter";
import DateReportFilter from "./commonFilters/DateReportFilter";
import EventTypeReportFilter from "./eventFilters/EventTypeReportFilter";

type EventReportFilterProps = {
  systemName: string;
} & ReportFilterProps;

const EventReportFilter = (props: EventReportFilterProps) => {
  const {systemName, ...filterProps} = props;
  switch (systemName) {
    case "id_event":
      return <EventIdReportFilter {...filterProps} />;
    case "id_dir":
      return <ClassificatorReportFilter {...filterProps} />;
    case "event_time":
      return <DateReportFilter {...filterProps} />;
    case "id_type_dir":
      return <EventTypeReportFilter {...filterProps} />;
    default:
      return null;
  }
};

export default EventReportFilter;
