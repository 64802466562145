import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type deviceApplicationType = {
  id_dev_app: number;
  id_app: number;
  app_name: string;
  app_description: string;
  app_uid: string;
  file_path: string;
  file_size: number;
  install_time: string | null;
  name: string;
  status: string | null;
  version: string;
  version_last: string;
};

const getDeviceApplication = async (id_device: number) =>
  baseGetRowsRequest<deviceApplicationType>(
    `/device/${id_device}/application_version/`
  );

export default getDeviceApplication;
