import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type deviceLogType = {
  id_device: number;
  id_devlog: number;
  log_time: string;
  tag: string;
  message: string;
  information: null;
};

const getDeviceLogs = async (
  id_device: number,
  tag: Array<string> | undefined,
  start_time: string | undefined,
  end_time: string | undefined,
  message: string | undefined,
  sort_field: string,
  sort_desc: boolean
) =>
  baseGetRowsRequest<deviceLogType>(`/device/${id_device}/log/`, {
    id_device,
    tag: tag ? tag.join(",") : undefined,
    start_time,
    end_time,
    message,
    sort_field,
    sort_desc,
  });

export default getDeviceLogs;
