import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type DeviceFirmwareType = {
  id_firmware: number;
  name: string;
  is_target: number | string;
  has_gms: number | string;
  file_size: number | null;
  file_path: string;
  comment_text: string;
};

const getDeviceFirmware = async (id_device: number) =>
  baseGetRowsRequest<DeviceFirmwareType>(`/device/${id_device}/firmware/`);

export default getDeviceFirmware;
