import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useContext, useState} from "react";
import {Transition} from "../components";
import {AppContext} from "../../App-context";
import RequestPinDialog from "../pin/RequestPinDialog";

export const ConfirmDialog = (props: {
  loading: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  message: string;
  checkPin?: boolean;
}) => {
  const {loading, handleClose, handleConfirm, message, checkPin} = props;

  const appContext = useContext(AppContext);
  const {pinStatus} = appContext;

  const [pinOpen, setPinOpen] = useState(false);

  const useStyles = makeStyles()((theme) => ({
    buttons: {
      marginBottom: theme.spacing(1),
    },
    buttonProgress: {
      position: "absolute",
      left: "50%",
      marginLeft: "-12px",
    },
  }));

  const {classes} = useStyles();

  const confirm = async () => {
    if (!loading && checkPin && pinStatus) {
      setPinOpen(true);
    } else if (!loading) {
      await handleConfirm();
    }
  };

  return (
    <React.Fragment>
      {checkPin && pinOpen && (
        <RequestPinDialog
          onClose={() => setPinOpen(false)}
          onSubmit={handleConfirm}
        />
      )}
      <Dialog
        open={true}
        onClose={() => !loading && handleClose()}
        keepMounted
        TransitionComponent={Transition}
      >
        <DialogTitle>
          {message.split("\n").map((i, key) => {
            return <div key={key}>{i}</div>;
          })}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.buttons}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                fullWidth
                onClick={confirm}
                id="confirm_dialog_yes"
              >
                Да
                {loading && (
                  <CircularProgress
                    size={28}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                onClick={() => handleClose()}
                disabled={loading}
                fullWidth
                id="confirm_dialog_no"
              >
                Нет
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
