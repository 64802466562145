import * as React from "react";
import {useState} from "react";
import BooleanField from "../../../../../common/formFields/BooleanField";
import {ReportFilterProps} from "../eventFilters/EventIdReportFilter";

const BooleanReportFilter = (props: ReportFilterProps) => {
  const [errors, setErrors] = useState({});

  return (
    <BooleanField
      value={props.value || "0"}
      setValue={props.setValue}
      name="value"
      label={props.name}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default BooleanReportFilter;
