import {basePostRequest} from "../base/basePostRequest";
import {projectProfileType} from "./getProjectProfiles";

const postProjectProfiles = async (
  id_project: number,
  ids: Array<number | string>
) =>
  basePostRequest<projectProfileType>(`/project/${id_project}/profile/`, {
    ids: ids,
  });

export default postProjectProfiles;
