import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {fieldProps, optionType} from "../../../common/types";

const SelectSingleField = (
  props: fieldProps & {
    options: Array<string | optionType>;
    size?: "small" | "medium" | undefined;
    endAdornment?: JSX.Element;
    variant?: "standard" | "outlined" | "filled";
  }
) => {
  const {
    setValue,
    value,
    options,
    name,
    label,
    errors,
    style,
    disabled,
    size,
    endAdornment,
  } = props;

  const handleChange = (e: any) => {
    delete errors[name];
    setValue(e.target.value);
  };

  return (
    <FormControl
      fullWidth
      error={!!errors[name]}
      style={style}
      size={size || "medium"}
    >
      <InputLabel id="select-field-label" variant="outlined">
        {label}
      </InputLabel>
      <Select
        value={value ?? ""}
        onChange={handleChange}
        inputProps={{name: name, id: name}}
        error={!!errors[name]}
        aria-describedby={`component-error-${name}`}
        disabled={disabled || false}
        endAdornment={endAdornment}
        variant="outlined"
        labelId="select-field-label"
        label={label}
      >
        {options.map((row: string | optionType, index: number) =>
          typeof row === "string" ? (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ) : (
            <MenuItem key={row.key || index.toString()} value={row.value}>
              {row.text}
            </MenuItem>
          )
        )}
      </Select>
      {errors[name] && (
        <FormHelperText id={`component-error-${name}`}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectSingleField;
