import Column from "../../Tables/Column";

const NotificationRecipientColumns = [
  new Column("id_channel", "ID", 100),
  new Column("name_type_channel", "Наименование", 600, {auto: true}),
  new Column("recipient", "Получатель", 200),
  new Column("is_deleted", "Удален", 130),
];

export default NotificationRecipientColumns;
