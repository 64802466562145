import * as React from "react";
import {useState} from "react";
import CoreTable from "../../Tables/CoreTable";
import UserReportFilterColumns from "./UserReportFilterColumns";
import getUserReportFilters from "../../../common/APIRequests/reportFilterUser/getUserReportFilters";
import {UserReport} from "../../../common/APIRequests/user_report/getUserReports";
import {AddNewButton} from "../../common/buttons/ToolbarButtons";
import {useTrigger} from "../../../common/customHooks";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import deleteReportFiltersUser from "../../../common/APIRequests/reportFilterUser/deleteReportFiltersUser";
import AddNewUserReportFilterDialog from "./AddNew/AddNewUserReportFilterDialog";

type UserReportFiltersTableProps = {
  userReport: UserReport;
};

const UserReportFiltersTable = (props: UserReportFiltersTableProps) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [trigger, updateTrigger] = useTrigger();

  return (
    <CoreTable
      name={"user_report_columns"}
      columns={UserReportFilterColumns}
      getRowId={(row) => row.id_report_filter_user}
      updateRows={() => getUserReportFilters(props.userReport.id_user_report)}
      selectionEnabled
      integratedSearch
      updateTrigger={trigger}
      height={"60vh"}
      defaultSorting={{columnName: "name", direction: "asc"}}
      selectionButton={(selection, update) => (
        <MenuDeleteAction
          selection={selection}
          update={update}
          action={(ids) => {
            updateTrigger();
            return deleteReportFiltersUser(ids);
          }}
        />
      )}
      toolBarLeftItems={[
        <AddNewButton
          onClick={() => setAddDialogOpen(true)}
          key="profile_devices-add_button"
        />,
      ]}
      dialog={
        <AddNewUserReportFilterDialog
          onClose={() => setAddDialogOpen(false)}
          open={addDialogOpen}
          userReport={props.userReport}
        />
      }
    />
  );
};

export default UserReportFiltersTable;
