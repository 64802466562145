import {baseDeleteRequest} from "../base/baseDeleteRequest";

const deleteNotificationRecipient = async (
  id_notify: number,
  notifificationRecipientIds: Array<number | string>
) =>
  baseDeleteRequest(`/notify/${id_notify}/recipient/`, {
    ids: notifificationRecipientIds,
  });

export default deleteNotificationRecipient;
