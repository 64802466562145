import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export const EVENT_IS_ACTIVE = "1";
export const EVENT_IS_NOT_ACTIVE = "0";

export type EventType = {
  id_event: number;
  id_type_user?: number;
  id_type_property?: number;
  id_type_by?: number;
  id_type_level?: number;
  type_user_name?: string;
  type_property_name?: string;
  type_by_name?: string;
  type_level_name?: string;
  is_seim_log?: number;
  event_code: string;
  event_name: string;
  editable: number;
  status: string;
};

export const defaultEventFilter: EventFilterType = {
  id_type_user: undefined,
  id_type_property: undefined,
  id_type_by: undefined,
  id_type_level: undefined,
  status: EVENT_IS_ACTIVE,
};

type EventFilterType = {
  id_type_user: number | undefined;
  id_type_property: number | undefined;
  id_type_by: number | undefined;
  id_type_level: number | undefined;
  status: string;
};

const getEvents = async (filters: EventFilterType = defaultEventFilter) =>
  baseGetRowsRequest<EventType>(`/event/`, {filters});

export default getEvents;
