import * as React from "react";
import {FormControl} from "@mui/material";
import SelectSingleFilterField from "../../common/filterFields/SelectSingleFilterField";
import {FilterComponentProps} from "../../Tables/CoreTable";
import {
  NotificationMessageFilterType,
  NotificationMessageStatusType,
} from "../../../common/APIRequests/notifyMessage/getNotificationMessages";

export const notificationMessageStatusTitles = {
  0: "Новое",
  1: "Отправлено",
  2: "Завершено",
  3: "Завершено с ошибкой",
};

type NotificationMessagesFilterProps =
  FilterComponentProps<NotificationMessageFilterType>;

const NotificationMessagesFilter = (props: NotificationMessagesFilterProps) => {
  return (
    <FormControl fullWidth>
      <SelectSingleFilterField
        name="status"
        label="Статус"
        includeAllOption
        value={props.filters.status}
        update={(status) =>
          props.updateFilters({...props.filters, status: status})
        }
        style={{width: 200}}
        options={Object.keys(notificationMessageStatusTitles).map((value) => {
          const valueNumber = parseInt(value);
          return {
            value: valueNumber,
            text: notificationMessageStatusTitles[
              valueNumber as NotificationMessageStatusType
            ],
          };
        })}
      />
    </FormControl>
  );
};

export default NotificationMessagesFilter;
