import {
  DataTypeProvider,
  DataTypeProviderProps,
} from "@devexpress/dx-react-grid";
import {Tooltip} from "@mui/material";
import moment from "moment";
import * as React from "react";
import {taskType} from "../../common/APIRequests/task/getTasks";
import {parseGeoFullName} from "../common/functions";
import {BooleanTypeProvider} from "../common/grid/providers";
import Column, {ProviderType} from "../Tables/Column";
import {FRONT_DATETIME_FORMAT} from "../../common/types";

const StatusProvider: ProviderType = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    for={props.for}
    formatterComponent={StatusFormatter}
    key={"tasks_datatype_provider"}
  />
);

export const StatusFormatter = (data: {value: number}) => {
  const colors: {[s: number]: string} = {
    0: "blue",
    1: "#E8C547", //yellow
    2: "green",
  };
  const titles: {[s: number]: string} = {
    0: "Инициализация",
    1: "В работе",
    2: "Завершено",
  };
  return (
    <Tooltip title={titles[data.value]}>
      <div
        className="status-circle"
        style={{
          backgroundColor: colors[data.value],
        }}
      />
    </Tooltip>
  );
};

const TasksColumns: Array<Column> = [
  new Column("id_task", "ID", 100, {hiddenByDefault: true}),
  new Column("status", "Статус", 100, {
    align: "center",
    provider: StatusProvider,
  }),
  new Column("create_date", "Время создания задания", 220, {
    getCellValue: (row: taskType) =>
      row.create_date
        ? moment(row.create_date).format(FRONT_DATETIME_FORMAT)
        : undefined,
  }),
  new Column("id_project", "ID проекта", 100, {hiddenByDefault: true}),
  new Column("name_project", "Наименование проекта", 250, {auto: true}),
  new Column("geo_net", "Сеть", 150, {
    getCellValue: (row) =>
      parseGeoFullName(row.geo_fullname, row.geo_count).net,
  }),
  new Column("geo_branch", "Филиал", 150, {
    getCellValue: (row) =>
      parseGeoFullName(row.geo_fullname, row.geo_count).branch,
  }),
  new Column("geo_division", "Подразделение", 150, {
    getCellValue: (row) =>
      parseGeoFullName(row.geo_fullname, row.geo_count).division,
  }),
  new Column("id_app_version", "ID версии приложения", 200, {
    hiddenByDefault: true,
  }),
  new Column("app_version_name", "Наименование версии", 200),
  new Column("app_version_description", "Описание версии", 200),
  new Column("ldap_login", "Инициатор", 200),
  new Column("children", "Включать подпроекты", 200, {
    align: "center",
    provider: BooleanTypeProvider,
  }),
];

export default TasksColumns;
