import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type deviceCommandType = {
  id_device: number;
  id_comdev_link: number;
  status: string;
  createdate: string;
  status_date: string;
  tag: string;
  addition_data: string;
  username: string;
  description: string;
};

const getDeviceCommands = async (
  id_device: number,
  sort_field: string,
  sort_desc: boolean,
  date?: string | null
) =>
  baseGetRowsRequest<deviceCommandType>(`/device/${id_device}/command/`, {
    id_device,
    date,
    sort_field,
    sort_desc,
  });

export default getDeviceCommands;
