import * as React from "react";
import PopupDialog from "../../common/PopupDialog";
import TaskTemplateForm from "../Forms/TaskTemplateForm";

type TaskTemplateDialogProps = {
  onClose: () => void;
  update: () => void;
  taskTemplateId?: number;
};

const TaskTemplateDialog = (props: TaskTemplateDialogProps) => {
  return (
    <PopupDialog
      onClose={props.onClose}
      title={"Создать шаблон задания"}
      maxWidth="md"
      fullWidth
    >
      <TaskTemplateForm
        handleClose={props.onClose}
        updateTable={props.update}
        taskTemplateId={props.taskTemplateId}
      />
    </PopupDialog>
  );
};

export default TaskTemplateDialog;
