import {baseDeleteRequest} from "../base/baseDeleteRequest";

const deleteProjectApplicationsProfile = async (
  id_project: number,
  id_app: number
) =>
  baseDeleteRequest(
    `/project/${id_project}/application/${id_app}/delete_profile/`
  );

export default deleteProjectApplicationsProfile;
