import {Checkbox, FormControl, FormControlLabel} from "@mui/material";

type BooleanFilterProps = {
  value: number;
  onSubmit: (name: string) => (value: number) => void;
  name: string;
  label: string;
};

const BooleanFilter = (props: BooleanFilterProps) => {
  return (
    <FormControl fullWidth style={{width: "250px", marginBottom: "-10px"}}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!props.value}
            id="includeChild"
            name={props.label}
            onChange={(event, value: boolean) =>
              props.onSubmit(props.name)(value ? 1 : 0)
            }
          />
        }
        label={props.label}
      />
    </FormControl>
  );
};

export default BooleanFilter;
