import {basePostRequest} from "../base/basePostRequest";
import {TaskTemplateDetailsType} from "./getTemplate";

export type PostTaskTemplateType = {
  name: string;
  id_project: number;
  children: "0" | "1";
  id_app_version: number;
  arr_geo: number[];
};

const postTaskTemplate = async (taskTemplate?: PostTaskTemplateType) =>
  basePostRequest<TaskTemplateDetailsType>(`/task_template/`, taskTemplate);

export default postTaskTemplate;
