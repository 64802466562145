import {Plugin, Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  CustomPaging,
  IntegratedFiltering,
  IntegratedSelection,
  PagingState,
  SearchState,
  SelectionState,
  Sorting,
  SortingState,
  Table as TableBase,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import * as React from "react";
import {
  ComponentType,
  ReactElement,
  ReactText,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {errorType} from "../../common/types";
import {StyledTableSelection, StyledVirtualTable} from "../common/grid/cell";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import RowSelectionState from "../common/grid/RowSelectionState";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../common/grid/StyledSearchPanel";
import CustomIntegratedSorting from "../common/CustomIntegratedSorting";
import {paginationMessages} from "../common/pagination";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import Column from "./Column";
import {calcPageSize} from "./helpers";
import {LoadingIndicator} from "../common/components";
import {MenuFilter} from "./MenuFilter";
import TableContext, {TableContextType} from "./TableContext";
import {
  RefreshButton,
  RefreshButtonProps,
} from "../common/buttons/ToolbarButtons";
import actionHasPermissions from "../common/actionPermissions";
import {AppContext} from "../App-context";

export type GeneralFilterType =
  | {[name: string]: string | number | string[] | number[] | null}
  | undefined;

export type UpdateRowsType<RowType, FilterType = undefined> = (params: {
  sorting?: {
    sort_field: string;
    sort_desc: boolean;
  };
  paging?: {
    page: number;
    page_size: number;
  };
  filters?: FilterType;
  prevRows: RowType[];
}) => Promise<{
  success: boolean;
  rows: Array<RowType>;
  errors: errorType;
  count?: number;
}>;

export type FilterComponentProps<FilterType> = {
  filters: FilterType;
  updateFilters: (filters: FilterType) => void;
  loading?: boolean;
  onClose?: () => void;
};

export type CoreTableProps<RowType, FilterType> = {
  name: string;
  columns: Array<Column>;
  getRowId: (row: RowType) => number | string;
  updateRows: UpdateRowsType<RowType, FilterType>;

  updateTrigger?: unknown | unknown[];
  //sorting
  defaultSorting?: Sorting;
  sortingEnabled?: boolean; // if false or not specified integrated sorting will be used
  dateFields?: string[];
  //filtering
  integratedSearch?: boolean;
  defaultFilters?: FilterType;
  ToolBarFilterComponent?: ComponentType<FilterComponentProps<FilterType>>;
  MenuFilterComponent?: ComponentType<FilterComponentProps<FilterType>>;
  // paging
  pagingEnabled?: boolean;
  // selection
  selectionButton?: (
    selection: ReactText[],
    update: () => void
  ) => React.ReactElement;
  selectRowsMode?: boolean;
  defaultSelection?: RowType[] | ReactText[];
  selectionEnabled?: boolean;
  onRowClick?: (row: RowType) => void;
  onRowsSet?: (rows: Array<RowType>) => void;
  onSetLoading?: (loading: boolean) => void;
  toolBarLeftItems?: ReactElement[];
  height?: number | string;
  disableToolBar?: boolean;
  RefreshButton?: React.ComponentType<RefreshButtonProps>;
  plugins?: React.ReactNode[];
  renderWithState?: (
    state: TableContextType<RowType, FilterType>
  ) => ReactElement;
  rowStyle?: (row: RowType) => string | undefined;
  hideRowPredicate?: (row: RowType) => boolean;
  dialog?: ReactElement;
  noDataMessage?: string;
};

const CoreTable = <RowType, FilterType extends GeneralFilterType = undefined>(
  props: CoreTableProps<RowType, FilterType>
) => {
  if (props.pagingEnabled && props.integratedSearch)
    console.warn(
      "Using both paging and integrated search is not recommended because search will work for only one page"
    );
  if (props.sortingEnabled && props.dateFields)
    console.warn(
      "Specifying dateFields only allowed for integrated sorting, which is disabled by sortingEnabled=true prop"
    );
  if (props.disableToolBar && props.toolBarLeftItems)
    console.warn(
      "toolBarLeftItems prop is ignored due to toolbar is disabled by prop disableToolBar"
    );
  if (props.disableToolBar && props.ToolBarFilterComponent)
    console.warn(
      "InlineFilterComponent prop is ignored due to toolbar is disabled by prop disableToolBar"
    );

  const {permissions} = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([] as Array<RowType>);
  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >(
    `${props.name}_columns`,
    props.columns
      .filter((column) => column.hiddenByDefault)
      .map((column) => column.name)
  );

  const [sorting, setSorting] = useLocalStorage<Sorting[]>(
    `${props.name}_sorting`,
    props.defaultSorting ? [props.defaultSorting] : []
  );

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    `${props.name}_ordering`,
    props.columns.map((column) => column.name)
  );

  const pageSize = calcPageSize(props.height);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState(0);

  const [selection, setSelection] = useState<ReactText[]>(
    (props.selectRowsMode
      ? (props.defaultSelection as RowType[])?.map((row) => props.getRowId(row))
      : (props.defaultSelection as ReactText[])) || []
  );
  const selectedRows = useRef<RowType[]>(
    (props.selectRowsMode && (props.defaultSelection as RowType[])) || []
  );

  const resetSelection = () => {
    setSelection([]);
    selectedRows.current = [];
  };

  const [filters, setFilters] = useState<FilterType>(
    props.defaultFilters || ({} as FilterType)
  );
  const updateFilters = (filters: FilterType) => {
    setCurrentPage(0);
    setFilters(filters);
  };
  const filtersEnabled =
    props.ToolBarFilterComponent || props.MenuFilterComponent;

  const updateRows = () => {
    setLoading(true);
    props.onSetLoading?.(true);
    const sort = sorting?.[0];
    props
      .updateRows({
        sorting: props.sortingEnabled
          ? {
              sort_field: sort?.columnName,
              sort_desc: sort?.direction && sort.direction == "desc",
            }
          : undefined,
        paging: props.pagingEnabled
          ? {
              page: currentPage + 1,
              page_size: pageSize,
            }
          : undefined,
        filters: filtersEnabled ? filters : undefined,
        prevRows: rows,
      })
      .then((result) => {
        if (result.success) {
          setRows(result.rows);
          props.onRowsSet?.(result.rows);

          if (result.count !== undefined) {
            setTotalCount(result.count);
          }
        }
      })
      .finally(() => {
        setLoading(false);
        props.onSetLoading?.(false);
      });
  };

  const useEffectDeps = [
    ...(props.sortingEnabled ? [sorting] : []),
    ...(props.pagingEnabled ? [currentPage, pageSize] : []),
    ...(filtersEnabled ? [filters] : []),
  ];
  const externalTriggers = Array.isArray(props.updateTrigger)
    ? props.updateTrigger
    : [props.updateTrigger];
  useEffect(updateRows, [...externalTriggers, ...useEffectDeps]);

  const autoColumn = props.columns.find((column) => column.auto);

  const classes = useGlobalStyles();
  const {ToolBarFilterComponent} = props;
  const ToolbarRefreshButton = props.RefreshButton || RefreshButton;

  const tableState: TableContextType<RowType, FilterType> = {
    loading,
    currentPage,
    totalCount,
    sorting,
    selection,
    selectedRows: selectedRows.current,
    filters,
    hiddenColumnNames,
    rows,
    columns: props.columns,
    updateRows,
  };

  return (
    <TableContext.Provider value={tableState}>
      {props.dialog}
      <Grid
        columns={props.columns}
        rows={
          props.hideRowPredicate
            ? rows.filter((row) => !props.hideRowPredicate?.(row))
            : rows
        }
        getRowId={props.getRowId}
      >
        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        {!props.disableToolBar && (
          <Template name="toolbarContent">
            {props.toolBarLeftItems
              ?.filter((item) =>
                actionHasPermissions(
                  permissions.user_type,
                  item.key?.toString()
                )
              )
              .map((button, index) => (
                <ToolbarItem key={`${props.name}_toolbar_button_${index}`}>
                  {button}
                </ToolbarItem>
              ))}
            {props.selectionButton && (
              <ToolbarItem>
                {props.selectionButton(selection, () => {
                  updateRows();
                  resetSelection();
                })}
              </ToolbarItem>
            )}
            {props.MenuFilterComponent && (
              <ToolbarItem>
                <MenuFilter
                  FilterComponent={props.MenuFilterComponent}
                  filters={filters}
                  updateFilters={updateFilters}
                  loading={loading}
                />
              </ToolbarItem>
            )}
            <ToolbarItem>
              <ToolbarRefreshButton
                disabled={loading}
                update={updateRows}
                id={`${props.name}_refresh`}
              />
            </ToolbarItem>
            <TemplatePlaceholder />
            {ToolBarFilterComponent && (
              <ToolbarItem>
                <ToolBarFilterComponent
                  filters={filters}
                  updateFilters={updateFilters}
                />
              </ToolbarItem>
            )}
          </Template>
        )}
        {/*Search*/}
        {props.integratedSearch && (
          <Plugin>
            <SearchState />
            <StyledSearchPanel />
            <IntegratedFiltering />
          </Plugin>
        )}
        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={props.columns}
        />
        {!props.sortingEnabled && (
          <CustomIntegratedSorting
            datetimeFields={props.dateFields || []}
            columns={props.columns}
          />
        )}
        {/*Paging*/}
        {props.pagingEnabled && (
          <Plugin>
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
            />
            <CustomPaging totalCount={totalCount} />
            <PagingPanel messages={paginationMessages} />
          </Plugin>
        )}
        <StyledVirtualTable
          rowComponent={(rowProps: TableBase.DataRowProps) => (
            <VirtualTable.Row
              {...rowProps}
              onClick={() => props.onRowClick?.(rowProps.row)}
              className={props.rowStyle?.(rowProps.row) || classes.gridRow}
              data-type={`${props.name}_row`}
              data-id={props.getRowId(rowProps.row)}
            >
              {rowProps.children}
            </VirtualTable.Row>
          )}
          height={props.height}
          columnExtensions={props.columns}
          pagination={props.pagingEnabled}
          noDataText={props.noDataMessage}
        />
        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />
        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        {!props.disableToolBar && <StyledColumnChooser />}
        {/*Column Resizing*/}
        <ColumnResizingHeader
          defaultColumnWidths={props.columns}
          columnExtensions={props.columns}
          localStorageName={props.name}
          autoWidthColumnName={autoColumn?.name}
        />
        {/*Selection*/}
        {props.selectionEnabled && (
          <Plugin>
            {props.selectRowsMode ? (
              <RowSelectionState
                selectedRows={selectedRows.current}
                onSelectedRowsChanged={({ids, rows}) => {
                  selectedRows.current = rows;
                  setSelection(ids);
                }}
              />
            ) : (
              <SelectionState
                selection={selection}
                onSelectionChange={setSelection}
              />
            )}
            <IntegratedSelection />
            <StyledTableSelection showSelectAll />
          </Plugin>
        )}
        {/*Providers*/}
        {props.columns.map(
          ({provider: Provider, columnName}) =>
            Provider && (
              <Provider
                for={[columnName]}
                key={`${props.name}_${columnName}_provider`}
              />
            )
        )}
        {/*Custom Plugins*/}
        {props.plugins}
        {loading && <LoadingIndicator />}
      </Grid>
      {props.renderWithState?.(tableState)}
    </TableContext.Provider>
  );
};

export default CoreTable;
