import * as React from "react";
import {useState} from "react";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import {DialogContent} from "@mui/material";
import {StyledTab, StyledTabs} from "../common/Tabs";
import {TabPanel, tabProps} from "../common/components";
import AddNotificationForm from "./AddNotificationForm";
import NotificationEventsTable from "./NotificationEvents/NotificationEventsTable";
import NotificationRecipientsTable from "./NotificationRecipients/NotificationRecipientsTable";
import {CustomDialog} from "../common/dialog/CustomDialog";
import {NotificationType} from "../../common/APIRequests/notify/getNotification";
import NotificationMessagesTable from "./NotificationMessages/NotificationMessagesTable";

type EditNotificationDialogProps = {
  notification: NotificationType;
  onClose: () => void;
  update: () => void;
};

const EditNotificationDialog = (props: EditNotificationDialogProps) => {
  const [tab, setTab] = useState("common");

  return (
    <CustomDialog
      onClose={props.onClose}
      maxWidth={tab === "common" ? "sm" : "lg"}
    >
      <ClosableDialogTitle id="modify-notify-modal" onClose={props.onClose}>
        Изменить оповещение
      </ClosableDialogTitle>
      <DialogContent>
        <StyledTabs
          value={tab}
          onChange={(_, tabValue) => setTab(tabValue)}
          aria-label="wrapped label tabs"
        >
          <StyledTab label="Общая информация" {...tabProps("common")} />
          <StyledTab label="События" {...tabProps("events")} />
          <StyledTab label="Получатели" {...tabProps("recipients")} />
          <StyledTab label="Список оповещений" {...tabProps("messages")} />
        </StyledTabs>
        {props.notification && (
          <TabPanel value={tab} index="common">
            <AddNotificationForm
              notification={props.notification}
              onClose={props.onClose}
              update={props.update}
            />
          </TabPanel>
        )}
        {tab === "events" && props.notification && (
          <TabPanel value={tab} index="events">
            <NotificationEventsTable notification={props.notification} />
          </TabPanel>
        )}
        {tab === "recipients" && props.notification && (
          <TabPanel value={tab} index="recipients">
            <NotificationRecipientsTable notification={props.notification} />
          </TabPanel>
        )}
        {tab === "messages" && props.notification && (
          <TabPanel value={tab} index="messages">
            <NotificationMessagesTable notification={props.notification} />
          </TabPanel>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default EditNotificationDialog;
