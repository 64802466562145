import {UserRole} from "../../common/constants/users";

const ViewPermissions = new Map<string, number>([
  ["/", UserRole.REPORT_VIEWER],
  ["/devices/", UserRole.USER],
  ["/devices/project/:id_project/", UserRole.USER],
  ["/geography/", UserRole.USER],
  ["/reports/", UserRole.REPORT_VIEWER],
  ["/firmwares/", UserRole.DEV],
  ["/projects/", UserRole.ADMIN],
  ["/applications/", UserRole.ADMIN],
  ["/tasks/", UserRole.ADMIN],
  ["/events/", UserRole.ADMIN],
  ["/log_events/", UserRole.ADMIN],
  ["/notifications/", UserRole.ADMIN],
  ["/console_logs/", UserRole.ADMIN],
  ["/user_reports/", UserRole.REPORT_VIEWER],
  ["/users/", UserRole.ADMIN],
  ["/profiles_apps/", UserRole.ADMIN],
  ["/profiles_devices/", UserRole.ADMIN],

  ["/policies/", UserRole.DEV],
]);

export const hasPermissions = (user_type: number, url: string) =>
  user_type >= (ViewPermissions.get(url) ?? UserRole.DEV);

export default hasPermissions;
