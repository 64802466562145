import React, {useState} from "react";
import {UserReportFilterType} from "../../../../common/APIRequests/reportFilterType/getReportFilterTypes";
import postReportFilterUser from "../../../../common/APIRequests/reportFilterUser/postReportFilterUser";
import {UserReport} from "../../../../common/APIRequests/user_report/getUserReports";
import FormButtons from "../../../common/buttons/FormButtons";
import {Transition} from "../../../common/components";
import ClosableDialogTitle from "../../../common/dialog/ClosableDialogTitle";
import {Dialog, DialogContent} from "@mui/material";
import DeviceReportFilters from "./setValueComponents/DeviceReportFilters";
import AppReportFilters from "./setValueComponents/AppReportFilters";
import EventReportFilter from "./setValueComponents/EventReportFilters";
import moment from "moment";
import {
  BACKEND_DATETIME_FORMAT,
  FRONT_DATETIME_FORMAT,
} from "../../../../common/types";

type Props = {
  reportFilterType?: UserReportFilterType;
  userReport: UserReport;
  onClose: () => void;
  onValueSet?: () => void;
};
const SetUserReportFilterValueDialog = (props: Props) => {
  const {reportFilterType, userReport, onValueSet} = props;

  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState<string>();

  const onClose = () => {
    setFilterValue(undefined);
    props.onClose();
  };

  const handleSubmit = () => {
    if (!reportFilterType) return;

    setLoading(true);

    let value = filterValue?.toString();

    // bug fix if boolean filter and not checked
    if (reportFilterType.value_type === "boolean" && !value) {
      value = "0";
    }

    postReportFilterUser({
      id_user_report: userReport.id_user_report,
      id_type_filter: reportFilterType.id_type_filter,
      value: value ?? null,
    })
      .then((result) => {
        if (result.success) {
          onClose();
          onValueSet?.();
        }
      })
      .finally(() => setLoading(false));
  };

  let FiltersComponent;
  switch (props.userReport.report_type) {
    case 1:
      FiltersComponent = DeviceReportFilters;
      break;
    case 2:
      FiltersComponent = AppReportFilters;
      break;
    case 3:
      FiltersComponent = EventReportFilter;
      break;
  }

  return (
    <Dialog
      open={!!reportFilterType}
      onClose={onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <ClosableDialogTitle onClose={onClose}>
        Добавить фильтр &quot;{reportFilterType?.name}&quot;
      </ClosableDialogTitle>
      <DialogContent>
        {FiltersComponent && reportFilterType ? (
          <FiltersComponent
            value={filterValue}
            setValue={setFilterValue}
            name={reportFilterType.name}
            systemName={reportFilterType.system_name}
          />
        ) : null}
        <FormButtons
          loading={loading}
          disabled={
            (filterValue === undefined || filterValue === "") &&
            reportFilterType?.value_type !== "boolean"
          }
          handleSubmit={handleSubmit}
          handleClose={onClose}
          submitMessage="Добавить"
        />
      </DialogContent>
    </Dialog>
  );
};

export default SetUserReportFilterValueDialog;
