import {PrimaryButton} from "../../../common/buttons/PrimaryButton";
import {useContext, useState} from "react";
import Timer from "../../../common/timer/Timer";
import {startVideoRecording, stopVideoRecording} from "../API/httpCommands";
import {AppContext} from "../../../App-context";

const DeviceVideoRecorder = (props: {device: string; className: string}) => {
  const [isRecording, setIsRecording] = useState(false);
  const {setAlert} = useContext(AppContext);

  const handleStart = async () => {
    const result = await startVideoRecording(props.device);
    if (result.success) {
      setIsRecording(true);
      setAlert({
        severity: "success",
        message: ["Запись началась"],
      });
    } else if (result.errors) {
      setAlert({
        severity: "error",
        message: ["Невозможно начать запись"],
      });
    }
  };

  const handleStop = async () => {
    const result = await stopVideoRecording(props.device);
    if (result.success) {
      setIsRecording(false);
      setAlert({
        severity: "success",
        message: ["Запись успешно остановлена"],
      });
    } else if (result.errors) {
      setAlert({
        severity: "error",
        message: ["Возникла ошибка"],
      });
    }
  };

  return (
    <>
      {isRecording ? (
        <PrimaryButton className={props.className} onClick={handleStop}>
          Остановить <Timer />
        </PrimaryButton>
      ) : (
        <PrimaryButton className={props.className} onClick={handleStart}>
          Начать запись
        </PrimaryButton>
      )}
    </>
  );
};

export default DeviceVideoRecorder;
