export const insert = <T>(arr: Array<T> | undefined, elem: T, pos?: number) => {
  if (arr === undefined) {
    return [elem];
  } else if (pos === undefined || arr.length <= pos) {
    return [...arr, elem];
  } else {
    return [...arr.slice(0, pos), elem, ...arr.slice(pos)];
  }
};

export const calcPageSize = (tableHeight?: string | number) => {
  const ROW_HEIGHT = 39; // px
  const HEADER_ROW_HEIGHT = 46; // px
  const AROUND_COMPONENTS_HEIGHT = 226; // px

  const {innerHeight: windowHeight} = window;

  let tableH = undefined;
  if (typeof tableHeight === "string") {
    const coefficient = Number(tableHeight.match(/([0-9]+)(vh)/)?.[1]) / 100;
    if (isNaN(coefficient)) {
      console.warn(
        `Cannot parse table height '${tableHeight}' to calculate page size.`
      );
    } else {
      tableH = windowHeight * coefficient;
    }
  } else if (typeof tableHeight === "number") {
    tableH = tableHeight;
  }

  tableH = tableH || windowHeight - AROUND_COMPONENTS_HEIGHT;
  return Math.floor((tableH - HEADER_ROW_HEIGHT) / ROW_HEIGHT);
};
