import * as React from "react";
import {useContext, useState} from "react";
import {
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import {AppContext} from "../App-context";
import {applicationVersionType} from "../../common/APIRequests/applicationVersion/getApplicationVersions";
import patchApplicationVersion from "../../common/APIRequests/applicationVersion/patchApplicationVersion";
import {errorType} from "../../common/types";
import {ConfirmDialog} from "../common/buttons/ConfirmDialog";
import theme from "../../theme";
import StringField from "../common/formFields/StringField";
import FormButtons from "../common/buttons/FormButtons";
import {ToolbarItem} from "../common/Toolbar";
import {Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import SwitchWithPin from "../common/buttons/SwitchWithPin";

const ApplicationVersionForm = (props: {
  handleClose: () => void;
  update: () => void;
  applicationVersion: applicationVersionType;
}) => {
  const {handleClose, update, applicationVersion} = props;

  const [values, setValues] = useState(applicationVersion);

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
  };

  const [isReleaseOpen, setIsReleaseOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({} as errorType);

  const handleSubmit = async () => {
    setIsReleaseOpen(false);

    setLoading(true);
    const result = await patchApplicationVersion(values);
    setLoading(false);

    if (result.success) {
      handleClose();
      setAlert({
        severity: "success",
        message: ["Описание изменено"],
      });
      update();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => !loading && handleClose()}
      keepMounted
      TransitionComponent={Transition}
    >
      <ClosableDialogTitle onClose={() => !loading && handleClose()}>
        Информация о версии {applicationVersion.name}
      </ClosableDialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            fullWidth
            label="Android версия"
            placeholder="Android версия"
            value={values.name}
            disabled={true}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            fullWidth
            label="Android код версии"
            placeholder="Android код версии"
            value={values.code}
            disabled={true}
            style={{marginTop: theme.spacing(2)}}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            fullWidth
            label="Путь до APK"
            placeholder="Путь до APK версии приложения"
            value={values.file_path}
            disabled={true}
            style={{marginTop: theme.spacing(2)}}
          />
        </FormControl>
        <StringField
          setValue={handleChange("description")}
          value={values.description}
          name="description"
          label="Описание"
          errors={errors}
          setErrors={setErrors}
          multiline
          style={{marginTop: theme.spacing(2)}}
          disabled={loading}
        />
        <FormButtons
          loading={loading}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          submitMessage="Сохранить"
          checkPin
        >
          <ToolbarItem>
            <FormControlLabel
              control={
                <SwitchWithPin
                  onChange={() => {
                    !values.is_release && setIsReleaseOpen(true);
                    values.is_release &&
                      setValues({
                        ...values,
                        is_release: 0,
                      });
                  }}
                  checked={values.is_release === 1}
                  disabled={loading}
                  checkPin
                />
              }
              label="Релизная"
            />
          </ToolbarItem>
        </FormButtons>
        {isReleaseOpen && (
          <ConfirmDialog
            loading={loading}
            handleClose={() => setIsReleaseOpen(false)}
            handleConfirm={() => {
              setValues({...values, is_release: 1});
              setIsReleaseOpen(false);
            }}
            message={`Эта версия приложения \n станет релизной, вы уверены?`}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ApplicationVersionForm;
