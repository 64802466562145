import Column from "../Tables/Column";

const ProfileDeviceColumns: Array<Column> = [
  new Column("id_prof", "ID", 100),
  new Column("name", "Наименование", 200),
  new Column("specification", "Описание", 200, {auto: true}),
  new Column("priority", "Приоритет", 150),
];

export default ProfileDeviceColumns;
