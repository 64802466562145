import {KonvaEventObject} from "konva/lib/Node";
import {Layer, Stage, Image} from "react-konva";
import {StreamImageDataType} from "./Hooks/useWebsocket";
import {KeyboardEvent, useEffect, useState} from "react";
import {RemoteControlActionType} from "./DeviceRemoteControl";
import {makeStyles} from "tss-react/mui";

export const FIXED_WIDTH: number = 294;
export const FIXED_HEIGHT: number = 490;

export const getHeight = (width: number, height: number) => {
  return height >= width ? FIXED_HEIGHT : FIXED_WIDTH;
};

export const getWidth = (width: number, height: number) => {
  return width <= height ? FIXED_WIDTH : FIXED_HEIGHT;
};

const useStyles = makeStyles<{width: number; height: number}>()(
  (theme, {width, height}) => ({
    smartphone: {
      position: "relative",
      width: width + 32,
      height: height + 72,
      margin: "auto",
      border: "16px black solid",
      borderTopWidth: "60px",
      borderBottomWidth: "10px",
      borderRadius: "36px 36px 0 0",
      "&::before": {
        content: '""',
        display: "block",
        width: "60px",
        height: "5px",
        position: "absolute",
        top: "-30px",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#333",
        borderRadius: "10px",
      },
    },
  })
);

const DeviceScreen = (props: {
  screenData: StreamImageDataType;
  handleCommand: (command: RemoteControlActionType) => void;
}) => {
  const [mouseDown, setMouseDown] = useState(false);
  const {
    handleCommand,
    screenData: {image},
  } = props;
  const [width, setWidth] = useState(
    getWidth(props.screenData.width, props.screenData.height)
  );
  const [height, setHeight] = useState(
    getHeight(props.screenData.width, props.screenData.height)
  );
  const {classes} = useStyles({width, height});

  useEffect(() => {
    setWidth(getWidth(props.screenData.width, props.screenData.height));
    setHeight(getHeight(props.screenData.width, props.screenData.height));
  }, [props.screenData.width, props.screenData.height]);

  const handleMouseDown = (event: KonvaEventObject<MouseEvent>) => {
    setMouseDown(true);
    handleCommand({
      actionType: "mouseDown",
      cursorPosition: {
        xPosition: (event.evt.offsetX / width).toFixed(2),
        yPosition: (event.evt.offsetY / height).toFixed(2),
      },
    });
  };

  const handleMouseUp = (event: KonvaEventObject<MouseEvent>) => {
    setMouseDown(false);
    handleCommand({
      actionType: "mouseUp",
      cursorPosition: {
        xPosition: (event.evt.offsetX / width).toFixed(2),
        yPosition: (event.evt.offsetY / height).toFixed(2),
      },
    });
  };

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    if (!mouseDown) return;
    handleCommand({
      actionType: "mouseMove",
      cursorPosition: {
        xPosition: (event.evt.offsetX / width).toFixed(2),
        yPosition: (event.evt.offsetY / height).toFixed(2),
      },
    });
  };

  const handleMouseLeave = (event: KonvaEventObject<MouseEvent>) => {
    setMouseDown(false);
  };

  const keydownHandler = (e: KeyboardEvent) => {
    handleCommand({
      actionType: "keyboard",
      keyOfKeyboard: {
        shiftKey: e.shiftKey,
        ctrlKey: e.ctrlKey,
        altKey: e.altKey,
        keyDown: e.key,
      },
    });
    e.preventDefault();
  };

  return (
    <>
      {height && width && (
        <div className={classes.smartphone}>
          <Stage width={width} height={height}>
            <Layer>
              {image && (
                <>
                  <Image
                    image={image}
                    width={width}
                    height={height}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                  />
                </>
              )}
            </Layer>
          </Stage>
        </div>
      )}
    </>
  );
};

export default DeviceScreen;
