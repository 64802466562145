import * as React from "react";
import {ReactText, useState} from "react";
import CoreTable, {UpdateRowsType} from "../../../Tables/CoreTable";
import getEvents, {
  EventType,
} from "../../../../common/APIRequests/event/getEvents";
import AddNotificationEventColumns from "./AddNotificationEventColumns";
import FormButtons from "../../../common/buttons/FormButtons";
import postNotifyEvents from "../../../../common/APIRequests/notifyEvent/postNotifyEvents";
import {NotificationType} from "../../../../common/APIRequests/notify/getNotification";
import {useTrigger} from "../../../../common/customHooks";
import {Transition} from "../../../common/components";
import ClosableDialogTitle from "../../../common/dialog/ClosableDialogTitle";
import {Dialog, DialogContent, FormControlLabel, Switch} from "@mui/material";
import {useGlobalStyles} from "../../../common/styles";

type AddNotificationEventDialogProps = {
  onSubmit: () => void;
  notification: NotificationType;
  notificationEventIds: number[];
  onClose: () => void;
};

const AddNotificationEventDialogTable = (
  props: AddNotificationEventDialogProps
) => {
  const updateRows: UpdateRowsType<EventType> = () => getEvents();
  const [showSystemEvents, setShowSystemEvents] = useState(false as boolean);
  const [loading, setLoading] = useState(false);
  const [trigger, refresh] = useTrigger();

  const handleAddNotification = (event_ids: ReactText[]) => {
    setLoading(true);
    postNotifyEvents(props.notification.id_notify, event_ids)
      .then((result) => {
        if (result.success) {
          refresh();
          props.onSubmit();
          props.onClose();
        }
      })
      .finally(() => setLoading(false));
  };

  const classes = useGlobalStyles();

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="lg"
    >
      <ClosableDialogTitle id="add-notify-event-modal" onClose={props.onClose}>
        Добавить события к оповещению {props.notification.name}
      </ClosableDialogTitle>
      <DialogContent>
        <CoreTable
          name="add_notification_events"
          columns={AddNotificationEventColumns}
          getRowId={(row) => row.id_event}
          updateRows={updateRows}
          integratedSearch
          defaultSorting={{columnName: "type_user_name", direction: "asc"}}
          selectionEnabled
          selectRowsMode
          updateTrigger={trigger}
          hideRowPredicate={
            (row) =>
              props.notificationEventIds.includes(row.id_event) ||
              (!showSystemEvents && !row.type_user_name) // hide system events if showSystemEvents disabled
          }
          rowStyle={(row) =>
            !row.type_user_name ? classes.gridRowSelected : classes.gridRow
          }
          toolBarLeftItems={[
            <FormControlLabel
              control={
                <Switch
                  checked={showSystemEvents}
                  onChange={(e) => setShowSystemEvents(e.target.checked)}
                  name="isSystemShowed"
                  size="medium"
                />
              }
              label="Показать системные"
              key="show-system-events-switch"
              style={{paddingLeft: 12}}
            />,
          ]}
          renderWithState={(state) => (
            <FormButtons
              loading={loading || state.loading}
              handleSubmit={() => handleAddNotification(state.selection)}
              handleClose={props.onClose}
              submitMessage="Добавить"
              submitId="events_add"
              closeId="events_close"
              disabled={loading || state.loading || !state.selectedRows.length}
              checkPin
            />
          )}
          height={"60vh"}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddNotificationEventDialogTable;
