import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type deviceProfileType = {
  id_prof_dev_link: number;
  id_prof: number;
  name_profile: string;
  specification_profile: string;
  is_active: 0 | 1;
  priority: number;
  is_inherited: 1 | 0;
  id_project: number;
  name_project: string;
};

const getDeviceProfiles = async (id_device: number) =>
  baseGetRowsRequest<deviceProfileType>(`/device/${id_device}/profile/`);

export default getDeviceProfiles;
