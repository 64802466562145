import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type NotificationRecipientType = {
  id_channel: number;
  id_type_channel: 1 | 2;
  name_type_channel: string;
  recipient: string;
  is_deleted: 1 | 2;
};

export type notifyRecipientInputsType = {
  type_channel: number;
  recipients: Array<string>;
};

const getNotificationRecipients = async (id_notify: number) =>
  baseGetRowsRequest<NotificationRecipientType>(
    `/notify/${id_notify}/recipient/`
  );

export default getNotificationRecipients;
