import {useContext} from "react";
import {Redirect, RouteProps, Route} from "react-router-dom";
import {AppContext} from "../App-context";
import hasPermissions from "./viewPermissions";

type CustomRouteProps = RouteProps;

const CustomRoute = (props: CustomRouteProps) => {
  const context = useContext(AppContext);

  if (props.path) {
    if (
      hasPermissions(context.permissions.user_type, props.path.toString()) &&
      props.path.toString() !== "/"
    ) {
      return <Route {...props} render={props.render} />;
    } else if (props.path.toString() === "/") {
      return <Route {...props} render={props.render} />;
    } else {
      return <Redirect to="/" />;
    }
  }
  return <Route {...props} render={props.render} />;
};

export default CustomRoute;
