import * as React from "react";
import {PropsWithChildren} from "react";
import {Button} from "@mui/material";
import {ButtonProps} from "@mui/material/Button/Button";

type SecondaryButtonProps = {
  title?: string;
} & ButtonProps;

export const SecondaryButton = (
  props: PropsWithChildren<SecondaryButtonProps>
) => {
  return (
    <Button variant="outlined" color="primary" {...props}>
      {props.title || props.children}
    </Button>
  );
};
