import {basePostRequest} from "../base/basePostRequest";

const postProjectApplicationsProfile = async (
  id_project: number,
  id_app: number,
  id_prof: number
) =>
  basePostRequest<{}>(
    `/project/${id_project}/application/${id_app}/add_profile/`,
    {id_prof}
  );

export default postProjectApplicationsProfile;
