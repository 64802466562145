import {TextField} from "@mui/material";
import {sanitizeDigits} from "./sanitizers";

type PinFieldProps = {
  value: string;
  onChange: (s: string) => void;
  onEnterPressed?: () => void;
  error?: boolean;
};

const DOT = "•";

const PinField = (props: PinFieldProps) => {
  const hidePin = (pin: string) => DOT.repeat(pin.length);

  const handleChange = (e: any) => {
    const oldChars = props.value.split("");
    const newChars: string[] = e.target.value.split("");
    const newValue = sanitizeDigits(
      newChars.map((c, i) => (c === DOT ? oldChars[i] : c)).join("")
    );
    props.onChange(newValue);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      id="console_pin"
      name="console_pin"
      inputProps={{
        maxLength: 8,
      }}
      label="Пин-код"
      autoComplete="off"
      placeholder="Пин-код администратора"
      type="text"
      value={hidePin(props.value)}
      aria-describedby="component-error-pin"
      onChange={handleChange}
      onKeyPress={(e) =>
        e.key == "Enter" && props.onEnterPressed && props.onEnterPressed()
      }
      autoFocus={true}
      error={props.error}
    />
  );
};

export default PinField;
