import {baseGetRequest} from "../base/baseGetRequest";

export type geoType = {
  id_geo: number;
  name_geo: string;
};

export type TaskTemplateDetailsType = {
  id_template: number;
  name: string;
  id_project?: number;
  name_project?: string;
  net?: number;
  branches?: geoType[];
  divisions?: geoType[];
  children: "0" | "1";
  id_app_version: number;
  app_version_name: string;
  app_version_description: string;
  app_version_file_path: string;
  geo_fullname: string;
  geo_count: number;
};

const getTemplate = async (id_template: number) =>
  baseGetRequest<TaskTemplateDetailsType>(`/task_template/${id_template}/`);

export default getTemplate;
