import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

export type BaseGetRowsResponse<T> = Promise<{
  success: boolean;
  rows: Array<T>;
  errors: errorType;
}>;

export function baseGetRowsRequest<T>(
  url: string,
  params?: any
): BaseGetRowsResponse<T> {
  return new Promise((resolve) =>
    apiClient({
      method: "get",
      url,
      params,
    })
      .then((response) =>
        resolve({
          success: true,
          rows: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          rows: [],
          errors: errors,
        });
      })
  );
}
