import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {IntegratedSorting, SortingState} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import React, {useEffect, useState} from "react";
import getApplications, {
  ApplicationType,
} from "../../common/APIRequests/application/getApplications";
import getReportVersions, {
  reportVersionsType,
} from "../../common/APIRequests/report/getReportVersions";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {
  columnType,
  FRONT_DATETIME_FORMAT,
  optionType,
  sortingType,
} from "../../common/types";
import {exportToCSV} from "../../common/utils/files";
import {StyledVirtualTable} from "../common/grid/cell";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../common/components";

const columns: columnType = [
  {name: "id_device", title: "ID"},
  {name: "serial_number", title: "Серийный номер"},
  {name: "code_division", title: "Код подразделения"},
  {name: "name_project", title: "Наименование проекта"},
  {name: "ldap_login", title: "Логин пользователя"},
  {name: "display_name", title: "Имя пользователя"},
  {name: "email", title: "E-mail"},
  {name: "app_name", title: "Наименование приложения"},
  {name: "app_version", title: "Android версия"},
  {name: "app_code", title: "Код версии"},
  {
    name: "date_inst",
    title: "Время установки",
    getCellValue: (row: reportVersionsType) =>
      row.date_inst
        ? moment(row.date_inst).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
];
const columnExtensions = [
  {columnName: "id_device", width: 100},
  {columnName: "serial_number", width: 250},
  {columnName: "code_division", width: 200},
  {columnName: "name_project", width: 200},
  {columnName: "ldap_login", width: 200},
  {columnName: "display_name", width: 200},
  {columnName: "email", width: 200},
  {columnName: "app_name", width: 200},
  {columnName: "app_version", width: 200},
  {columnName: "app_code", width: 150},
  {columnName: "date_inst", width: 200},
];

// exclude sorting for date-time fields
// because it's not supported by integrated sort
const sortingStateColumnExtensions = [
  {columnName: "date_inst", sortingEnabled: false},
];

const ReportsVersions = (props: {
  projectId: number;
  includeChild: 0 | 1;
  children?: any;
  loading: boolean;
  setLoading: Function;
}) => {
  const {projectId, includeChild, children, loading, setLoading} = props;

  let cancelled = false;
  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("reports_version_column", [
    "id_device",
    "display_name",
    "email",
    "name_project",
  ]);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "reports_version_sorting",
    [{columnName: "id_device", direction: "asc"}]
  );

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "report_versions_ordering",
    columns.map((row) => row.name)
  );

  const updateRows = async () => {
    setLoading(true);
    const result = await getReportVersions(
      projectId,
      app || undefined,
      includeChild ? 1 : 0
    );
    if (cancelled) {
      return;
    }
    setLoading(false);

    result.success && setRows(result.rows);
  };

  const [rows, setRows] = useState([] as Array<reportVersionsType>);

  const [app, setApp] = useState(0);
  const [apps, setApps] = useState([] as optionType[]);

  useEffect(() => {
    return () => {
      cancelled = true;
    };
  }, []);

  useEffect(() => {
    projectId && updateRows();
  }, [projectId, includeChild, app]);

  const updateApps = async () => {
    setLoading(true);
    const result = await getApplications();
    if (cancelled) {
      return;
    }
    setLoading(false);

    if (result.success) {
      setApps(
        result.rows.map((row: ApplicationType) => {
          return {
            key: row.id_app,
            value: row.id_app,
            text: row.name,
          };
        })
      );
    }
  };

  useEffect(() => {
    if (projectId) {
      updateApps();
    }
  }, [projectId]);

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      <Grid
        columns={columns}
        rows={rows}
        getRowId={(row) => `${row.id_device}_${row.app_code}`}
      >
        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingStateColumnExtensions}
        />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          {children}
          <ToolbarItem>
            <FormControl style={{width: "200px"}}>
              <InputLabel htmlFor="project_app">Приложение</InputLabel>
              <Select
                id="project_app"
                value={app || ""}
                onChange={(e: any) => setApp(e.target.value)}
                disabled={loading || !projectId}
              >
                <MenuItem key="" value="">
                  Все
                </MenuItem>
                {apps.map((option: optionType) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              variant="text"
              color="secondary"
              startIcon={<GetAppIcon />}
              disabled={loading || rows.length === 0}
              onClick={() => exportToCSV<reportVersionsType>(rows, columns)}
              id="report_download_btn"
            >
              Скачать
            </Button>
          </ToolbarItem>
          <TemplatePlaceholder />
        </Template>

        <StyledVirtualTable
          height="79vh"
          messages={{
            noData: !projectId || loading ? "Выберите проект" : "Нет данных",
          }}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="reports_versions"
          autoWidthColumnName={"app_name"}
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default ReportsVersions;
