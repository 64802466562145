import * as React from "react";
import StringFilterField from "../../../../../common/filterFields/StringFilterField";
import {ReportFilterProps} from "../eventFilters/EventIdReportFilter";

const StringReportFilter = (props: ReportFilterProps) => {
  return (
    <StringFilterField
      label={props.name}
      name={"value"}
      value={props.value ?? ""}
      onChange={props.setValue}
      onResetClick={() => props.setValue(undefined)}
    />
  );
};

export default StringReportFilter;
