import {PrimaryButton} from "../../../common/buttons/PrimaryButton";
import {useContext} from "react";
import {takeScreenshot} from "../API/httpCommands";
import {AppContext} from "../../../App-context";

const DeviceScreenshotTaker = (props: {device: string; className: string}) => {
  const {setAlert} = useContext(AppContext);
  const handleTakeScreenshot = async () => {
    const result = await takeScreenshot(props.device);
    if (result.success) {
      setAlert({
        severity: "success",
        message: ["Скриншот успешно сохранен"],
      });
    } else if (result.errors) {
      setAlert({
        severity: "error",
        message: ["Возникла ошибка во время создания скриншота"],
      });
    }
  };

  return (
    <PrimaryButton className={props.className} onClick={handleTakeScreenshot}>
      Сделать скриншот
    </PrimaryButton>
  );
};

export default DeviceScreenshotTaker;
