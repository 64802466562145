import {Dialog, DialogContent} from "@mui/material";
import React from "react";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import {Transition} from "../common/components";
import {NestedPolicyType} from "../../common/APIRequests/policy/types";
import NestedPolicyForm from "./NestedPolicyForm";

type NestedPolicyEditDialogProps<PolicyType> = {
  onClose: () => void;
  policy?: PolicyType;
  onChange: (policy: PolicyType) => void;
};

const NestedPolicyEditDialog = <PolicyType extends NestedPolicyType>(
  props: NestedPolicyEditDialogProps<PolicyType>
) => (
  <Dialog
    open={props.policy !== undefined}
    onClose={props.onClose}
    keepMounted
    TransitionComponent={Transition}
  >
    <ClosableDialogTitle onClose={props.onClose}>
      Изменить политику {props.policy?.policy_name}
    </ClosableDialogTitle>
    <DialogContent>
      {props.policy !== undefined && (
        <NestedPolicyForm
          handleClose={props.onClose}
          nestedPolicy={props.policy}
          update={props.onChange}
        />
      )}
    </DialogContent>
  </Dialog>
);

export default NestedPolicyEditDialog;
