import React from "react";
import {FormControl, FormHelperText, TextField} from "@mui/material";
import {fieldProps} from "../../../common/types";

const StringField = (props: fieldProps) => {
  const {
    setValue,
    value,
    name,
    label,
    errors,
    inputProps,
    sanitizer,
    multiline,
    style,
    disabled,
    helperText,
    inputRef,
    autoFocus,
    type,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    delete errors[name];
    const value = sanitizer ? sanitizer(e.target.value) : e.target.value;
    setValue(value);
  };

  return (
    <React.Fragment>
      <FormControl fullWidth error={!!errors[name]} style={style}>
        <TextField
          variant="outlined"
          fullWidth
          id={name}
          name={name}
          type={type || "text"}
          error={!!errors[name]}
          autoComplete="off"
          label={label}
          onChange={handleChange}
          value={value}
          aria-describedby={`component-error-${name}`}
          inputProps={inputProps}
          multiline={multiline || false}
          disabled={disabled || false}
          helperText={helperText}
          inputRef={inputRef}
          autoFocus={autoFocus}
        />
        {errors[name] && (
          <FormHelperText id={`component-error-${name}`}>
            {errors[name]}
          </FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

export default StringField;
