import {baseGetRowsRequestWithCount} from "../base/baseGetRowsRequestWithCount";

export type graphicalStatusType =
  | "ok"
  | "unenrolled"
  | "initializing"
  | "discredit";

export type deviceType = {
  id_device: number;
  device_uid: string;
  comment_text: string | null;
  is_compromised: "0" | "1";
  policy_code: 0 | 1 | -100;
  name: string | null;
  device_registration_status: number;
  device_registration_time: string | null;
  code_division: string | null;
  serial_number: string | null;
  firmware: string | null;
  imei: string | null;
  mac: string | null;
  is_cellullar_enabled: 0 | 1;
  is_cellullar_roaming: 0 | 1;
  ip4: string | null;
  ip6: string | null;
  sim_id: string | null;
  sim_id_register: string | null;
  cellular_signal_strength: number | null;
  wifi_on: 0 | 1;
  wifi_ip4: string | null;
  wifi_ip6: string | null;
  wifi_ssid: string | null;
  wifi_signal_strength: number | null;
  csr_file: string | null;
  date_csr: string | null;
  get_config_date: string | null;
  apply_config_date: string | null;
  cellular_network_provider: string | null;
  policy_apply_time: string | null;
  last_seen_time: string | null;
  last_seen_time_from_now: number | null;
  graphical_status: graphicalStatusType;
  lan_mac: string | null;
  lan_ip4: string | null;
  lan_ip6: string | null;
  admin_mode: string;

  id_project: number;
  project_name: string;
  id_user: number;
  user_display_name: string;
  user_email: string;
  user_ldap_login: string;
  id_model: number;
  model_name: string;
  model_producer: string;
  id_type_device: number;
  type_device_name: string;
  id_type_connect: number;
  type_connect_name: string;
  id_dev_version: number;
  version_code: string;
};

export type DeviceFilterType = {
  serial_number?: string;
  code_division?: string;
  id_project: number | null;
  inherited?: 1 | 0;
  model?: string;
  firmware?: string;
  imei?: string;
  wi_fi_mac?: string;
  lan_mac?: string;
  version_code?: string;
  ldap_login?: string;
  id_net?: number | null;
  id_branch?: number | null;
};

const getDevices = async (
  page: number,
  page_size: number,
  sort_field: string,
  sort_desc: boolean,
  filters: DeviceFilterType | undefined
) =>
  baseGetRowsRequestWithCount<deviceType>(`/device/`, {
    page,
    page_size,
    sort_field,
    sort_desc,
    filters,
  });

export default getDevices;
