import {Dialog, DialogContent} from "@mui/material";
import {useState} from "react";
import getProfiles, {
  APP_PROFILE_TYPE,
  profileType,
} from "../../../common/APIRequests/profile/getProfiles";
import {projectApplicationType} from "../../../common/APIRequests/projectApplication/getProjectApplications";
import postProjectApplicationsProfile from "../../../common/APIRequests/projectApplicationProfile/postProjectApplicationsProfile";
import {useTrigger} from "../../../common/customHooks";
import {ConfirmDialog} from "../../common/buttons/ConfirmDialog";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import AddApplicationProfileColumns from "./AddApplicationProfileColumns";

type AddApplicationProfileDialogTableProps = {
  onSubmit: () => void;
  projectApplication: projectApplicationType;
  onClose: () => void;
};

const AddApplicationProfileDialogTable = (
  props: AddApplicationProfileDialogTableProps
) => {
  const filters = {id_type_profile: APP_PROFILE_TYPE};
  const updateRows: UpdateRowsType<profileType> = () => getProfiles(filters);
  const [trigger, refresh] = useTrigger();
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState(
    undefined as profileType | undefined
  );

  const confirmAddProfile = (id_prof: number) => {
    setLoading(true);
    postProjectApplicationsProfile(
      props.projectApplication.id_project,
      props.projectApplication.id_app,
      id_prof
    )
      .then((result) => {
        if (result.success) {
          refresh();
          props.onSubmit();
          props.onClose();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {confirmOpen && (
        <ConfirmDialog
          loading={loading}
          handleClose={() => setConfirmOpen(false)}
          handleConfirm={() => {
            selectedProfile && confirmAddProfile(selectedProfile.id_prof);
          }}
          message="Привязать профиль?"
          checkPin
        />
      )}
      <Dialog
        open={true}
        onClose={props.onClose}
        keepMounted
        TransitionComponent={Transition}
        maxWidth="lg"
      >
        <ClosableDialogTitle
          id="add-application-profile-modal"
          onClose={props.onClose}
        >
          Добавить профиль к приложению {props.projectApplication.app_name}{" "}
          проекта {props.projectApplication.name_project}
        </ClosableDialogTitle>
        <DialogContent>
          <CoreTable
            name="add_application_profile"
            columns={AddApplicationProfileColumns}
            getRowId={(r: profileType) => r.id_prof}
            updateRows={updateRows}
            defaultSorting={{columnName: "name", direction: "asc"}}
            integratedSearch
            updateTrigger={trigger}
            height="60vh"
            onRowClick={(r: profileType) => {
              setSelectedProfile(r);
              setConfirmOpen(true);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddApplicationProfileDialogTable;
