import {BooleanTypeProvider} from "../common/grid/providers";
import Column from "../Tables/Column";

const ApplicationVersionColumns = [
  new Column("id_app_version", "ID", 70, {hiddenByDefault: true}),
  new Column("name", "Android версия", 150),
  new Column("code", "Код версии", 120),
  new Column("file_path", "Путь до APK", 500, {hiddenByDefault: true}),
  new Column("file_hash", "Hash-сумма", 300, {hiddenByDefault: true}),
  new Column("description", "Описание", 200, {auto: true}),
  new Column("is_release", "Релизная", 120, {
    provider: BooleanTypeProvider,
    align: "center",
  }),
];

export default ApplicationVersionColumns;
