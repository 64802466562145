import {notifyRecipientInputsType} from "./getNotifyRecipient";
import {basePostRequest} from "../base/basePostRequest";

const postNotificationRecipient = async (
  id_notify: number,
  notifyRecipientInputs: notifyRecipientInputsType
) =>
  basePostRequest<notifyRecipientInputsType>(
    `/notify/${id_notify}/recipient/`,
    notifyRecipientInputs
  );

export default postNotificationRecipient;
