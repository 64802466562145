import {basePostRequest} from "../base/basePostRequest";
import {taskType} from "./getTasks";

export type PostTaskType = {
  id_project?: number;
  children?: "0" | "1";
  id_app_version?: number;
  arr_geo?: number[];
};

const postTask = async (task: PostTaskType) =>
  basePostRequest<taskType>(`/task/`, task);

export default postTask;
