import {Tooltip} from "@mui/material";
import {optionType} from "../../../common/types";
import React from "react";
import {DataTypeProvider} from "@devexpress/dx-react-grid";
import {CustomDataTypeProviderProps} from "../../common/grid/providers";

export const notificationMessageIsActiveOptions = [
  {value: 0, text: "Отключено"},
  {value: 1, text: "Активно"},
];

export const notificationMessageCurrentStatusOptions = [
  {value: 0, text: "Новое"},
  {value: 1, text: "Отправлено"},
  {value: 2, text: "Завершено"},
  {value: 3, text: "Завершено с ошибкой"},
];

const NotificationMessageIsActiveFormatter = (
  data: DataTypeProvider.ValueFormatterProps
) => {
  const colors: {[s: string]: string} = {
    0: "red",
    1: "green",
  };
  return (
    <Tooltip
      title={
        notificationMessageIsActiveOptions.filter(
          (o: optionType) => o.value === parseInt(data.value)
        )[0].text
      }
    >
      <div
        className="status-circle"
        style={{
          backgroundColor: colors[data.value],
        }}
      />
    </Tooltip>
  );
};

export const NotificationMessageIsActiveProvider = (
  props: CustomDataTypeProviderProps
) => (
  <DataTypeProvider
    formatterComponent={NotificationMessageIsActiveFormatter}
    {...props}
  />
);

const NotificationMessageStatusFormatter = (
  data: DataTypeProvider.ValueFormatterProps
) => {
  const colors: {[s: string]: string} = {
    0: "darkgray",
    1: "#E8C547", //yellow
    2: "green",
    3: "red",
  };

  return (
    <Tooltip
      title={
        notificationMessageCurrentStatusOptions.filter(
          (o: optionType) => o.value === parseInt(data.value)
        )[0].text
      }
    >
      <div
        className="status-circle"
        style={{
          backgroundColor: colors[data.value],
        }}
      />
    </Tooltip>
  );
};

export const NotificationMessageStatusProvider = (
  props: CustomDataTypeProviderProps
) => (
  <DataTypeProvider
    formatterComponent={NotificationMessageStatusFormatter}
    {...props}
  />
);
