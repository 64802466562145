import * as React from "react";
import {useContext, useState} from "react";
import {Button, IconButton} from "@mui/material";
import theme from "../../../theme";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import {PrimaryButton} from "../../common/buttons/PrimaryButton";
import RequestPinDialog from "../../common/pin/RequestPinDialog";
import {AppContext} from "../../App-context";

export const RunTaskButton = (props: {
  disabled?: boolean;
  onClick: () => void;
}) => {
  const [pinOpen, setPinOpen] = useState(false);
  const {pinStatus} = useContext(AppContext);

  const onClick = async () => {
    if (pinStatus) {
      setPinOpen(true);
    } else {
      await props.onClick();
    }
  };

  return (
    <>
      {pinOpen && (
        <RequestPinDialog
          onClose={() => setPinOpen(false)}
          onSubmit={props.onClick}
        />
      )}
      <PrimaryButton
        title="Запустить"
        id={"run-task-button"}
        disabled={props.disabled}
        onClick={onClick}
      />
    </>
  );
};

export const SelectAppButton = (props: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button
      size="medium"
      variant="contained"
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled || false}
      style={{marginTop: theme.spacing(2)}}
    >
      Выбрать приложение
    </Button>
  );
};

export const ClearButton = (props: {
  onClick: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}) => (
  <IconButton
    id={"clear-button-id"}
    onClick={props.onClick}
    size="small"
    disabled={props.disabled || false}
    style={{marginRight: "24px", ...props.style}}
  >
    <ClearIcon />
  </IconButton>
);

export const AddMoreButton = (props: {
  onClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}) => (
  <Button
    onClick={props.onClick}
    variant="contained"
    size="medium"
    disabled={props.disabled || false}
    style={{marginRight: "24px", height: "56px", ...props.style}}
    color="primary"
  >
    <AddIcon />
  </Button>
);
