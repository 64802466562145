import * as React from "react";
import {useRef} from "react";
import {Getter, Plugin} from "@devexpress/dx-react-core";
import TableHeaderRowWidthControl, {
  minWidthSetter,
} from "./TableHeaderRowWidthControl";
import StyledColumnResizing, {
  StyledColumnResizingProps,
} from "./StyledColumnResizing";

type ColumnResizingHeaderProps = {
  hideHeaderSortingIndicator?: boolean;
  autoWidthColumnName?: string;
} & StyledColumnResizingProps;

const ColumnResizingHeader = (props: ColumnResizingHeaderProps) => {
  const extensionsRef = useRef(props.columnExtensions);

  const setColumnMinWidth: minWidthSetter = (columnParams) =>
    extensionsRef.current.forEach(
      (extension) =>
        extension.columnName == columnParams.columnName &&
        (extension.minWidth = columnParams.minWidth)
    );

  const {hideHeaderSortingIndicator, ...resizingProps} = props;
  return (
    <Plugin name="ColumnResizingWithHeaderMinWidth">
      <Getter name="columnExtensions" value={extensionsRef.current} />

      <StyledColumnResizing
        {...resizingProps}
        resizingMode="widget"
        columnExtensions={extensionsRef.current}
      />
      <TableHeaderRowWidthControl
        showSortingControls={!hideHeaderSortingIndicator}
        addMinWidth={setColumnMinWidth}
      />
    </Plugin>
  );
};

export default ColumnResizingHeader;
