import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type ModelType = {
  id_model: number;
  name: string;
  producer: string;
  description: string;
  username: string;
  lastdate: string;
};

const getModels = async () =>
  baseGetRowsRequest<ModelType>(`/device_model/`, {});

export default getModels;
