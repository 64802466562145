import {Chip, FormControl, FormHelperText, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {useState} from "react";
import {errorType, fieldProps} from "../../../common/types";

const chipAddChecker = (
  type: "email" | "sms",
  value: string,
  name: string,
  errors: errorType,
  setErrors?: Function
) => {
  let result: boolean = false;

  if (type === "email") {
    result = validateEmail(value);
  } else if (type === "sms") {
    result = validatePhone(value);
  }

  let what;
  type == "sms" && (what = "телефона");
  type == "email" && (what = "почты");

  !result &&
    setErrors &&
    setErrors({
      ...errors,
      [name]: [`Введенное значение не соответствует формату ${what}`],
    });

  return result;
};

export const validatePhone = (text: string | null) => {
  const re = /^[+]{1}[7]{1}[0-9]{10}$/im;
  const result = re.test(String(text).toLowerCase());
  return result;
};

export const validateEmail = (text: string | null) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]*[\wа-яА-Я]+\.)+[a-zA-Z]*[\wа-яА-Я]{2,}))$/;
  const result = re.test(String(text).toLowerCase());
  return result;
};

const ChipTextField = (props: fieldProps) => {
  const {
    setValue,
    value,
    name,
    label,
    errors,
    setErrors,
    inputProps,
    sanitizer,
    style,
    disabled,
    chipAddValidator,
  } = props;

  const [inputValue, setInputValue] = useState("");

  const handleDelete = (option: string) => {
    let values = value ? value.split(",") : [];
    values = values.filter((x: string) => x !== option);
    setValue(values.join(","));
  };

  const handleAdd = (option: string) => {
    const trimmedOption = option.trim();
    // validator
    if (chipAddValidator && trimmedOption) {
      if (
        !chipAddChecker(
          chipAddValidator,
          trimmedOption,
          name,
          errors,
          setErrors
        )
      ) {
        return;
      }
    }

    if (errors[name] || !trimmedOption) {
      return;
    }
    let values = value ? value.split(",") : [];
    !values.includes(trimmedOption) && values.push(trimmedOption);

    setValue(values.join(","));
    setInputValue("");
  };

  const handleChangeInputValue = (e: any) => {
    if (!e) {
      return;
    }
    delete errors[name];
    let newInputValue = sanitizer ? sanitizer(e.target.value) : e.target.value;

    const maxLength = inputProps && inputProps.maxLength;
    let newLength = (value ? value.length + 1 : 0) + newInputValue.length;

    // fix when newInputValue is empty and maxLength is achieved
    value && !newInputValue && (newLength -= 1);

    newInputValue === "," && (newInputValue = "");
    setInputValue(newInputValue);
    maxLength &&
      newLength > maxLength &&
      setErrors &&
      setErrors({[name]: ["Слишком длинная строка"]});
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!errors[name]}
      style={style}
    >
      <Autocomplete
        multiple
        id={name}
        options={[] as string[]}
        value={value ? value.split(",") : []}
        open={false}
        placeholder={label}
        clearIcon={<React.Fragment />}
        forcePopupIcon={false}
        renderTags={(value, getTagProps) =>
          value.map((option: string, index: number) => {
            const chip: any = getTagProps({index}) as any;
            return (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({index})}
                onDelete={() => {
                  handleDelete(option);
                  chip.onDelete();
                }}
                size="small"
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            label={label}
            onKeyPress={(e: any) =>
              ["Enter", ","].includes(e.key) && handleAdd(e.target.value)
            }
            error={!!errors[name]}
            aria-describedby={`component-error-${name}`}
          />
        )}
        inputValue={inputValue}
        onInputChange={handleChangeInputValue}
        onBlur={(e: any) => e && inputValue && handleAdd(inputValue)}
        disabled={disabled || false}
      />
      {errors[name] && (
        <FormHelperText id={`component-error-${name}`}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ChipTextField;
