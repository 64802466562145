import {useEffect, useRef, useState} from "react";
import StringFilterField from "./StringFilterField";
import {useDebouncedEffect} from "../../../common/customHooks";
import {debounceTimeout} from "../../../settings";

type StringFilterProps = {
  label: string;
  name: string;
  submittedValue?: string;
  onSubmit: (name: string) => (value: string) => void;
  focus?: string;
};

const StringFilter = (props: StringFilterProps) => {
  const [filterValue, setFilterValue] = useState(props.submittedValue);
  const valueSubmitted = useRef(false);

  const submitFilterValue = (value: string) => {
    valueSubmitted.current = true;
    props.onSubmit(props.name)(value.trim());
  };

  useDebouncedEffect(
    () => {
      if (
        filterValue !== undefined &&
        filterValue !== props.submittedValue &&
        !valueSubmitted.current
      ) {
        submitFilterValue(filterValue);
      }
      valueSubmitted.current = false;
    },
    debounceTimeout,
    [filterValue]
  );

  useEffect(() => setFilterValue(props.submittedValue), [props.submittedValue]);

  return (
    <StringFilterField
      label={props.label}
      name={props.name}
      value={filterValue || ""}
      onChange={(value: string) => setFilterValue(value)}
      onEnterPress={submitFilterValue}
      onResetClick={() => {
        submitFilterValue("");
        setFilterValue("");
      }}
    />
  );
};

export default StringFilter;
