import {apiClient} from "../../utils/apiClient";

export type comparedTreesType = {
  display_name: string;
  policy_name: string;
  value_result: string | undefined;
  file_value_result: string | undefined;
  match: boolean;
};

type compareErrorType = {file: [string]};

const postComparePolicyTreeWithCsv = async (
  id_project: number,
  data: FormData
): Promise<{
  success: boolean;
  data: Array<comparedTreesType>;
  errors?: compareErrorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "post",
      url: `/project/${id_project}/compare_policy_tree_with_csv/`,
      data,
    })
      .then((response) =>
        resolve({
          success: true,
          data: response.data as Array<comparedTreesType>,
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          data: [] as Array<comparedTreesType>,
          errors,
        });
      })
  );

export default postComparePolicyTreeWithCsv;
