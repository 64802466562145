import * as React from "react";
import {Dialog, DialogContent} from "@mui/material";
import {Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import UserReportForm from "./UserReportForm";
import {UserReport} from "../../common/APIRequests/user_report/getUserReports";

type AddNewUserReportDialogProps = {
  onClose: () => void;
  open: boolean;
  onCreate: (userReport: UserReport) => void;
};

const AddNewUserReportDialog = (props: AddNewUserReportDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      maxWidth="md"
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Добавить пользовательский отчет
      </ClosableDialogTitle>
      <DialogContent>
        <UserReportForm onClose={props.onClose} onCreate={props.onCreate} />
      </DialogContent>
    </Dialog>
  );
};

export default AddNewUserReportDialog;
