import {AppContextProps} from "../../components/App-context";
import {loginType} from "../APIRequests/login/postSession";

const getLoginInfo = (): loginType => {
  const authStorage = localStorage.getItem("auth");
  if (!authStorage) {
    return AppContextProps.permissions;
  }
  try {
    return JSON.parse(authStorage) as loginType;
  } catch (error) {
    return AppContextProps.permissions;
  }
};
export default getLoginInfo;
