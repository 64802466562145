import ReactEcharts from "echarts-for-react";
import React from "react";
import {barDataType} from "./BarChart";
import {EChartOption} from "echarts";
import theme from "../../../theme";

type Props = {
  data: Array<barDataType>;
  color?: Array<string>;
};

const BarVerticalChart = (props: Props) => {
  const {data, color = [theme.palette.primary.main]} = props;

  const option: EChartOption = {
    color,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: data.map((d: barDataType) => d.name),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: data.map((d: barDataType) => d.quantity),
        type: "bar",
      },
    ],
  };

  return <ReactEcharts notMerge={true} lazyUpdate={true} option={option} />;
};

export default BarVerticalChart;
