import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

const cancelDeviceCommand = async (
  id_device: number,
  id_comdev_link: number
): Promise<{
  success: boolean;
  status: string;
  errors: errorType;
}> =>
  new Promise((resolve) =>
    apiClient({
      method: "delete",
      url: `/device/${id_device}/command/${id_comdev_link}/`,
    })
      .then((response) =>
        resolve({
          success: true,
          status: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          status: "",
          errors: errors,
        });
      })
  );

export default cancelDeviceCommand;
