import {
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Theme,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useState} from "react";
import {optionType} from "../../common/types";
import {filterType} from "./EventTable";
import {Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import FormButtons from "../common/buttons/FormButtons";
import FilterIconButton from "../common/buttons/FilterIconButton";
import {defaultEventFilter} from "../../common/APIRequests/event/getEvents";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: "62vw",
    zIndex: 1000,
  },
  filterItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
}));

const eventStatusOptions = [
  {key: 1, value: "1", text: "Активные"},
  {key: 0, value: "0", text: "Удаленные"},
];

type Props = {
  isSystemShowed: boolean;
  setIsSystemShowed: Function;
  filters: filterType;
  setFilters: Function;
  loading: boolean;
  setLoading: Function;
  userDirsOptions: Array<optionType>;
  propertyDirsOptions: Array<optionType>;
  byDirsOptions: Array<optionType>;
  levelDirsOptions: Array<optionType>;
  setDirectoryFormOpen: Function;
  setDirectoryModifyFormOpen: Function;
};

const EventFilter = (props: Props) => {
  const {
    isSystemShowed,
    setIsSystemShowed,
    loading,
    userDirsOptions,
    propertyDirsOptions,
    levelDirsOptions,
  } = props;

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({...props.filters});

  const handleChange = (name: string, value: number) => {
    if (value === 0) {
      setFilters({...filters, [name]: undefined});
    } else {
      setFilters({...filters, [name]: value});
    }
  };

  const {classes} = useStyles();

  return (
    <React.Fragment>
      <FilterIconButton onClick={() => setOpen(true)} />
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          keepMounted
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          classes={{
            scrollPaper: classes.scrollPaper,
          }}
        >
          <ClosableDialogTitle onClose={() => setOpen(false)}>
            Фильтр
          </ClosableDialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  {userDirsOptions.length > 0 && (
                    <Grid item xs={3} className={classes.filterItem}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="id_type_user">
                          Классификатор
                        </InputLabel>
                        <Select
                          id="id_type_user"
                          name="id_type_user"
                          fullWidth
                          value={
                            filters.id_type_user
                              ? filters.id_type_user.toString()
                              : "0"
                          }
                          onChange={(e: any) =>
                            handleChange("id_type_user", e.target.value)
                          }
                          disabled={loading}
                        >
                          {userDirsOptions.map((e: optionType) => (
                            <MenuItem key={e.key} value={e.value}>
                              {e.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {propertyDirsOptions.length > 0 && (
                    <Grid item xs={2} className={classes.filterItem}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="id_type_property">
                          Тип события
                        </InputLabel>
                        <Select
                          id="id_type_property"
                          name="id_type_property"
                          fullWidth
                          value={
                            filters.id_type_property
                              ? filters.id_type_property.toString()
                              : "0"
                          }
                          onChange={(e: any) =>
                            handleChange("id_type_property", e.target.value)
                          }
                          disabled={loading}
                        >
                          {propertyDirsOptions.map((e: optionType) => (
                            <MenuItem key={e.key} value={e.value}>
                              {e.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {levelDirsOptions.length > 0 && (
                    <Grid item xs={2} className={classes.filterItem}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="id_type_level">
                          Уровень хранения
                        </InputLabel>
                        <Select
                          id="id_type_level"
                          name="id_type_level"
                          fullWidth
                          value={
                            filters.id_type_level
                              ? filters.id_type_level.toString()
                              : "0"
                          }
                          onChange={(e: any) =>
                            handleChange("id_type_level", e.target.value)
                          }
                          disabled={loading}
                        >
                          {levelDirsOptions.map((e: optionType) => (
                            <MenuItem key={e.key} value={e.value}>
                              {e.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={2} className={classes.filterItem}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Статус</InputLabel>
                      <Select
                        id="status"
                        name="status"
                        fullWidth
                        value={filters.status ? filters.status : "0"}
                        onChange={(e: any) =>
                          handleChange("status", e.target.value)
                        }
                        disabled={loading}
                      >
                        {eventStatusOptions.map((e: optionType) => (
                          <MenuItem key={e.key} value={e.value}>
                            {e.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} className={classes.filterItem}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isSystemShowed}
                          onChange={(e: any) =>
                            setIsSystemShowed(e.target.checked)
                          }
                          name="isSystemShowed"
                          size="medium"
                        />
                      }
                      label="Показать системные"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FormButtons
              loading={loading}
              handleSubmit={() => {
                props.setFilters(filters);
              }}
              handleClose={() => {
                setFilters(defaultEventFilter);
                props.setFilters(defaultEventFilter);
              }}
              submitMessage="Показать"
              cancelMessage="Сбросить все"
            />
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default EventFilter;
