import * as React from "react";
import {useContext} from "react";
import TableContext from "../Tables/TableContext";
import {ConsoleLogsFilterType} from "./ConsoleLogsFilter";
import {
  RefreshButton,
  RefreshButtonProps,
} from "../common/buttons/ToolbarButtons";

const ConsoleLogRefreshButton = (props: RefreshButtonProps) => {
  const context = useContext(TableContext);
  const filters = context.filters as ConsoleLogsFilterType;

  return (
    <RefreshButton {...props} disabled={!filters.source || props.disabled} />
  );
};

export default ConsoleLogRefreshButton;
