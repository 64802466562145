import {FormControl, FormHelperText} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {errorType} from "../../../common/types";
import theme from "../../../theme";
import {AppContext} from "../../App-context";
import FormButtons from "../../common/buttons/FormButtons";
import BooleanField from "../../common/formFields/BooleanField";
import SelectApplicationDialog from "../Dialogs/SelectApplicationDialog";
import SelectProjectForm from "./SelectProjectForm";
import StringField from "../../common/formFields/StringField";
import SelectedApplicationView, {
  AppVersionViewType,
} from "../Components/SelectedApplicationView";
import SelectGeographyForm, {GeosType} from "./SelectGeographyForm";
import {RunTaskButton, SelectAppButton} from "../Components/buttons";
import patchTaskTemplate from "../../../common/APIRequests/taskTemplate/patchTaskTemplate";
import postTaskTemplate from "../../../common/APIRequests/taskTemplate/postTaskTemplate";
import getTemplate, {
  TaskTemplateDetailsType,
} from "../../../common/APIRequests/taskTemplate/getTemplate";
import postTask from "../../../common/APIRequests/task/postTask";
import {LoadingIndicator} from "../../common/components";
import {ToolbarItem} from "../../common/Toolbar";

export const convertGeoToArr: (geos: GeosType) => number[] = ({
  net,
  branches,
  divisions,
}) => {
  if (divisions && divisions.length > 0) return divisions.map((d) => d.id_geo);
  else if (branches && branches.length > 0)
    return branches.map((b) => b.id_geo);
  else return net ? [net] : [];
};

const TaskTemplateForm = (props: {
  taskTemplateId?: number;
  handleClose: () => void;
  updateTable: () => void;
}) => {
  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const templateId = useRef(props.taskTemplateId);

  const [errors, setErrors] = useState({} as errorType);

  const [isUpdated, setIsUpdated] = useState(templateId.current === undefined);
  const setUpdated = (newValue: boolean) =>
    newValue !== isUpdated && setIsUpdated(newValue);

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    children: "0",
  } as TaskTemplateDetailsType);

  const [addAppOpen, setAddAppOpen] = useState(false);

  const loadTemplateDetails = async () => {
    if (templateId.current) {
      setLoading(true);
      const result = await getTemplate(templateId.current);
      setLoading(false);
      if (result.success && result.data) {
        selectedAppVersionDetails.current = {
          name: result.data.app_version_name,
          description: result.data.app_version_description,
          file_path: result.data.app_version_file_path,
        };
        setValues(result.data);
      }
    }
  };

  useEffect(() => {
    loadTemplateDetails();
  }, []);

  const updateValues = (newValues: TaskTemplateDetailsType) => {
    setUpdated(true);
    setValues(newValues);
  };

  const selectedAppVersionDetails = useRef<AppVersionViewType | null>(null);

  const mapToPostData = ({
    name,
    id_project,
    id_app_version,
    children,
    net,
    branches,
    divisions,
  }: TaskTemplateDetailsType) => {
    if (Object.keys(errors).length || loading) {
      return;
    }
    const localErrors = {} as errorType;
    if (!name) {
      localErrors.name = ["Заполните поле наименование"];
    }
    if (!id_app_version) {
      localErrors.id_app_version = ["Выберите приложение"];
    }
    if (!id_project) {
      localErrors.id_project = ["Выберите проект"];
    }
    if (Object.keys(localErrors).length > 0) {
      setErrors(localErrors);
    } else {
      return {
        name,
        id_project: id_project!,
        id_app_version: id_app_version!,
        children,
        arr_geo: convertGeoToArr({net, branches, divisions}),
      };
    }
  };

  const handleSubmit = async () => {
    const postData = mapToPostData(values);
    if (!postData) return;

    let result;
    setLoading(true);

    if (templateId.current) {
      result = await patchTaskTemplate(templateId.current, postData);
    } else {
      result = await postTaskTemplate(postData);
    }
    setLoading(false);

    if (result.success) {
      setErrors({});
      setAlert({
        severity: "success",
        message: ["Шаблон задания сохранен"],
      });
      props.updateTable();
      if (templateId.current === undefined) {
        templateId.current = result.row?.id_template;
      }
      setUpdated(false);
    } else {
      setErrors(result.errors);
    }
  };

  const handleChange = (name: "name" | "children") => (value: string) => {
    delete errors[name];
    updateValues({...values, [name]: value});
  };

  const startTask = async () => {
    setLoading(true);
    const {id_project, id_app_version, children} = values;
    const result = await postTask({
      id_project,
      id_app_version,
      children,
      arr_geo: convertGeoToArr(values),
    });
    setLoading(false);

    if (result.success) {
      setErrors({});
      props.handleClose();
      setAlert({
        severity: "success",
        message: ["Задание запущено"],
      });
      props.updateTable();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <React.Fragment>
      {addAppOpen && (
        <SelectApplicationDialog
          onClose={() => setAddAppOpen(false)}
          setSelected={(app_version) => {
            delete errors.id_app_version;
            selectedAppVersionDetails.current = app_version;
            updateValues({
              ...values,
              id_app_version: app_version.id_app_version,
            });
          }}
        />
      )}

      <StringField
        label="Наименование"
        value={values.name || ""}
        setValue={handleChange("name")}
        name="name"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 255}}
        disabled={loading}
      />
      <FormControl fullWidth error={!!errors.geo}>
        <SelectGeographyForm
          errors={errors}
          geos={values as GeosType}
          setGeos={(geo) => {
            delete errors.geo;
            updateValues({...values, ...geo});
          }}
          disabled={loading}
        />
      </FormControl>
      <FormControl fullWidth error={!!errors.id_project}>
        <SelectProjectForm
          loading={loading}
          selectedProjectId={values.id_project}
          onSelect={(id_project) => {
            delete errors.id_project;
            updateValues({...values, id_project: id_project});
          }}
          errors={errors.id_project}
        />
      </FormControl>
      <BooleanField
        setValue={handleChange("children")}
        name="children"
        value={values.children ? values.children.toString() : "0"}
        label="Включать подпроекты"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        style={{marginTop: theme.spacing(1)}}
      />
      <SelectAppButton onClick={() => setAddAppOpen(true)} disabled={loading} />

      {selectedAppVersionDetails.current && (
        <SelectedApplicationView
          app_version={selectedAppVersionDetails.current}
        />
      )}
      {errors.id_app_version && (
        <FormControl fullWidth error>
          <FormHelperText id={`component-error-id_project`}>
            {errors.id_app_version}
          </FormHelperText>
        </FormControl>
      )}

      {loading && <LoadingIndicator />}
      <FormButtons
        disabled={!isUpdated}
        handleSubmit={handleSubmit}
        handleClose={props.handleClose}
        checkPin
        alignChildrenLeft
      >
        <ToolbarItem>
          <RunTaskButton onClick={startTask} disabled={isUpdated || loading} />
        </ToolbarItem>
      </FormButtons>
    </React.Fragment>
  );
};

export default TaskTemplateForm;
