import {Grid} from "@mui/material";
import FormButtons from "../common/buttons/FormButtons";
import {FilterComponentProps} from "../Tables/CoreTable";
import {DeviceFilterType} from "../../common/APIRequests/device/getDevices";
import {StyledMenuFilterGrid} from "../common/grid/StyledFilterGrid";
import BooleanFilter from "../common/filterFields/BooleanFilter";
import {useEffect, useState} from "react";
import StringFilterField from "../common/filterFields/StringFilterField";
import getBranch, {
  geographyType,
} from "../../common/APIRequests/geography/getBranch";
import SelectSingleFilterField from "../common/filterFields/SelectSingleFilterField";

const deviceStringFilters = [
  {label: "Модель", name: "model"},
  {label: "Прошивка", name: "firmware"},
  {label: "IMEI", name: "imei"},
  {label: "WI-FI MAC", name: "wi_fi_mac"},
  {label: "LAN MAC", name: "lan_mac"},
  {label: "Версия Android", name: "version_code"},
  {label: "Логин пользователя", name: "ldap_login"},
];

const deviceBooleanFilters = [
  {label: "Включать подпроекты", name: "inherited"},
];

const deviceSelectFilters = [
  {label: "Сеть", name: "id_net"},
  {label: "Филиал", name: "id_branch"},
];

type DeviceFilterKeys = keyof DeviceFilterType;

const dialogFilterNames = [
  ...deviceStringFilters,
  ...deviceBooleanFilters,
  ...deviceSelectFilters,
].map((f) => f.name);

const DeviceDialogFilter = (props: FilterComponentProps<DeviceFilterType>) => {
  const [filters, setFilters] = useState({...props.filters});
  const setFiltersHandler = (filterName: string) => (value: string | number) =>
    setFilters({...filters, [filterName]: value});
  const [loading, setLoading] = useState(false);
  const [networks, setNetworks] = useState([] as geographyType[]);
  const [branches, setBranches] = useState([] as geographyType[]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getBranch();
      result.success && setNetworks(result.rows);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (filters.id_net) {
      (async () => {
        setLoading(true);
        const result = await getBranch(filters.id_net?.toString());
        result.success && setBranches(result.rows);
        setLoading(false);
      })();
    }
  }, [filters.id_net]);

  return (
    <>
      <Grid container>
        {deviceStringFilters.map((filterParams) => (
          <StyledMenuFilterGrid item xs={3} key={`${filterParams.name}`}>
            <StringFilterField
              {...filterParams}
              value={
                filters[filterParams.name as DeviceFilterKeys]?.toString() ?? ""
              }
              onChange={(value: string) =>
                setFilters({...filters, [filterParams.name]: value})
              }
              onEnterPress={(value: string) =>
                setFilters({...filters, [filterParams.name]: value})
              }
              onResetClick={() => {
                setFilters({...filters, [filterParams.name]: ""});
              }}
            />
          </StyledMenuFilterGrid>
        ))}

        {deviceBooleanFilters.map((filterParams) => (
          <StyledMenuFilterGrid item xs={3} key={`${filterParams.name}`}>
            <BooleanFilter
              {...filterParams}
              value={filters[filterParams.name as DeviceFilterKeys] as number}
              onSubmit={setFiltersHandler}
            />
          </StyledMenuFilterGrid>
        ))}

        <StyledMenuFilterGrid item xs={3}>
          <SelectSingleFilterField
            name="net_id"
            label="Сеть"
            options={networks.map((network: geographyType) => ({
              value: network.id_geo,
              text: network.name_geo,
            }))}
            value={networks.length && filters.id_net ? filters.id_net : null}
            update={(value: number | null) =>
              setFilters({...filters, id_net: value})
            }
            disabled={loading}
            includeAllOption
          />
        </StyledMenuFilterGrid>
        <StyledMenuFilterGrid item xs={3}>
          <SelectSingleFilterField
            name="branch_id"
            label="Филиал"
            options={branches.map((branch: geographyType) => ({
              value: branch.id_geo,
              text: branch.name_geo,
            }))}
            value={
              branches.length && filters.id_branch ? filters.id_branch : null
            }
            update={(value: number | null) =>
              setFilters({...filters, id_branch: value})
            }
            disabled={loading}
            includeAllOption
          />
        </StyledMenuFilterGrid>
      </Grid>
      <FormButtons
        loading={props.loading}
        handleSubmit={() => {
          props.updateFilters(filters);
        }}
        handleClose={() => {
          const cleanedFilters = Object.fromEntries(
            Object.entries(filters).filter(
              ([name]) => !dialogFilterNames.includes(name)
            )
          ) as DeviceFilterType;
          setFilters(cleanedFilters);
          props.updateFilters(cleanedFilters);
        }}
        submitMessage="Показать"
        cancelMessage="Сбросить все"
      />
    </>
  );
};

export default DeviceDialogFilter;
