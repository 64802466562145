import {Slider, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {RemoteControlActionType} from "./DeviceRemoteControl";

const DeviceQualityControls = (props: {
  handleCommand: (command: RemoteControlActionType) => void;
}) => {
  const [scale, setScale] = useState<number>(1);
  const [quality, setQuality] = useState<number>(10);
  const [fps, setFps] = useState<number>(0);

  useEffect(() => {
    props.handleCommand({actionType: "setScale", value: scale});
    props.handleCommand({
      actionType: "setQuality",
      value: quality,
    });
    props.handleCommand({actionType: "setFps", value: fps});
  }, []);

  const handleScaleChange = (event: Event, newValue: number | number[]) => {
    setScale(newValue as number);
    props.handleCommand({actionType: "setScale", value: newValue as number});
  };

  const handleQualityChange = (event: Event, newValue: number | number[]) => {
    setQuality(newValue as number);
    props.handleCommand({
      actionType: "setQuality",
      value: newValue as number,
    });
  };

  const handleFpsChange = (event: Event, newValue: number | number[]) => {
    setFps(newValue as number);
    props.handleCommand({actionType: "setFps", value: newValue as number});
  };

  return (
    <>
      <Typography id="scale-slider" gutterBottom>
        Масштаб:
      </Typography>
      <Slider
        value={scale}
        onChange={handleScaleChange}
        min={1}
        step={0.5}
        max={2.5}
        marks={[
          {
            value: 1,
            label: "1",
          },
          {
            value: 1.5,
            label: "1.5",
          },
          {
            value: 2,
            label: "2",
          },
          {
            value: 2.5,
            label: "2.5",
          },
        ]}
        valueLabelDisplay="auto"
        aria-labelledby="scale-slider"
      />
      <Typography id="quality-slider" gutterBottom>
        Качество (%):
      </Typography>
      <Slider
        value={quality}
        onChange={handleQualityChange}
        min={1}
        max={100}
        marks={[
          {
            value: 1,
            label: "1",
          },
          {
            value: 25,
            label: "25",
          },
          {
            value: 50,
            label: "50",
          },
          {
            value: 75,
            label: "75",
          },
          {
            value: 100,
            label: "100",
          },
        ]}
        valueLabelDisplay="auto"
        aria-labelledby="quality-slider"
      />
      <Typography id="fps-slider" gutterBottom>
        Частота:
      </Typography>
      <Slider
        value={fps}
        onChange={handleFpsChange}
        min={0}
        max={25}
        step={1}
        marks={[
          {
            value: 0,
            label: "исходная",
          },
          {
            value: 5,
            label: "5",
          },
          {
            value: 12,
            label: "12",
          },
          {
            value: 18,
            label: "18",
          },
          {
            value: 25,
            label: "25",
          },
        ]}
        valueLabelDisplay="auto"
        aria-labelledby="fps-slider"
      />
    </>
  );
};

export default DeviceQualityControls;
