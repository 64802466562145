import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

export type BaseDeleteResponse = Promise<{
  success: boolean;
  errors: errorType;
}>;

export function baseDeleteRequest<DataParamsType, ParamsType>(
  url: string,
  data?: DataParamsType,
  params?: ParamsType
): BaseDeleteResponse {
  return new Promise((resolve) =>
    apiClient({
      method: "delete",
      url,
      data,
      params,
    })
      .then(() =>
        resolve({
          success: true,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          errors: errors,
        });
      })
  );
}
