import Column from "../../../Tables/Column";

const AddNotificationEventColumns = [
  new Column("id_event", "ID", 100, {hiddenByDefault: true}),
  new Column("event_name", "Наименование", 380, {auto: true}),
  new Column("event_code", "Код", 200),
  new Column("type_user_name", "Классификатор", 330),
  new Column("type_property_name", "Тип", 150),
  new Column("type_level_name", "Хранение", 150),
];

export default AddNotificationEventColumns;
