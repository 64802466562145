import * as React from "react";
import {Box, LinearProgress, Typography} from "@mui/material";

type ProgressBarProps = {
  progress?: number;
};
const ProgressBar = (props: ProgressBarProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={props.progress || 0}
          style={{height: 60}}
        />
      </Box>
      <Box minWidth={90}>
        <Typography variant="h6" style={{textAlign: "center"}}>{`${
          props.progress !== undefined ? Math.round(props.progress) : ""
        }%`}</Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
