import {Dialog, DialogContent} from "@mui/material";
import {ReactText, useState} from "react";
import getApplications, {
  ApplicationType,
} from "../../../common/APIRequests/application/getApplications";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import postProjectApplications from "../../../common/APIRequests/projectApplication/postProjectApplications";
import {useTrigger} from "../../../common/customHooks";
import FormButtons from "../../common/buttons/FormButtons";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import AddApplicationColumns from "./AddApplicationColumns";

type AddApplicationDialogTableProps = {
  onSubmit: () => void;
  project: projectType;
  projectApplicationIds: number[];
  onClose: () => void;
};

const AddApplicationDialogTable = (props: AddApplicationDialogTableProps) => {
  const updateRows: UpdateRowsType<ApplicationType> = () => getApplications();
  const [trigger, refresh] = useTrigger();
  const [loading, setLoading] = useState(false);

  const handleAddApplication = (application_ids: ReactText[]) => {
    setLoading(true);
    postProjectApplications(
      props.project.id_project,
      application_ids.map((id: ReactText) => ({id_app: id}))
    )
      .then((result) => {
        if (result.success) {
          refresh();
          props.onSubmit();
          props.onClose();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="lg"
    >
      <ClosableDialogTitle
        id="add-project-applications-modal"
        onClose={props.onClose}
      >
        Добавить приложения к проекту {props.project.project_name}
      </ClosableDialogTitle>
      <DialogContent>
        <CoreTable
          name="add_project_applications"
          columns={AddApplicationColumns}
          getRowId={(r: ApplicationType) => r.id_app}
          updateRows={updateRows}
          integratedSearch
          defaultSorting={{columnName: "name", direction: "asc"}}
          selectionEnabled
          selectRowsMode
          updateTrigger={trigger}
          height="60vh"
          hideRowPredicate={(row) =>
            props.projectApplicationIds.includes(row.id_app)
          }
          renderWithState={(state) => (
            <FormButtons
              loading={loading || state.loading}
              handleSubmit={() => handleAddApplication(state.selection)}
              handleClose={props.onClose}
              submitMessage="Добавить"
              submitId="applications_add"
              closeId="applications_close"
              disabled={loading || state.loading || !state.selectedRows.length}
              checkPin
            />
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddApplicationDialogTable;
