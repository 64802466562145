import React, {useContext, useState} from "react";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import getApplicationVersions, {
  applicationVersionType,
} from "../../common/APIRequests/applicationVersion/getApplicationVersions";
import {ApplicationType} from "../../common/APIRequests/application/getApplications";
import ApplicationVersionForm from "./ApplicationVersionForm";
import ApplicationVersionColumns from "./ApplicationVersionColumns";
import {useTrigger} from "../../common/customHooks";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import deleteApplicationVersions from "../../common/APIRequests/applicationVersion/deleteApplicationVersions";
import {AppContext} from "../App-context";
import actionHasPermissions from "../common/actionPermissions";

type ApplicationVersionsTableProps = {
  application: ApplicationType;
  onChange: () => void;
};

const ApplicationVersionsTable = (props: ApplicationVersionsTableProps) => {
  const [rowClicked, setRowClicked] = useState(
    undefined as applicationVersionType | undefined
  );

  const [refreshTrigger, refresh] = useTrigger();

  const {permissions} = useContext(AppContext);
  const deleteAllowed = actionHasPermissions(
    permissions.user_type,
    "delete_app_versions"
  );

  const update: UpdateRowsType<applicationVersionType> = () =>
    getApplicationVersions(props.application.id_app);

  return (
    <>
      {rowClicked && (
        <ApplicationVersionForm
          handleClose={() => setRowClicked(undefined)}
          update={refresh}
          applicationVersion={rowClicked}
        />
      )}
      <CoreTable
        name={"application_version"}
        columns={ApplicationVersionColumns}
        updateRows={update}
        getRowId={(row: applicationVersionType) => row.id_app_version || ""}
        updateTrigger={[refreshTrigger, props.application.id_app]}
        defaultSorting={{columnName: "name", direction: "asc"}}
        onRowClick={setRowClicked}
        integratedSearch
        selectionEnabled={deleteAllowed}
        selectionButton={
          deleteAllowed
            ? (selection, update) => (
                <MenuDeleteAction
                  selection={selection}
                  update={update}
                  action={(ids) =>
                    deleteApplicationVersions(
                      props.application.id_app,
                      ids
                    ).then((result) => {
                      result.success && props.onChange();
                      return result;
                    })
                  }
                />
              )
            : undefined
        }
      />
    </>
  );
};

export default ApplicationVersionsTable;
