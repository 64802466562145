import {Link} from "@mui/material";
import React, {useContext, useState} from "react";
import deleteDeviceSession from "../../common/APIRequests/user/deleteDeviceSession";
import {ConfirmDialog} from "../common/buttons/ConfirmDialog";
import {AppContext} from "../App-context";
import RequestPinDialog from "../common/pin/RequestPinDialog";
import TableContext, {TableContextType} from "../Tables/TableContext";
import {UserType} from "../../common/APIRequests/user/getUsers";

type DeleteSessionButtonProps = {
  id_user: number;
};

const DeleteSessionButton = (props: DeleteSessionButtonProps) => {
  const {updateRows} = useContext(TableContext) as TableContextType<UserType>;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pinOpen, setPinOpen] = useState(false);
  const {pinStatus} = useContext(AppContext);

  const onClick = () => {
    if (pinStatus) {
      setPinOpen(true);
    } else {
      handleDelete();
    }
  };

  const handleDelete = () => {
    setLoading(true);
    deleteDeviceSession(props.id_user)
      .then((result) => {
        if (result.success) {
          updateRows?.();
        }
      })
      .finally(() => {
        setLoading(false);
        setDialogOpen(false);
      });
  };
  return (
    <>
      {pinOpen && (
        // stopPropagation is needed to avoid row click event
        <div onClick={(e) => e.stopPropagation()}>
          <RequestPinDialog
            onClose={() => setPinOpen(false)}
            onSubmit={handleDelete}
          />
        </div>
      )}
      {dialogOpen && (
        <div onClick={(e) => e.stopPropagation()}>
          <ConfirmDialog
            handleClose={() => setDialogOpen(false)}
            handleConfirm={onClick}
            message={"Вы действительно хотите завершить\n сессию пользователя?"}
            loading={loading}
          />
        </div>
      )}
      <Link
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          setDialogOpen(true);
        }}
      >
        Удалить сессию
      </Link>
    </>
  );
};

export default DeleteSessionButton;
