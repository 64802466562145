import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  DataTypeProvider,
  IntegratedFiltering,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import cancelDeviceCommand from "../../../common/APIRequests/deviceСommand/cancelDeviceCommand";
import getDeviceCommands, {
  deviceCommandType,
} from "../../../common/APIRequests/deviceСommand/getDeviceCommands";
import {useLocalStorage, useMounted} from "../../../common/customHooks";
import {
  BACKEND_DATE_FORMAT,
  columnType,
  FRONT_DATETIME_FORMAT,
  sortingType,
} from "../../../common/types";
import {AppContext} from "../../App-context";
import {ConfirmDialog} from "../../common/buttons/ConfirmDialog";
import {RefreshButton} from "../../common/buttons/ToolbarButtons";
import PickerDate from "../../common/formFields/PickerDate";
import {StyledVirtualTable} from "../../common/grid/cell";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../../common/grid/StyledSearchPanel";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import MenuCommandsAction from "./MenuCommandsAction";
import {LoadingIndicator} from "../../common/components";
import {
  DeviceFilterType,
  deviceType,
} from "../../../common/APIRequests/device/getDevices";

const StatusFormatter = (data: any) => {
  const colors: {[s: string]: string} = {
    new: "gray",
    sended: "blue",
    received: "yellow",
    completed: "green",
    cancelled: "dimgray",
    cancelled_completed: "red",
    unsuccessful: "brown",
    delayed: "purple",
  };
  const titles: {[s: string]: string} = {
    new: "Новая",
    sended: "Отправлена на устройство",
    received: "Получена устройством",
    completed: "Завершена",
    cancelled: "Отменена",
    cancelled_completed: "Отменена, но завершена",
    unsuccessful: "Выполнено с ошибкой",
    delayed: "Отложено",
  };
  return (
    <Tooltip title={titles[data.value]}>
      <div
        className="status-circle"
        style={{
          backgroundColor: colors[data.value],
        }}
      />
    </Tooltip>
  );
};

const columns: columnType = [
  {name: "id_comdev_link", title: "ID"},
  {name: "status", title: "Статус"},
  {name: "tag", title: "Команда"},
  {
    name: "createdate",
    title: "Дата отправки",
    getCellValue: (row: deviceCommandType) =>
      row.createdate
        ? moment(row.createdate).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
  {name: "username", title: "Пользователь"},
  {
    name: "status_date",
    title: "Дата изменения статуса",
    getCellValue: (row: deviceCommandType) =>
      row.status_date
        ? moment(row.status_date).format(FRONT_DATETIME_FORMAT)
        : undefined,
  },
  {name: "addition_data", title: "Дополнительно"},
  {name: "description", title: "Описание"},
  {name: "cancel", title: "Отменить"},
];
const columnExtensions = [
  {columnName: "id_comdev_link", width: 150},
  {columnName: "status", width: 100},
  {columnName: "tag", width: 200},
  {columnName: "createdate", width: 300},
  {columnName: "status_date", width: 300},
  {columnName: "addition_data", width: 150},
  {columnName: "description", width: 200},
  {columnName: "cancel", width: 200},
  {columnName: "username", width: 200},
];
const columnAlignExtension = [{columnName: "status", align: "center"}];
const hiddenColumnNames = ["id_comdev_link", "addition_data"];

const sortingStateColumnExtensions = [
  {columnName: "cancel", sortingEnabled: false},
];

const Commands = (props: {
  loading: boolean;
  setLoading: (l: boolean) => void;
  device: deviceType;
}) => {
  const {loading, setLoading, device} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [date, setDate] = useState(null as string | null);

  const [localLoading, setLocalLoading] = useState(false);

  const [selected, setSelected] = useState(
    undefined as deviceCommandType | undefined
  );

  const [hideFilters, setHideFilters] = useState(true);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "device_commands_sorting",
    [{columnName: "addition_data", direction: "desc"}]
  );

  const isMounted = useMounted();

  const [rows, setRows] = useState([] as Array<deviceCommandType>);
  useEffect(() => {
    updateRows();
  }, [date, sorting]);

  const updateRows = async () => {
    const {direction, columnName} = sorting[0];

    setLoading(true);
    const result = await getDeviceCommands(
      device.id_device,
      columnName,
      direction == "desc",
      date ? moment(date).format(BACKEND_DATE_FORMAT) : undefined
    );
    setLoading(false);
    result.success && setRows(result.rows);

    if (!isMounted && result.rows.length > 0) {
      setHideFilters(false);
    }
  };

  const handleCancel = async () => {
    if (!selected) {
      return;
    }

    setLocalLoading(true);
    const result = await cancelDeviceCommand(
      device.id_device,
      selected.id_comdev_link
    );
    setLocalLoading(false);
    setSelected(undefined);

    if (result.success) {
      let message = "";
      switch (result.status) {
        case "cancelled":
          message = "Команда отменена";
          break;
        case "cancelled_completed":
          message = "Команда уже завершилась";
      }
      setAlert({
        severity: "success",
        message: [message],
      });
      updateRows();
    }
  };

  const CancelFormatter = (data: any) => {
    if (
      [
        "cancelled",
        "cancelled_completed",
        "completed",
        "unsuccessful",
      ].includes(data.row.status)
    ) {
      return null;
    }
    return (
      <CloseIcon
        color="primary"
        onClick={() => setSelected(data.row)}
        style={{cursor: "pointer"}}
      />
    );
  };

  return (
    <div style={{position: "relative"}}>
      <Grid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id_comdev_link}
      >
        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingStateColumnExtensions}
        />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem id="commands_list">
            <MenuCommandsAction
              selection={[device.id_device]}
              rowDataSelection={[device]}
              filters={
                {
                  id_project: device.id_project ? device.id_project : null,
                } as DeviceFilterType
              }
              update={() => updateRows()}
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateRows}
              id="commands_refresh"
            />
          </ToolbarItem>
          <TemplatePlaceholder />
          <ToolbarItem>
            <PickerDate
              setValue={setDate}
              value={date}
              name={`date`}
              label={`Дата`}
              disabled={hideFilters}
            />
          </ToolbarItem>
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          height="68vh"
          columnExtensions={columnAlignExtension}
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              data-type="commands_row"
              data-id={row.id_comdev_link}
              {...restProps}
            />
          )}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="device_command"
          autoWidthColumnName={"tag"}
        />

        <DataTypeProvider
          for={["status"]}
          formatterComponent={StatusFormatter}
        />
        <DataTypeProvider
          for={["cancel"]}
          formatterComponent={CancelFormatter}
        />

        {loading && <LoadingIndicator />}
      </Grid>
      {selected && (
        <ConfirmDialog
          loading={localLoading}
          handleClose={() => setSelected(undefined)}
          handleConfirm={handleCancel}
          message="Отменить команду?"
        />
      )}
    </div>
  );
};

export default Commands;
