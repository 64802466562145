import {Plugin, Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  CustomTreeData,
  IntegratedFiltering,
  IntegratedSelection,
  SearchState,
  SelectionState,
  TreeDataState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Dialog, DialogContent, Link} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import deleteProjects from "../../common/APIRequests/project/deleteProjects";
import {projectType} from "../../common/APIRequests/project/getProjects";
import {columnType} from "../../common/types";
import {AppContext} from "../App-context";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import {AddNewButton, RefreshButton} from "../common/buttons/ToolbarButtons";
import {
  LoadingIndicator,
  TabPanel,
  tabProps,
  Transition,
} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import {CustomDialog} from "../common/dialog/CustomDialog";
import {smashTree} from "../common/functions";
import {
  StyledTableHeaderRow,
  StyledTableTreeColumn,
  StyledVirtualTable,
} from "../common/grid/cell";
import StyledSearchPanel from "../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../common/styles";
import {StyledTab, StyledTabs} from "../common/Tabs";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import ProjectApplicationsTable from "./ProjectApplications/ProjectApplicationTable";
import ProjectForm from "./ProjectForm";
import ProjectPoliciesTable from "./ProjectPolicies/ProjectPoliciesTable";
import ProjectProfilesTab from "./ProjectProfiles/ProjectProfilesTab";
import actionHasPermissions from "../common/actionPermissions";
import {Link as RouterLink} from "react-router-dom";

const columns: columnType = [
  {name: "project_name", title: "Наименование"},
  {name: "ldap_group", title: "Группа LDAP"},
  {name: "description", title: "Описание"},
  {
    name: "project_devices",
    title: "Список устройств",
    getCellValue: (row: projectType) => (
      <Link component={RouterLink} to={`/devices/project/${row.id_project}/`}>
        Перейти к устройствам
      </Link>
    ),
  },
];
const defaultTab = "common";

const Projects = () => {
  const appContext = useContext(AppContext);
  const {projects, updateProjects, loading, permissions} = appContext;

  const [projectsLoading, setProjectsLoading] = useState(false);

  const update = () => {
    setSelection([]);
    setProjectsLoading(true);
    updateProjects().then(() => setProjectsLoading(false));
  };

  const [selected, setSelected] = useState(
    undefined as projectType | undefined
  );

  const [selection, setSelection] = useState([] as Array<string | number>);

  const [tab, setTab] = useState(defaultTab);

  useEffect(() => setTab(defaultTab), [selected]);

  const [tabsLoading, setTabsLoading] = useState(false);

  const [policiesChanged, setPoliciesChanged] = useState<boolean>(false);

  const handleTabChange = (tabValue: string) => {
    if (tabsLoading) {
      return;
    }
    if (
      !policiesChanged ||
      window.confirm(
        "Вы действительно хотите покинуть вкладку? Несохраненные данные будут потеряны."
      )
    ) {
      setTab(tabValue);
      setPoliciesChanged(false);
    }
  };

  const handleClose = () => {
    if (tabsLoading) {
      return;
    }
    if (
      !policiesChanged ||
      window.confirm(
        "Вы действительно хотите закрыть окно? Несохраненные данные будут потеряны."
      )
    ) {
      setSelected(undefined);
      setPoliciesChanged(false);
    }
  };

  const [addOpen, setAddOpen] = useState(false);

  const flatRows = useMemo(() => {
    const result: Array<projectType> = [];
    projects.forEach((project: projectType) => smashTree(project, result));
    return result;
  }, [projects]);

  const [expandedRowIds, setExpandedRowIds] = useState<Array<number | string>>(
    []
  );

  useEffect(() => {
    const result: Array<number | string> = [];
    flatRows.forEach((p: projectType) => {
      p.children &&
        p.children.length &&
        p.id_project &&
        result.push(p.id_project);
    });
    setExpandedRowIds(result);
  }, [flatRows]);

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      {addOpen && (
        <Dialog
          open={true}
          onClose={() => !tabsLoading && setAddOpen(false)}
          keepMounted
          TransitionComponent={Transition}
        >
          <ClosableDialogTitle
            onClose={() => !tabsLoading && setAddOpen(false)}
            id="button_project_add_form_close"
          >
            Добавить проект
          </ClosableDialogTitle>
          <DialogContent id="dialog_project_add">
            <ProjectForm
              handleClose={() => setAddOpen(false)}
              project={{
                id_project: 0,
                project_name: "",
                ldap_group: "",
                successor: "0",
                description: "",
                higher: null,
              }}
              loading={tabsLoading}
              setLoading={setTabsLoading}
              updateProjects={update}
            />
          </DialogContent>
        </Dialog>
      )}

      {selected && (
        <CustomDialog
          onClose={handleClose}
          maxWidth={tab === "common" ? "sm" : "lg"}
          id="project_dialog"
        >
          <ClosableDialogTitle
            onClose={handleClose}
            id="button_project_info_close"
          >
            Информация о проекте {selected ? selected.project_name : ""}
          </ClosableDialogTitle>
          <DialogContent id="dialog_project_info">
            <StyledTabs
              value={tab}
              onChange={(_, tabValue) => handleTabChange(tabValue)}
              aria-label="wrapped label tabs"
            >
              <StyledTab
                label="Общая информация"
                {...tabProps("common")}
                disabled={tabsLoading}
                id="tab_project_info"
              />
              <StyledTab
                label="Приложения"
                {...tabProps("apps")}
                disabled={tabsLoading}
                id="tab_project_applications"
              />
              <StyledTab
                label="Политики"
                {...tabProps("policies")}
                disabled={tabsLoading}
                id="tab_project_policies"
              />
              <StyledTab
                label="Профили"
                {...tabProps("profiles")}
                disabled={tabsLoading}
                id="tab_project_profiles"
              />
            </StyledTabs>
            <TabPanel value={tab} index="common" id="tab_panel_project_form">
              <ProjectForm
                handleClose={handleClose}
                project={selected}
                loading={tabsLoading}
                setLoading={setTabsLoading}
                updateProjects={update}
              />
            </TabPanel>
            {tab === "apps" && (
              <TabPanel value={tab} index="apps" id="tab_panel_apps">
                <ProjectApplicationsTable
                  project={selected}
                  onClose={handleClose}
                  loading={tabsLoading}
                  setLoading={setTabsLoading}
                />
              </TabPanel>
            )}
            {tab === "policies" && (
              <TabPanel value={tab} index="policies" id="tab_panel_policies">
                <ProjectPoliciesTable
                  project={selected}
                  onClose={handleClose}
                  notifyHasChanges={(hasChanges) =>
                    setPoliciesChanged(hasChanges)
                  }
                />
              </TabPanel>
            )}
            {tab === "profiles" && (
              <TabPanel value={tab} index="profiles" id="tab_panel_profiles">
                <ProjectProfilesTab
                  project={selected}
                  loading={tabsLoading}
                  setLoading={setTabsLoading}
                />
              </TabPanel>
            )}
          </DialogContent>
        </CustomDialog>
      )}

      <Grid
        rows={projects}
        columns={columns}
        getRowId={(row) => row.id_project}
      >
        {/*Tree Data*/}
        <TreeDataState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <CustomTreeData
          getChildRows={(row: projectType, rootRows: Array<projectType>) =>
            row ? row.children || null : rootRows
          }
        />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          {[
            <AddNewButton
              onClick={() => setAddOpen(true)}
              id="button_add_project"
              key="button_add_project"
            />,
            <MenuDeleteAction
              selection={selection}
              update={update}
              action={deleteProjects}
              id="button_delete_projects"
              key="button_delete_projects"
              checkPin
            />,
            <RefreshButton
              disabled={loading || projectsLoading}
              update={update}
              id="button_projects_refresh"
              key="button_projects_refresh"
            />,
          ]
            .filter((item) =>
              actionHasPermissions(permissions.user_type, item.key?.toString())
            )
            .map((item, index) => (
              <ToolbarItem key={`projects_toolbar_item_${index}`}>
                {item}
              </ToolbarItem>
            ))}

          <TemplatePlaceholder />
        </Template>

        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              {...restProps}
              onClick={() => setSelected(row)}
              className={`${classes.gridRow} ${
                !parseInt(row.successor) && classes.rowColorRed
              }`}
              id={`${row.project_name}`}
            />
          )}
          height="80vh"
        />

        <StyledTableHeaderRow />

        {/*Row Selection*/}
        {actionHasPermissions(permissions.user_type, "projects_selection") && (
          <Plugin>
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <IntegratedSelection />

            <StyledTableTreeColumn
              for="project_name"
              showSelectionControls
              showSelectAll
            />
          </Plugin>
        )}

        {(loading || projectsLoading) && <LoadingIndicator />}
      </Grid>
    </React.Fragment>
  );
};

export default Projects;
