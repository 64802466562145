import {CardContent, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React from "react";
import {StyledCard} from "../../common/components";

const useStyles = makeStyles()((theme) => ({
  card: {
    width: "inherit",
    height: "inherit",
    paddingBottom: theme.spacing(2),
  },
  titleWrapper: {
    height: "15%",
  },
  media: {
    padding: theme.spacing(2),
    height: "85%",
    position: "relative",
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  noData: {
    textAlign: "center",
    position: "absolute",
    top: "40%",
    width: "100%",
  },
}));

const Chart = <DataType,>(props: {
  name: string;
  Chart: React.ComponentType<{data: DataType[]; color?: Array<string>}>;
  data: DataType[];
  color?: Array<string>;
}) => {
  const {name, data, color, Chart} = props;

  const {classes} = useStyles();

  return (
    <StyledCard className={classes.card}>
      <CardContent className={classes.titleWrapper}>
        <Typography variant="h6" component="h2" className={classes.title}>
          {name}
        </Typography>
      </CardContent>
      <CardContent className={classes.media}>
        {data.length ? (
          <Chart data={data} color={color} />
        ) : (
          <div className={classes.noData}>Данные отсутствуют</div>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default Chart;
