import {FormControl, IconButton, TextField} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, {CSSProperties} from "react";

type StringFilterFieldType = {
  label: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onEnterPress?: (value: string) => void;
  onResetClick?: () => void;
  inputRef?: React.MutableRefObject<HTMLFormElement | null>;
  disabled?: boolean;
  style?: CSSProperties;
};

const StringFilterField = (props: StringFilterFieldType) => {
  const {
    label,
    name,
    value,
    onChange,
    onFocus,
    onEnterPress,
    onResetClick,
    inputRef,
    disabled,
    style,
  } = props;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {value} = e.target;
    onChange(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onEnterPress && onEnterPress(value);
    }
  };

  const handleResetClient = () => {
    onResetClick && onResetClick();
  };

  return (
    <FormControl fullWidth style={{minWidth: 200, ...style}}>
      <TextField
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onFocus={onFocus}
        disabled={disabled}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleResetClient}
              size="small"
              disabled={!value || disabled}
              name={name}
              style={{marginLeft: 10}}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </FormControl>
  );
};

export default StringFilterField;
