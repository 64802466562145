import Grid from "@mui/material/Grid";
import * as React from "react";
import {ReactNode, useContext, useEffect, useState} from "react";
import {policyType} from "../../common/APIRequests/policy/getPolicies";
import patchPolicy from "../../common/APIRequests/policy/patchPolicy";
import postPolicy from "../../common/APIRequests/policy/postPolicy";
import {useMounted} from "../../common/customHooks";
import {errorType, fieldProps} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import BooleanField from "../common/formFields/BooleanField";
import ChipTextField from "../common/formFields/ChipTextField";
import {sanitizeFloat, sanitizeInt} from "../common/formFields/sanitizers";
import SelectMultipleField from "../common/formFields/SelectMultipleField";
import SelectSingleField from "../common/formFields/SelectSingleField";
import StringField from "../common/formFields/StringField";

const PolicyForm = (props: {
  handleClose: () => void;
  update: () => void;
  policy: policyType;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const {handleClose, update, policy, loading, setLoading} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const isMounted = useMounted();

  const isCreated = policy.id_pol !== 0;

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState(policy);

  useEffect(() => {
    isMounted && setValues({...values, possible_values: "", value: ""});
  }, [values.value_type]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    if (
      values.possible_values &&
      values.value &&
      values.value_type === "list"
    ) {
      // remove value from list if possible value was removed
      const options = values.possible_values.split(",");
      const newValues = values.value
        .split(",")
        .filter((x) => options.includes(x));
      setValues({...values, value: newValues.join(",")});
    } else {
      setValues({...values, value: ""});
    }
  }, [values.possible_values]);

  const handleSubmit = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    const newValues = values;
    if (values.value_type === "boolean" && !values.value) {
      newValues.value = "0";
    }

    let result;
    setLoading(true);
    if (isCreated) {
      result = await patchPolicy(newValues.id_pol, newValues);
    } else {
      result = await postPolicy(newValues);
    }
    setLoading(false);

    if (result.success) {
      setErrors({});
      handleClose();
      setAlert({
        severity: "success",
        message: ["Политика сохранена"],
      });
      update();
    } else {
      setErrors(result.errors);
    }
  };

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
  };

  let valueField: ReactNode;
  const valueProps: fieldProps = {
    setValue: handleChange("value"),
    value: values.value,
    name: "value",
    label: "Значение",
    errors: errors,
    setErrors: setErrors,
    inputProps: {maxLength: 2000},
    disabled: loading,
    style: {marginTop: theme.spacing(2)},
  };
  if (
    ["int", "float", "string"].includes(values.value_type) &&
    values.possible_values
  ) {
    valueField = (
      <SelectSingleField
        {...valueProps}
        options={values.possible_values.split(",")}
      />
    );
  } else if (values.value_type === "int") {
    valueField = <StringField {...valueProps} sanitizer={sanitizeInt} />;
  } else if (values.value_type === "float") {
    valueField = <StringField {...valueProps} sanitizer={sanitizeFloat} />;
  } else if (values.value_type === "string") {
    valueField = <StringField {...valueProps} />;
  } else if (values.value_type === "boolean") {
    valueField = <BooleanField {...valueProps} />;
  } else if (values.value_type === "list" && values.possible_values) {
    valueField = (
      <SelectMultipleField
        {...valueProps}
        key={`${values.possible_values}`}
        options={values.possible_values.split(",")}
        style={{
          marginTop: values.possible_values?.length ? theme.spacing(2) : "0",
        }}
      />
    );
  } else if (values.value_type === "list" && !values.possible_values) {
    valueField = <ChipTextField {...valueProps} />;
  } else {
    valueField = null;
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          marginTop: "-10px",
          paddingTop: "10px",
        }}
      >
        <StringField
          setValue={handleChange("display_name")}
          value={values.display_name}
          name="display_name"
          label="Наименование"
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 255}}
          disabled={loading}
        />
        <StringField
          setValue={handleChange("policy_name")}
          value={values.policy_name}
          name="policy_name"
          label="Системное наименование"
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 255}}
          disabled={loading}
          style={{marginTop: theme.spacing(2)}}
        />
        <StringField
          setValue={handleChange("description")}
          value={values.description || ""}
          name="description"
          label="Описание"
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 2000}}
          multiline
          disabled={loading}
          style={{marginTop: theme.spacing(2)}}
        />
        <SelectSingleField
          setValue={handleChange("value_type")}
          value={values.value_type}
          name="value_type"
          label="Тип значения"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          style={{marginTop: theme.spacing(2)}}
          options={[
            {key: "boolean", value: "boolean", text: "Логическое"},
            {key: "int", value: "int", text: "Целочисленное"},
            {key: "float", value: "float", text: "Дробное"},
            {key: "string", value: "string", text: "Строка"},
            {key: "list", value: "list", text: "Список"},
          ]}
        />
        {values.value_type !== "" && values.value_type !== "boolean" && (
          <ChipTextField
            setValue={handleChange("possible_values")}
            value={values.possible_values}
            name="possible_values"
            label="Возможные значения"
            errors={errors}
            setErrors={setErrors}
            sanitizer={
              values.value_type === "int"
                ? sanitizeInt
                : values.value_type === "float"
                ? sanitizeFloat
                : undefined
            }
            inputProps={{maxLength: 2000}}
            disabled={loading}
            style={{marginTop: theme.spacing(2)}}
          />
        )}
        {valueField && (
          <Grid item xs={12}>
            {valueField}
          </Grid>
        )}
        <BooleanField
          setValue={handleChange("is_project")}
          name="is_project"
          value={values.is_project ? values.is_project.toString() : "0"}
          label="Для проекта"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          style={{marginTop: theme.spacing(1)}}
        />

        <BooleanField
          setValue={handleChange("is_profile_device")}
          name="is_profile_device"
          value={
            values.is_profile_device ? values.is_profile_device.toString() : "0"
          }
          label="Для профиля устройства"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
        />

        <BooleanField
          setValue={handleChange("is_profile_app")}
          name="is_profile_app"
          value={values.is_profile_app ? values.is_profile_app.toString() : "0"}
          label="Для профиля приложения"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
        />
        <StringField
          setValue={handleChange("group_name")}
          value={values.group_name || ""}
          name="group_name"
          label="Наименование группы"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 30}}
          style={{marginTop: theme.spacing(1)}}
        />
      </Grid>
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitMessage="Сохранить"
        checkPin={isCreated}
      />
    </React.Fragment>
  );
};

export default PolicyForm;
