import {comparedTreesType} from "../../../common/APIRequests/policy/postComparePolicyTreeWithCsv";
import {projectType} from "../../../common/APIRequests/project/getProjects";
import {useGlobalStyles} from "../../common/styles";
import CoreTable from "../../Tables/CoreTable";
import getCompareTreesColumns from "./CompareTreesColumns";
import {BaseGetRowsResponse} from "../../../common/APIRequests/base/baseGetRowsRequest";

type CompareTreesTableType = {
  close: () => void;
  currentProject: projectType;
  destinationProject?: projectType;
  update: (
    currentIdProject: number,
    destinationIdProject?: number
  ) => BaseGetRowsResponse<comparedTreesType>;
  fileName?: string;
};

const CompareTreesTable = (props: CompareTreesTableType) => {
  const {currentProject, destinationProject, fileName} = props;
  const classes = useGlobalStyles();

  const getRowStyle = (row: comparedTreesType) => {
    if (!row.match) return classes.gridRowChanged;
    else return classes.gridRowSelected;
  };

  return (
    <CoreTable
      height="70vh"
      rowStyle={getRowStyle}
      name="compare_trees_table"
      columns={getCompareTreesColumns({
        currentProject,
        destinationProject,
        fileName,
      })}
      updateRows={() =>
        props.update(currentProject.id_project, destinationProject?.id_project)
      }
      getRowId={(row: comparedTreesType) => row.policy_name}
      integratedSearch
      defaultSorting={{columnName: "display_name", direction: "asc"}}
    />
  );
};

export default CompareTreesTable;
