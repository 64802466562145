import Column from "../../Tables/Column";

export const ProjectApplicationColumns = [
  new Column("id_pr_app_link", "ID", 70, {hiddenByDefault: true}),
  new Column("app_uid", "Идентификатор Android", 200),
  new Column("app_name", "Наименование", 200, {auto: true}),
  new Column("app_description", "Описание", 300, {hiddenByDefault: true}),
  new Column("name_project", "Проект", 200),
  new Column("id_prof", "ID профиля", 240, {hiddenByDefault: true}),
  new Column("prof_name", "Наименование профиля", 240),
  new Column("id_project", "ID проекта", 200, {hiddenByDefault: true}),
  new Column("is_inherited", "Наследуется", 100, {hiddenByDefault: true}),
];

export default ProjectApplicationColumns;
