import {basePostRequest} from "../base/basePostRequest";
import {UserReportFilter} from "./getUserReportFilters";

type SubmitReportFilterData = {
  id_type_filter: number;
  id_user_report: number;
  value: string | null;
};

const postReportFilterUser = async (reportFilterUser: SubmitReportFilterData) =>
  basePostRequest<UserReportFilter>("/report_filter_user/", reportFilterUser);

export default postReportFilterUser;
