import {DataTypeProviderProps, GetCellValueFn} from "@devexpress/dx-react-grid";
import * as React from "react";

export type ProviderType = React.ComponentType<DataTypeProviderProps>;

interface OptionalColumnParams {
  minWidth?: number;
  auto?: boolean;
  getCellValue?: GetCellValueFn;
  sortingEnabled?: boolean;
  align?: string;
  hiddenByDefault?: boolean;
  provider?: ProviderType;
}

class Column implements OptionalColumnParams {
  name: string;
  columnName: string;
  title: string;
  width: number;
  minWidth?: number;
  auto?: boolean;
  getCellValue?: GetCellValueFn;
  sortingEnabled: boolean;
  align?: string;
  hiddenByDefault: boolean;
  provider?: ProviderType;

  constructor(
    name: string,
    title: string,
    width: number,
    options: OptionalColumnParams = {}
  ) {
    this.name = name;
    this.columnName = name;
    this.title = title;
    this.width = width;
    this.minWidth = options.minWidth || 70;
    this.auto = options.auto || false;
    this.getCellValue = options.getCellValue;
    this.sortingEnabled =
      options.sortingEnabled !== undefined ? options.sortingEnabled : true;
    this.align = options.align;
    this.hiddenByDefault = options.hiddenByDefault || false;
    this.provider = options.provider;
  }
}

export default Column;
