import {Grid} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import {useCallback, useContext, useState} from "react";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import {useGlobalStyles} from "../../common/styles";
import {FRONT_DATETIME_FORMAT} from "../../../common/types";
import DeviceRemoteControl from "../DeviceRemoteControl/DeviceRemoteControl";
import {PrimaryButton} from "../../common/buttons/PrimaryButton";
import {AppContext} from "../../App-context";
import {checkRemoteControlSupport} from "../DeviceRemoteControl/utils/utils.";

const typeConnectNames: {[s: string]: string} = {
  wifi: "Wi-Fi",
  cellular: "GSM",
  lan: "LAN",
  other: "Прочее",
};

const DeviceInfo = (props: {device?: deviceType}) => {
  const {device} = props;
  const [isRemoteControlOpen, setRemoteControlOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useGlobalStyles();
  const {setAlert} = useContext(AppContext);

  const handleOpenRemoteControl = useCallback(async () => {
    setLoading(true);
    const {isSupported, errors} = await checkRemoteControlSupport(device!);
    setLoading(false);
    if (!isSupported) {
      setAlert({
        severity: "error",
        message: ["Невозможно подключиться к устройству. ", ...errors],
      });
      return;
    }
    setRemoteControlOpen(true);
  }, [device]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PrimaryButton onClick={handleOpenRemoteControl} disabled={loading}>
            Удаленное управление
          </PrimaryButton>
        </Grid>
        <Grid item xs={6}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="device-info"
              id="device-info"
              classes={{
                root: classes.panelSummary,
                content: classes.panelSummaryContent,
              }}
            >
              <Typography>Устройство</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetail}>
              <Typography>
                <span>Производитель: {device?.model_producer}</span>
                <br />
                <span>Модель: {device?.model_name}</span>
                <br />
                <span>Версия Android: {device?.version_code}</span>
                <br />
                <span>
                  Статус устройства:{" "}
                  {device?.device_registration_status
                    ? "Зарегистрирован"
                    : "Не зарегистрирован"}
                </span>
                <br />
                <span>
                  Дата последней синхронизации:{" "}
                  {device?.last_seen_time
                    ? moment(device?.last_seen_time).format(
                        FRONT_DATETIME_FORMAT
                      )
                    : undefined}
                </span>
                <br />
                <span>Серийный номер: {device?.serial_number}</span>
                <br />
                <span>Идентификатор (UDID): {device?.device_uid}</span>
                <br />
                <span>
                  Статус активации KNOX:{" "}
                  {device?.is_compromised === "1"
                    ? "Скомпрометирован"
                    : "Не скомпрометирован"}
                </span>
                <br />
                <span>
                  Тип передачи данных:{" "}
                  {device?.type_connect_name &&
                    typeConnectNames[device?.type_connect_name]}
                </span>
                <br />
                <span>Режим администрирования: {device?.admin_mode}</span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={6}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="device-project"
              id="device-project"
              classes={{
                root: classes.panelSummary,
                content: classes.panelSummaryContent,
              }}
            >
              <Typography>Проект</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetail}>
              <Typography>
                <span>Проект: {device?.project_name}</span>
                <br />
                <span>
                  Код подразделения:{" "}
                  {device?.code_division ?? "Отсутствует код ТО"}
                </span>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="device-user"
              id="device-user"
              classes={{
                root: classes.panelSummary,
                content: classes.panelSummaryContent,
              }}
            >
              <Typography>Пользователь</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetail}>
              <Typography>
                <span>Пользователь: {device?.user_display_name}</span>
                <br />
                <span>Логин: {device?.user_ldap_login}</span>
                <br />
                <span>Почтовый адрес: {device?.user_email}</span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {isRemoteControlOpen && (
        <DeviceRemoteControl
          uidDevice={device?.device_uid as string}
          open={isRemoteControlOpen}
          setOpen={setRemoteControlOpen}
        />
      )}
    </>
  );
};

export default DeviceInfo;
