import * as React from "react";
import {useContext} from "react";
import TableContext, {TableContextType} from "../../../Tables/TableContext";
import CoreTable from "../../../Tables/CoreTable";
import {UserReport} from "../../../../common/APIRequests/user_report/getUserReports";
import getReportColumnList from "../../../../common/APIRequests/reportColumnList/getReportColumnList";
import {UserReportColumn} from "../../../../common/APIRequests/reportColumnUser/getReportColumnsUser";
import AddNewUserReportFieldColumns from "./AddNewUserReportFieldColumns";
import FormButtons from "../../../common/buttons/FormButtons";
import postReportColumnsUser from "../../../../common/APIRequests/reportColumnUser/postReportColumnsUser";
import {Transition} from "../../../common/components";
import ClosableDialogTitle from "../../../common/dialog/ClosableDialogTitle";
import {Dialog, DialogContent} from "@mui/material";

type AddNewUserReportFieldsDialogTableProps = {
  userReport: UserReport;
  open: boolean;
  onClose: () => void;
};

const AddNewUserReportFieldsDialogTable = (
  props: AddNewUserReportFieldsDialogTableProps
) => {
  const {rows, updateRows} = useContext(
    TableContext
  ) as TableContextType<UserReportColumn>;

  const alreadySetColumnsIds = rows.map((row) => row.id_report_column_list);

  const getAvailableColumns = async () => {
    return getReportColumnList({
      report_type: props.userReport.report_type,
    }).then((result) => ({
      success: result.success,
      rows: result.rows.filter(
        (row) => !alreadySetColumnsIds.includes(row.id_report_column_list)
      ),
      errors: {},
    }));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      maxWidth="md"
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Добавить поля
      </ClosableDialogTitle>
      <DialogContent>
        <CoreTable
          name={"add_new_user_report_filters"}
          columns={AddNewUserReportFieldColumns}
          getRowId={(row) => row.id_report_column_list}
          updateRows={getAvailableColumns}
          height={"60vh"}
          updateTrigger={alreadySetColumnsIds.toString()}
          selectionEnabled
          integratedSearch
          defaultSorting={{columnName: "name_column", direction: "asc"}}
          renderWithState={(state) => (
            <FormButtons
              loading={state.loading}
              handleSubmit={() => {
                return postReportColumnsUser(
                  props.userReport.id_user_report,
                  state.selection
                ).finally(() => {
                  props.onClose();
                  updateRows?.();
                  state.updateRows?.();
                });
              }}
              handleClose={props.onClose}
              submitMessage="Добавить"
              submitId="user_report_column_add"
              closeId="user_report_column_close"
              disabled={state.loading || !state.selection.length}
            />
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddNewUserReportFieldsDialogTable;
