import * as React from "react";
import {useContext, useState} from "react";
import {
  profileType,
  profileTypes,
} from "../../common/APIRequests/profile/getProfiles";
import patchProfile from "../../common/APIRequests/profile/patchProfile";
import postProfile from "../../common/APIRequests/profile/postProfile";
import {errorType} from "../../common/types";
import theme from "../../theme";
import {AppContext} from "../App-context";
import FormButtons from "../common/buttons/FormButtons";
import {sanitizeInt} from "../common/formFields/sanitizers";
import StringField from "../common/formFields/StringField";

const ProfileForm = (props: {
  handleClose: () => void;
  update: () => void;
  profile: profileType;
  id_type_profile: profileTypes;
}) => {
  const {handleClose, update, profile, id_type_profile} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const isCreated = profile.id_prof !== 0;

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState(profile);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (Object.keys(errors).length || loading) {
      return;
    }

    let result;
    setLoading(true);
    if (isCreated) {
      result = await patchProfile(values.id_prof, values);
    } else {
      result = await postProfile(values);
    }
    setLoading(false);

    if (result.success) {
      setErrors({});
      handleClose();
      setAlert({
        severity: "success",
        message: ["Профиль сохранен"],
      });
      update();
    } else {
      setErrors(result.errors);
    }
  };

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
  };

  return (
    <React.Fragment>
      <StringField
        setValue={handleChange("name")}
        value={values.name}
        name="name"
        label="Наименование"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 100}}
        disabled={loading}
      />
      <StringField
        setValue={handleChange("specification")}
        value={values.specification || ""}
        name="specification"
        label="Описание"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 300}}
        multiline
        style={{marginTop: theme.spacing(2)}}
        disabled={loading}
      />
      {id_type_profile == 2 && (
        <StringField
          setValue={handleChange("priority")}
          value={values.priority ? values.priority.toString() : ""}
          name="priority"
          label="Приоритет"
          errors={errors}
          setErrors={setErrors}
          inputProps={{
            maxLength: 100,
          }}
          sanitizer={sanitizeInt}
          style={{marginTop: theme.spacing(2)}}
          disabled={loading}
          // helperText="Профиль с большим значением приоритета переопределяет значения профиля с меньшим приоритетом"
        />
      )}
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitMessage="Сохранить"
      />
    </React.Fragment>
  );
};

export default ProfileForm;
