import {projectType} from "../../../common/APIRequests/project/getProjects";
import Column from "../../Tables/Column";

type compareTreesColumnProps = {
  currentProject: projectType;
  destinationProject?: projectType;
  fileName?: string;
};

const getCompareTreesColumns = (props: compareTreesColumnProps) => [
  new Column("display_name", "Наименование", 400, {auto: true}),
  new Column("value_result", props.currentProject.project_name, 400),
  new Column(
    "file_value_result",
    props.destinationProject
      ? props.destinationProject.project_name
      : `Файл (${props.fileName})`,
    400
  ),
];

export default getCompareTreesColumns;
