import {basePostRequest} from "../base/basePostRequest";
import {UserReportColumn} from "./getReportColumnsUser";

const postReportColumnsUser = async (
  id_user_report: number,
  ids: Array<number | string>
) =>
  basePostRequest<UserReportColumn>("/report_column_user/", {
    id_user_report,
    ids,
  });

export default postReportColumnsUser;
