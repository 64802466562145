import * as React from "react";
import {useContext} from "react";
import {AppContext} from "../App-context";
import {Typography} from "@mui/material";

export const VersionDescription = () => {
  const {version} = useContext(AppContext);
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      style={{textAlign: "center"}}
    >
      {`EMMobile ${version ? "v" + version : ""}`}
    </Typography>
  );
};

export const Description = (props: {
  title: string;
  value: string | number | undefined;
}) => (
  <Typography style={{marginBottom: 8}}>
    <b>{props.title}:</b> {props.value}
  </Typography>
);
