import {Dialog, FormHelperText} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {errorType} from "../../../common/types";
import FormButtons from "../../common/buttons/FormButtons";
import {Transition} from "../../common/components";

const CommandsChangePinForm = (props: {
  setCommandReady: Function;
  setCommandCode: Function;
  setAdditionData: Function;
}) => {
  const {setCommandReady, setCommandCode, setAdditionData} = props;

  const [errors, setErrors] = useState({} as errorType);
  const [values, setValues] = useState({
    console_pin: "",
    pin_confirm: "",
  });
  const handleChange = (name: string) => (e: any) => {
    delete errors[name];
    setValues({...values, [name]: e.target.value});
  };

  const validate = async () => {
    if (values.console_pin && !/^\d+$/.test(values.console_pin)) {
      setErrors({pin: ["Пин-код должен состоять из цифр"]});
      return;
    }
    if (values.console_pin.length < 4 || values.console_pin.length > 16) {
      setErrors({pin: ["Пин-код должен быть длинной от 4 до 16 символов"]});
      return;
    }
    if (values.console_pin !== values.pin_confirm) {
      setErrors({pin_confirm: ["Пин-коды не совпадают"]});
      return;
    }
    setAdditionData(values.console_pin);
    setCommandReady(true);
  };

  return (
    <Dialog
      open={true}
      onClose={() => setCommandCode("")}
      keepMounted
      TransitionComponent={Transition}
    >
      <DialogTitle>Изменение пин-кода</DialogTitle>
      <DialogContent>
        <FormControl fullWidth style={{marginBottom: "10px"}}>
          <TextField
            variant="outlined"
            fullWidth
            id="console_pin"
            label="Новый пин-код"
            name="console_pin"
            placeholder="Новый пин-код"
            value={values.console_pin}
            error={!!errors.console_pin}
            onChange={handleChange("console_pin")}
          />
          {errors.console_pin && (
            <FormHelperText id="component-error-pin">
              {errors.console_pin}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth style={{marginBottom: "10px"}}>
          <TextField
            variant="outlined"
            fullWidth
            id="pin_confirm"
            label="Подтвердите пин-код"
            name="pin_confirm"
            placeholder="Подтвердите пин-код"
            value={values.pin_confirm}
            error={!!errors.pin_confirm}
            onChange={handleChange("pin_confirm")}
          />
          {errors.pin_confirm && (
            <FormHelperText id="component-error-pin_confirm">
              {errors.pin_confirm}
            </FormHelperText>
          )}
        </FormControl>
        <FormButtons
          loading={false}
          handleSubmit={validate}
          handleClose={() => setCommandCode("")}
          submitMessage="Отправить"
        />
      </DialogContent>
    </Dialog>
  );
};

export default CommandsChangePinForm;
