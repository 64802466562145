import React, {useState} from "react";
import {
  ColumnChooserProps,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {IconButton, TextField} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

const SearchInput = (props: SearchPanel.InputProps) => {
  const [value, setValue] = useState(props.value);
  return (
    <TextField
      id={"search_input"}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        props.onValueChange(e.target.value.trim());
      }}
      placeholder={props.getMessage("searchPlaceholder")}
      InputProps={{
        startAdornment: <SearchIcon />,
        endAdornment: (
          <IconButton
            id="clear_search_input"
            onClick={() => {
              setValue("");
              props.onValueChange("");
            }}
            size="small"
            disabled={!props.value}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};

const StyledSearchPanel = (props: ColumnChooserProps) => (
  <SearchPanel
    {...props}
    messages={{searchPlaceholder: "Искать..."}}
    inputComponent={SearchInput}
  />
);

export default StyledSearchPanel;
