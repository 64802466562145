import * as React from "react";
import {useState} from "react";
import {UserReport} from "../../../common/APIRequests/user_report/getUserReports";
import {useTrigger} from "../../../common/customHooks";
import CoreTable from "../../Tables/CoreTable";
import UserReportFieldColumns from "./UserReportFieldColumns";
import getReportColumnUser from "../../../common/APIRequests/reportColumnUser/getReportColumnsUser";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import deleteReportColumnsUser from "../../../common/APIRequests/reportColumnUser/deleteReportColumnsUser";
import {AddNewButton} from "../../common/buttons/ToolbarButtons";
import AddNewUserReportFieldsDialogTable from "./AddNew/AddNewUserReportFieldsDialogTable";

type UserReportFieldsTableProps = {
  userReport: UserReport;
};

const UserReportFieldsTable = (props: UserReportFieldsTableProps) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [trigger, updateTrigger] = useTrigger();

  return (
    <CoreTable
      name={"user_report_filters"}
      columns={UserReportFieldColumns}
      getRowId={(row) => row.id_report_user_column}
      updateRows={() => getReportColumnUser(props.userReport.id_user_report)}
      selectionEnabled
      updateTrigger={trigger}
      height={"60vh"}
      defaultSorting={{columnName: "name_column", direction: "asc"}}
      selectionButton={(selection, update) => (
        <MenuDeleteAction
          selection={selection}
          update={update}
          action={(ids) => {
            updateTrigger();
            return deleteReportColumnsUser(ids);
          }}
        />
      )}
      toolBarLeftItems={[
        <AddNewButton
          onClick={() => setAddDialogOpen(true)}
          key="profile_devices-add_button"
        />,
      ]}
      dialog={
        <AddNewUserReportFieldsDialogTable
          onClose={() => setAddDialogOpen(false)}
          open={addDialogOpen}
          userReport={props.userReport}
        />
      }
    />
  );
};

export default UserReportFieldsTable;
