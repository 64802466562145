import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import getDeviceFirmware, {
  DeviceFirmwareType,
} from "../../../common/APIRequests/deviceFirmware/getDeviceFirmware";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import DeviceFirmwareColumns from "./DeviceFirmwareColumns";
import {useContext, useState} from "react";
import {ConfirmDialog} from "../../common/buttons/ConfirmDialog";
import postDeviceFirmware from "../../../common/APIRequests/deviceFirmware/postDeviceFirmware";
import {AppContext} from "../../App-context";
import {useTrigger} from "../../../common/customHooks";
import {PrimaryButton} from "../../common/buttons/PrimaryButton";
import postCommand from "../../../common/APIRequests/command/postCommand";

export const DeviceFirmwaresTab = (props: {
  device: deviceType;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const {setAlert} = useContext(AppContext);
  const {device, loading, setLoading} = props;
  const [refreshTrigger, refresh] = useTrigger();
  const [targetFrameworkSelected, setTargetFrameworkSelected] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [rowClicked, setRowClicked] = useState(
    undefined as DeviceFirmwareType | undefined
  );
  const UPDATE_FIRMWARE_TAG = "UPDATE_FIRMWARE";

  const updateRows: UpdateRowsType<DeviceFirmwareType> = () =>
    getDeviceFirmware(device.id_device);

  const setTargetFirmwareHandler = async () => {
    if (!rowClicked) return;

    setLoading(true);
    const result = await postDeviceFirmware(
      device.id_device,
      rowClicked.id_firmware
    );
    setLoading(false);
    if (result.success) {
      setAlert({
        severity: "success",
        message: ["Версия успешно задана как целевая"],
      });
      refresh();
    }
    setRowClicked(undefined);
  };

  const sendUpdateCommandHandler = async () => {
    setLoading(true);
    const result = await postCommand(UPDATE_FIRMWARE_TAG, [device.id_device]);
    setLoading(false);
    if (result.success) {
      setAlert({
        severity: "success",
        message: ["Команда успешно отправлена на устройство"],
      });
      refresh();
    }
    setUpdateDialogOpen(false);
  };

  const rowsSetHandler = (frameworks: Array<DeviceFirmwareType>) => {
    if (frameworks.some((f) => f.is_target === 1)) {
      setTargetFrameworkSelected(true);
    }
  };

  return (
    <>
      {rowClicked && (
        <ConfirmDialog
          loading={loading}
          handleClose={() => setRowClicked(undefined)}
          handleConfirm={setTargetFirmwareHandler}
          message={"Вы хотите задать данную версию как целевую?"}
          checkPin
        />
      )}
      {updateDialogOpen && (
        <ConfirmDialog
          loading={loading}
          handleClose={() => setUpdateDialogOpen(false)}
          handleConfirm={sendUpdateCommandHandler}
          message={
            "Действительно ли вы хотите отправить команду на обновление прошивки устройства?"
          }
          checkPin
        />
      )}
      <CoreTable
        columns={DeviceFirmwareColumns}
        getRowId={(row) => row.id_firmware}
        name={"device_firmwares"}
        updateRows={updateRows}
        onRowClick={setRowClicked}
        onRowsSet={rowsSetHandler}
        onSetLoading={setLoading}
        updateTrigger={refreshTrigger}
        defaultSorting={{columnName: "name", direction: "desc"}}
        integratedSearch
        toolBarLeftItems={[
          <PrimaryButton
            onClick={() => setUpdateDialogOpen(true)}
            title="Обновить прошивку"
            key="update_device_firmware"
            disabled={loading || !targetFrameworkSelected}
          />,
        ]}
      />
    </>
  );
};
