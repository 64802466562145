import {basePostRequest} from "../base/basePostRequest";
import {deviceApplicationType} from "./getDeviceApplication";

const postDeviceApplications = async (
  id_device: number,
  id_app: number,
  id_app_version: number | undefined
) =>
  basePostRequest<deviceApplicationType>(
    `/device/${id_device}/application_version/`,
    {id_app, id_app_version}
  );

export default postDeviceApplications;
