import {DialogContent} from "@mui/material";
import React, {useState} from "react";
import {
  profileType,
  profileTypes,
} from "../../../common/APIRequests/profile/getProfiles";
import theme from "../../../theme";
import {TabPanel, tabProps} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {CustomDialog} from "../../common/dialog/CustomDialog";
import {StyledTab, StyledTabs} from "../../common/Tabs";
import ProfileForm from "../ProfileForm";
import ProfilePoliciesTable from "../ProfilePolicies/ProfilePoliciesTable";

type ProfileOnSelectedDialogProps = {
  onClose: () => void;
  update: () => void;
  selected: profileType;
  id_type_profile: profileTypes;
};

const ProfileOnSelectedDialog = (props: ProfileOnSelectedDialogProps) => {
  const [tab, setTab] = useState("common");

  const [policiesChanged, setPoliciesChanged] = useState(false);

  const handleTabChange = (tabValue: string) => {
    if (
      !policiesChanged ||
      window.confirm(
        "Вы действительно хотите покинуть вкладку? Несохраненные данные будут потеряны."
      )
    ) {
      setTab(tabValue);
      setPoliciesChanged(false);
    }
  };

  return (
    <CustomDialog
      onClose={props.onClose}
      maxWidth={tab === "common" ? "sm" : "lg"}
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Информация о профиле {props.selected ? props.selected.name : ""}
      </ClosableDialogTitle>
      <DialogContent style={{paddingTop: theme.spacing(0)}}>
        <StyledTabs
          value={tab}
          onChange={(_, tabValue) => handleTabChange(tabValue)}
          aria-label="wrapped label tabs"
        >
          <StyledTab label="Общая информация" {...tabProps("common")} />
          <StyledTab label="Политики" {...tabProps("policies")} />
        </StyledTabs>
        <TabPanel value={tab} index="common">
          <ProfileForm
            handleClose={props.onClose}
            update={props.update}
            profile={props.selected}
            id_type_profile={props.id_type_profile}
          />
        </TabPanel>
        {tab === "policies" && (
          <TabPanel value={tab} index="policies" id="tab_panel_policies">
            <ProfilePoliciesTable
              profile={props.selected}
              onClose={props.onClose}
              notifyHasChanges={(hasChanges) => setPoliciesChanged(hasChanges)}
            />
          </TabPanel>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default ProfileOnSelectedDialog;
