import React, {useState} from "react";
import getTasks, {
  taskFilterType,
  taskType,
} from "../../common/APIRequests/task/getTasks";
import {SettingsButton} from "../common/buttons/ToolbarButtons";
import TasksOnSelectedDialog from "./Dialogs/TasksOnSelectedDialog";
import TasksSettingsDialog from "./Dialogs/TasksSettingsDialog";
import TaskFilter from "./TaskFilter";
import TasksColumns from "./TasksColumns";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";

export const DEFAULT_TASK_FILTER: taskFilterType = {
  id_net: null,
  id_branch: null,
  code_division: "",
  id_project: null,
  id_app: null,
  id_app_version: null,
  login: "",
};

const TasksTable = () => {
  const [rowClicked, setRowClicked] = useState(
    undefined as taskType | undefined
  );

  const [isSettingsDialogOpen, setSettingsDialogOpen] = useState(false);

  const updateTasks: UpdateRowsType<taskType, taskFilterType> = ({filters}) =>
    // using integrated sorting
    getTasks(undefined, undefined, filters);

  return (
    <>
      {rowClicked && (
        <TasksOnSelectedDialog
          onClose={() => setRowClicked(undefined)}
          selectedRow={rowClicked}
        />
      )}
      {isSettingsDialogOpen && (
        <TasksSettingsDialog onClose={() => setSettingsDialogOpen(false)} />
      )}
      <CoreTable
        name={"tasks"}
        columns={TasksColumns}
        updateRows={updateTasks}
        dateFields={["create_date"]}
        defaultSorting={{columnName: "id_task", direction: "asc"}}
        integratedSearch
        MenuFilterComponent={TaskFilter}
        onRowClick={setRowClicked}
        getRowId={(row: taskType) => row.id_task || ""}
        toolBarLeftItems={[
          <SettingsButton
            onClick={() => setSettingsDialogOpen(true)}
            key="tasks_setting_button"
          />,
        ]}
      />
    </>
  );
};

export default TasksTable;
