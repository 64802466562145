import {Dialog, DialogContent} from "@mui/material";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import FirmwareForm from "./FirmwareForm";
import {Transition} from "../common/components";

type FirmwareDialogProps = {
  onClose: () => void;
  update: () => void;
};

const AddFirmwareDialog = (props: FirmwareDialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Добавить прошивку
      </ClosableDialogTitle>
      <DialogContent>
        <FirmwareForm handleClose={props.onClose} update={props.update} />
      </DialogContent>
    </Dialog>
  );
};

export default AddFirmwareDialog;
