import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import {makeStyles} from "tss-react/mui";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import AppsIcon from "@mui/icons-material/Apps";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import {LeftPanelGroupListItem, leftPaneStyleParams} from "./LeftPanelItems";
import {LeftPanelGroup} from "./LeftPanelGroup";
import SecurityIcon from "@mui/icons-material/Security";
import {Box, Theme} from "@mui/material";
import LeftPanelLogo from "./LeftPanelLogo";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const drawerWidth = 240;

const useStyles = makeStyles()((theme: Theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: 64,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: leftPaneStyleParams.panelWrapped.width,
  },
  link: {
    "&:hover": {
      color: "inherit",
    },
  },
}));

const LeftPanel = (props: {open: boolean; handleClose: () => void}) => {
  const {open, handleClose} = props;

  const {classes, cx} = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: cx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => handleClose()} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={"calc(100% - 64px)"}
      >
        <List>
          <LeftPanelGroupListItem
            url="/"
            name="dashboard"
            title="Аналитика"
            icon={DashboardIcon}
            leftPanelOpen={open}
          />
          <LeftPanelGroupListItem
            url="/projects/"
            name="projects"
            title="Проекты"
            icon={AccountTreeIcon}
            leftPanelOpen={open}
          />

          <LeftPanelGroup
            leftPanelOpen={open}
            title="Политики"
            icon={SecurityIcon}
            links={[
              {
                url: "/policies/",
                name: "policies",
                title: "Список политик",
              },
              {
                url: "/profiles_apps/",
                name: "profiles_apps",
                title: "Профили приложений",
              },
              {
                url: "/profiles_devices/",
                name: "profiles_devices",
                title: "Профили устройств",
              },
            ]}
          />

          <LeftPanelGroup
            leftPanelOpen={open}
            title="Устройства"
            icon={DevicesOtherIcon}
            links={[
              {
                url: "/devices/",
                name: "devices",
                title: "Список устройств",
              },
              {
                url: "/geography/",
                name: "geography",
                title: "География",
              },
            ]}
          />
          <LeftPanelGroupListItem
            url="/firmwares/"
            name="firmwares"
            title="Прошивки"
            icon={SystemUpdateAltIcon}
            leftPanelOpen={open}
          />
          <LeftPanelGroup
            leftPanelOpen={open}
            title="Приложения"
            icon={AppsIcon}
            links={[
              {
                url: "/applications/",
                name: "applications",
                title: "Список приложений",
              },
              {
                url: "/tasks/",
                name: "tasks",
                title: "Задания",
              },
            ]}
          />

          <LeftPanelGroup
            leftPanelOpen={open}
            title="События"
            icon={EventNoteIcon}
            links={[
              {
                url: "/events/",
                name: "events",
                title: "Список событий",
              },
              {
                url: "/log_events/",
                name: "log_events",
                title: "Журнал событий",
              },
              {
                url: "/notifications/",
                name: "notifications",
                title: "Оповещения",
              },
              {
                url: "/console_logs/",
                name: "console_logs",
                title: "Журнал консоли",
              },
            ]}
          />

          <LeftPanelGroup
            leftPanelOpen={open}
            title="Отчеты"
            icon={InsertChartIcon}
            links={[
              {
                url: "/reports/",
                name: "reports",
                title: "Отчеты по умолчанию",
              },
              {
                url: "/user_reports/",
                name: "user_reports",
                title: "Гибкие отчеты",
              },
            ]}
          />

          <LeftPanelGroupListItem
            url="/users/"
            name="users"
            title="Пользователи"
            icon={PeopleAltIcon}
            leftPanelOpen={open}
          />
        </List>

        <LeftPanelLogo leftPanelOpen={open} />
      </Box>
    </Drawer>
  );
};

export default LeftPanel;
