export const isDev = process.env.REACT_APP_ENVIRONMENT
  ? process.env.REACT_APP_ENVIRONMENT === "production"
  : process.env.NODE_ENV === "production";

/** backend address */
const localBackendAddress = "http://127.0.0.1:1000";
export const Proxy: string = `${
  process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : localBackendAddress
}`;

/** Remote control server address */
const localRemoteControlServerAddress = "localhost:8080";
export const RemoteControlProxy: string = `${
  process.env.REACT_APP_REMOTE_CONTROL_SERVER_URL
    ? process.env.REACT_APP_REMOTE_CONTROL_SERVER_URL
    : localRemoteControlServerAddress
}`;


export const WebSocketProtocolPrefix = "wss";
export const HttpProtocolPrefix = "https";

// test properties
export const TEST_USER = "svc_PlservDgm";
export const TEST_PASSWORD = "Rfnz<htec2014";

// pagination
export const pageSizes = [10, 30, 50];

// timeout for debounce effect in setValueComponents
export const debounceTimeout = 2000;

// maximum apk size when loading
export const maxAPKsize = process.env.REACT_APP_MAX_APK_SIZE
  ? parseInt(process.env.REACT_APP_MAX_APK_SIZE)
  : 100;

// timeout for remote control's session and websocket connection
export const RC_WS_CONN_TIMEOUT_MS = 900000;
export const RC_SESSION_TIMEOUT_MS = 120000;
