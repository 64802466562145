import {Grid} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useState} from "react";
import {NotificationType} from "../../../common/APIRequests/notify/getNotification";
import {notifyRecipientInputsType} from "../../../common/APIRequests/notifyRecipient/getNotifyRecipient";
import postNotificationRecipient from "../../../common/APIRequests/notifyRecipient/postNotificationRecipient";
import {errorType, optionType} from "../../../common/types";
import theme from "../../../theme";
import FormButtons from "../../common/buttons/FormButtons";
import ChipTextField from "../../common/formFields/ChipTextField";
import SelectSingleField from "../../common/formFields/SelectSingleField";

const useStyles = makeStyles()(() => ({
  root: {
    width: 500,
  },
}));

const CHANNEL_SMS = 2;
const CHANNEL_EMAIL = 1;

const channelOptions: {text: "sms" | "email"; value: number; key: number}[] = [
  {
    key: CHANNEL_EMAIL,
    value: CHANNEL_EMAIL,
    text: "email",
  },
  {
    key: CHANNEL_SMS,
    value: CHANNEL_SMS,
    text: "sms",
  },
];

const defaultNotifyRecipient = {
  type_channel: 1,
  recipients: [],
};

type NotificationRecipientFormProps = {
  onClose: () => void;
  update: () => void;
  notification: NotificationType;
};

const NotificationRecipientForm = (props: NotificationRecipientFormProps) => {
  const {onClose, update, notification} = props;

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState(
    defaultNotifyRecipient as notifyRecipientInputsType
  );
  useEffect(() => {
    setValues({...values, recipients: []});
    setErrors({});
  }, [values.type_channel]);

  const handleChange = (name: string) => (value: string) => {
    if (name === "recipients") {
      value
        ? setValues({...values, [name]: value.split(",")})
        : setValues({...values, [name]: []});
    } else {
      setValues({...values, [name]: value});
    }
  };

  const handleSubmit = async () => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      const result = await postNotificationRecipient(
        notification.id_notify,
        values
      );
      if (result.success) {
        await update();
        onClose();
      } else {
        setErrors(result.errors);
      }
      setLoading(false);
    }
  };

  const {classes} = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <SelectSingleField
          setValue={handleChange("type_channel")}
          value={values.type_channel.toString()}
          name="type_channel"
          label="Тип канала"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          style={{marginTop: theme.spacing(2)}}
          options={channelOptions}
        />
        <ChipTextField
          setValue={handleChange("recipients")}
          value={values.recipients.join(",")}
          name="recipients"
          label={
            values.type_channel === CHANNEL_SMS
              ? "Формат +79996668888, ввести Enter"
              : "Формат example@email.com, ввести Enter"
          }
          errors={errors}
          setErrors={setErrors}
          chipAddValidator={
            channelOptions.filter(
              (o: optionType) => o.value === values.type_channel
            )[0].text
          }
          inputProps={{maxLength: 2000}}
          disabled={loading}
          style={{marginTop: theme.spacing(2)}}
        />
        <FormButtons
          loading={loading}
          disabled={values.recipients.length === 0}
          handleSubmit={handleSubmit}
          handleClose={onClose}
          submitMessage="Сохранить"
          checkPin
        />
      </Grid>
    </div>
  );
};

export default NotificationRecipientForm;
