import {Dialog, DialogContent} from "@mui/material";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import {Transition} from "../../common/components";
import {generateProjectOptionsForEachProject} from "../../common/functions";
import {useContext, useMemo, useState} from "react";
import {AppContext} from "../../App-context";
import SelectSingleField from "../../common/formFields/SelectSingleField";
import FormButtons from "../../common/buttons/FormButtons";

type SelectProjectDialogProps = {
  onClose: () => void;
  update: (id_project: number) => void;
  loading: boolean;
};

const SelectProjectDialog = (props: SelectProjectDialogProps) => {
  const {projects} = useContext(AppContext);
  const {onClose, update, loading} = props;
  const [projectId, setProjectId] = useState<number>();

  const options = useMemo(() => {
    return generateProjectOptionsForEachProject(projects);
  }, [projects]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <ClosableDialogTitle onClose={onClose}>Выбор проекта</ClosableDialogTitle>
      <DialogContent>
        <SelectSingleField
          setValue={(value: number) => setProjectId(value)}
          value={projectId?.toString()}
          name={"project_list"}
          label={"Наименование"}
          errors={{}}
          options={options}
        />
        <FormButtons
          loading={loading}
          handleSubmit={async () => {
            await update(projectId as number);
            onClose();
          }}
          handleClose={onClose}
          submitMessage="Выбрать"
          disabled={!projectId}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SelectProjectDialog;
