import {Dialog, DialogContent} from "@mui/material";
import * as React from "react";
import {Transition} from "../../common/components";
import ClosableDialogTitle from "../../common/dialog/ClosableDialogTitle";
import ProfileForm from "../ProfileForm";
import {APP_PROFILE_TYPE} from "../../../common/APIRequests/profile/getProfiles";

type ProfileDialogProps = {
  onClose: () => void;
  update: () => void;
  id_type_profile: 1 | 2;
};

const AddProfileDialog = (props: ProfileDialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <ClosableDialogTitle onClose={props.onClose}>
        Добавить профиль
      </ClosableDialogTitle>
      <DialogContent>
        <ProfileForm
          handleClose={props.onClose}
          update={props.update}
          profile={{
            id_prof: 0,
            name: "",
            specification: "",
            id_type_profile: props.id_type_profile,
            priority: props.id_type_profile == APP_PROFILE_TYPE ? 0 : undefined,
          }}
          id_type_profile={props.id_type_profile}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddProfileDialog;
