import {Grid} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {default as React} from "react";
import {deviceType} from "../../../common/APIRequests/device/getDevices";
import {useGlobalStyles} from "../../common/styles";

const DeviceNetwork = (props: {device: deviceType}) => {
  const {device} = props;

  const classes = useGlobalStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="network-mobile"
            id="network-mobile"
            classes={{
              root: classes.panelSummary,
              content: classes.panelSummaryContent,
            }}
          >
            <Typography>Мобильная сеть</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelDetail}>
            <Typography>
              <span>
                Передача данных по сотовой сети:{" "}
                {device.is_cellullar_enabled ? "Включена" : "Выключена"}
              </span>
              <br />
              <span>
                В роуминге: {device.is_cellullar_roaming ? "Да" : "Нет"}
              </span>
              <br />
              <span>IP адрес: {device.ip4}</span>
              <br />
              <span>
                Оператор сотовой сети: {device.cellular_network_provider}
              </span>
              <br />
              <span>IMEI: {device.imei}</span>
              <br />
              <span>Текущий идентификатор сим-карты: {device.sim_id}</span>
              <br />
              <span>
                Идентификатор сим-карты при регистрации:{" "}
                {device.sim_id_register}
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={6}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="network-wifi"
            id="network-wifi"
            classes={{
              root: classes.panelSummary,
              content: classes.panelSummaryContent,
            }}
          >
            <Typography>Wi-Fi</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelDetail}>
            <Typography>
              <span>IP адрес: {device.wifi_ip4}</span>
              <br />
              <span>MAC адрес wi-fi модуля: {device.mac}</span>
              <br />
              <span>Уровень сигнала: {device.wifi_signal_strength}</span>
              <br />
              <span>SSID сети: {device.wifi_ssid}</span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="network-lan"
            id="network-lan"
            classes={{
              root: classes.panelSummary,
              content: classes.panelSummaryContent,
            }}
          >
            <Typography>LAN</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelDetail}>
            <Typography>
              <span>IP адрес: {device.lan_ip4}</span>
              <br />
              <span>MAC адрес: {device.lan_mac}</span>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default DeviceNetwork;
