import * as React from "react";
import {useState} from "react";
import {Chip, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {optionType} from "../../../common/types";
import theme from "../../../theme";
import {makeStyles} from "tss-react/mui";
import ClearIcon from "@mui/icons-material/Clear";

type ChipMultiSelectFieldProps = {
  label: string;
  selected: optionType[];
  handleDelete: (id?: string) => void; // delete all values if id is not specified
  onClick: () => void;
  disabled?: boolean;
};
const ChipMultiSelectField = (props: ChipMultiSelectFieldProps) => {
  const [focused, setFocused] = useState(false);

  const {classes} = makeStyles()(() => ({
    root: {
      marginTop: theme.spacing(2),
      flexGrow: 2,
    },
  }))();

  return (
    <Autocomplete
      multiple
      disabled={props.disabled || false}
      options={[] as optionType[]}
      value={props.selected}
      open={false}
      limitTags={focused ? undefined : 5}
      placeholder={props.label}
      onInputChange={(e) => {
        e?.stopPropagation();
        props.handleDelete();
      }}
      forcePopupIcon={false}
      popupIcon={<ClearIcon />}
      renderTags={(value) =>
        value.map((option: optionType, index) => (
          <Chip
            key={`multiselect-chip-${index}`}
            variant="outlined"
            label={option.text}
            onDelete={() => props.handleDelete(option.value as string)}
            size="small"
            style={{margin: theme.spacing(0.4)}}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          InputProps={{
            ...params.InputProps,
            readOnly: !!props.onClick,
          }}
        />
      )}
      className={classes.root}
    />
  );
};

export default ChipMultiSelectField;
