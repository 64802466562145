import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type NotificationType = {
  id_notify: number;
  name: string;
  is_active: "0" | "1";
};

const getNotification = async () =>
  baseGetRowsRequest<NotificationType>("/notify/");

export default getNotification;
