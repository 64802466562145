import React from "react";
import ReactDOM from "react-dom";

//material ui
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import {ThemeProvider} from "@mui/material/styles";

//styles
import "./common/scss/common.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const render = () => {
  ReactDOM.render(
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CacheProvider>,
    document.getElementById("root")
  );
};

window.onload = () => render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
