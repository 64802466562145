import {FormControlLabel} from "@mui/material";
import {useState} from "react";
import {NotificationType} from "../../common/APIRequests/notify/getNotification";
import patchNotification from "../../common/APIRequests/notify/patchNotification";
import postNotification from "../../common/APIRequests/notify/postNotification";
import toggleNotification from "../../common/APIRequests/notify/toggleNotification";
import {errorType} from "../../common/types";
import theme from "../../theme";
import FormButtons from "../common/buttons/FormButtons";
import SwitchWithPin from "../common/buttons/SwitchWithPin";
import StringField from "../common/formFields/StringField";

const defaultNotification: NotificationType = {
  id_notify: 0,
  name: "",
  is_active: "1",
};

type AddNotificationDialogProps = {
  notification?: NotificationType;
  onClose: () => void;
  update: () => void;
};

const AddNotificationDialog = (props: AddNotificationDialogProps) => {
  const {onClose, update, notification} = props;

  const [values, setValues] = useState(
    notification ? notification : (defaultNotification as NotificationType)
  );

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as errorType);

  const handleChange = (name: string) => (value: number) => {
    setValues({...values, [name]: value});
  };

  const handleSubmit = () => {
    const updateNotification = values.id_notify
      ? patchNotification
      : postNotification;

    setLoading(true);
    updateNotification(values)
      .then((result) => {
        if (result.success) {
          update();
          onClose();
        } else {
          setErrors(result.errors);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleToggle = (
    notification: NotificationType,
    is_active: "0" | "1"
  ) => {
    setLoading(true);
    toggleNotification(notification, is_active)
      .then((result) => {
        if (result.success) {
          setValues({...values, is_active});
          onClose();
          update();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <StringField
        value={values.name}
        setValue={handleChange("name")}
        name="name"
        label="Наименование"
        errors={errors}
        setErrors={setErrors}
        style={{marginBottom: theme.spacing(2)}}
      />
      {notification && (
        <FormControlLabel
          control={
            <SwitchWithPin
              onChange={() =>
                handleToggle(notification, values.is_active == "1" ? "0" : "1")
              }
              checked={values.is_active == "1" ? true : false}
              checkPin
              name={notification.name}
              inputProps={{"aria-label": notification.name}}
            />
          }
          label="Активно"
        />
      )}
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={onClose}
        submitMessage="Сохранить"
        checkPin
      />
    </>
  );
};

export default AddNotificationDialog;
