import * as React from "react";
import {useContext, useState} from "react";
import {EventType} from "../../../../../../common/APIRequests/event/getEvents";
import SelectSingleField from "../../../../../common/formFields/SelectSingleField";
import {AppContext} from "../../../../../App-context";

export type ReportFilterProps = {
  value?: string;
  setValue: (value?: string) => void;
  name: string;
};

const EventIdReportFilter = (props: ReportFilterProps) => {
  const [errors, setErrors] = useState({});
  const {events} = useContext(AppContext);

  return (
    <SelectSingleField
      options={events.map((ev: EventType) => ({
        key: ev.id_event,
        value: ev.id_event,
        text: ev.event_name,
      }))}
      setValue={props.setValue}
      value={props.value ?? ""}
      name="value"
      label={props.name}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default EventIdReportFilter;
