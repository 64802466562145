import {getUsername} from "../../getUsername";
import {errorType} from "../../types";
import {apiClient} from "../../utils/apiClient";

const getGeneratePolicyTreeCsv = async (
  project_id: number
): Promise<{success: boolean; file: any; errors: errorType}> =>
  new Promise((resolve) =>
    apiClient({
      responseType: "arraybuffer",
      method: "get",
      url: `/project/${project_id}/generate_policy_tree_csv/`,
      params: {
        username: getUsername(),
      },
    })
      .then((response) =>
        resolve({
          success: true,
          file: response.data,
          errors: {},
        })
      )
      .catch((errors) => {
        resolve({
          success: false,
          file: undefined,
          errors: errors,
        });
      })
  );

export default getGeneratePolicyTreeCsv;
