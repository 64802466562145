import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  DataTypeProvider,
  IntegratedSelection,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {ArrowDownward} from "@mui/icons-material";
import moment from "moment";
import React, {useEffect, useState} from "react";
import deleteDeviceLogFile from "../../../common/APIRequests/logs/deleteDeviceLogFile";
import downloadDeviceLogFile from "../../../common/APIRequests/logs/downloadDeviceLogFile";
import getDeviceLogFiles, {
  deviceLogFileType,
} from "../../../common/APIRequests/logs/getDeviceLogFiles";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
  useMounted,
} from "../../../common/customHooks";
import {
  BACKEND_DATE_FORMAT,
  columnType,
  FRONT_DATETIME_FORMAT,
  sortingType,
} from "../../../common/types";
import {downloadFile} from "../../../common/utils/files";
import MenuDeleteAction from "../../common/buttons/MenuDeleteAction";
import {RefreshButton} from "../../common/buttons/ToolbarButtons";
import PickerDate from "../../common/formFields/PickerDate";
import {StyledTableSelection, StyledVirtualTable} from "../../common/grid/cell";
import StyledColumnChooser from "../../common/grid/StyledColumnChooser";
import {useGlobalStyles} from "../../common/styles";
import {ToolbarItem, ToolbarRoot} from "../../common/Toolbar";
import SingleLogCommand from "../Commands/SingleLogCommand";
import ColumnResizingHeader from "../../common/grid/ColumnResizingHeader";
import {LoadingIndicator} from "../../common/components";

const columns: columnType = [
  {name: "id_device", title: "ID устройства"},
  {name: "id_logfile", title: "ID лог файла"},
  {name: "logfile_name", title: "Имя файла"},
  {
    name: "date_start",
    title: "Дата начала лога",
    getCellValue: (deviceLogFile: deviceLogFileType) =>
      moment(deviceLogFile.date_start).format(FRONT_DATETIME_FORMAT),
  },
  {
    name: "date_end",
    title: "Дата окончания лога",
    getCellValue: (deviceLogFile: deviceLogFileType) =>
      moment(deviceLogFile.date_end).format(FRONT_DATETIME_FORMAT),
  },
  {name: "download", title: "Скачать"},
];
const columnExtensions = [
  {columnName: "id_device", width: 130},
  {columnName: "id_logfile", width: 130},
  {columnName: "logfile_name", width: 200},
  {columnName: "date_start", width: 200},
  {columnName: "date_end", width: 200},
  {columnName: "download", width: 150},
];
const hiddenColumnNames = ["id_device", "id_logfile"];

const sortingStateColumnExtensions = [
  {columnName: "download", sortingEnabled: false},
];

const LogFiles = (props: {
  loading: boolean;
  setLoading: Function;
  id_device: number;
}) => {
  const {loading, setLoading, id_device} = props;

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "device_log_files",
    [{columnName: "id_logfile", direction: "asc"}]
  );

  const [selection, setSelection] = useState([] as Array<string | number>);

  const DownloadFormatter = (data: any) => {
    return (
      <ArrowDownward
        color="primary"
        onClick={() => handleDownloadFile(data.row)}
        style={{cursor: "pointer"}}
      />
    );
  };

  const [date, setDate] = useState(null as string | null);

  const [hideFilters, setHideFilters] = useState(true);

  const isMounted = useMounted();

  const [rows, setRows] = useState([] as Array<deviceLogFileType>);
  useEffect(() => {
    (async () => {
      await updateRows();
    })();
  }, [date, sorting]);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "log_file_ordering",
    columns.map((row) => row.name)
  );

  const updateRows = async () => {
    setSelection([]);

    const {direction, columnName} = sorting[0];

    setLoading(true);
    const result = await getDeviceLogFiles(
      id_device,
      date ? moment(date).format(BACKEND_DATE_FORMAT) : undefined,
      columnName,
      direction == "desc"
    );
    setLoading(false);
    result.success && setRows(result.rows);

    if (!isMounted && result.rows.length > 0) {
      setHideFilters(false);
    }
  };

  const handleDownloadFile = async (logfile: deviceLogFileType) => {
    setLoading(true);
    const result = await downloadDeviceLogFile(id_device, logfile.id_logfile);
    setLoading(false);

    if (result.success) {
      downloadFile(result.file, logfile.logfile_name);
    }
  };

  const classes = useGlobalStyles();

  return (
    <div style={{position: "relative"}}>
      <Grid columns={columns} rows={rows} getRowId={(row) => row.id_logfile}>
        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingStateColumnExtensions}
        />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem id="logfiles_request">
            <SingleLogCommand
              selection={[id_device]}
              command="GETADBLOG"
              buttonTitle="Запрос adb-лога"
            />
          </ToolbarItem>
          <ToolbarItem id="logfiles_delete">
            <MenuDeleteAction
              selection={selection}
              update={() => updateRows()}
              action={(ids: Array<number | string>) =>
                deleteDeviceLogFile(id_device, ids)
              }
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton
              disabled={loading}
              update={updateRows}
              id="logfiles_refresh"
            />
          </ToolbarItem>
          <TemplatePlaceholder />
          <ToolbarItem>
            <PickerDate
              setValue={setDate}
              value={date}
              name="date"
              label="Дата"
              disabled={hideFilters}
            />
          </ToolbarItem>
        </Template>

        <StyledVirtualTable
          height="68vh"
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row
              data-type="logfiles_row"
              data-id={row.id_logfile}
              {...restProps}
            />
          )}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="device_log_file"
          autoWidthColumnName={"logfile_name"}
        />

        {/*Row Selection*/}
        <SelectionState
          selection={selection}
          onSelectionChange={setSelection}
        />
        <IntegratedSelection />
        <StyledTableSelection showSelectAll />

        <DataTypeProvider
          for={["download"]}
          formatterComponent={DownloadFormatter}
        />

        {loading && <LoadingIndicator />}
      </Grid>
    </div>
  );
};

export default LogFiles;
