import {Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {Dialog, DialogContent, Link, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {DirectoryType} from "../../common/APIRequests/directory/getDirectory";
import deleteEvents from "../../common/APIRequests/event/deleteEvents";
import {
  EVENT_IS_ACTIVE,
  EventType,
} from "../../common/APIRequests/event/getEvents";
import {
  useLocalStorage,
  useLocalStorageColumnOrder,
} from "../../common/customHooks";
import {columnType, optionType, sortingType} from "../../common/types";
import MenuDeleteAction from "../common/buttons/MenuDeleteAction";
import {AddNewButton, RefreshButton} from "../common/buttons/ToolbarButtons";
import {LoadingIndicator, Transition} from "../common/components";
import ClosableDialogTitle from "../common/dialog/ClosableDialogTitle";
import {
  StyledSelectionCell,
  StyledSelectionCellDisabled,
  StyledTableSelection,
  StyledVirtualTable,
} from "../common/grid/cell";
import ColumnResizingHeader from "../common/grid/ColumnResizingHeader";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import StyledSearchPanel from "../common/grid/StyledSearchPanel";
import {useGlobalStyles} from "../common/styles";
import {ToolbarItem, ToolbarRoot} from "../common/Toolbar";
import DirectoryForm from "./DirectoryForm";
import EventFilter from "./EventFilter";
import EventForm from "./EventForm";
import {AppContext} from "../App-context";

export const SeimFieldFormatter = (data: any) => {
  let result = <React.Fragment />;
  const seim: {[i: number]: string} = {
    1: "Участвует в журналировании SEIM",
    0: "Не участвует в журналировании SEIM",
  };
  if (data.value == 1) {
    result = <CheckIcon fontSize="small" style={{color: "green"}} />;
  } else {
    result = <CloseIcon fontSize="small" />;
  }
  return <Tooltip title={seim[data.value]}>{result}</Tooltip>;
};

export const defaultEvent = {
  id_type_user: undefined,
  id_type_property: undefined,
  id_type_by: 1,
  id_type_level: undefined,
  editable: 1,
  status: EVENT_IS_ACTIVE,
  event_code: "",
  event_name: "",
};

export type filterType = {
  id_type_user: undefined | number;
  id_type_property: undefined | number;
  id_type_by: undefined | number;
  id_type_level: undefined | number;
  status: string;
};

const CheckFormatter = (data: any) =>
  data.value === 1 ? (
    <span style={{color: "green"}}>Да</span>
  ) : (
    <span style={{color: "red"}}>Нет</span>
  );

const columns: columnType = [
  {name: "id_event", title: "ID"},
  {name: "event_name", title: "Наименование"},
  {name: "event_code", title: "Код"},
  {
    name: "type_user_name",
    title: "Классификатор",
  },
  {name: "type_property_name", title: "Тип"},
  {name: "type_level_name", title: "Хранение"},
  {name: "status", title: "Активно"},
  {
    name: "log_event",
    title: "Журнал событий",
    getCellValue: (row: EventType) => (
      <Link href={`/log_events/${row.id_event}/`}>Перейти к журналу</Link>
    ),
  },
  {name: "is_seim_log", title: "SEIM журнал"},
];
const columnExtensions = [
  {columnName: "id_event", width: 100},
  {columnName: "event_name", width: 380},
  {columnName: "event_code", width: 200},
  {columnName: "type_user_name", width: 330},
  {columnName: "type_property_name", width: 130},
  {columnName: "type_level_name", width: 130},
  {columnName: "status", width: 100},
  {columnName: "is_seim_log", width: 170},
  {columnName: "log_event", width: 200},
];

const sortingStateColumnExtensions = [
  {columnName: "log_event", sortingEnabled: false},
];

type Props = {
  loading: boolean;
  setLoading: (l: boolean) => void;
  rows: Array<EventType>;
  isSystemShowed: boolean;
  setIsSystemShowed: Function;
  update: () => void;
  userDirsOptions: Array<optionType>;
  propertyDirsOptions: Array<optionType>;
  byDirsOptions: Array<optionType>;
  levelDirsOptions: Array<optionType>;
  updateDirectoryOptions: () => void;
  filters: filterType;
  setFilters: Function;
  userDirs: Array<DirectoryType>;
  selection: Array<number | string>;
  setSelection: Function;
};

const EventTab = (props: Props) => {
  const {
    loading,
    rows,
    isSystemShowed,
    setIsSystemShowed,
    update,
    setLoading,
    userDirsOptions,
    propertyDirsOptions,
    byDirsOptions,
    levelDirsOptions,
    updateDirectoryOptions,
    filters,
    setFilters,
    userDirs,
    selection,
    setSelection,
  } = props;

  const {updateEvents} = useContext(AppContext);

  const [instanceToModify, setInstanceToModify] = useState(
    undefined as EventType | undefined
  );

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage<
    Array<string>
  >("event_columns", ["id_event", "status"]);

  const [sorting, setSorting] = useLocalStorage<sortingType>("event_sorting", [
    {columnName: "type_user_name", direction: "desc"},
  ]);

  const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder(
    "event_ordering",
    columns.map((row) => row.name)
  );

  const [selectedUserDir, setSelectedUserDir] = useState(
    undefined as undefined | DirectoryType
  );

  const [eventValues, setEventValues] = useState(
    instanceToModify && instanceToModify.id_event
      ? instanceToModify
      : (defaultEvent as EventType)
  );
  useEffect(() => {
    if (instanceToModify && instanceToModify.id_type_user) {
      const dir = userDirs.filter(
        (dir: DirectoryType) => dir.id_dir === instanceToModify.id_type_user
      )[0];
      setSelectedUserDir(dir);
      setEventValues(instanceToModify);
    }
  }, [
    instanceToModify && instanceToModify.id_type_user,
    instanceToModify && instanceToModify.id_event,
  ]);

  const [eventFormOpen, setEventFormOpen] = useState(false as boolean);

  const [directoryFormOpen, setDirectoryFormOpen] = useState(false as boolean);

  const [directoryModifyFormOpen, setDirectoryModifyFormOpen] = useState(
    false as boolean
  );

  const classes = useGlobalStyles();

  return (
    <Fragment>
      {instanceToModify && (
        <Dialog
          open={true}
          onClose={() => setInstanceToModify(undefined)}
          keepMounted
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
        >
          <ClosableDialogTitle onClose={() => setInstanceToModify(undefined)}>
            Изменить событие
          </ClosableDialogTitle>
          <DialogContent>
            <EventForm
              loading={loading}
              setLoading={setLoading}
              values={eventValues}
              setValues={setEventValues}
              handleClose={() => setInstanceToModify(undefined)}
              update={update}
              event={instanceToModify}
              updateDirectoryOptions={updateDirectoryOptions}
              userDirsOptions={userDirsOptions}
              propertyDirsOptions={propertyDirsOptions}
              levelDirsOptions={levelDirsOptions}
              setDirectoryFormOpen={setDirectoryFormOpen}
              setDirectoryModifyFormOpen={setDirectoryModifyFormOpen}
              userDirs={userDirs}
              setSelectedUserDir={setSelectedUserDir}
              selectedUserDir={selectedUserDir}
              checkPin
            />
          </DialogContent>
        </Dialog>
      )}
      {eventFormOpen && (
        <Dialog
          open={eventFormOpen}
          onClose={() => !loading && setEventFormOpen(false)}
          keepMounted
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
        >
          <ClosableDialogTitle
            onClose={() => !loading && setEventFormOpen(false)}
          >
            Добавить событие
          </ClosableDialogTitle>
          <DialogContent>
            <EventForm
              loading={loading}
              setLoading={setLoading}
              values={eventValues}
              setValues={setEventValues}
              handleClose={() => setEventFormOpen(false)}
              update={update}
              updateDirectoryOptions={updateDirectoryOptions}
              userDirsOptions={userDirsOptions}
              propertyDirsOptions={propertyDirsOptions}
              levelDirsOptions={levelDirsOptions}
              setDirectoryFormOpen={setDirectoryFormOpen}
              setDirectoryModifyFormOpen={setDirectoryModifyFormOpen}
              userDirs={userDirs}
              setSelectedUserDir={setSelectedUserDir}
              selectedUserDir={selectedUserDir}
            />
          </DialogContent>
        </Dialog>
      )}
      {directoryFormOpen && (
        <Dialog
          open={true}
          onClose={() => !loading && setDirectoryFormOpen(false)}
          keepMounted
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
        >
          <ClosableDialogTitle
            onClose={() => !loading && setDirectoryFormOpen(false)}
          >
            Добавить классификатор
          </ClosableDialogTitle>
          <DialogContent>
            <DirectoryForm
              loading={loading}
              setLoading={setLoading}
              handleClose={() => setDirectoryFormOpen(false)}
              eventValues={eventValues}
              setEventValues={setEventValues}
              open={directoryFormOpen}
              update={updateDirectoryOptions}
              updateEvents={update}
              setSelectedUserDir={setSelectedUserDir}
            />
          </DialogContent>
        </Dialog>
      )}
      {directoryModifyFormOpen && (
        <Dialog
          open={true}
          onClose={() => !loading && setDirectoryModifyFormOpen(false)}
          keepMounted
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
        >
          <ClosableDialogTitle
            onClose={() => !loading && setDirectoryModifyFormOpen(false)}
          >
            Изменить классификатор
          </ClosableDialogTitle>
          <DialogContent>
            <DirectoryForm
              loading={loading}
              setLoading={setLoading}
              handleClose={() => setDirectoryModifyFormOpen(false)}
              eventValues={eventValues}
              setEventValues={setEventValues}
              open={directoryFormOpen}
              update={updateDirectoryOptions}
              directory={selectedUserDir}
              updateEvents={update}
              setSelectedUserDir={setSelectedUserDir}
            />
          </DialogContent>
        </Dialog>
      )}
      <Grid
        columns={columns}
        rows={
          isSystemShowed
            ? rows
            : rows.filter((event: EventType) => Boolean(event.type_user_name))
        }
        getRowId={(row) => row.id_event}
      >
        {/*Sorting*/}
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={sortingStateColumnExtensions}
        />
        <IntegratedSorting />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />
        <Template name="toolbarContent">
          <ToolbarItem>
            <AddNewButton onClick={() => setEventFormOpen(true)} />
          </ToolbarItem>
          <ToolbarItem>
            <MenuDeleteAction
              selection={selection}
              update={() => {
                update();
                updateEvents();
              }}
              action={deleteEvents}
              checkPin
            />
          </ToolbarItem>
          <ToolbarItem>
            <EventFilter
              loading={loading}
              isSystemShowed={isSystemShowed}
              setIsSystemShowed={setIsSystemShowed}
              setLoading={setLoading}
              filters={filters}
              setFilters={setFilters}
              userDirsOptions={userDirsOptions}
              propertyDirsOptions={propertyDirsOptions}
              byDirsOptions={byDirsOptions}
              levelDirsOptions={levelDirsOptions}
              setDirectoryFormOpen={setDirectoryFormOpen}
              setDirectoryModifyFormOpen={setDirectoryModifyFormOpen}
            />
          </ToolbarItem>
          <ToolbarItem>
            <RefreshButton disabled={loading} update={update} />
          </ToolbarItem>
          <TemplatePlaceholder />

          <TemplatePlaceholder />
        </Template>

        {/*Search*/}
        <SearchState />
        <StyledSearchPanel />
        <IntegratedFiltering />

        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => {
            return (
              <VirtualTable.Row
                onDoubleClick={() => {
                  row.id_type_user && setInstanceToModify(row);
                }}
                className={
                  row.id_type_user ? classes.gridRow : classes.gridRowSelected
                }
                {...restProps}
              />
            );
          }}
        />

        {/*Column Reordering*/}
        <DragDropProvider />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        {/*Column Visibility*/}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <ColumnResizingHeader
          columnExtensions={columnExtensions}
          localStorageName="event"
          autoWidthColumnName={"event_code"}
        />

        {/*Rows Selection*/}
        <SelectionState
          selection={selection}
          onSelectionChange={(selection: Array<string | number>) =>
            setSelection(selection)
          }
        />
        <IntegratedSelection />
        <StyledTableSelection
          cellComponent={({row, ...restProps}: any) =>
            row.id_type_user === null ? (
              <StyledSelectionCellDisabled {...restProps} disabled />
            ) : (
              <StyledSelectionCell {...restProps} />
            )
          }
          showSelectAll
        />

        <DataTypeProvider
          for={["is_seim_log"]}
          formatterComponent={SeimFieldFormatter}
        />

        <DataTypeProvider
          for={["status"]}
          formatterComponent={CheckFormatter}
        />
        {loading && <LoadingIndicator />}
      </Grid>
    </Fragment>
  );
};

export default EventTab;
