import Column from "../../Tables/Column";
import {ProfilePolicy} from "../../../common/APIRequests/policy/types";

const ProfilePolicyColumns = [
  new Column("id_prof_pol", "ID", 70, {hiddenByDefault: true}),
  new Column("id_prof", "ID Профиля", 70, {hiddenByDefault: true}),
  new Column("id_pol", "ID Политики", 70, {hiddenByDefault: true}),
  new Column("policy_name", "Наименование", 300),
  new Column("description", "Описание", 200, {auto: true}),
  new Column("value", "Значение", 200, {
    getCellValue: (row: ProfilePolicy) => {
      if (row.value_type === "boolean") {
        return row.value === "1" ? "true" : "false";
      } else return row.value;
    },
  }),
  new Column("value_type", "Тип значения", 200, {hiddenByDefault: true}),
  new Column("possible_values", "Возможные значения", 200, {
    hiddenByDefault: true,
  }),
];

export default ProfilePolicyColumns;
