import {baseGetRowsRequest} from "../base/baseGetRowsRequest";

export type UserReportFilter = {
  id_report_filter_user: number;
  id_type_filter: number;
  id_user_report: number;
  value: string | null;
  name: string;
  system_name: string;
  value_type: "boolean" | "string" | "int" | "date";
  interval_type: number | null;
};

const getUserReportFilters = async (id_user_report: number) =>
  baseGetRowsRequest<UserReportFilter>("/report_filter_user/", {
    filters: {id_user_report},
  });

export default getUserReportFilters;
