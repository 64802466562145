import SwitchWithPin from "../common/buttons/SwitchWithPin";
import {useState} from "react";

type TableRowSwitchProps = {
  checked: boolean;
  onCheck: () => Promise<void>;
};

const TableRowSwitch = (props: TableRowSwitchProps) => {
  const [loading, setLoading] = useState(false);
  const onChange = () => {
    setLoading(true);
    props.onCheck().finally(() => setLoading(true));
  };
  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <SwitchWithPin
          disabled={loading}
          onChange={onChange}
          checked={props.checked}
        />
      </div>
    </>
  );
};

export default TableRowSwitch;
